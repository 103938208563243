import { zonedTimeToUtc } from "@gymflow/helpers";
import { useMutation } from "@tanstack/react-query";

export function useClassesRunReportCsv({
  api,
  tz,
}: {
  api: {
    memberApi: {
      classesRunCsv: (params: {
        dateFrom?: string;
        dateTo?: string;
        activityId?: number[];
        eventHost?: string[];
      }) => Promise<any>;
    };
  };
  startDate?: string;
  endDate?: string;
  activityId?: number[];
  eventHostId?: string[];
  tz: string;
}) {
  const csvMutation = useMutation({
    mutationFn: async ({
      startDate,
      endDate,
      activityId,
      eventHostId,
    }: {
      startDate?: string;
      endDate?: string;
      activityId?: number[];
      eventHostId?: string[];
    }) => {
      const utcStart = zonedTimeToUtc(startDate as string, tz);
      const utcEnd = zonedTimeToUtc(endDate as string, tz);
      const result = await api.memberApi.classesRunCsv({
        dateFrom: utcStart,
        dateTo: utcEnd,
        activityId,
        eventHost: eventHostId,
      });
      return result.data as string;
    },
  });
  return csvMutation;
}
