import React, { ReactNode } from "react";

import { FormLabel } from "./FormLabel";

export interface LabeledFormProps {
  label: string;
  tooltip?: string;
  children: ReactNode;
}

export const LabeledForm: React.FC<LabeledFormProps> = ({
  children,
  label,
  tooltip,
}) => {
  return (
    <div className="flex flex-col gap-y-1">
      <FormLabel label={label} tooltip={tooltip} />
      {children}
    </div>
  );
};
