import { TaskDTO } from '@gymflow/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import merge from 'lodash/merge';
import { deadlineDatesToZonedTime } from './deadlineDatesToZonedTime';

import { taskQueryKeys } from './taskQueryKeys';

export function useTask(
  { api, taskId, tz }: {
    api: {
      taskApi:
      { findById: (arg0: number) => Promise<any> }
    },
    taskId: number,
    tz: string
  }, opts?: UseQueryOptions<TaskDTO | null>
) {
  const result = useQuery({
    queryKey: taskQueryKeys.details(taskId),
    queryFn: async () => {
      const result = await api.taskApi.findById(taskId);
      return result.data as TaskDTO;
    },
    enabled: !!taskId,
    initialData: null,
    select: (data: TaskDTO | null) => {
      if (data) {
        return merge({}, data, deadlineDatesToZonedTime(data, tz)) as TaskDTO;
      }
      return data;
    },
    ...opts
  });

  return result;
}
