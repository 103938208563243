import { QueryKey } from "@tanstack/react-query";

export const memberAsMemberQueryKeys = {
  all: () => ["memberAsMember"] as const,
  details: (memberId: string) =>
    [...memberAsMemberQueryKeys.all(), memberId, "details"] as QueryKey,
  paymentMethods: (clubId: number, memberId: string) =>
    [
      ...memberAsMemberQueryKeys.all(),
      memberId,
      "paymentMethods",
      clubId,
    ] as QueryKey,
  invoices: (filters?: Readonly<{ [k: string]: any }>) =>
    [...memberAsMemberQueryKeys.all(), "invoices", filters] as QueryKey,
  invoiceFile: (clubId: number, invoiceNumber: string) =>
    [
      ...memberAsMemberQueryKeys.all(),
      "invoiceFile",
      clubId,
      invoiceNumber,
    ] as QueryKey,
};
