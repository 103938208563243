import PropTypes from "prop-types";
import classNames from "classnames";
import { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";

import { SearchModalResult } from "./SearchModal/SearchModalResult";
import useGymflowModels from "../store";
import { SearchSelectValueContainer } from "./atoms";

const Option = ({ value, ...props }) => {
  return (
    <components.Option {...props}>
      {value && (
        <SearchModalResult title={`${value.firstName} ${value.lastName}`} text={value.email} picture={value.picture} />
      )}
    </components.Option>
  );
};

const MultiUsersSelect = ({ value, onChange, placeholder, className, styles, ...rest }) => {
  const { api } = useGymflowModels();
  const loadOptions = async (term, _, { page }) => {
    if (term === "") {
      return { options: [{ label: "Type a name to search", isDisabled: true }], hasMore: false };
    }
    const { data } = await api.memberApi.globalSearch(term, undefined, 20);
    const rows = data.map((user) => {
      return {
        label: `${user.firstName} ${user.lastName}`,
        value: user,
      };
    });
    return {
      options: rows,
      hasMore: false,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <AsyncPaginate
      components={{ Option, ValueContainer: SearchSelectValueContainer }}
      additional={{
        page: 0,
      }}
      isMulti={true}
      className={classNames("react-select react-select-sm info", className)}
      classNamePrefix="react-select"
      loadOptions={loadOptions}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      menuContainerStyle={{ zIndex: "99999 !important" }}
      styles={{
        control: (provided) => ({
          ...provided,
          background: "#f5f6f6 !important",
          borderRadius: 20,
          border: 0,
          minHeight: 35,
          height: "unset !important",
        }),
        valueContainer: (provided) => ({
          ...provided,
          paddingLeft: "0px !important",
        }),
        multiValue: (provided) => ({
          ...provided,
          background: "#6186db !important",
          color: "white !important",
          border: "0 !important",
          borderRadius: "16px !important",
          padding: "0 4px",
          height: 24,
        }),
        multiValueLabel: (provided) => ({
          ...provided,
          color: "white !important",
        }),
        multiValueRemove: (provided) => ({
          ...provided,
          background: "white !important",
          color: "#6186db !important",
          borderRadius: "50%",
          padding: 2,
          margin: "auto",
          width: 16,
          height: 16,
        }),
        ...styles,
      }}
      {...rest}
    />
  );
};

MultiUsersSelect.defaultProps = {
  placeholder: "Search to select an user to associate with the task",
};

MultiUsersSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(null)]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default MultiUsersSelect;
