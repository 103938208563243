import { ApiListResponse, InvoiceDTO, InvoiceStatus } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { memberAsMemberQueryKeys } from "./memberAsMemberQueryKeys";

export function useMemberInvoiceListAsMember(
  {
    api,
    opts,
  }: {
    api: {
      profileApi: {
        findInvoices: (_: unknown, opts: any) => Promise<any>;
      };
    };
    opts?: InvoiceListAsMemberFilter;
  },
  queryOpts?: UseQueryOptions<ApiListResponse<InvoiceDTO>>,
) {
  const result = useQuery({
    queryKey: memberAsMemberQueryKeys.invoices(opts),
    queryFn: async () => {
      const result = await api.profileApi.findInvoices(undefined, opts);
      return result.data as ApiListResponse<InvoiceDTO>;
    },
    initialData: defaultPage<InvoiceDTO>,
    ...queryOpts,
  });
  return result;
}

export interface InvoiceListAsMemberFilter {
  readonly extraParams?: {
    readonly userId?: string;
    readonly status: InvoiceStatus[];
    readonly [k: string]: any;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc: boolean;
  };
}
