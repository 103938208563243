import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  clubStaleTime,
  useFacility,
  useFacilityCategories,
  useFacilityCreate,
  useFacilityEdit,
  useFacilityUpdatePicture,
} from "@gymflow/api";
import { FormikInput, useRecordForm } from "@gymflow/common";
import { useFormik } from "formik";
import omit from "lodash/omit";
import { createContext, ReactNode, useCallback, useState } from "react";
import { Tooltip } from "react-tooltip";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { getDefaultsFromZodSchema } from "../../../helpers/zod";
import useGymflowModels from "../../../store";
import {
  Avatar,
  Button,
  PaginatedSelect,
  SlideSideBar,
  UploadArea,
} from "../../atoms";
import { FacilityMapper } from "./FacilityMapper";
import { FacilityZodSchema } from "./FacilityZodSchema";

interface FacilitySideBarContextType {
  openNew: (params?: { onClose?: () => void }) => void;
  openExisting: (params: { facilityId: number; onClose?: () => void }) => void;
}

export const FacilitySideBarContext = createContext<FacilitySideBarContextType>(
  {
    openNew: () => {
      throw new Error("function called outside of context");
    },
    openExisting: () => {
      throw new Error("function called outside of context");
    },
  },
);

export function FacilitySideBarFormProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { api } = useGymflowModels();

  const [sidebarState, setSidebarState] = useState<{
    isOpen: boolean;
    editingId?: number;
    onClose?: () => void;
  }>({
    isOpen: false,
  });

  const { data: editing } = useFacility({
    api,
    facilityId: sidebarState.editingId,
  });
  const newFacilityMutation = useFacilityCreate({ api });
  const editFacilityMutation = useFacilityEdit({ api });
  const updatePictureMutation = useFacilityUpdatePicture({ api });
  const { data: categories } = useFacilityCategories(
    { api },
    { staleTime: clubStaleTime },
  );
  const openNew = useCallback((params?: { onClose?: () => void }) => {
    setSidebarState({ isOpen: true, onClose: params?.onClose });
  }, []);
  const openExisting = useCallback(
    ({ facilityId, onClose }: { facilityId: number; onClose?: () => void }) => {
      setSidebarState({ isOpen: true, editingId: facilityId, onClose });
    },
    [],
  );

  const onClose = useCallback(() => {
    if (sidebarState?.onClose) {
      sidebarState.onClose();
    }
    setSidebarState({ isOpen: false });
  }, [sidebarState]);

  const { initialValues, getPatchedValues, getValues } = useRecordForm({
    record: !!sidebarState.editingId && editing ? editing : null,
    fields: getDefaultsFromZodSchema(FacilityZodSchema),
    mapper: new FacilityMapper(),
  });
  const formik = useFormik<{
    name?: string;
    description?: string;
    "facility-category-id"?: number;
    picture?: { blob: string };
  }>({
    enableReinitialize: true,
    initialValues,
    validationSchema: toFormikValidationSchema(FacilityZodSchema),
    onSubmit: async (values) => {
      if (sidebarState.editingId) {
        const patched = getPatchedValues(values);
        await editFacilityMutation.mutateAsync({
          facilityId: sidebarState.editingId,
          patchedFields: omit(patched, "picture"),
        });
        if (patched?.picture?.name) {
          await updatePictureMutation.mutateAsync({
            facilityId: sidebarState.editingId,
            file: {
              blob: patched.picture.blob,
              name: patched.picture.file.name,
            },
          });
        }
      } else {
        const newValues = getValues(values);
        const result = await newFacilityMutation.mutateAsync({
          name: newValues.name,
          facilityCategoryId: newValues.facilityCategoryId,
          description: newValues.description,
        });
        if (result.id && newValues?.picture?.name) {
          await updatePictureMutation.mutateAsync({
            facilityId: result.id,
            file: {
              blob: newValues.picture.blob,
              name: newValues.picture.file.name,
            },
          });
        }
      }
      onClose();
      formik.resetForm();
    },
  });
  const selectedCategory =
    formik.values["facility-category-id"] && categories
      ? categories.find((c) => c.id === formik.values["facility-category-id"])
      : null;

  return (
    <FacilitySideBarContext.Provider value={{ openNew, openExisting }}>
      <SlideSideBar
        isOpen={sidebarState.isOpen}
        hide={onClose}
        isLoading={false}
      >
        <div className="flex h-full max-h-full flex-col overflow-hidden">
          <div className="flex h-full max-h-full flex-col overflow-hidden">
            <div className="flex flex-col justify-between border-b border-gray-200 p-8">
              <div className="mb-1 flex flex-row items-center justify-between">
                <div className="text-xl font-semibold text-gray-900">
                  {sidebarState.editingId ? "Edit" : "Create"} Facility
                </div>

                <FontAwesomeIcon
                  onClick={() => {
                    onClose();
                  }}
                  className="cursor-pointer text-xl text-gray-600"
                  icon={faClose}
                />
              </div>
              <div className="text-sm font-medium text-gray-600">
                {sidebarState.editingId ? "Edit" : "Create"} a facility to host
                classes or appointments in.
              </div>
            </div>

            <div className="flex max-h-full flex-col gap-4 overflow-y-auto p-8">
              <div className="flex flex-col gap-y-1">
                <label className="!m-0 text-gray-700" htmlFor="name">
                  Name
                </label>
                <TypedFormikInput
                  placeholder="Enter a facility name."
                  autoComplete="off"
                  maxLength="128"
                  name="name"
                  type="text"
                  formikProps={formik}
                  className="!p-3 text-gray-700"
                />
              </div>

              <div className="flex flex-col gap-1.5">
                <label
                  className="!m-0 flex flex-row items-center gap-x-2 text-gray-700"
                  htmlFor="facility-category-id"
                >
                  Category
                  <FontAwesomeIcon
                    data-tooltip-id="category-tooltip"
                    data-tooltip-content="Used to filter views on the calendar and apps only."
                    className="cursor-pointer"
                    icon={faQuestionCircle}
                  />
                  <Tooltip
                    className="!bg-primary-700 flex max-w-sm flex-col items-center rounded-lg text-center text-xs"
                    id="category-tooltip"
                  />
                </label>

                <PaginatedSelect
                  value={
                    selectedCategory
                      ? {
                          label: selectedCategory.name,
                          value: selectedCategory.id,
                        }
                      : null
                  }
                  onChange={(newValue) => {
                    formik.setFieldValue(
                      "facility-category-id",
                      newValue.value,
                    );
                  }}
                  loadOptions={() => {
                    return Promise.resolve({
                      options: (categories || []).map((category) => {
                        return { label: category.name, value: category.id };
                      }),
                    });
                  }}
                />

                <label className="text-error-500">
                  {formik.touched["facility-category-id"] &&
                    (formik.errors["facility-category-id"] as any)}
                </label>
              </div>

              <div className="flex flex-col gap-1.5">
                <label className="!m-0 text-gray-700" htmlFor="description">
                  Description
                </label>
                <TypedFormikInput
                  placeholder="Enter a facility description."
                  autoComplete="off"
                  maxLength="128"
                  name="description"
                  type="textarea"
                  formikProps={formik}
                  className="!p-3 text-gray-700"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <label
                  className="!m-0 flex flex-row items-center gap-x-2 text-gray-700"
                  htmlFor="picture"
                >
                  Picture
                  <FontAwesomeIcon
                    data-tooltip-id="picture-tooltip"
                    data-tooltip-content="Add an image or graphic of the space which will be shown in the members app."
                    className="cursor-pointer"
                    icon={faQuestionCircle}
                  />
                  <Tooltip
                    className="!bg-primary-700 flex max-w-sm flex-col items-center rounded-lg text-center text-xs"
                    id="picture-tooltip"
                  />
                </label>
                <div className="grid grid-cols-8 gap-2">
                  <div className="col-span-2">
                    <Avatar
                      url={formik.values.picture?.blob}
                      className="!h-[64px] !w-[64px]"
                    />
                  </div>
                  <UploadArea
                    className="col-span-6"
                    onChange={(file: any) => {
                      formik.setFieldValue("picture", file);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex h-20 flex-row items-center gap-2 border-t border-gray-200 px-6">
            <Button className="flex-1" onClick={onClose}>
              Cancel
            </Button>
            <Button
              className="flex-1"
              intent="primary"
              onClick={() => {
                formik.submitForm();
              }}
            >
              {sidebarState.editingId ? "Edit" : "Create"}
            </Button>
          </div>
        </div>
      </SlideSideBar>
      {children}
    </FacilitySideBarContext.Provider>
  );
}

const TypedFormikInput = FormikInput as React.FC<{ [key: string]: any }>;
