import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { ReactNode } from "react";

import { Spinner } from "./Spinner";

export interface SlideSideBarProps {
  hide: () => void;
  isOpen: boolean;
  isLoading?: boolean;
  children?: ReactNode;
  className?: string;
  unmount?: boolean;
}
export function SlideSideBar({
  children,
  isOpen,
  hide,
  isLoading,
  unmount,
  className,
}: SlideSideBarProps) {
  return (
    <>
      <div
        onClick={hide}
        className={classNames(
          `pointer-events-none absolute inset-0 z-[10000] backdrop-blur-[2px]`,
          { hidden: !isOpen },
        )}
      />
      <div
        onClick={hide}
        className={`${
          isOpen ? "opacity-10" : "pointer-events-none opacity-0"
        } bg-primary-700 absolute inset-0 z-[10001]`}
      />
      <Transition
        className={classNames(
          "absolute inset-y-0 right-0 z-[10002] w-96 max-w-full overflow-hidden overflow-y-auto bg-white",
          className,
        )}
        show={isOpen}
        enter="transition-transform"
        enterFrom="translate-x-full delay-300 w-0"
        enterTo="translate-x-0"
        leave="transition-transform"
        leaveFrom="translate-x-0 delay-300 w-0"
        leaveTo="translate-x-full"
        unmount={unmount}
      >
        <div
          className={classNames("absolute w-full pt-28", {
            hidden: !isLoading,
          })}
        >
          <Spinner className="!h-12 !w-12" />
        </div>
        <div className={classNames("h-full", { "opacity-25": isLoading })}>
          {children}
        </div>
      </Transition>
    </>
  );
}
