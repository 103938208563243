import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useMemberCreate(
  {
    api,
  }: {
    api: {
      memberApi: {
        create: (arg1: unknown) => Promise<any>;
        createWithMinimumDetails: (arg1: unknown) => Promise<any>;
      };
    };
  },
  mutationOpts?: UseMutationOptions<unknown, unknown, { [k: string]: any }>,
) {
  const queryClient = useQueryClient();
  const createMutation = useMutation({
    mutationFn: (newMember: { [k: string]: any }) =>
      api.memberApi.create(newMember),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
    },
    ...mutationOpts,
  });

  const createWithMinimumDetailsMutation = useMutation({
    mutationFn: (newMember: { [k: string]: any }) =>
      api.memberApi.createWithMinimumDetails(newMember),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
    },
    ...mutationOpts,
  });

  return {
    createMutation,
    createWithMinimumDetailsMutation,
  };
}
