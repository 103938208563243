import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useProductList } from "@gymflow/api";
import { cn, formatCurrency } from "@gymflow/helpers";
import React, { useContext, useEffect, useState } from "react";

import searchIcon from "../../../assets/img/search-lg.svg";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { Button } from "../atoms";
import { ProductFormSidebarProviderContext } from "../Settings/Product/ProductFormSidebarProvider";
import { ShopItemPickerProps } from "./ShopItemPicker";
import { ShopCartType } from "./ShopTypes";

export type ShopProductSelectProps = {
  cart?: ShopCartType;
  setCart: ShopItemPickerProps["setCart"];
  hideShopSidebar: () => void;
  showShopSidebar: () => void;
};

export const ShopProductSelect: React.FC<ShopProductSelectProps> = ({
  cart,
  setCart,
  hideShopSidebar,
  showShopSidebar,
}) => {
  const { api } = useGymflowModels();
  const clubSettings = useClubSettings();
  const { data } = useProductList({
    api,
    opts: {
      sort: { field: "createdDate", desc: true },
      extraParams: {
        status: "AVAILABLE",
        unpaged: true,
      },
    },
  });
  const { showProductForm } = useContext(ProductFormSidebarProviderContext);
  const [query, setQuery] = useState("");
  useEffect(() => {
    if (cart?.type !== "PRODUCT") {
      setCart(
        {
          type: "PRODUCT",
          payload: [],
        },
        true,
      );
    }
  }, [setCart, cart]);
  if (cart?.type !== "PRODUCT") return null;
  return (
    <div className="pt-[2px]">
      <div className="flex flex-row items-center justify-between px-6 py-4">
        <img src={searchIcon} alt="search-icon" className="h-5" />
        <input
          placeholder="Search..."
          className="flex h-11 w-full pl-2 placeholder:text-base placeholder:text-gray-900 focus:outline-none"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <Button
          className="mt-0 min-w-fit"
          onClick={() => {
            hideShopSidebar();
            showProductForm({
              onClose: () => {
                showShopSidebar();
              },
            });
          }}
        >
          Create New
        </Button>
      </div>
      {data?.content
        .filter(
          (e) =>
            e.name.toLowerCase().includes(query.toLowerCase()) ||
            e.price.toString().includes(query),
        )
        ?.map((product) => {
          const productInCart = cart?.payload?.find(
            (e) => e.product.id === product.id,
          );
          return (
            <div
              key={product.id}
              className={cn(
                "-z-10 flex w-full items-center justify-between border-t border-gray-100 px-6 py-4",
              )}
            >
              <div className="flex flex-col">
                <div className="text-sm text-gray-900">{product.name}</div>
                <div className="text-sm text-gray-600">
                  SOH:{" "}
                  {product.stockType === "LIMITED"
                    ? product.stockQuantity
                    : "Unlimited"}
                </div>
              </div>
              <div className="flex h-full flex-row items-center gap-x-2">
                <div className="mb-auto flex flex-col items-start justify-start font-semibold">
                  {formatCurrency(product.price, clubSettings.defaultCurrency)}
                </div>
                <div className="flex flex-row items-center">
                  <Button
                    className="mt-0 h-9 min-h-0 px-0"
                    disabled={(productInCart?.quantity ?? 0) === 0}
                    onClick={() => {
                      if ((productInCart?.quantity ?? 0) === 0) {
                        return;
                      }
                      setCart(
                        {
                          type: "PRODUCT",
                          payload: cart?.payload?.map((e) => {
                            if (e.product.id === product.id) {
                              const newItem = {
                                ...e,
                                quantity: e.quantity - 1,
                              };
                              return newItem;
                            }
                            return e;
                          }),
                        },
                        true,
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faMinus}
                      className="h-4 w-4 hover:opacity-90"
                    />
                  </Button>
                  <div className="flex w-8 items-center justify-center text-center font-semibold">
                    {productInCart?.quantity ?? 0}
                  </div>

                  <Button
                    className="mt-0 h-9 min-h-0 px-0"
                    disabled={
                      productInCart?.quantity === product.stockQuantity &&
                      product.stockType === "LIMITED"
                    }
                    onClick={() => {
                      if (
                        productInCart?.quantity === product.stockQuantity &&
                        product.stockType === "LIMITED"
                      ) {
                        return;
                      }
                      const newPayload = cart?.payload?.map((e) => {
                        if (e.product.id === product.id) {
                          const newItem = {
                            ...e,
                            quantity: e.quantity + 1,
                          };
                          return newItem;
                        }
                        return e;
                      });
                      if (
                        !newPayload.some((e) => e.product.id === product.id)
                      ) {
                        newPayload.push({
                          product,
                          quantity: 1,
                        });
                      }
                      setCart(
                        {
                          type: "PRODUCT",
                          payload: newPayload,
                        },
                        true,
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="h-4 w-4 hover:opacity-90"
                    />
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
