import React, { useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';

const LaneHeader = ({ id, index, noDragDrop, onDragChange, children }) => {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag({
    type: 'lane',
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    onDragChange && onDragChange(isDragging);
  }, [isDragging, onDragChange])

  drag(ref);

  return (
    <div
      ref={!noDragDrop ? ref : null}
      className="d-flex justify-content-between"
      style={{ cursor: noDragDrop ? 'default' : 'move' }}
    >
      {children}
    </div>
  );
};

export default LaneHeader;

