import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useCheckInMutation,
  useCheckInQuery,
  useCheckOutMutation,
  useClub,
  useMember,
} from "@gymflow/api";
import classNames from "classnames";
import { capitalize } from "lodash";
import React, { useContext } from "react";
import { useHistory } from "react-router";

import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { ModalContext, useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { ConfirmModal } from "../templates";
import { Button } from "./Button";
import { UserCheckInIcon } from "./icons";
import { Spinner } from "./Spinner";

export interface CheckInButtonProps {
  userMemberId: string;
  hideIfCheckedOut?: boolean;
  hideCheckInText?: boolean;
  onCheckInOutFinished?: (isCheckedIn: boolean) => void;
}

export const CheckInButton: React.FC<CheckInButtonProps> = ({
  userMemberId,
  hideIfCheckedOut,
  hideCheckInText,
  onCheckInOutFinished,
}) => {
  const history = useHistory();
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { data: club } = useClub({ api, clubId: settings.clubId });
  const { data: userContainer } = useMember({
    api,
    memberId: userMemberId,
    tz: club?.timezone!,
  });
  const { data: checkInContainer } = useCheckInQuery({ api, userMemberId });
  const userMember = userContainer?.user;
  const { mutateAsync: checkIn, isSuccess: didCheckIn } = useCheckInMutation(
    {
      api,
    },
    {
      onSuccess: () => onCheckInOutFinished?.(true),
    },
  );
  const { mutateAsync: checkOut, isSuccess: didCheckOut } = useCheckOutMutation(
    { api },
    {
      onSuccess: () => onCheckInOutFinished?.(false),
    },
  );
  const checkedInOrOut = didCheckIn || didCheckOut;
  const userActiveSubscription = userMember?.subscriptions?.find(
    (e) => e.active && e.membershipBean,
  );
  const { setModal, hide } = useContext(ModalContext);
  const { createMemberLink } = usePortalRoutes();
  if (!userMember || !checkInContainer) return <Spinner />;
  if (!checkInContainer.isCheckedIn && hideIfCheckedOut) return null;
  return (
    <Button
      disabled={checkedInOrOut}
      onClick={async () => {
        if (checkedInOrOut) return;
        if (!checkInContainer?.isCheckedIn) {
          if (!userActiveSubscription) {
            setModal(
              <ConfirmModal
                title="No Membership"
                cancelText="View"
                confirmText="Yes"
                onCancel={() => {
                  hide();
                  history.push(createMemberLink(userMember.id));
                }}
                onConfirm={async () => {
                  await checkIn({
                    userMemberId: userMember.id,
                    payload: { guest: true },
                  });
                  hide();
                }}
              >
                {userMember.firstName} {userMember.lastName} doesn’t have a
                membership, do you want to check-in as a guest?
              </ConfirmModal>,
            );
            return;
          }

          if (userActiveSubscription.status !== "ACTIVE") {
            setModal(
              <ConfirmModal
                title="No Membership"
                cancelText="View"
                confirmText="Yes"
                onCancel={() => {
                  hide();
                  history.push(createMemberLink(userMember.id));
                }}
                onConfirm={async () => {
                  await checkIn({
                    userMemberId: userMember.id,
                    payload: { guest: true },
                  });
                  hide();
                }}
                type="danger"
              >
                {userMember.firstName} {userMember.lastName} subscription is{" "}
                <b>{capitalize(userActiveSubscription.status)}</b> do you still
                want to check in?
              </ConfirmModal>,
            );
            return;
          }
        }
        if (checkInContainer?.isCheckedIn) {
          await checkOut({ userMemberId: userMember.id });
        } else {
          await checkIn({
            userMemberId: userMember.id,
            payload: { guest: false },
          });
        }
      }}
      className={classNames("!mt-0 flex", {
        "bg-success-600/10 !text-success-600 !ring-success-600/40 !border-success-600/20 hover:bg-success-600/10 hover:!text-success-600 hover:!ring-success-600/40 hover:!border-success-600/20 !cursor-default":
          checkedInOrOut,
      })}
      title={
        !checkedInOrOut
          ? checkInContainer?.isCheckedIn
            ? "Check Out"
            : "Check In"
          : didCheckIn
          ? "Checked In"
          : "Checked Out"
      }
    >
      <div className="flex min-w-fit flex-row items-center gap-x-2">
        {!checkedInOrOut && (
          <>
            <UserCheckInIcon className="h-6" />
            {hideCheckInText
              ? ""
              : checkInContainer?.isCheckedIn
              ? "Check Out"
              : "Check In"}
          </>
        )}
        {checkedInOrOut && (
          <>
            <FontAwesomeIcon icon={faCheck} className="text-success-600" />
            {hideCheckInText ? "" : didCheckIn ? "Checked In" : "Checked Out"}
          </>
        )}
      </div>
    </Button>
  );
};
