import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useClubSettings } from "apps/portal/src/providers";
import { useStoreState } from "easy-peasy";
import { forwardRef, useState } from "react";

import useGymflowModels from "../../../store";
import AddPaymentMethodForm from "./AddPaymentMethodForm";

const AddPaymentMethodFormWrapper = forwardRef((props, ref) => {
  const { settingsStore } = useGymflowModels();
  const settings = useClubSettings();
  const { stripeApplicationId } = useStoreState(settingsStore);

  const [stripePromise] = useState(
    loadStripe(settings.stripe_api_key, {
      stripeAccount: stripeApplicationId,
    }),
  );

  return (
    <Elements stripe={stripePromise}>
      <AddPaymentMethodForm ref={ref} {...props} />
    </Elements>
  );
});

AddPaymentMethodFormWrapper.displayName = "AddPaymentMethodFormWrapper";

export default AddPaymentMethodFormWrapper;
