import {
  clubStaleTime,
  useAppointment,
  useAppointmentCreate,
  useAppointmentEdit,
  useClub,
} from "@gymflow/api";
import { NotificationContext } from "@gymflow/common";
import { AppointmentPostDTO, AppointmentStatus } from "@gymflow/types";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

import { ModalContext, useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { HostNotAvailableModal } from "./HostNotAvailableModal";
import { SideBarAppointmentForm } from "./SideBarAppointmentForm";

interface SideBarAppointmentFormProviderProps {
  children: ReactNode;
}

interface SideBarAppointmentFormProviderContextType {
  openEdit: (params: {
    appointmentId: number;
    onClose?: () => Promise<void>;
  }) => void;
  openNew: (params: {
    referenceDate: string;
    host?: { name: string; id: string };
    onClose?: () => Promise<void>;
  }) => void;
}

export const SideBarAppointmentFormProviderContext =
  createContext<SideBarAppointmentFormProviderContextType>({} as any);

export function SideBarAppointmentFormProvider({
  children,
}: SideBarAppointmentFormProviderProps) {
  const { setModal, hide } = useContext(ModalContext);
  const settings = useClubSettings();
  const clubId = settings.clubId;

  const [isLoaded, setIsLoaded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const [sideBarParams, setSideBarParams] = useState<{
    appointmentId?: number;
    onClose?: () => void;
    initialDateAndTime?: string;
    initialHost?: {
      name: string;
      id: string;
    };
  }>();

  const { api } = useGymflowModels();
  const { data: club } = useClub({ api, clubId }, { staleTime: clubStaleTime });
  const { data: editing, isFetching } = useAppointment({
    api,
    appointmentId: sideBarParams?.appointmentId,
  });

  const { notifyDanger } = useContext(NotificationContext);
  const errorHandler = useCallback(
    (e: any, appointment: Omit<AppointmentPostDTO, "status">) => {
      setIsVisible(false);
      if (
        e?.response?.data?.error_message ===
        "This staff has no available slots for this booking time."
      ) {
        setModal(
          <HostNotAvailableModal
            onConfirm={async () => {
              await createAppointmentMutation.mutateAsync({
                ...appointment,
                availabilityValidationIgnored: true,
                status: "BOOKED",
              });
              hide();
              setIsLoaded(false);
            }}
            onCancel={() => {
              hide();
              setIsVisible(true);
            }}
          />,
        );
      } else if (
        e?.response?.data?.error_message ===
        "This facility has no available slots for this booking time."
      ) {
        setModal(
          <HostNotAvailableModal
            onConfirm={async () => {
              await createAppointmentMutation.mutateAsync({
                ...appointment,
                availabilityValidationIgnored: true,
                status: "BOOKED",
              });
              hide();
              setIsLoaded(false);
            }}
            onCancel={() => {
              hide();
              setIsVisible(true);
            }}
          />,
        );
      } else {
        setIsLoaded(false);
        notifyDanger(e);
      }
    },
    [notifyDanger],
  );

  const createAppointmentMutation = useAppointmentCreate({
    api,
    tz: club?.timezone,
  });
  const editAppointmentMutation = useAppointmentEdit({
    api,
    tz: club?.timezone,
  });

  return (
    <SideBarAppointmentFormProviderContext.Provider
      value={{
        openEdit: ({ appointmentId, onClose }) => {
          setSideBarParams({ appointmentId, onClose });
          setIsLoaded(true);
          setIsVisible(true);
        },
        openNew: ({ referenceDate, host, onClose }) => {
          setSideBarParams({
            initialDateAndTime: referenceDate,
            initialHost: host,
            onClose,
          });
          setIsVisible(true);
          setIsLoaded(true);
        },
      }}
    >
      <div>
        <SideBarAppointmentForm
          defaultStartDate={sideBarParams?.initialDateAndTime}
          defaultHost={sideBarParams?.initialHost}
          isVisible={isVisible}
          isLoaded={isLoaded}
          onClose={() => {
            setIsVisible(false);
            setIsLoaded(false);
            if (sideBarParams?.onClose) {
              sideBarParams.onClose();
            }
            setSideBarParams(undefined);
          }}
          value={editing}
          isLoading={isFetching}
          onChange={async (newValues) => {
            try {
              if (sideBarParams?.appointmentId) {
                await editAppointmentMutation.mutateAsync({
                  appointmentId: sideBarParams.appointmentId,
                  patchedFields: newValues,
                });
              } else {
                const newAppointment = {
                  ...newValues,
                  status: "BOOKED" as AppointmentStatus,
                };
                await createAppointmentMutation.mutateAsync(newAppointment);
              }
              setIsLoaded(false);
              setIsVisible(false);
            } catch (e) {
              errorHandler(e, newValues);
            }
            if (sideBarParams?.onClose) {
              sideBarParams.onClose();
            }
          }}
        />
      </div>
      {children}
    </SideBarAppointmentFormProviderContext.Provider>
  );
}
