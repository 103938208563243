// @ts-ignore
import { PARAMETER_TIME_FORMAT } from "@gymflow/common";
import { DATE_ONLY_FORMAT, iso8601WithoutTz } from "@gymflow/helpers";
import parse from "date-fns/parse";
import { format, zonedTimeToUtc } from "date-fns-tz";

export function deadlineDatesToUtc(
  {
    deadlineDate,
    deadlineTime,
  }: { deadlineDate?: string; deadlineTime?: string },
  tz: string,
) {
  const deadlineDateUtc =
    deadlineDate &&
    deadlineTime &&
    zonedTimeToUtc(
      parse(`${deadlineDate}T${deadlineTime}`, iso8601WithoutTz, new Date()),
      tz,
    );

  const result = {
    deadlineDate: deadlineDateUtc && format(deadlineDateUtc, DATE_ONLY_FORMAT),
    deadlineTime:
      deadlineDateUtc && format(deadlineDateUtc, PARAMETER_TIME_FORMAT),
  };

  return result;
}
