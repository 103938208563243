import { UTCDateMini } from "@date-fns/utc";
import { DATE_FORMAT } from "@gymflow/common";
import add from "date-fns/add";
import addYears from "date-fns/addYears";
import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import isLeapYear from "date-fns/isLeapYear";

export const generateDates = ({
  intervalType,
  intervalValue,
  start: startParam,
  end: endParam,
  dateFormat = DATE_FORMAT,
}: {
  intervalType:
    | "years"
    | "months"
    | "weeks"
    | "days"
    | "hours"
    | "minutes"
    | "seconds";
  intervalValue: number;
  start?: Date;
  end?: Date;
  dateFormat?: string;
}) => {
  const start = startParam || new UTCDateMini();
  const end = endParam || addYears(new UTCDateMini(), 1);

  let idx = 1;
  const dates = [format(start, dateFormat)];
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const nextInterval = add(start, { [intervalType]: intervalValue * idx });
    if (isAfter(nextInterval, end)) {
      break;
    }
    dates.push(format(nextInterval, dateFormat));
    idx++;
  }
  return dates;
};

export const isBirthday = (birthday: Date, today = new Date()) => {
  const february = 1;
  if (
    today.getDate() === birthday.getDate() &&
    today.getMonth() === birthday.getMonth()
  ) {
    return true;
  } else if (
    birthday.getMonth() === february &&
    birthday.getDate() === 29 &&
    !isLeapYear(today)
  ) {
    // For people born on the 29th of February, lets celebrate
    // their birthday on the days around it on non leap years
    const isFirstMarch = today.getDate() === 1 && today.getMonth() === 2;
    const isLastFebruary =
      today.getDate() === 28 && today.getMonth() === february;
    if (isFirstMarch || isLastFebruary) {
      return true;
    }
  }
  return false;
};
