import { cn } from "@gymflow/helpers";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useContext } from "react";

import { ModalContext } from "../../../providers";
import { DotsVerticalIcon } from "../../atoms";
import { NewUserSideBarProviderContext } from "../../molecules";
import { LeadStatusFormModal } from "./LeadStatusFormModal";

export function LeadPipelineActionsDropdown({
  openFiltersSidebar,
}: {
  openFiltersSidebar: () => void;
}) {
  const { setModal, hide: hideModal } = useContext(ModalContext);

  const { open: openNewUserSideBar } = useContext(
    NewUserSideBarProviderContext,
  );
  return (
    <Menu as="div" className="relative z-[9999] inline-block text-left">
      <div>
        <Menu.Button
          as="div"
          className="flex w-full cursor-pointer items-center justify-center"
        >
          <DotsVerticalIcon
            className="h-6 w-6"
            pathClassName="stroke-gray-400"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="min-w-32 absolute right-0 z-[9999] mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="min-w-[8rem] py-2">
            <Menu.Item>
              {({ active }) => (
                <div
                  className={cn(
                    "flex cursor-pointer items-center gap-2 p-2 text-sm text-gray-700",
                    {
                      "bg-gray-50": active,
                    },
                  )}
                  onClick={() => {
                    openNewUserSideBar({ creationMode: "LEAD" });
                  }}
                >
                  Add Lead
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  className={cn("cursor-pointer p-2", {
                    "bg-gray-50": active,
                  })}
                  onClick={() => {
                    openFiltersSidebar();
                  }}
                >
                  Filters
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  className={cn("cursor-pointer p-2", {
                    "font-semibold text-gray-600": !active,
                    "font-bold text-gray-600": active,
                  })}
                  onClick={() => {
                    setModal(<LeadStatusFormModal onClose={hideModal} />);
                  }}
                >
                  Add Step
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
