import * as Yup from "yup";

import { YupMixedBuilder } from "../../../helpers/yupBuilders";

export const APPOINTMENT_USER_ID = "appointment-user-id";
export const APPOINTABLE_ID = "appointable-id";
export const APPOINTMENT_HOST_ID = "appointment-host-id";
export const APPOINTMENT_FACILITY_ID = "appointment-facility-id";
export const START_DATE = "start-date";

export const createUserSchemaFields = () => {
  return {
    [APPOINTMENT_USER_ID]: Yup.object()
      .shape({
        value: Yup.mixed(),
        label: Yup.string(),
      })
      .required()
      .default(null),
  };
};

const hostDefinition = new YupMixedBuilder()
  .isRequiredIfFieldValueIs(
    Yup.ref("availability-type"),
    "STAFF",
    "Host trainer is required.",
  )
  .build()
  .default(null);

export const createServiceDetailsSchemaFields = () => {
  const schemaFields: {
    "appointable-id": any;
    "appointment-host-id"?: any;
    "appointment-facility-id"?: any;
  } = {
    [APPOINTABLE_ID]: Yup.object()
      .shape({ value: Yup.number(), label: Yup.string() })
      .required()
      .default(null),
    [APPOINTMENT_HOST_ID]: hostDefinition.nullable(),
    [APPOINTMENT_FACILITY_ID]: new YupMixedBuilder()
      .isRequiredIfFieldValueIs(
        Yup.ref("availability-type"),
        "FACILITY",
        "Host facility is required.",
      )
      .build()
      .default(null),
  };

  return schemaFields;
};

export const createDateAndTimeSchemaFields = (date: string) => {
  return {
    [START_DATE]: Yup.string().required().default(date),
  };
};

export const createAppointmentSchema = ({ date }: { date: string }) =>
  Yup.object()
    .shape(createUserSchemaFields())
    .shape(createServiceDetailsSchemaFields())
    .shape(createDateAndTimeSchemaFields(date));

export const createRescheduleSchema = (date: string) => {
  return Yup.object()
    .shape({
      [APPOINTMENT_HOST_ID]: hostDefinition,
    })
    .shape(createDateAndTimeSchemaFields(date));
};
