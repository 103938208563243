import { clubStaleTime, useClub } from "@gymflow/api";
import { DATE_FORMAT, PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import { useClubSettings } from "apps/portal/src/providers";
import moment from "moment";
import QueryString from "qs";
import { useContext } from "react";
import { useHistory } from "react-router";

import { usePortalRoutes } from "../../../../../hooks/usePortalRoutes";
import { RouteFeature } from "../../../../../routes/feature";
import { RouteLayout } from "../../../../../routes/layout";
import useGymflowModels from "../../../../../store";
import ReportWidgetCard from "../../../../ReportWidgetCard/ReportWidgetCard";
import { ReportContext } from "../../../ReportContext";
import { TransactionType } from "../../../Revenue";

export const ReportRevenueTransactionDetailsCreditAppliedWidget = ({
  parentRoute,
}: {
  parentRoute: string;
}) => {
  const { createClubLink } = usePortalRoutes();
  const data = useContext(ReportContext);
  const history = useHistory();
  const { api } = useGymflowModels();

  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { data: club } = useClub({ api, clubId }, { staleTime: clubStaleTime });

  return (
    <ReportWidgetCard
      title="Credit Applied"
      value={formatCurrency(
        Math.round(data.revenue?.creditsApplied?.total || 0),
        club?.defaultCurrency!,
        true,
      )}
      isLoading={data.isLoading}
      variation={data.revenue?.creditsApplied?.variation}
      explanation="Sum of all credits applied to invoices by staff."
      onClick={() =>
        history.push({
          pathname: createClubLink(
            RouteLayout.Staff,
            RouteFeature.RevenueTransaction,
          ),
          search: QueryString.stringify({
            dates: {
              startDate: moment(data.startDate, DATE_FORMAT).format(
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ),
              endDate: moment(data.endDate, DATE_FORMAT)
                .endOf("day")
                .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
            },
            type: TransactionType.MANUAL_CREDIT,
            backRoute: parentRoute,
          }),
        })
      }
    />
  );
};
