import accessApi from "./accessApi";
import activityApi from "./activityApi";
import appointableApi from "./appointableApi";
import appointmentApi from "./appointmentApi";
import automationApi from "./automationApi";
import availabilityApi from "./availabilityApi";
import clubApi from "./clubApi";
import companyApi from "./companyApi";
import creditPackApi from "./creditPackApi";
import customerActivityApi from "./customerActivity";
import customerAppointmentApi from "./customerAppointmentApi";
import customerAvailabilityApi from "./customerAvailabilityApi";
import customerClubApi from "./customerClubApi";
import customerFacilityApi from "./customerFacilityApi";
import customerOccurrenceApi from "./customerOccurrence";
import emailApi from "./emailApi";
import emailTemplateApi from "./emailTemplateApi";
import baseEndpoints from "./endpoints";
import eventApi from "./eventApi";
import facilityApi from "./facilityApi";
import hostedClubApi from "./hostedClubApi";
import kisiApi from "./kisiApi";
import leadApi from "./leadApi";
import leadSourceApi from "./leadSourceApi";
import noteApi from "./noteApi";
import productApi from "./productApi";
import profileApi from "./profileApi";
import promotionApi from "./promotionApi";
import publicActivityApi from "./publicActivityApi";
import publicAppointableApi from "./publicAppointableApi";
import publicClubApi from "./publicClubApi";
import publicCreditPack from "./publicCreditPackApi";
import publicEventApi from "./publicEventApi";
import publicFacilityApi from "./publicFacilityApi";
import publicHostedClubApi from "./publicHostedClubApi";
import publicLeadApi from "./publicLeadApi";
import publicHostedRuleApi from "./publicRuleApi";
import publicServiceApi from "./publicServiceApi";
import publicStrongCustomerAuthorizationApi from "./publicStrongCustomerAuthorizationApi";
import publicUserMemberApi from "./publicUserMemberApi";
import revenueApi from "./revenueApi";
import ruleApi from "./ruleApi";
import serviceApi from "./serviceApi";
import statisticsApi from "./statisticsApi";
import strongCustomerAuthorizationApi from "./strongCustomerAuthorizationApi";
import taskApi from "./taskApi";
import timelineApi from "./timelineApi";
import userMemberApi from "./userMemberApi";
import userStaffApi from "./userStaffApi";

export { default as DurationType } from "./DurationType";
export {
  PARAMETER_DATE_FORMAT,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
  PARAMETER_DATE_ONLY_FORMAT,
  PARAMETER_TIME_FORMAT,
} from "./eventApi";
export { default as EventStatus } from "./EventStatus";
export { default as ExpiryType } from "./ExpiryType";
export { default as Gender } from "./gender";
export { default as GuestStatus } from "./GuestStatus.ts";
export * from "./interceptors";
export { default as InvoiceStatus } from "./InvoiceStatus";
export { default as ProductStatus } from "./ProductStatus";
export { default as ProductStockType } from "./ProductStockType";
export * from "./publicAxiosInstance";
export { default as RecurringType } from "./RecurringType";
export { default as ServiceStatus } from "./ServiceStatus";
export { default as ServiceType } from "./ServiceType";
export {
  EVENT_FIELD,
  MEMBERSHIP_FIELD,
  REVENUE_FIELD,
  STATISTICS_CATEGORY,
} from "./statisticsApi";
export { default as StripeConnectStatus } from "./StripeConnectStatus";
export { default as SubscriptionStatus } from "./SubscriptionStatus";
export { default as UserRole } from "./UserRole";
export * from "./validations";

export const gymflowApi = ({ apiUrl, axiosInstance, publicOnly, clubId }) => {
  const publicApis = {
    public: {
      userMemberApi: publicUserMemberApi(apiUrl, clubId),
      serviceApi: publicServiceApi(apiUrl, clubId),
      creditPackApi: publicCreditPack(apiUrl, clubId),
      clubApi: publicClubApi(apiUrl, clubId),
      eventApi: publicEventApi(apiUrl, clubId),
      hostedClubApi: publicHostedClubApi(apiUrl, clubId),
      ruleApi: publicHostedRuleApi(apiUrl, clubId),
      leadApi: publicLeadApi(apiUrl, clubId),
      strongCustomerAuthorizationApi: publicStrongCustomerAuthorizationApi(
        apiUrl,
        clubId,
      ),
      facilityApi: publicFacilityApi(apiUrl, clubId),
      activityApi: publicActivityApi(apiUrl, clubId),
      appointableApi: publicAppointableApi(apiUrl, clubId),
    },
  };

  if (publicOnly) {
    return publicApis;
  }

  return {
    activityApi: activityApi(axiosInstance, apiUrl, clubId),
    clubApi: clubApi(axiosInstance, apiUrl, clubId),
    companyApi: companyApi(axiosInstance, apiUrl, clubId),
    creditPackApi: creditPackApi(axiosInstance, apiUrl, clubId),
    customerClubApi: customerClubApi(axiosInstance, apiUrl, clubId),
    customerOccurrenceApi: customerOccurrenceApi(axiosInstance, apiUrl, clubId),
    customerActivityApi: customerActivityApi(axiosInstance, apiUrl, clubId),
    customerFacilityApi: customerFacilityApi(axiosInstance, apiUrl, clubId),
    customerAvailabilityApi: customerAvailabilityApi(
      axiosInstance,
      apiUrl,
      clubId,
    ),
    eventApi: eventApi(axiosInstance, apiUrl, clubId),
    facilityApi: facilityApi(axiosInstance, apiUrl, clubId),
    productApi: productApi(axiosInstance, apiUrl, clubId),
    profileApi: profileApi(axiosInstance, apiUrl, clubId),
    serviceApi: serviceApi(axiosInstance, apiUrl, clubId),
    leadSourceApi: leadSourceApi(axiosInstance, apiUrl, clubId),
    staffApi: userStaffApi(axiosInstance, apiUrl, clubId),
    statisticsApi: statisticsApi(axiosInstance, apiUrl, clubId),
    memberApi: userMemberApi(axiosInstance, apiUrl, clubId),
    hostedClubApi: hostedClubApi(axiosInstance, apiUrl, clubId),
    noteApi: noteApi(axiosInstance, apiUrl, clubId),
    timelineApi: timelineApi(axiosInstance, apiUrl, clubId),
    ruleApi: ruleApi(axiosInstance, apiUrl, clubId),
    leadApi: leadApi(axiosInstance, apiUrl, clubId),
    automationApi: automationApi(axiosInstance, apiUrl, clubId),
    emailTemplateApi: emailTemplateApi(axiosInstance, apiUrl, clubId),
    revenueApi: revenueApi(axiosInstance, apiUrl, clubId),
    accessApi: accessApi(axiosInstance, apiUrl, clubId),
    emailApi: emailApi(axiosInstance, apiUrl, clubId),
    taskApi: taskApi(axiosInstance, apiUrl, clubId),
    strongCustomerAuthorizationApi: strongCustomerAuthorizationApi(
      axiosInstance,
      apiUrl,
      clubId,
    ),
    promotionApi: promotionApi(axiosInstance, apiUrl, clubId),
    kisiApi: kisiApi(axiosInstance, apiUrl, clubId),
    appointableApi: appointableApi(axiosInstance, apiUrl, clubId),
    availabilityApi: availabilityApi(axiosInstance, apiUrl, clubId),
    appointmentApi: appointmentApi(axiosInstance, apiUrl, clubId),
    customerAppointmentApi: customerAppointmentApi(
      axiosInstance,
      apiUrl,
      clubId,
    ),
    ...publicApis,
  };
};

export const endpoints = {
  ...baseEndpoints,
  internal: {
    userMember: userMemberApi.endpoints,
    rule: ruleApi.endpoints,
    public: {
      rule: publicHostedRuleApi.endpoints,
    },
  },
};
export { default as permissions } from "./permissions";
