import { createPublicAxiosInstance } from "@gymflow/common";
import { AxiosInstance } from "axios";

export class BootstrapServerRepository {
  protected baseUrl: string;
  protected axiosInstance: AxiosInstance;

  constructor(baseUrl: string) {
    this.axiosInstance = createPublicAxiosInstance({
      apiUrl: baseUrl,
    });
    this.baseUrl = baseUrl;
  }

  async getServerByRouteId(routeId: string) {
    const response = await this.axiosInstance.get(routeId);
    return response.data as ServerDetails;
  }

  async findRoutes({
    searchTerm,
    routeId,
    page,
    limit,
  }: {
    searchTerm?: string;
    routeId?: string;
    page: number;
    limit: number;
  }) {
    const response = await this.axiosInstance.get("/search", {
      params: { search: searchTerm, routeId, page, limit },
    });
    return response.data as RouteResultPage;
  }

  async getRoutesByBrand({
    search,
    brand,
    company,
    page,
    limit,
    clubIds,
    routeId,
  }: {
    search?: string;
    brand: string;
    company?: string;
    clubIds?: number[];
    routeId?: string;
    page: number;
    limit: number;
  }) {
    const params: {
      search?: string;
      brand: string;
      company?: string;
      routeId?: string;
      page: number;
      limit: number;
      clubId?: string;
    } = {
      search,
      brand,
      company,
      routeId,
      page,
      limit,
    };

    if (clubIds) {
      params.clubId = clubIds.join(",");
    }

    const response = await this.axiosInstance.get("/search", {
      params,
    });
    return response.data as RouteResultPage;
  }
}

export interface ServerDetails {
  serverId: string;
  clubId: number;
  authRealm: string;
  urls: {
    api: string;
    auth: string;
  };
}

export interface RouteResult {
  routeId: string;
  brand: string;
  company: string;
  location: string;
  status: "ENABLED" | "DISABLED";
  serverId: string;
  displayName: string;
  clubId: number;
  authRealm: string;
}

export interface RouteResultPage {
  results: RouteResult[];
  length: number;
  hasMore: boolean;
}
