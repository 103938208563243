import {UseQueryOptions, useQuery} from '@tanstack/react-query';

import {promotionQueryKeys} from './promotionQueryKeys';
import {PromotionDTO} from '@gymflow/types';

export function usePromotion(
  {
    api,
    promotionId,
  }: {
    api: {
      promotionApi: {findById: (arg0: number) => Promise<any>};
    };
    promotionId: number;
  },
  queryOpts?: UseQueryOptions<PromotionDTO | null>,
) {
  const result = useQuery({
    queryKey: promotionQueryKeys.details(promotionId),
    queryFn: async () => {
      const result = await api.promotionApi.findById(promotionId);
      return result.data as PromotionDTO;
    },
    enabled: !!promotionId,
    initialData: null,
    ...queryOpts,
  });

  return result;
}
