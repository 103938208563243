import {
  clubStaleTime,
  useClubHostedPagesSettingsAsPublic,
  useQueryClubFeatureFlagsAsPublic,
} from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";

import { PublicFooter } from "../../components/atoms";
import { SiteNavHeader } from "../../components/organisms";
import {
  useHtmlNodeDarkMode,
  useInjectWebsiteCustomizations,
} from "../../hooks";
import useGoogleTagManager from "../../hooks/useGoogleTagManager";
import { LoginOverlayProvider, useClubSettings } from "../../providers";
import {
  generateSiteWithAuthRoutes,
  RouteDefinition,
  RouteFeatureSite,
} from "../../routes";
import useGymflowModels from "../../store";

export function SiteWithAuth() {
  const match = useRouteMatch();

  const getRoutes = (routes: RouteDefinition[]) => {
    const nodes = routes.map((prop) => {
      return (
        <Route path={match.path + prop.path} key={prop.path}>
          {(props) => <prop.component {...props} />}
        </Route>
      );
    });
    return nodes;
  };

  useGoogleTagManager();

  const { api } = useGymflowModels();
  const { clubId, stripe_account_type } = useClubSettings();

  const { data: featureFlags } = useQueryClubFeatureFlagsAsPublic({
    api,
    clubId,
  });

  const routesWithAuth = generateSiteWithAuthRoutes(featureFlags);
  const { data: hostedPagesSettings } = useClubHostedPagesSettingsAsPublic(
    { api, clubId },
    { staleTime: clubStaleTime },
  );

  useHtmlNodeDarkMode({ supportDarkMode: true });
  useInjectWebsiteCustomizations();

  const isStripeEnabled = stripe_account_type === "STANDARD" || "CONNECTED";
  const isNotAvailableRoute = matchPath(
    match.url + RouteFeatureSite.NotAvailable,
    window.location.pathname,
  );
  if (!isStripeEnabled && !isNotAvailableRoute) {
    return <Redirect to={match.url + RouteFeatureSite.NotAvailable} />;
  }

  return (
    <div
      className={cn("w-screen", {
        dark: hostedPagesSettings?.settings?.darkMode,
      })}
    >
      <LoginOverlayProvider>
        <div className="w-100 dark:bg-darkModeFill flex h-screen flex-col justify-between overflow-hidden bg-white">
          <SiteNavHeader />
          <div className="flex-1 overflow-y-scroll">
            <Switch>{getRoutes(routesWithAuth)}</Switch>
          </div>
          <PublicFooter />
        </div>
      </LoginOverlayProvider>
    </div>
  );
}
