import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AlertContext,
  humanizeDate,
  NotificationContext,
} from "@gymflow/common";
import { useStoreState } from "easy-peasy";
import moment from "moment-timezone";
import { useContext, useEffect } from "react";
import { useDrag } from "react-dnd";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Label,
  PopoverBody,
  UncontrolledPopover,
  UncontrolledTooltip,
} from "reactstrap";

import { getDeadlineColor } from "../../hooks/useTaskColumnDefs";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import TaskAlert from "../Task/TaskAlert";
import { NoteFormSideBarProviderContext } from "../UserMember/Notes/NoteFormSideBarProvider";
import SendEmailAlert from "../UserMember/SendEmails/SendEmailAlert";

function LeadCard(props) {
  const {
    firstName,
    lastName,
    created,
    email,
    phoneNumber,
    source,
    emailCommunication,
    id,
    profileLink,
    nextTask,
    onDragChange,
    sendEmails,
    createTask,
  } = props;
  const { settingsStore } = useGymflowModels();
  const settings = useClubSettings();
  const dateFormat = settings.date_format;
  const { timezone, email: clubEmail } = useStoreState(settingsStore);
  const name = `${firstName} ${lastName}`;
  const [collected, drag] = useDrag(() => ({
    type: "card",
    item: { name, created, source, id },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  }));

  const { setAlert, hide } = useContext(AlertContext);
  const { notify } = useContext(NotificationContext);

  useEffect(() => {
    onDragChange(collected.isDragging);
  }, [collected.isDragging, onDragChange]);

  const { triggerNewNote } = useContext(NoteFormSideBarProviderContext);
  return (
    <div ref={drag} style={{ cursor: "default" }}>
      <div>
        <Card className="mb-3" style={{ minWidth: 197, minHeight: 40 }}>
          <CardBody className="!p-0">
            <div className="d-flex justify-content-between px-5 py-3">
              <div className="font-size-sm">
                <Button className="btn-link font-weight-bold m-0 p-0">
                  <Link to={profileLink} id={`lead-card-info-tooltip-${id}`}>
                    {name}
                  </Link>
                </Button>
                {nextTask && (
                  <div
                    style={{
                      textDecoration: nextTask.complete
                        ? "line-through"
                        : "none",
                    }}
                  >
                    <div>{nextTask.name}</div>
                    <div>
                      <span className="text-muted">Due: </span>
                      <span
                        style={{
                          color: getDeadlineColor(
                            nextTask.deadlineDate,
                            nextTask.deadlineTime,
                            timezone,
                          ),
                        }}
                      >
                        {humanizeDate(
                          moment(
                            `${nextTask.deadlineDate} ${nextTask.deadlineTime}`,
                          ),
                          `${dateFormat} [at] h:mm a`,
                        )}
                      </span>
                    </div>
                  </div>
                )}
                <UncontrolledTooltip
                  placement="bottom"
                  target={`lead-card-info-tooltip-${id}`}
                >
                  <div
                    className="text-left"
                    style={{ padding: 10, minWidth: 142 }}
                  >
                    <div>
                      <Label className="text-muted mr-1">Email: </Label>
                      <span className="font-weight-bold">{email}</span>
                    </div>
                    <div>
                      <Label className="text-muted mr-1">Phone: </Label>
                      <span className="font-weight-bold">{phoneNumber}</span>
                    </div>
                    <div>
                      <Label className="text-muted mr-1">Submitted: </Label>
                      <span className="font-weight-bold">{created}</span>
                    </div>
                    <div>
                      <Label className="text-muted mr-1">Source: </Label>
                      <span className="font-weight-bold">{source}</span>
                    </div>
                  </div>
                </UncontrolledTooltip>
              </div>
              <div>
                <Button className="btn-link m-0 p-0">
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    icon={faChevronDown}
                    style={{ fontSize: 12 }}
                    id={`lead-card-menu-tooltip-${id}`}
                  />
                </Button>
                <UncontrolledPopover
                  placement="bottom"
                  target={`lead-card-menu-tooltip-${id}`}
                  trigger="legacy"
                >
                  <PopoverBody>
                    <div>
                      <Button
                        className="btn-link"
                        onClick={() =>
                          setAlert(
                            <SendEmailAlert
                              allowMarketing={emailCommunication}
                              from={clubEmail}
                              to={name}
                              onSubmit={(values) => {
                                const emailRecipientList = [];
                                emailRecipientList.push({
                                  leadId: id,
                                });

                                const bcc = values.bcc
                                  ? values.bcc.split(",")
                                  : [];
                                return sendEmails(
                                  values.subject,
                                  values.body,
                                  emailRecipientList,
                                  values.marketing,
                                  bcc,
                                );
                              }}
                              onCancel={hide}
                            />,
                          )
                        }
                      >
                        Send Email
                      </Button>
                    </div>
                    <div>
                      <Button
                        className="btn-link"
                        onClick={() => {
                          triggerNewNote({
                            leadId: id,
                            name,
                          });
                        }}
                      >
                        Add Note
                      </Button>
                    </div>
                    <div>
                      <Button
                        className="btn-link"
                        onClick={() =>
                          setAlert(
                            <TaskAlert
                              defaultRelatedLeads={[
                                { id, firstName, lastName },
                              ]}
                              onCancel={hide}
                              onSubmit={async (values) => {
                                await createTask(values);
                                notify({ message: "task is added to Lead." });
                                hide();
                              }}
                            />,
                          )
                        }
                      >
                        Add Task
                      </Button>
                    </div>
                  </PopoverBody>
                </UncontrolledPopover>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default LeadCard;
