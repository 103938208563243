import check, { assert } from "check-types";

import endpoints from "./endpoints";
import { createPublicAxiosInstance } from "./publicAxiosInstance";

const publicHostedClubApi = (apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.publicHostedClub}`,
    http: createPublicAxiosInstance({
      apiUrl,
    }),
  };

  return Object.assign(state, {
    findById(id) {
      assert(
        check.any([check.string(id), check.number(id)]),
        "id must be number or string",
      );
      return state.http.get(`${state.baseUrl}/settings`);
    },
  });
};

export default publicHostedClubApi;
