import * as Sentry from "@sentry/browser";
import { InternalAxiosRequestConfig } from "axios";

export function axiosRequestInterceptor(
  requestConfig: InternalAxiosRequestConfig<any>,
) {
  if (requestConfig.url?.includes("//")) {
    Sentry.captureMessage(
      `Detected bad URL string on ${requestConfig.url}`,
      "warning",
    );
  }
  return requestConfig;
}
