import { assert } from "check-types";

import endpoints from "./endpoints";
import {
  canCreate,
  canFindById,
  canTrimFields,
  canUpdate,
} from "./features/apiResource";

const appointmentEndpoints = {
  collectPayment: `/collect-payment`,
  refundPayment: `/refund-payment/:appointmentId`,
};

const appointmentApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: [],
    baseUrl: `${clubPrefix}${endpoints.appointment}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(
    state,
    canTrimFields(state),
    canFindById(state),
    canCreate(state),
    canUpdate(state),
    {
      createSortParam(field, desc = false) {
        return `${field},${desc ? "desc" : "asc"}`;
      },
      find({ page = 0, limit = 10, extraParams = {}, sort }) {
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");

        const body = {
          page,
          size: limit,
          ...extraParams,
        };

        if (sort) {
          assert.string(
            sort.field,
            "sort.field must be a string if sort is specified.",
          );
          config.params.sort = state.createSortParam(sort.field, sort.desc);
        }

        return state.http.put(`${state.baseUrl}/search`, body);
      },
      collectPayment({
        appointmentId,
        paymentMethodId,
        paidWithSessions,
        promotionCode,
      }) {
        return state.http.post(
          `${state.baseUrl}${appointmentEndpoints.collectPayment}`,
          {
            appointmentId,
            paymentMethodId,
            paidWithSessions,
            promotionCode,
          },
        );
      },
      refundPayment(clubId, appointmentId) {
        const url = `${state.baseUrl
          }${appointmentEndpoints.refundPayment.replace(
            ":appointmentId",
            appointmentId,
          )}`;
        return state.http.post(url);
      },

      summary(params) {
        const url = `${state.baseUrl}/summary`;
        return state.http.post(url, params);
      },
    },
  );
};

export default appointmentApi;
