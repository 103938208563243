import { FormMapper } from "@gymflow/common";
import pick from "lodash/pick";

import {
  APPOINTABLE_ID,
  APPOINTMENT_FACILITY_ID,
  APPOINTMENT_HOST_ID,
  APPOINTMENT_USER_ID,
} from "./AppointmentSchema";

export class AppointmentFormMapper extends FormMapper {
  constructor() {
    super({
      outValue: [
        {
          key: APPOINTMENT_HOST_ID,
          transform: (value: any) => value?.value,
        },
        {
          key: APPOINTMENT_FACILITY_ID,
          transform: (value: any) => value?.value,
        },
        {
          key: APPOINTMENT_USER_ID,
          transform: (value: any) => value.value.id,
        },
        {
          key: APPOINTABLE_ID,
          transform: (value: any) => value.value,
        },
      ],
    });
  }

  override to(values: any) {
    const parsed: Record<string, any> = super.to(values);

    const user = parsed["appointment-user"];
    if (user) {
      parsed["appointment-user-id"] = {
        value: user,
        label: `${user.firstName} ${user.lastName}`,
      };
    }

    const appointable = parsed["appointable"];
    if (appointable) {
      parsed["appointable-id"] = {
        value: appointable.id,
        label: appointable.name,
      };
    }

    const host = parsed["appointment-host"];
    if (host) {
      parsed["appointment-host-id"] = {
        value: host.id,
        label: `${host.firstName} ${host.lastName}`,
      };
    }

    return pick(parsed, [
      "appointable-id",
      "appointment-host-id",
      "appointment-facility-id",
      "appointment-user-id",
      "start-date",
    ]);
  }

  override from(values: Record<string, any>) {
    const parsed: any = super.from(values);
    if (parsed.availabilityType === "STAFF") {
      delete parsed["appointmentFacilityId"];
    } else if (parsed.availabilityType === "FACILITY") {
      delete parsed["appointmentHostId"];
    }
    delete parsed["availabilityType"];
    return parsed;
  }
}
