import { UserRole } from "@gymflow/types";
import axios, {
  AxiosError,
  AxiosInstance,
  HttpStatusCode,
  InternalAxiosRequestConfig,
} from "axios";
import { decode as atob } from "base-64";
import EventEmitter from "events";
import qs from "qs";

import { ApiMeta } from "./hooks";
import { TokenStorage } from "./TokenStorage";

const TOKEN_KEY = "access-token";
const REFRESH_TOKEN_KEY = "refresh-token";

export class AxiosAuthenticationLoader {
  private axiosInstance: AxiosInstance;
  public apiMeta: ApiMeta;
  private tokenStorageApi: TokenStorage;
  public authenticated = false;
  private tokenKey: string;
  private refreshTokenKey: string;
  private appId: string;
  public eventEmitter = new EventEmitter();
  private static refMap = new WeakMap<
    object,
    [requestInterceptorId: number, errorInterceptorId: number]
  >();

  constructor(
    axiosInstance: AxiosInstance,
    apiMeta: ApiMeta,
    tokenStorageApi: TokenStorage,
    appId: string,
  ) {
    this.axiosInstance = axiosInstance;
    this.apiMeta = apiMeta;
    this.tokenStorageApi = tokenStorageApi;
    const instanceKey = `${apiMeta.apiUrl} ${apiMeta.keycloakRealm}`;
    this.tokenKey = `${TOKEN_KEY} ${instanceKey}`;
    this.refreshTokenKey = `${REFRESH_TOKEN_KEY} ${instanceKey}`;
    this.appId = appId;
  }

  async userInClub(userType: "STAFF" | "MEMBER") {
    try {
      const url = `${this.apiMeta.apiUrl}${
        userType === "STAFF"
          ? `/user/staff/${await this.getUserId()}`
          : "/customer/user/member"
      }`;
      await this.axiosInstance.get(url);
    } catch (e) {
      if (e instanceof AxiosError) {
        if (userType === "STAFF" && e.code === "403") return false;
        else return false;
      }
    }
    return true;
  }

  async login(username: string, password: string) {
    const response = await axios.post(
      `${this.apiMeta.keycloakUrl}/realms/${this.apiMeta.keycloakRealm}/protocol/openid-connect/token`,
      qs.stringify({
        username,
        password,
        client_id: "gymflow-app",
        grant_type: "password",
        scope: "profile offline_access",
      }),
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    );
    await this.tokenStorageApi.set(this.tokenKey, response.data.access_token);
    await this.tokenStorageApi.set(
      this.refreshTokenKey,
      response.data.refresh_token,
    );
    await this.loadAuthentication();
    this.authenticated = true;
  }

  async logout() {
    await this.tokenStorageApi.remove(this.tokenKey);
    await this.tokenStorageApi.remove(this.refreshTokenKey);
    await this.loadAuthentication();
    this.eventEmitter.emit("logout");
  }

  ejectInterceptor() {
    const interceptorIds = AxiosAuthenticationLoader.refMap.get(
      this.axiosInstance,
    );
    if (interceptorIds !== undefined) {
      this.axiosInstance.interceptors.request.eject(interceptorIds[0]);
      this.axiosInstance.interceptors.response.eject(interceptorIds[1]);
      AxiosAuthenticationLoader.refMap.delete(this.axiosInstance);
    }
  }

  isAuthenticated() {
    return this.authenticated;
  }

  async refreshAuthentication() {
    const refreshToken = await this.tokenStorageApi.get(this.refreshTokenKey);
    if (refreshToken !== null) {
      try {
        const response = await axios.post(
          `${this.apiMeta.keycloakUrl}/realms/${this.apiMeta.keycloakRealm}/protocol/openid-connect/token`,
          qs.stringify({
            client_id: this.appId,
            grant_type: "refresh_token",
            refresh_token: refreshToken,
          }),
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          },
        );
        await this.tokenStorageApi.set(
          this.tokenKey,
          response.data.access_token,
        );
        await this.tokenStorageApi.set(
          this.refreshTokenKey,
          response.data.refresh_token,
        );
        await this.loadAuthentication();
        return response.data.access_token;
      } catch (e) {
        if (
          e instanceof AxiosError &&
          e.response?.status === HttpStatusCode.BadRequest &&
          e.response?.data?.error
        ) {
          console.info("refresh token", refreshToken);
          this.eventEmitter.emit("refresh-error", {
            keycloakUrl: this.apiMeta.keycloakUrl,
            keycloakRealm: this.apiMeta.keycloakRealm,
            refreshToken: refreshToken,
            url: `${this.apiMeta.keycloakUrl}/realms/${this.apiMeta.keycloakRealm}/protocol/openid-connect/token`,
            data: e.response?.data,
            status: e.response.status,
          });
          this.logout();
          return false;
        }
        await this.loadAuthentication();
        return false;
      }
    }
  }

  public async loadAuthentication() {
    const accessToken = await this.tokenStorageApi.get(this.tokenKey);
    this.loadInterceptor();
    if (accessToken !== null) {
      this.axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessToken}`;
      return true;
    } else {
      delete this.axiosInstance.defaults.headers.common["Authorization"];
      this.authenticated = false;
      return false;
    }
  }

  async getRoles(): Promise<UserRole[] | null> {
    const token = await this.tokenStorageApi.get(this.tokenKey);
    if (token === null) {
      return null;
    }
    const tokenData = AxiosAuthenticationLoader.parseJwt(token);

    return tokenData.realm_access.roles;
  }

  async getUserId() {
    const token = await this.tokenStorageApi.get(this.tokenKey);
    if (token === null) {
      return null;
    }
    const tokenData = AxiosAuthenticationLoader.parseJwt(token);

    return tokenData.sub;
  }

  async getEmail() {
    const token = await this.tokenStorageApi.get(this.tokenKey);
    if (token === null) {
      return null;
    }
    const tokenData = AxiosAuthenticationLoader.parseJwt(token);

    return tokenData.email;
  }

  private loadInterceptor() {
    if (AxiosAuthenticationLoader.refMap.has(this.axiosInstance)) {
      return;
    }

    const requestInterceptorId = this.axiosInstance.interceptors.request.use(
      (requestConfig: AxiosRequestConfigWithMeta) => {
        requestConfig.meta = requestConfig.meta || {};
        requestConfig.meta.requestStartedAt = new Date().getTime();
        return requestConfig;
      },
      this.requestErrorInterceptor.bind(this),
    );

    const responseInterceptorId = this.axiosInstance.interceptors.response.use(
      (response) => response,
      this.responseErrorInterceptor.bind(this),
    );
    AxiosAuthenticationLoader.refMap.set(this.axiosInstance, [
      requestInterceptorId,
      responseInterceptorId,
    ]);
  }

  private async requestErrorInterceptor(error: AxiosError) {
    const executionTimeMs =
      new Date().getTime() - (error.config as any).meta.requestStartedAt;
    const errorDetails = {
      executionTimeMs,
      method: error.config!.method,
      url: `${error.config!.baseURL}/${error.config!.url}`,
      statusCode: error.response?.status,
      requestBody: error.config?.data
        ? JSON.stringify(error.response?.data)
        : null,
      code: error.code,
    };

    this.eventEmitter.emit("http-error", errorDetails);
    return Promise.reject(error);
  }

  private async responseErrorInterceptor(error: AxiosError) {
    if (
      error?.response?.status === HttpStatusCode.Unauthorized &&
      error.config?.baseURL === this.apiMeta.apiUrl
    ) {
      const newToken = await this.refreshAuthentication();
      if (newToken) {
        (error.config.headers as any).Authorization = `Bearer ${newToken}`;
        return axios.request(error.config);
      }
      return Promise.resolve();
    }
    if (
      error?.response?.status === HttpStatusCode.Forbidden &&
      error.config?.baseURL === this.apiMeta.apiUrl &&
      !error.config?.url?.includes("/kisi/unlock")
    ) {
      const errorDetails = {
        method: error.config!.method,
        url: `${error.config!.baseURL}/${error.config!.url}`,
        statusCode: error.response?.status,
        token: await this.tokenStorageApi.get(this.refreshTokenKey),
        headers: error?.request?.headers,
        requestBody: error.config?.data
          ? JSON.stringify(error.config?.data)
          : null,
        responseBody: error.response?.data
          ? JSON.stringify(error.response?.data)
          : null,
      };
      console.info("forbidden", errorDetails);
      this.eventEmitter.emit("forbidden", errorDetails);
    }
    console.error({
      message: error.message,
      code: error.code,
      url: error.config?.url,
      response: error.response?.data
        ? JSON.stringify(error.response?.data)
        : null,
    });

    const executionTimeMs =
      new Date().getTime() - (error.config as any).meta.requestStartedAt;

    const errorDetails = {
      executionTimeMs,
      method: error.config!.method,
      url: `${error.config!.baseURL}/${error.config!.url}`,
      statusCode: error.response?.status,
      requestBody: error.config?.data
        ? JSON.stringify(error.config?.data)
        : null,
      responseBody: error.response?.data
        ? JSON.stringify(error.response?.data)
        : null,
      code: error.code,
    };
    this.eventEmitter.emit("http-error", errorDetails);
    return await Promise.reject(error);
  }

  private static parseJwt(token: string): any {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );

    return JSON.parse(jsonPayload);
  }
}

interface AxiosRequestConfigWithMeta extends InternalAxiosRequestConfig {
  meta?: {
    requestStartedAt?: number;
  };
}
