import range from "lodash/range";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";

import { PaginatedSelect } from "./PaginatedSelect";

interface TimeSelectProps {
  value: string;
  onChange: (newValue: string) => void;
}

export function TimeSelect({ value, onChange }: TimeSelectProps) {
  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const hour = useMemo(() => {
    if (!internalValue) {
      return { value: null, label: "HH" };
    }
    const newHour = moment(internalValue, "hh:mm a").format("h");
    return { value: +newHour, label: newHour };
  }, [internalValue]);

  const minutes = useMemo(() => {
    if (!internalValue) {
      return { value: null, label: "MM" };
    }
    const newMinutes = moment(internalValue, "hh:mm a").minutes();
    return {
      value: newMinutes,
      label: newMinutes.toString().padStart(2, "0"),
    };
  }, [internalValue]);

  const amOrPm = useMemo(() => {
    return moment(internalValue, "hh:mm a").hour() < 12
      ? { value: "AM", label: "AM" }
      : { value: "PM", label: "PM" };
  }, [internalValue]);

  return (
    <div className="flex">
      <div className="mr-2">
        <PaginatedSelect
          value={hour}
          onChange={function (newHour) {
            const minutesString = minutes.label === "MM" ? "00" : minutes.label;
            onChange(`${newHour.label}:${minutesString} ${amOrPm.label}`);
          }}
          loadOptions={async function () {
            const options = range(1, 13).map((hour) => {
              return { label: hour, value: hour };
            });
            return {
              options,
              hasMore: false,
            };
          }}
        />
      </div>
      <div className="mr-2">
        <PaginatedSelect
          value={minutes}
          onChange={function (newMinutes) {
            const hourString = hour.label === "HH" ? "08" : hour.label;
            onChange(`${hourString}:${newMinutes.label} ${amOrPm.label}`);
          }}
          loadOptions={async function () {
            const options = range(0, 60, 5).map((minutes) => {
              return {
                label: minutes.toString().padStart(2, "0"),
                value: minutes,
              };
            });
            return {
              options,
              hasMore: false,
            };
          }}
        />
      </div>
      <div>
        <PaginatedSelect
          value={amOrPm}
          onChange={function (newAmOrPm) {
            onChange(`${hour.label}:${minutes.label} ${newAmOrPm.label}`);
          }}
          loadOptions={async function () {
            return {
              options: [
                { label: "AM", value: "AM" },
                { label: "PM", value: "PM" },
              ],
              hasMore: false,
            };
          }}
        />
      </div>
    </div>
  );
}
