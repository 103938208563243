import {
  formikHelpers,
  FormikInput,
  PaymentMethod,
  PaymentMethodSwitch,
  StripeCard,
} from "@gymflow/common";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useClubSettings } from "apps/portal/src/providers";
import classNames from "classnames";
import { useFormik } from "formik";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Col, Form, FormGroup, Row } from "reactstrap";

import PaymentMethodSchema from "./PaymentMethodSchema";

const AddPaymentMethodForm = forwardRef(
  ({ cardHolderName, fetchBacsCheckoutId }, ref) => {
    const [paymentType, setPaymentType] = useState(PaymentMethod.Card);
    const stripe = useStripe();
    const elements = useElements();
    const formikProps = useFormik({
      enableReinitialize: true,
      initialValues: { name: cardHolderName || "", isCardValid: false },
      onSubmit: noop,
      validateOnBlur: true,
      validationSchema: PaymentMethodSchema,
    });

    const { errorClass } = formikHelpers(formikProps);
    const { values, isValid, setFieldValue, touched, validateForm } =
      formikProps;

    const [cardError, setCardError] = useState("");
    const settings = useClubSettings();

    useEffect(() => {
      validateForm();
    }, []);

    useImperativeHandle(
      ref,
      () => {
        return {
          getValues: async () => {
            if (paymentType === PaymentMethod.BacsDirectDebit) {
              const response = await fetchBacsCheckoutId();
              await ref.current.stripe.redirectToCheckout({
                sessionId: response.data,
              });

              return { paymentType };
            }
            if (!isValid) {
              return { paymentType, isValid, name: values.name };
            }
            if (paymentType === PaymentMethod.Card) {
              const stripeCard = await ref.current.stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardElement),
                // eslint-disable-next-line camelcase
                billing_details: { name: values.name },
              });

              return {
                paymentType,
                isValid,
                name: values.name,
                paymentMethod: stripeCard.paymentMethod,
              };
            }
          },
          stripe,
        };
      },
      [stripe, isValid, paymentType],
    );

    const renderCardForm = () => {
      return (
        <>
          <Row className="mt-2">
            <Col>
              <FormGroup className={errorClass("name")}>
                <FormikInput
                  placeholder="Card Holder's Name"
                  autoComplete="off"
                  data-testid="name"
                  maxLength="128"
                  name="name"
                  type="text"
                  formikProps={formikProps}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className={classNames({ "has-danger": cardError })}>
                <StripeCard
                  onChange={(card) => {
                    if (card.error) {
                      setFieldValue("isCardValid", false);
                      if (card.error) {
                        setCardError(card.error.message);
                      }
                    } else {
                      setFieldValue("isCardValid", true);
                      setCardError(null);
                    }
                  }}
                />
                {
                  <label
                    style={{
                      visibility:
                        touched["isCardValid"] && cardError
                          ? "invisible"
                          : "visible",
                    }}
                    className="error"
                  >
                    {cardError}
                  </label>
                }
              </FormGroup>
            </Col>
          </Row>
        </>
      );
    };

    const renderDirectDebitMessage = () => {
      return (
        <p>
          You will be redirect to Stripe to collect your Direct Debit details.
        </p>
      );
    };

    const supportedPaymentMethods = [PaymentMethod.Card];
    if (settings.allowDirectDebit) {
      supportedPaymentMethods.push(PaymentMethod.BacsDirectDebit);
    }

    return (
      <Form className="form-horizontal" style={{ textAlign: "start" }}>
        <Row>
          <Col>
            <PaymentMethodSwitch
              value={paymentType}
              setValue={setPaymentType}
              availableValues={supportedPaymentMethods}
            />
          </Col>
        </Row>
        {paymentType === PaymentMethod.Card && renderCardForm()}
        {paymentType === PaymentMethod.BacsDirectDebit &&
          renderDirectDebitMessage()}
      </Form>
    );
  },
);

AddPaymentMethodForm.displayName = "AddPaymentMethodForm";

AddPaymentMethodForm.propTypes = {
  cardHolderName: PropTypes.string,
};

export default AddPaymentMethodForm;
