import classNames from "classnames";
import { Field, useFormikContext } from "formik";

import { TextInputClassNames } from "./TextInput";

interface FormikTextInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "type"> {
  type:
    | "email"
    | "number"
    | "text"
    | "password"
    | "search"
    | "tel"
    | "url"
    | "textarea";
  hideErrors?: boolean;
}

export function FormikTextInput({
  className,
  type,
  hideErrors,
  ...props
}: FormikTextInputProps) {
  const { touched, errors }: any = useFormikContext();
  const fieldName = props.name as string;
  const hasErrors = touched[fieldName] && errors[fieldName];
  return (
    <div className="w-full">
      <div className="flex w-full">
        <Field
          type={type}
          className={classNames(TextInputClassNames, className)}
          {...props}
        />
      </div>
      {!hideErrors && hasErrors && (
        <div className="flex w-full">
          <div className="text-error-600 ml-3.5 mt-2">{errors[fieldName]}</div>
        </div>
      )}
    </div>
  );
}

FormikTextInput.defaultProps = {
  type: "text",
};
