import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";

import { AsyncSelectProps } from "./atoms";
import { dropdownFilterClasses } from "./DropdownFilter";

/**
 * @deprecated replaced by PaginatedSelect
 */
function AsyncDropdownFilter({
  className,
  components,
  ...props
}: AsyncSelectProps) {
  return (
    /* TODO: Investigate type not matching */
    /* @ts-expect-error */
    <AsyncPaginate
      additional={{
        page: 0,
      }}
      components={{
        DropdownIndicator: ({ selectProps: { menuIsOpen } }: any) => {
          if (menuIsOpen) {
            return (
              <FontAwesomeIcon
                icon={faAngleUp}
                className="ml-2 mr-4 text-gray-500"
              />
            );
          }
          return (
            <FontAwesomeIcon
              icon={faAngleDown}
              className="ml-2 mr-4 text-gray-500"
            />
          );
        },
        IndicatorSeparator: () => {
          return null;
        },
        ValueContainer: ({ children }: any) => {
          return (
            <div className="ml-4 flex overflow-x-hidden font-semibold [&>div]:!text-gray-500">
              {children}
            </div>
          );
        },
        ...components,
      }}
      classNames={{
        control: () =>
          "!border-none bg-transparent !outline-0 !shadow-none !flex !flex-row justify-between !flex-nowrap",
        menu: () => "!z-[9999] !rounded-lg",
        option: ({ isDisabled }: { isDisabled: boolean }) =>
          classNames("!bg-white", {
            "cursor-none !text-gray-600": isDisabled,
            "!cursor-pointer hover:!bg-primary-50 !text-gray-800": !isDisabled,
          }),
        singleValue: () => `text-ellipsis whitespace-nowrap !max-w-[180px]`,
        valueContainer: () =>
          "border-none rounded-xl shadow-none h-[calc(2.75rem-2px)] cursor-pointer",
      }}
      className={classNames(dropdownFilterClasses, className)}
      isSearchable={false}
      {...props}
    />
  );
}

AsyncDropdownFilter.defaultProps = {
  className: "",
  components: {},
};

AsyncDropdownFilter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  loadOptions: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default AsyncDropdownFilter;
