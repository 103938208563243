import { appointableCategoriesQueryFn } from "@gymflow/api";
import { ReactNode } from "react";

import CogIcon from "../../../assets/img/cog.svg";
import useGymflowModels from "../../store";
import { PaginatedSelect } from ".";

export interface AppointableCategorySelectOption {
  label: string;
  value: number;
}

interface AppointableCategorySelectProps {
  value: AppointableCategorySelectOption[];
  onChange: (
    newValue: (
      | AppointableCategorySelectOption
      | { value: "create"; label: ReactNode }
    )[],
  ) => void;
  allowMultipleSelection?: boolean;
  placeholder?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  showManageOption?: boolean;
}

export function AppointableCategorySelect({
  value,
  onChange,
  allowMultipleSelection,
  placeholder,
  isClearable,
  isSearchable,
  showManageOption,
}: AppointableCategorySelectProps) {
  const { api } = useGymflowModels();

  return (
    <PaginatedSelect
      isMulti={allowMultipleSelection}
      isSearchable={isSearchable}
      placeholder={placeholder}
      loadOptions={async (term, _, { page }) => {
        const categoryList = await appointableCategoriesQueryFn({
          api,
          paginationOption: {
            page,
            size: 100,
          },
          filter: {
            statusList: ["ACTIVE"],
          },
        });
        const options: { label: ReactNode; value: number | "create" }[] =
          categoryList.content
            .map((category) => ({
              label: category.name,
              value: category.id,
            }))
            .filter((category) =>
              category.label.toLowerCase().includes(term.toLowerCase()),
            );
        if (showManageOption) {
          options.push({
            value: "create",
            label: (
              <div className="hover:!bg-primary-50 !-m-2 flex !cursor-pointer flex-row items-center justify-center gap-x-2 border-t border-gray-300 !bg-white p-2 !text-gray-800">
                <img alt="" src={CogIcon} />
                Create new category
              </div>
            ),
          });
        }
        return {
          options,
        };
      }}
      value={value}
      onChange={(newValue) => {
        onChange(allowMultipleSelection ? newValue : [newValue]);
      }}
      isClearable={isClearable}
      refetchOnMenuOpen={showManageOption}
    />
  );
}
