import {
  useMemberInvoiceAsMember,
  useMemberInvoiceListAsMember,
} from "@gymflow/api";
import {
  AlertContext,
  CardBodyWithSpinner,
  InvoiceStatus,
  NotificationContext,
  PaymentConfirmationStatus,
  StrongAuthorizationPaymentStatus,
  usePaymentAuthorizationAlert,
} from "@gymflow/common";
import { useStoreState } from "easy-peasy";
import merge from "lodash/merge";
import { useCallback, useContext, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { Button, Col, Row } from "reactstrap";

import PaymentCard from "../components/PaymentCard";
import CancelAuthorizationAlert from "../components/UserMember/CancelAuthorizationAlert";
import useAddPaymentMethod from "../components/UserMember/PaymentMethods/useAddPaymentMethod";
import UserMemberInvoices from "../components/UserMember/UserMemberInvoices";
import { useClubSettings } from "../providers";
import useGymflowModels from "../store";

const responsive = {
  wider: { breakpoint: { max: 4000, min: 2000 }, items: 5 },
  desktop: { breakpoint: { max: 2000, min: 1024 }, items: 3.5 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 3 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 1.2 },
};

function Receipts() {
  const { authStore, UserMemberStore, settingsStore, api } = useGymflowModels();
  const { id: loggedInId } = useStoreState(authStore);
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const {
    fetchById,
    clearEditingRecord,
    fetchPaymentMethods,
    addPaymentMethod,
    removePaymentMethod,
    assignDefaultPaymentMethod,
  } = UserMemberStore.useStoreActions((actions) => actions);

  const { editing, paymentMethods, loadingPaymentMethods } =
    UserMemberStore.useStoreState((state) => state);
  const { id } = useStoreState(authStore);
  const { show: showPaymentAuthorizationAlert } =
    usePaymentAuthorizationAlert();
  const alert = useContext(AlertContext);
  const toast = useContext(NotificationContext);

  const currency = useStoreState(
    (state) => settingsStore(state).defaultCurrency,
  );

  const { showAlert: showAddPaymentAlert } = useAddPaymentMethod({
    user: editing,
    onAddPaymentMethod: (method) =>
      addPaymentMethod({ userMemberId: loggedInId, clubId, ...method }),
    fetchBacsCheckoutId: () =>
      api.profileApi.attachPaymentMethodBacs(loggedInId, clubId),
  });

  const [invoicesFilter, setInvoicesFilter] = useState({});

  const {
    isFetching: loadingInvoices,
    data: {
      content: invoices,
      number: invoicesPage,
      totalPages: invoicesPageCount,
    },
    refetch: refreshInvoices,
  } = useMemberInvoiceListAsMember(
    {
      api,
      memberId: id,
      opts: invoicesFilter,
    },
    {
      enabled: typeof invoicesFilter?.page !== "undefined",
    },
  );
  const { payInvoiceMutation } = useMemberInvoiceAsMember({ api });

  const fetchInvoicesByUser = useCallback(
    (params) => {
      const allTypesButVoid = Object.values(InvoiceStatus).filter(
        (v) => v !== InvoiceStatus.Void,
      );

      setInvoicesFilter(
        merge({}, params, {
          extraParams: {
            userId: id,
            status: allTypesButVoid,
          },
        }),
      );
      return Promise.resolve();
    },
    [setInvoicesFilter],
  );

  const payInvoice = useCallback(
    (clubId, invoiceNumber, amount) => {
      return payInvoiceMutation.mutateAsync({
        clubId,
        invoiceNumber,
        amount,
      });
    },
    [payInvoiceMutation],
  );

  const sortedPaymentMethods = paymentMethods.reduce((acc, item) => {
    if (item.defaultPaymentMethod === true) {
      return [item, ...acc];
    }
    return [...acc, item];
  }, []);

  useEffect(() => {
    fetchById();
    fetchPaymentMethods({ userMemberId: loggedInId, clubId });
    return () => clearEditingRecord();
  }, [id, fetchById, clearEditingRecord, fetchPaymentMethods]);

  if (!editing) {
    return null;
  }

  return (
    <div className="content h-full overflow-y-auto p-8">
      <CardBodyWithSpinner
        isLoading={loadingPaymentMethods || loadingInvoices}
        className="p-0"
      >
        <Row>
          <Col className="d-flex justify-content-between">
            <h2>Payment Method</h2>
            {sortedPaymentMethods.length > 0 && (
              <Button className="btn-link" onClick={showAddPaymentAlert}>
                Add New Card
              </Button>
            )}
          </Col>
        </Row>
        {sortedPaymentMethods.length === 0 && (
          <Row>
            <Col>
              <span className="text-muted">No card saved, add new card </span>
              <Button className="btn-link" onClick={showAddPaymentAlert}>
                here
              </Button>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Carousel
              partialVisible={false}
              responsive={responsive}
              className="h-100"
              itemClass="p-2"
            >
              {sortedPaymentMethods.map((method) => (
                <div style={{ width: 250 }} key={method.id}>
                  <div className="d-flex justify-content-between w-100">
                    {method.defaultPaymentMethod ? (
                      <div>Default Card</div>
                    ) : (
                      <Button
                        className="btn-link"
                        onClick={async () => {
                          await alert.showWarning(
                            "You sure you want to make this the default payment method?",
                            {
                              title: "Confirmation",
                              onConfirm: async () => {
                                alert.hide();
                                try {
                                  await assignDefaultPaymentMethod({
                                    userMemberId: loggedInId,
                                    clubId,
                                    paymentMethod: method.id,
                                  });
                                } catch (e) {
                                  toast.notifyDanger(e);
                                }
                              },
                              confirmBtnText: "Yes, change it!",
                            },
                          );
                        }}
                      >
                        Make Default
                      </Button>
                    )}
                    <Button
                      color="danger"
                      className="btn-link"
                      onClick={() => {
                        alert.showWarning(
                          "Removing it will make it unavailable for future use.",
                          {
                            title:
                              "Are you sure you want to remove this method of payment?",
                            onConfirm: async () => {
                              alert.hide();
                              try {
                                await removePaymentMethod({
                                  userMemberId: loggedInId,
                                  clubId,
                                  paymentMethod: method.id,
                                });
                              } catch (e) {
                                toast.notifyDanger(e);
                              }
                            },
                            confirmBtnText: "Yes, remove it!",
                            confirmBtnBsStyle: "danger",
                          },
                        );
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                  <PaymentCard
                    brand={method.brand}
                    last4Digits={method.last4Digits}
                    // cardHolderName={method.cardHolderName}
                    cardHolderName={`${editing.firstName} ${editing.lastName}`}
                    expire={`${method?.expMonth}/${method?.expYear
                      .toString()
                      ?.slice(-2)}`}
                  />
                </div>
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <UserMemberInvoices
              isLoading={loadingInvoices}
              fetchInvoices={fetchInvoicesByUser}
              refreshInvoices={refreshInvoices}
              invoices={invoices}
              page={invoicesPage}
              pageCount={invoicesPageCount}
              userMemberId={id}
              currency={currency}
              requestAuthorization={async ({
                number,
                paymentIntentIdForAuthorization,
              }) => {
                const result =
                  await api.strongCustomerAuthorizationApi.confirmPayment(
                    paymentIntentIdForAuthorization,
                  );
                if (
                  result.data.result ===
                  StrongAuthorizationPaymentStatus.AuthorizationNotRequired
                ) {
                  await alert.show("The payment was confirmed.", {
                    title: "Success!",
                    showCancel: false,
                  });
                } else {
                  try {
                    await api.strongCustomerAuthorizationApi.sendEmail(number);
                  } catch (e) {
                    if (e.response.data.error_message) {
                      alert.showError(e.response.data.error_message);
                      refreshInvoices();
                      return;
                    }
                  }

                  const paymentConfirmationResult =
                    await showPaymentAuthorizationAlert({
                      paymentIntentIdForAuthorization,
                      confirmPayment:
                        api.strongCustomerAuthorizationApi.confirmPayment,
                      messageText: (
                        <>
                          Your bank has requested additional authorization to
                          make this payment.
                          <br />
                          Please check your email and click the authorization
                          link to complete this purchase.
                        </>
                      ),
                      hideCancel: true,
                    });
                  if (
                    paymentConfirmationResult.status ===
                    PaymentConfirmationStatus.Success
                  ) {
                    alert.show("The payment was confirmed.", {
                      title: "Success!",
                      showCancel: false,
                    });
                  } else if (
                    paymentConfirmationResult.status ===
                    PaymentConfirmationStatus.Waiting
                  ) {
                    toast.notify({
                      message: "Awaiting payment, check later.",
                      type: "warning",
                    });
                  } else if (
                    paymentConfirmationResult.status ===
                    PaymentConfirmationStatus.Failed
                  ) {
                    toast.notify({
                      message: "Payment Failed.",
                      type: "danger",
                    });
                  }
                  refreshInvoices();
                }
              }}
              cancelAuthorization={({ paymentIntentIdForAuthorization }) => {
                alert.setAlert(
                  <CancelAuthorizationAlert
                    onConfirm={async () => {
                      await api.strongCustomerAuthorizationApi.cancelAuthorization(
                        paymentIntentIdForAuthorization,
                      );
                      alert.hide();
                      await refreshInvoices();
                      toast.notify({ message: "Authorization cancelled." });
                    }}
                    onCancel={alert.hide}
                  />,
                );
              }}
              getInvoiceFile={(clubId, invoiceNumber) =>
                api.profileApi.invoiceFile(clubId, invoiceNumber)
              }
              payInvoice={payInvoice}
            />
          </Col>
        </Row>
      </CardBodyWithSpinner>
    </div>
  );
}

const ReceiptsWithProvider = () => {
  const { UserMemberStore } = useGymflowModels();
  return (
    <UserMemberStore.Provider>
      <Receipts />
    </UserMemberStore.Provider>
  );
};

export default ReceiptsWithProvider;
