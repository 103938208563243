import {
  AppointmentDTO,
  AppointmentPaymentPostDTO,
  InvoiceDTO,
} from "@gymflow/types";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { calendarQueryKeys } from "../calendar";

import { appointmentQueryKeys } from "./appointmentQueryKeys";

export function useAppointmentCollectPayment(
  {
    api,
  }: {
    api: {
      appointmentApi: {
        collectPayment: (args: AppointmentPaymentPostDTO) => Promise<{
          data: AppointmentCollectPaymentResponse;
        }>;
      };
    };
  },
  mutationOpts?: UseMutationOptions<
    AppointmentCollectPaymentResponse,
    unknown,
    AppointmentPaymentPostDTO
  >,
) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (paymentDetails: AppointmentPaymentPostDTO) => {
      const response = await api.appointmentApi.collectPayment(paymentDetails);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: appointmentQueryKeys.all() });
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.appointments(),
      });
    },
    ...mutationOpts,
  });

  return mutation;
}

interface AppointmentCollectPaymentResponse {
  readonly appointment: AppointmentDTO;
  readonly invoice: InvoiceDTO;
}
