import React from 'react';
import DropdownFilter from '../components/DropdownFilter';
import PropTypes from 'prop-types';

export const MarketingType = {
  SMS: 'SMS',
  EMAIL: 'Email',
};

const marketingOptions = Object.values(MarketingType).map((value) => ({ label: value, value }));

marketingOptions.unshift({
  label: 'All',
  value: null,
});

const MarketingFilter = ({ value, onChange }) => {
  return (
    <DropdownFilter
      placeholder="Marketing"
      options={marketingOptions}
      value={marketingOptions.find((option) => option.value === value)}
      onChange={onChange}
    />
  );
};

MarketingFilter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
  onChange: PropTypes.func.isRequired,
};

export default MarketingFilter;
