import { zonedTimeToUtc } from "@gymflow/helpers";
import { ApiListResponse, EventOccurrenceListItemDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import merge from "lodash/merge";
import omit from "lodash/omit";

import { defaultPage } from "../defaultPage";
import { eventOccurrenceQueryKeys } from "./eventOccurrenceQueryKeys";
import { eventOccurrenceToZonedTime } from "./eventOccurrenceToZonedTime";

const createApiParams = ({
  filter,
  tz,
}: {
  filter?: EventOccurrenceListFilter;
  tz: string;
}) => {
  const extraParams = { ...omit(filter, ["page", "limit", "sort"]) };

  if (filter?.["dateFrom"]) {
    extraParams["dateFrom"] = zonedTimeToUtc(filter["dateFrom"], tz);
  }

  if (filter?.["dateTo"]) {
    extraParams["dateTo"] = zonedTimeToUtc(filter["dateTo"], tz);
  }

  return {
    page: filter?.["page"],
    limit: filter?.["limit"],
    sort: filter?.["sort"],
    extraParams,
  };
};

export async function eventOccurrenceListQueryFn({
  filter,
  tz,
  api,
}: {
  filter?: EventOccurrenceListFilter;
  tz: string;
  api: EventOccurrenceListApi;
}) {
  const result = await api.eventApi.find(createApiParams({ filter, tz }));
  return result.data as ApiListResponse<EventOccurrenceListItemDTO>;
}

export function eventOccurrenceListSelectFn(
  data: ApiListResponse<EventOccurrenceListItemDTO>,
) {
  return merge({}, data, {
    content: data.content.map(eventOccurrenceToZonedTime),
  }) as ApiListResponse<EventOccurrenceListItemDTO>;
}

export function useEventOccurrenceList(
  {
    api,
    opts,
    tz,
  }: {
    api: EventOccurrenceListApi;
    opts?: EventOccurrenceListFilter;
    tz?: string;
  },
  queryOpts?: UseQueryOptions<ApiListResponse<EventOccurrenceListItemDTO>>,
) {
  const result = useQuery({
    queryKey: eventOccurrenceQueryKeys.list({ ...opts, tz }),
    placeholderData: defaultPage<EventOccurrenceListItemDTO>,
    queryFn: () => eventOccurrenceListQueryFn({ filter: opts, tz: tz!, api }),
    select: eventOccurrenceListSelectFn,
    enabled: !!tz && (!!opts?.["dateFrom"] || !!opts?.["dateTo"]),
    ...queryOpts,
  });
  return result;
}

export interface EventOccurrenceListFilter {
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc?: boolean;
  };
  readonly dateFrom?: string;
  readonly dateTo?: string;
  readonly unpaged?: boolean;
  readonly id?: number[];
  readonly ["event.activity.activityCategory.id"]?: number[];
  readonly ["event.activity.id"]?: number[];
  readonly ["event.facility.id"]?: number[];
  readonly ["event.isPublic"]?: boolean;
  readonly ["event.isBookable"]?: boolean;
  readonly ["event.userEventHost.id"]?: string[];
  readonly ["eventRsvpList.userMember.id"]?: string;
}

interface EventOccurrenceListApi {
  eventApi: {
    find: (arg0: { [k: string]: any }) => Promise<any>;
  };
}
