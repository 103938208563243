import {
  useMutationCreateLeadStatus,
  useMutationEditLeadStatus,
} from "@gymflow/api";
import { formikHelpers, NotificationContext } from "@gymflow/common";
import { Formik, useFormikContext } from "formik";
import { useContext } from "react";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { getDefaultsFromZodSchema } from "../../../helpers/zod";
import { ModalWrapper } from "../../../providers";
import useGymflowModels from "../../../store";
import { Button, ColorInput, FormikTextInput } from "../../atoms";

export const LeadStatusFormModal = ({
  leadStatusId,
  leadStatusName,
  onClose,
}: {
  leadStatusId?: number;
  leadStatusName?: string;
  leadStatusColor?: string;
  onClose: () => void;
}) => {
  const { api } = useGymflowModels();

  const { mutateAsync: createStatus } = useMutationCreateLeadStatus({ api });
  const { mutateAsync: editStatus } = useMutationEditLeadStatus({ api });

  const { notifyDanger, notify } = useContext(NotificationContext);

  const validationSchema = createValidationSchema(leadStatusName);

  return (
    <ModalWrapper onCancel={onClose}>
      <div className="mb-5 text-lg font-semibold text-gray-900">
        {leadStatusId ? "Edit Step" : "Add Step"}
      </div>
      <Formik
        enableReinitialize
        initialValues={
          getDefaultsFromZodSchema(validationSchema) as LeadStatusFormType
        }
        validationOnBlur
        validationSchema={toFormikValidationSchema(validationSchema)}
        onSubmit={async (values) => {
          if (leadStatusId) {
            try {
              await editStatus({
                columnId: leadStatusId,
                newName: values.name,
              });
              notify({ message: "Step Changed" });
            } catch (e) {
              notifyDanger(e);
            }
          } else {
            try {
              await createStatus({
                newName: values.name,
              });
              notify({ message: "Step Added" });
            } catch (e) {
              notifyDanger(e);
            }
          }
          onClose();
        }}
      >
        <LeadStatusForm onCancel={onClose} leadStatusId={leadStatusId} />
      </Formik>
    </ModalWrapper>
  );
};

function LeadStatusForm({
  onCancel,
  leadStatusId,
}: {
  onCancel: () => void;
  leadStatusId?: number;
}) {
  const formikProps = useFormikContext<LeadStatusFormType>();
  const { errorClass } = formikHelpers(formikProps);
  const { values, setFieldValue } = formikProps;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2">
        <div>
          <label className="mb-0 flex pb-2 text-sm font-medium text-gray-700">
            Step Name
          </label>
          <div className={errorClass("name")}>
            <FormikTextInput
              placeholder="Step Name"
              autoComplete="off"
              maxLength={128}
              name="name"
            />
          </div>
        </div>
        <div className="hidden">
          <label className="mb-0 flex pb-2 text-sm font-medium text-gray-700">
            Color
          </label>
          <div>
            <ColorInput
              onChange={(newColor) => {
                setFieldValue("color", newColor);
              }}
              value={values["color"]}
            />
          </div>
        </div>
      </div>

      <div className="flex gap-4">
        <Button className="flex-1" onClick={onCancel}>
          Cancel
        </Button>

        <Button
          intent="primary"
          className="flex-1"
          onClick={async () => {
            await formikProps.submitForm();
          }}
        >
          {leadStatusId ? "Rename" : "Create"}
        </Button>
      </div>
    </div>
  );
}

function createValidationSchema(name?: string, color?: string) {
  return z.object({
    name: z
      .string()
      .min(3, "Must be at least 3 characters")
      .default(name || ""),
    color: z.string().default(color || ""),
  });
}

type LeadStatusFormType = z.infer<ReturnType<typeof createValidationSchema>>;
