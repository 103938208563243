import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { hasStaffRole } from "@gymflow/api";
import { promiseState } from "@gymflow/helpers";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";

import { PublicFooter } from "../../../components/atoms";
import { useAuthenticatedUser } from "../AuthenticatedProvider";
import {
  LoginOverlayContext,
  OverlayContextType,
  OverlayState,
} from "./context";
import { LoginForm } from "./LoginForm";
import { TrySignUpOverlay } from "./TrySignUpOverlay";

export function LoginOverlayProvider({
  children,
}: {
  children: ((overlayContext: OverlayContextType) => ReactNode) | ReactNode;
}) {
  const auth = useAuthenticatedUser();
  const openPromiseRef = useRef(Promise.resolve({ loggedIn: false }));
  const openPromiseResolveRef = useRef<any>();

  const getOpenPromiseResolve = useCallback(async () => {
    const state = await promiseState(openPromiseRef.current);

    if (state !== "pending") {
      openPromiseRef.current = new Promise((resolve) => {
        openPromiseResolveRef.current = resolve;
      });
    }

    return openPromiseResolveRef.current;
  }, []);

  const [defaultEmail, setDefaultEmail] = useState("");
  const [overlayState, setOverlayState] = useState<OverlayState>("CLOSED");
  const showLoginOverlay = useCallback(
    async (email?: string) => {
      await getOpenPromiseResolve();
      if (email) {
        setDefaultEmail(email);
      }
      setOverlayState("LOGIN");
      return openPromiseRef.current;
    },
    [getOpenPromiseResolve],
  );
  const showSignUpOverlay = useCallback(
    async (skipEmailCheck?: boolean) => {
      await getOpenPromiseResolve();
      setOverlayState(skipEmailCheck ? "SIGNUP_FORM" : "SIGNUP_CHECK_EMAIL");
      return openPromiseRef.current;
    },
    [getOpenPromiseResolve],
  );
  const closeOverlay = useCallback(({ loggedIn }: { loggedIn: boolean }) => {
    setOverlayState("CLOSED");
    openPromiseResolveRef.current({ loggedIn });
  }, []);

  useEffect(() => {
    if (hasStaffRole(auth.roles)) {
      auth.logout();
    }
  }, []);

  return (
    <LoginOverlayContext.Provider
      value={{
        showLoginOverlay,
        showSignUpOverlay,
        closeOverlay,
      }}
    >
      <Overlay
        isOpen={overlayState !== "CLOSED"}
        hide={() => {
          closeOverlay({ loggedIn: false });
        }}
        overlayState={overlayState}
        defaultEmail={defaultEmail}
      />
      {typeof children === "function"
        ? children({ showLoginOverlay, showSignUpOverlay, closeOverlay })
        : children}
    </LoginOverlayContext.Provider>
  );
}

function Overlay({
  isOpen,
  hide,
  overlayState,
  defaultEmail,
}: {
  isOpen?: boolean;
  hide: () => void;
  overlayState: OverlayState;
  defaultEmail: string;
}) {
  return (
    <>
      <div
        className={classNames(
          `pointer-events-none absolute inset-0 z-[10000] backdrop-blur-[2px]`,
          { hidden: !isOpen },
        )}
      />
      <div
        onClick={hide}
        className={`${
          isOpen ? "opacity-10" : "pointer-events-none opacity-0"
        } bg-primary-700 absolute inset-0 z-[10001]`}
      />
      <Transition
        className="dark:bg-darkModeFill absolute inset-y-0 right-0 z-[10002] w-full overflow-hidden overflow-y-auto bg-white duration-300 ease-in-out"
        show={isOpen}
        enter="transition-transform"
        enterFrom="translate-y-full w-0"
        enterTo="translate-y-0"
        leave="transition-transform"
        leaveFrom="translate-y-0 w-0"
        leaveTo="translate-y-full"
      >
        <div className="flex h-full max-h-full flex-col justify-between">
          <div className="flex h-[4.75rem] w-screen flex-row-reverse items-center px-6">
            <FontAwesomeIcon
              onClick={() => {
                hide();
              }}
              className="cursor-pointer text-xl text-gray-600"
              icon={faClose}
            />
          </div>
          <div className="flex justify-center overflow-y-scroll">
            <div>
              {overlayState === "SIGNUP_CHECK_EMAIL" && (
                <TrySignUpOverlay defaultEmail={defaultEmail} />
              )}
              {overlayState === "SIGNUP_FORM" && (
                <TrySignUpOverlay
                  defaultEmail={defaultEmail}
                  defaultScreen="SIGN_UP_FORM"
                />
              )}
            </div>
            <div>
              {overlayState === "LOGIN" && (
                <LoginForm defaultEmail={defaultEmail} />
              )}
            </div>
          </div>
          <PublicFooter />
        </div>
      </Transition>
    </>
  );
}
