import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useMemberChangePicture(
  {
    api,
  }: {
    api: {
      memberApi: {
        updatePicture: (
          memberId: string,
          { blob, name }: { blob: string; name: string },
        ) => Promise<any>;
      };
    };
  },
  mutationOpts?: UseMutationOptions<unknown, unknown, { memberId: string }>,
) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      memberId,
      blob,
      filename,
    }: {
      memberId: string;
      blob: string;
      filename: string;
    }) => {
      return api.memberApi.updatePicture(memberId, { blob, name: filename });
    },
    onSuccess: (_, { memberId }: { memberId: string }) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
    },
    ...mutationOpts,
  });
  return mutation;
}
