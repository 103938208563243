import { zonedTimeToUtc } from "@gymflow/helpers";
import { AppointmentDTO, AppointmentPostDTO } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { cloneDeep } from "lodash";
import { Mutable } from "utility-types";

import { availabilityQueryKeys } from "../availability";
import { calendarQueryKeys } from "../calendar";
import { appointmentQueryKeys } from "./appointmentQueryKeys";

export function useAppointmentEdit({
  api,
  tz,
}: {
  api: {
    appointmentApi: {
      update: (
        appointmentId: number,
        fields: Partial<AppointmentPostDTO>,
      ) => Promise<{ data: AppointmentDTO }>;
    };
  };
  tz?: string;
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      patchedFields,
      appointmentId,
    }: {
      patchedFields: Partial<AppointmentPostDTO>;
      appointmentId: number;
    }) => {
      const utcPatchedFields: Mutable<Partial<AppointmentPostDTO>> =
        cloneDeep(patchedFields);
      if (utcPatchedFields.startDate) {
        utcPatchedFields.startDate = zonedTimeToUtc(
          utcPatchedFields.startDate,
          tz!,
        );
      }
      const response = await api.appointmentApi.update(
        appointmentId,
        utcPatchedFields,
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: appointmentQueryKeys.all() });
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.appointments(),
      });
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.hostCalculateAvailability(),
      });
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.hostCalculateAvailabilityMappedByHost(),
      });
    },
  });

  return mutation;
}
