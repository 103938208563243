import {
  useInfiniteQueryCalendarAppointments,
  useInfiniteQueryCalendarEventOccurrences,
} from "@gymflow/api";
import { PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import * as Sentry from "@sentry/browser";
import moment from "moment-timezone";
import { useEffect, useMemo } from "react";

import { useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { CalendarFilters } from "../../organisms";
import {
  CalendarAppointment,
  CalendarEvent,
  CalendarOccurrence,
} from "./types";
import { CalendarMode } from "./useCalendarMode";

export function useCalendarEvents({
  intervalLoaded,
  modeSelected,
  filteredStaffIds,
  filters,
  enabled = true,
}: {
  enabled?: boolean;
  intervalLoaded?: { start: Date; end: Date };
  modeSelected: CalendarMode;
  filteredStaffIds?: string[];
  filters: CalendarFilters;
}) {
  const { api } = useGymflowModels();
  const { timezone } = useClubSettings();
  const dateFrom =
    intervalLoaded?.start &&
    moment(intervalLoaded.start).format(PARAMETER_DATE_FORMAT_WITHOUT_TZ);
  const {
    hasNextPage: hasMoreOccurrences,
    data: occurrences,
    fetchNextPage: fetchNextOccurrences,
    isFetching: isFetchingOccurrences,
  } = useInfiniteQueryCalendarEventOccurrences(
    {
      api,
      tz: timezone,
      filters: {
        dateFrom,
        dateTo:
          intervalLoaded?.end &&
          moment(intervalLoaded.end).format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
        limit: 10,
        includeBookedCounts: true,
        includeWaitingCounts: true,
        activityId: filters.classes
          ? filters.classes.map(({ value }) => value)
          : undefined,
        activityCategoryId: filters.classCategory
          ? filters.classCategory.map(({ value }) => value)
          : undefined,
        eventHostId: filteredStaffIds,
        facilityId: filters?.facility
          ? filters.facility.map(({ value }) => value)
          : undefined,
      },
    },
    { enabled },
  );

  useEffect(() => {
    if (hasMoreOccurrences) {
      fetchNextOccurrences();
    }
  }, [
    hasMoreOccurrences,
    occurrences?.pageParams.length,
    fetchNextOccurrences,
  ]);

  const {
    hasNextPage: hasMoreAppointments,
    data: appointments,
    fetchNextPage: fetchNextAppointmentsPage,
    isFetching: isFetchingAppointments,
    refetch: refetchAppointments,
  } = useInfiniteQueryCalendarAppointments(
    {
      api,
      tz: timezone,
      filters: {
        dateFrom,
        dateTo:
          intervalLoaded?.end &&
          moment(intervalLoaded.end).format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
        limit: 10,
        appointmentHostId: filteredStaffIds,
        appointableIds: filters.appointments
          ? filters.appointments.map(({ value }) => value)
          : undefined,
        appointableCategoryIds: filters.appointmentCategory
          ? filters.appointmentCategory.map(({ value }) => value)
          : undefined,
      },
    },
    { enabled },
  );

  useEffect(() => {
    if (hasMoreAppointments) {
      fetchNextAppointmentsPage();
    }
  }, [
    hasMoreAppointments,
    appointments?.pageParams.length,
    fetchNextAppointmentsPage,
  ]);

  const calendarEvents = useMemo(() => {
    const mappedOccurrences: CalendarEvent[] =
      occurrences?.pages
        .flatMap((t) => t)
        .map((occurrence) => {
          let resourceId;
          if (modeSelected === "TRAINER") {
            resourceId = occurrence.hostStaffId;
          }
          if (modeSelected === "FACILITY") {
            resourceId = occurrence.facilityId;
          }
          return {
            id: `occurrence-${occurrence.eventOccurrenceId}`,
            occurrenceId: occurrence.eventOccurrenceId,
            title: occurrence.activityName,
            start: occurrence.startDate,
            end: occurrence.endDate,
            hostName: occurrence.hostName,
            hostId: occurrence.hostStaffId,
            type: "OCCURRENCE",
            capacity: occurrence?.capacity,
            waitListCapacity: occurrence?.waitListCapacity,
            bookedCount: occurrence?.bookedCount,
            waitingCount: occurrence?.waitingCount,
            resourceId,
          } as CalendarOccurrence;
        })
        .filter(() => {
          if (modeSelected === "APPOINTMENTS") {
            return false;
          }
          return !filters.eventType || filters.eventType === "OCCURRENCE";
        }) || [];

    const mappedAppointments: CalendarEvent[] =
      appointments?.pages
        .flatMap((t) => t)
        .map((appointment) => {
          let resourceId;
          if (modeSelected === "TRAINER") {
            resourceId = appointment.hostStaffId;
          }
          if (modeSelected === "FACILITY") {
            resourceId = appointment.facilityId;
          }
          return {
            id: `appointment-${appointment.appointmentId}`,
            appointmentId: appointment.appointmentId,
            title: appointment.appointableName,
            start: appointment.startDate,
            end: appointment.endDate,
            hostName: appointment.hostName,
            hostId: appointment.hostStaffId,
            participantName: appointment.participantName,
            appointmentStatus: appointment.status,
            type: "APPOINTMENT",
            resourceId,
            paymentStatus: appointment.paymentStatus,
          } as CalendarAppointment;
        })
        .filter((appointment) => {
          if (modeSelected === "CLASSES") {
            return false;
          }
          if (appointment.appointmentStatus === "CANCELLED") {
            return false;
          }
          return !filters.eventType || filters.eventType === "APPOINTMENT";
        }) || [];

    return mappedOccurrences.concat(mappedAppointments).map((calendarEvent) => {
      const startMoment = moment(
        calendarEvent.start,
        PARAMETER_DATE_FORMAT_WITHOUT_TZ,
      );
      const endMoment = moment(
        calendarEvent.end,
        PARAMETER_DATE_FORMAT_WITHOUT_TZ,
      );
      if (endMoment.isSameOrBefore(startMoment)) {
        console.info("Calendar Event with bad data:", calendarEvent);
        Sentry.captureMessage(
          `Calendar event has endDate before startDate. id: ${calendarEvent.id}`,
          "warning",
        );
        return {
          ...calendarEvent,
          end: startMoment
            .clone()
            .add(15, "minutes")
            .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
        };
      }
      return calendarEvent;
    });
  }, [
    appointments?.pages,
    filters.eventType,
    occurrences?.pages,
    modeSelected,
  ]);

  return {
    calendarEvents,
    isFetching:
      isFetchingAppointments ||
      isFetchingOccurrences ||
      hasMoreAppointments ||
      hasMoreOccurrences,
    refetchAppointments,
  };
}
