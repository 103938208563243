import React, { ReactNode } from "react";

export interface SettingsContainerProps {
  title: ReactNode;
  subTitle: string;
  actions?: ReactNode;
  children: ReactNode;
}

export const SettingsContainer: React.FC<SettingsContainerProps> = ({
  title,
  actions,
  children,
  subTitle,
}) => {
  return (
    <div className="flex h-full max-h-full w-full flex-col gap-4">
      <div className="flex w-full flex-row flex-wrap items-center justify-between gap-4">
        <div className="flex flex-col items-start">
          <div className="flex text-lg font-semibold">{title}</div>
          <div className="flex text-sm text-gray-600">{subTitle}</div>
        </div>
        {actions && (
          <div className="flex flex-row flex-wrap items-center justify-end gap-2">
            {actions}
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
