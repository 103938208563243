import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import PropTypes from "prop-types";
import { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { Media } from "reactstrap";
import { twMerge } from "tailwind-merge";

function AddUserInput({
  value,
  placeholder,
  placeholderAvatar,
  excludeIds,
  onChange,
  fetchUsers,
  styles,
  isDisabled = false,
}) {
  const renderObfuscatedPhoneNumber = (mobileNumber) => {
    if (!mobileNumber) {
      return "";
    }
    return (
      mobileNumber.substring(0, 5) +
      "*".repeat(mobileNumber.length - 8) +
      mobileNumber.substring(mobileNumber.length - 3)
    );
  };

  const loadOptions = async (term, _, { page }) => {
    if (term === "") {
      return {
        options: [{ label: "Type a name to search", isDisabled: true }],
        hasMore: false,
      };
    }
    const { data } = await fetchUsers(term, { limit: 50 });
    const rows = data.map((user) => {
      const disableResult = excludeIds.includes(user.id);
      return {
        label: (
          <div
            className={classNames("d-flex align-items-center w-100 h-100", {
              "text-muted": disableResult,
            })}
          >
            <Media
              className="w-100"
              style={{ cursor: disableResult ? "not-allowed" : "pointer" }}
            >
              <Media left href="#">
                <Media
                  object
                  src={user.picture || placeholderAvatar}
                  style={{
                    borderRadius: 16,
                    height: 27,
                    width: 27,
                  }}
                />
              </Media>
              <Media
                body
                className="d-flex justify-content-between"
                style={{
                  marginLeft: 15,
                }}
              >
                <div>
                  <Media
                    className={classNames({ "text-muted": disableResult })}
                    style={{
                      fontSize: "0.75rem",
                      fontWeight: 400,
                      color: "#0a0d16",
                      marginBottom: 0,
                    }}
                  >
                    {`${user.firstName} ${user.lastName}`}
                  </Media>
                  {user.mobileNumber && (
                    <p
                      style={{
                        fontSize: "0.625rem",
                        fontWeight: 400,
                        color: "#9d9ea2",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      {renderObfuscatedPhoneNumber(user.mobileNumber)}
                    </p>
                  )}
                </div>
              </Media>
            </Media>
          </div>
        ),
        value: user,
        isDisabled: disableResult,
      };
    });
    return {
      options: rows,
      hasMore: false,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <AsyncPaginate
      isDisabled={isDisabled}
      additional={{
        page: 0,
      }}
      className="react-select react-select-sm info"
      classNamePrefix="react-select"
      loadOptions={loadOptions}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      menuContainerStyle={{ zIndex: "99999 !important" }}
      styles={{
        control: (provided) => ({
          ...provided,
          background: "#f5f6f6 !important",
          borderRadius: "20px !important",
          border: 0,
          minHeight: "35px !important",
          height: "unset !important",
        }),

        indicatorsContainer: (provided) => ({ ...provided, display: "none" }),

        ...styles,
      }}
      components={{
        ValueContainer: ({ children, className, ...props }) => {
          return (
            <>
              <div className="pl-4">
                <FontAwesomeIcon icon={faSearch} style={{ fontSize: 18 }} />
              </div>
              <components.ValueContainer
                className={twMerge(
                  classNames(
                    "text-left font-semibold [&>div]:text-gray-500",
                    className,
                  ),
                )}
                {...props}
              >
                {children}
              </components.ValueContainer>
            </>
          );
        },
      }}
    />
  );
}
AddUserInput.defaultProps = {
  excludeIds: [],
  placeholder: "Select a user to add to the guest list",
};

AddUserInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  excludeIds: PropTypes.array,
};

export default AddUserInput;
