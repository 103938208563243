import { ClubSettings, FeatureFlags } from "@gymflow/types";
import { ReactNode } from "react";

import accessIcon from "../../assets/img/access.svg";
import calendarIcon from "../../assets/img/calendar-icon.svg";
import checkDoneIcon from "../../assets/img/check-done-icon.svg";
import filterFunnelIcon from "../../assets/img/filter-funnel-icon.svg";
import homeIcon from "../../assets/img/home_icon.svg";
import pieChartIcon from "../../assets/img/pie-chart-icon.svg";
import roadmapIcon from "../../assets/img/roadmap-icon.svg";
import settingsIcon from "../../assets/img/settings-icon.svg";
import shoppingBagIcon from "../../assets/img/shopping-bag-icon.svg";
import tagIcon from "../../assets/img/tag-icon.svg";
import usersIcon from "../../assets/img/users-icon.svg";
import CheckIn from "../components/Kiosk/CheckIn";
import {
  Calendar,
  FacilityAvailability,
  KisiIntegration,
  LeadPipeline,
  ScheduleAppointment,
  SiteNotAvailable,
  SiteRegistration,
  StaffAvailability,
} from "../components/pages";
import { AppointableWizardRoutes } from "../components/Settings/Appointable/AppointableWizard";
import { BuyMembership as BuyMembershipSite } from "../components/site/BuyMembership";
import { BuySessionPack as BuySessionPackSite } from "../components/site/BuySessionPack";
import { Enquiry } from "../components/site/Enquiry";
import { GuestRegistration } from "../components/site/GuestRegistration";
import { SaleCompleteSca } from "../components/site/SaleCompleteSca";
import { SecurePayment } from "../components/site/SecurePayment";
import { Timetable } from "../components/site/Timetable";
import { Subject, SubjectType } from "../permissions";
import { Access } from "../views/Access";
import Account from "../views/Account";
import Buy from "../views/Buy";
import { Dashboard } from "../views/Dashboard";
import { EventOccurrence } from "../views/EventOccurrence";
import LeadProfile from "../views/LeadProfile";
import Leads from "../views/Leads";
import { Marketing } from "../views/Marketing";
import Profile from "../views/Profile";
import Receipts from "../views/Receipts";
import { ClassesRun } from "../views/reports/ClassesRun";
import { EventAttendance } from "../views/reports/EventAttendance";
import { LateCancellations } from "../views/reports/LateCancellations";
import { LeadOpen } from "../views/reports/LeadOpen";
import { LeadStatus } from "../views/reports/LeadStatus";
import { MembershipEnding } from "../views/reports/MembershipEnding";
import { MembershipOverdue } from "../views/reports/MembershipOverdue";
import { MembershipPaused } from "../views/reports/MembershipPaused";
import { MembershipPausing } from "../views/reports/MembershipPausing";
import { MembershipPending } from "../views/reports/MembershipPending";
import { MembershipResuming } from "../views/reports/MembershipResuming";
import { MembershipSold } from "../views/reports/MembershipSold";
import { NoShows } from "../views/reports/NoShows";
import { ReportHome } from "../views/reports/ReportHome";
import { reportHomeRoutes } from "../views/reports/reportHomeRoutes";
import { RevenueAccountsReceivable } from "../views/reports/RevenueAccountsReceivable";
import { RevenueSchedule } from "../views/reports/RevenueSchedule";
import { RevenueTotal } from "../views/reports/RevenueTotal";
import { RevenueTransaction } from "../views/reports/RevenueTransaction";
import { Roadmap } from "../views/Roadmap";
import Sales from "../views/Sales";
import { Sandbox } from "../views/Sandbox";
import { Settings } from "../views/Settings";
import { Tasks } from "../views/Tasks";
import { Unauthorized } from "../views/Unauthorized";
import UserCalendar from "../views/UserCalendar";
import { Dashboard as UserDashboard } from "../views/UserDashboard";
import { UserMember } from "../views/UserMember";
import UserMemberList from "../views/UserMemberList";
import { UserMemberListNew } from "../views/UserMemberListNew";
import { RouteFeature, RouteFeatureSite } from "./feature";

export * from "./feature";
export * from "./layout";

export function generateStaffRoutes({
  featureFlags,
}: {
  featureFlags?: FeatureFlags | null;
}): RouteCollection {
  return [
    {
      path: RouteFeature.Dashboard,
      name: "Dashboard",
      component: Dashboard,
      icon: homeIcon,
      permission: Subject.Dashboard,
    },
    {
      path: RouteFeature.UserMember,
      name: "",
      component: UserMember,
      invisible: true,
      permission: Subject.MemberList,
    },
    {
      path: RouteFeature.Sales,
      name: "Sales",
      icon: shoppingBagIcon,
      component: Sales,
      permission: Subject.Sales,
    },
    {
      path: RouteFeature.Calendar,
      name: "Calendar",
      icon: calendarIcon,
      component: Calendar,
      permission: Subject.StaffCalendar,
    },
    {
      path: RouteFeature.EventOccurrence,
      name: "Event Occurrence",
      component: EventOccurrence,
      permission: Subject.StaffCalendar,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.Access,
      name: "Access",
      icon: accessIcon,
      component: Access,
      permission: Subject.Access,
    },
    {
      path: RouteFeature.Leads,
      name: "Leads",
      icon: filterFunnelIcon,
      component: featureFlags?.featureFlags.LEAD_PIPELINE_REDESIGN
        ? LeadPipeline
        : Leads,
      permission: Subject.Leads,
    },
    {
      path: RouteFeature.LeadProfile,
      name: "",
      component: LeadProfile,
      invisible: true,
      permission: Subject.Leads,
    },
    {
      path: RouteFeature.UserMemberList,
      name: "Users",
      icon: usersIcon,
      component: featureFlags?.featureFlags.MEMBER_LIST_REDESIGN
        ? UserMemberListNew
        : UserMemberList,
      permission: Subject.MemberList,
    },

    {
      path: "/sandbox",
      name: "Component Sandbox",
      component: Sandbox,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.Task,
      name: "Tasks",
      icon: checkDoneIcon,
      component: Tasks,
      permission: Subject.Task,
    },
    {
      path: RouteFeature.Marketing,
      name: "Marketing",
      icon: tagIcon,
      component: Marketing,
      permission: Subject.Marketing,
    },
    {
      path: RouteFeature.Reports,
      name: "Reports",
      icon: pieChartIcon,
      component: ReportHome,
      children: [
        {
          path: `${RouteFeature.Reports}${reportHomeRoutes.Revenue}`,
          name: "Revenue",
          component: ReportHome,
        },
        {
          path: `${RouteFeature.Reports}${reportHomeRoutes.Members}`,
          name: "Members",
          component: ReportHome,
        },
        {
          path: `${RouteFeature.Reports}${reportHomeRoutes.Leads}`,
          name: "Leads",
          component: ReportHome,
        },
        {
          path: `${RouteFeature.Reports}${reportHomeRoutes.Events}`,
          name: "Classes",
          component: ReportHome,
        },
      ],
      permission: Subject.Reports,
    },
    {
      path: RouteFeature.OverdueMembers,
      name: "Overdue Members",
      component: MembershipOverdue,
      navbarVisible: false,
      invisible: true,
    },
    {
      path: RouteFeature.MembershipEnding,
      name: "Memberships Ending",
      component: MembershipEnding,
      navbarVisible: false,
      invisible: true,
    },
    {
      path: RouteFeature.PendingMemberships,
      name: "Pending Memberships",
      component: MembershipPending,
      navbarVisible: false,
      invisible: true,
    },
    {
      path: RouteFeature.EventAttendance,
      name: "Event Attendance",
      component: EventAttendance,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.PausedMemberships,
      name: "Paused Memberships",
      component: MembershipPaused,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.PausingMemberships,
      name: "Pausing Memberships",
      component: MembershipPausing,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.ResumingMemberships,
      name: "Resuming Memberships",
      component: MembershipResuming,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.MembershipSold,
      name: "Memberships Sold",
      component: MembershipSold,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.NewLeads,
      name: "New Leads",
      component: () => <LeadStatus leadStatus="NEW_LEAD" />,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.OpenedLeads,
      name: "Open Leads",
      component: () => <LeadOpen />,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.ClosedLeads,
      name: "Closed Leads",
      component: () => <LeadStatus leadStatus="DEAL_CLOSED" />,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.LostLeads,
      name: "Lost Leads",
      component: () => <LeadStatus leadStatus="DEAL_LOST" />,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.Revenue,
      name: "Revenue",
      component: RevenueTotal,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.RevenueTransaction,
      name: "Revenue",
      component: RevenueTransaction,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.RevenueSchedule,
      name: "Revenue",
      component: RevenueSchedule,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.RevenueAccountReceivable,
      name: "Revenue",
      component: RevenueAccountsReceivable,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.ClassesRun,
      name: "Classes Run",
      component: ClassesRun,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.NoShows,
      name: "No Shows",
      component: NoShows,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.LateCancellations,
      name: "Late Cancellations",
      component: LateCancellations,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.KisiIntegration,
      name: "Kisi Integration",
      component: KisiIntegration,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.AppointableWizard,
      name: "Appointable Wizard",
      component: AppointableWizardRoutes,
      permission: Subject.Settings,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.StaffAvailability,
      name: "Staff Availability",
      component: StaffAvailability,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.FacilityAvailability,
      name: "Facility Availability",
      component: FacilityAvailability,
      invisible: true,
      navbarVisible: false,
    },
    {
      path: RouteFeature.Roadmap,
      name: "Roadmap",
      icon: roadmapIcon,
      component: Roadmap,
      align: "bottom",
    },
    {
      path: RouteFeature.Settings,
      name: "Settings",
      icon: settingsIcon,
      component: Settings,
      permission: Subject.Settings,
      align: "bottom",
    },
  ];
}

export const kiosk: RouteCollection = [
  {
    path: RouteFeature.KioskCheckIn,
    name: "Kiosk Check In",
    component: CheckIn,
    invisible: true,
  },
];

export const member: RouteCollection = [
  {
    path: RouteFeature.Dashboard,
    name: "Dashboard",
    component: UserDashboard,
    icon: homeIcon,
    permission: Subject.MyProfile,
  },
  {
    path: RouteFeature.Profile,
    name: "Edit Profile",
    icon: usersIcon,
    component: Profile,
    permission: Subject.MyProfile,
  },
  {
    path: RouteFeature.Account,
    name: "Account",
    icon: usersIcon,
    component: Account,
    permission: Subject.MyProfile,
  },
  {
    path: RouteFeature.CustomerSale,
    name: "Shop",
    icon: tagIcon,
    component: Buy,
    permission: Subject.CustomerSales,
  },
  {
    path: RouteFeature.Calendar,
    name: "Book",
    icon: calendarIcon,
    component: UserCalendar,
    permission: Subject.UserCalendar,
  },
  {
    path: RouteFeature.Receipts,
    name: "Payments",
    icon: shoppingBagIcon,
    component: Receipts,
    permission: Subject.MyProfile,
  },
];

export function generateSiteRoutes(
  featureFlags?: FeatureFlags | null,
): RouteDefinition[] {
  return [
    {
      path: RouteFeature.SiteBuyMembership,
      name: "Memberships",
      component: BuyMembershipSite,
    },
    {
      path: RouteFeature.SiteBuySessionPack,
      name: "Credit Packs",
      component: BuySessionPackSite,
    },
    {
      path: RouteFeature.SiteTimetable,
      name: "Schedule",
      component: Timetable,
    },
    ...(featureFlags?.featureFlags.HOSTED_PAGES_GUEST_REGISTRATION_REDESIGN
      ? []
      : [
          {
            path: RouteFeature.SiteGuestRegistration,
            name: "Guest Registration",
            component: GuestRegistration,
            invisible: true,
          },
        ]),

    {
      path: RouteFeature.SiteEnquiry,
      name: "Contact Us",
      component: Enquiry,
    },
    {
      path: RouteFeature.SiteProcessingSca,
      name: "Processing Payment Authorization",
      component: SaleCompleteSca,
      invisible: true,
    },
    {
      path: RouteFeature.SiteSaleComplete,
      name: "Sale Complete",
      component: SaleCompleteSca,
      invisible: true,
    },
    {
      path: RouteFeature.SiteSecurePayment,
      name: "Payment Authorization",
      component: SecurePayment,
      invisible: true,
    },
  ];
}

export function generateSiteWithAuthRoutes(
  featureFlags?: FeatureFlags | null,
  clubSettings?: ClubSettings | null,
): RouteDefinition[] {
  return [
    {
      path: RouteFeatureSite.Appointments,
      name: "Appointments",
      component: ScheduleAppointment,
      invisible: !clubSettings?.appointmentsNavEnabled,
    },
    ...(featureFlags?.featureFlags.HOSTED_PAGES_GUEST_REGISTRATION_REDESIGN
      ? [
          {
            path: RouteFeature.SiteGuestRegistration,
            name: "Guest Registration",
            component: SiteRegistration,
            invisible: true,
          },
        ]
      : []),
    {
      path: RouteFeatureSite.NotAvailable,
      name: "Not Available",
      component: SiteNotAvailable,
      invisible: true,
    },
  ];
}

export const authentication: RouteDefinition[] = [
  {
    path: RouteFeature.Unauthorized,
    name: "Unauthorized",
    component: Unauthorized,
    invisible: true,
  },
];

export interface BaseRouteDefinition {
  readonly name: string;
  readonly invisible?: boolean;
  readonly icon?: string;
  readonly permission?: SubjectType;
  readonly align?: "bottom";
  readonly menuItemClassName?: string;
}

export interface ParentRouteDefinition extends BaseRouteDefinition {
  readonly path: string;
  readonly navbarVisible?: boolean;
  readonly component: () => ReactNode;
  readonly children: RouteDefinition[];
}

export interface RouteDefinition extends BaseRouteDefinition {
  readonly path: string;
  readonly component: (props?: any) => ReactNode;
  readonly navbarVisible?: boolean;
}

export type RouteCollection = (ParentRouteDefinition | RouteDefinition)[];
