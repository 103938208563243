import { zonedTimeToUtc } from "@gymflow/helpers";
import { LeadPipelineItem } from "@gymflow/types";
import { useInfiniteQuery } from "@tanstack/react-query";

import { leadQueryKeys } from "./leadQueryKeys";

export function useInfiniteQueryLeadList(
  {
    api,
    opts = {},
    tz,
  }: {
    api: {
      leadApi: {
        list: (filter: LeadListFilter) => Promise<{
          data: { content: LeadPipelineItem[]; nextPageToken?: string };
        }>;
      };
    };
    opts?: LeadListFilter;
    tz: string;
  },
  queryOpts?: { enabled?: boolean },
) {
  const result = useInfiniteQuery({
    queryKey: leadQueryKeys.list(opts),
    queryFn: async ({ pageParam }) => {
      const parsedOpts = { ...opts, nextPageToken: pageParam };
      if (opts?.createdFrom) {
        parsedOpts.createdFrom = zonedTimeToUtc(opts.createdFrom, tz);
      }
      if (opts?.createdTo) {
        parsedOpts.createdTo = zonedTimeToUtc(opts.createdTo, tz);
      }

      const result = await api.leadApi.list(parsedOpts);
      return result.data;
    },
    getNextPageParam: (lastPage) => {
      return lastPage.nextPageToken;
    },
    enabled: !!tz,
    ...queryOpts,
  });
  return result;
}

interface LeadListFilter {
  createdFrom?: string;
  createdTo?: string;
  smsCommunication?: boolean;
  emailCommunication?: boolean;
  leadStatusId?: number[];
  leadSourceId?: number[];
  includeNotCompleteTasks?: boolean;
}
