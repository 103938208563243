import { ApiListResponse, InvoiceDTO, InvoiceStatus } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { memberQueryKeys } from "./memberQueryKeys";

export async function memberInvoiceListQueryFn({
  api,
  memberId,
  filter,
}: {
  api: MemberInvoiceListApi;
  memberId: string;
  filter: InvoiceListFilter;
}) {
  const result = await api.memberApi.findInvoices(memberId, filter);
  return result.data as ApiListResponse<InvoiceDTO>;
}

export function useMemberInvoiceList(
  {
    api,
    memberId,
    opts,
  }: {
    api: MemberInvoiceListApi;
    memberId: string;
    opts: InvoiceListFilter;
  },
  queryOpts: UseQueryOptions<ApiListResponse<InvoiceDTO>>,
) {
  const result = useQuery({
    queryKey: memberQueryKeys.invoices(memberId, opts),
    queryFn: () => memberInvoiceListQueryFn({ api, memberId, filter: opts }),
    initialData: defaultPage<InvoiceDTO>,
    ...queryOpts,
  });
  return result;
}

interface InvoiceListFilter {
  readonly extraParams?: {
    readonly status?: InvoiceStatus;
    readonly number?: string;
    readonly totalAmount?: number;
    readonly issueDate?: string;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc?: boolean;
  };
}

interface MemberInvoiceListApi {
  memberApi: {
    findInvoices: (
      memberId: string,
      opts: { [k: string]: any },
    ) => Promise<any>;
  };
}
