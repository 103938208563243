import { useLead, useMember } from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { Popover, Transition } from "@headlessui/react";
import { Fragment, ReactNode, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";

import { copyToClipboard } from "../../helpers";
import { usePortalRoutes } from "../../hooks";
import { useClubSettings } from "../../providers";
import { RouteFeature, RouteLayout } from "../../routes";
import useGymflowModels from "../../store";
import { Button, CopyIcon, Spinner } from "../atoms";
import { NewUserSideBarProviderContext } from "../molecules";

const timeoutDuration = 120;
export function MemberCard({
  isOpen,
  leadId,
  memberId,
  children,
}: {
  isOpen: boolean;
  leadId: number;
  memberId?: string;
  children: ReactNode;
}) {
  const triggerRef = useRef<any>();
  const timeOutRef = useRef<any>();

  const handleEnter = (isOpen: boolean) => {
    clearTimeout(timeOutRef.current);
    !isOpen && triggerRef.current?.click();
  };

  const handleLeave = (isOpen: boolean) => {
    timeOutRef.current = setTimeout(() => {
      isOpen &&
        document.activeElement === triggerRef.current &&
        triggerRef.current?.click();
    }, timeoutDuration);
  };

  return (
    <Popover className="relative">
      {({ open }) => (
        <div
          onMouseEnter={() => handleEnter(open)}
          onMouseLeave={() => handleLeave(open)}
        >
          <Popover.Button as="div" ref={triggerRef}>
            {children}
          </Popover.Button>
          <Transition
            show={isOpen}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-[99999] mt-3 -translate-x-1/4 -translate-y-4 px-4">
              <div
                className="flex flex-col gap-2 rounded bg-white p-4 drop-shadow"
                data-no-dnd={true}
              >
                {memberId ? (
                  <MemberContents memberId={memberId} />
                ) : (
                  <LeadContents leadId={leadId} />
                )}
              </div>
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
}

function LeadContents({ leadId }: { leadId: number }) {
  const { api } = useGymflowModels();
  const { data, isFetching } = useLead({ api, leadId });
  const { open } = useContext(NewUserSideBarProviderContext);
  const { createClubLink } = usePortalRoutes();
  const history = useHistory();
  if (isFetching || !data) {
    return <Spinner />;
  }
  return (
    <>
      <div className="text-lg font-bold text-gray-900">
        {data.firstName} {data.lastName}
      </div>
      <div className="flex gap-4 text-sm font-normal text-gray-700">
        <div>{data.email}</div>
        <div
          onClick={async () => {
            await copyToClipboard(data.email);
          }}
        >
          <CopyIcon pathClassName="stroke-gray-400" />
        </div>
      </div>
      <div
        className={cn("flex gap-4 text-sm font-normal text-gray-700", {
          hidden: !data.mobileNumber,
        })}
      >
        <div>{data.mobileNumber}</div>
        <div
          onClick={async () => {
            await copyToClipboard(data.mobileNumber);
          }}
        >
          <CopyIcon pathClassName="stroke-gray-400" />
        </div>
      </div>
      <div className="text-sm font-normal text-gray-700">
        {data.source.name}
      </div>
      <div className="flex">
        <Button
          intent="secondaryWithoutBg"
          className="flex-1"
          onClick={() => {
            open({
              creationMode: "MEMBER",
              defaultFormValues: {
                email: data.email,
                "mobile-number": data.mobileNumber,
                "first-name": data.firstName,
                "last-name": data.lastName,
                source: data.source.name,
              },
              onClose: async (arg) => {
                if (arg?.userType === "MEMBER") {
                  history.push(
                    createClubLink(
                      RouteLayout.Staff,
                      RouteFeature.UserMember.replace(":id", arg.userMemberId),
                    ),
                  );
                }
              },
            });
          }}
        >
          Setup Account
        </Button>
      </div>
    </>
  );
}

function MemberContents({ memberId }: { memberId: string }) {
  const { api } = useGymflowModels();
  const { timezone } = useClubSettings();
  const { data, isFetching } = useMember({ api, memberId, tz: timezone });
  const { createClubLink } = usePortalRoutes();
  const history = useHistory();
  if (isFetching || !data) {
    return <Spinner />;
  }
  return (
    <>
      <div className="text-lg font-bold text-gray-900">
        {data.user.firstName} {data.user.lastName}
      </div>
      <div className="flex gap-4 text-sm font-normal text-gray-700">
        <div>{data.user.email}</div>
        <div
          onClick={async () => {
            await copyToClipboard(data.user.email);
          }}
        >
          <CopyIcon pathClassName="stroke-gray-400" />
        </div>
      </div>
      <div
        className={cn("flex gap-4 text-sm font-normal text-gray-700", {
          hidden: !data.user.mobileNumber,
        })}
      >
        <div>{data.user.mobileNumber}</div>
        <div
          onClick={async () => {
            await copyToClipboard(data.user.mobileNumber);
          }}
        >
          <CopyIcon pathClassName="stroke-gray-400" />
        </div>
      </div>
      <div className="text-sm font-normal text-gray-700">
        {data.user.lead.source.name}
      </div>
      <div className="flex">
        <Button
          className="flex-1"
          onClick={() => {
            history.push(
              createClubLink(
                RouteLayout.Staff,
                RouteFeature.UserMember.replace(":id", memberId),
              ),
            );
          }}
        >
          View Profile
        </Button>
      </div>
    </>
  );
}
