import { useStoreState } from "easy-peasy";
import { ServiceType } from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import useGymflowModels from "../store";

/**
 * @deprecated
 */
function useMembershipLoadOptions() {
  const { api, settingsStore } = useGymflowModels();
  const { defaultCurrency: currency } = useStoreState(settingsStore);
  const loadOptions = async (_, __, { page }) => {
    const results = await api.serviceApi.find({ page });

    const options = results.data.content.map(({ id, name, type, nextMonthlyPayment, defaultPrice }) => ({
      value: { id, name },
      label: (
        <>
          {name}{" "}
          <span className="text-muted">{`(${formatCurrency(
            type === ServiceType.Recurring ? nextMonthlyPayment : defaultPrice,
            currency,
          )})`}</span>
        </>
      ),
    }));

    if (page === 0) {
      options.unshift({
        label: "All",
        value: null,
      });
    }

    return {
      options,
      hasMore: !results.data.last,
      additional: {
        page: page + 1,
      },
    };
  };

  return loadOptions;
}

export default useMembershipLoadOptions;
