// @ts-ignore
import { tzDateTimeStringToUtc } from "@gymflow/common";
import { useMutation } from "@tanstack/react-query";

export function useAccessReportCsv({
  api,
  tz,
}: {
  api: {
    accessApi: {
      csv: (params: {
        dateFrom?: string;
        dateTo?: string;
        membershipIncluded?: string[];
        onSite?: boolean;
        guest?: boolean;
      }) => Promise<any>;
    };
  };
  tz: string;
}) {
  const csvMutation = useMutation({
    mutationFn: async ({
      dateFrom,
      dateTo,
      membershipIncluded,
      onSite,
      guest,
    }: {
      dateFrom?: string;
      dateTo?: string;
      membershipIncluded?: string[];
      onSite?: boolean;
      guest?: boolean;
    }) => {
      const result = await api.accessApi.csv({
        ...(dateFrom !== undefined
          ? { dateFrom: tzDateTimeStringToUtc(dateFrom, tz) }
          : {}),
        ...(dateTo !== undefined
          ? { dateTo: tzDateTimeStringToUtc(dateTo, tz) }
          : {}),
        membershipIncluded,
        onSite,
        guest,
      });
      return result.data as string;
    },
  });
  return csvMutation;
}
