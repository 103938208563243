import PropTypes from 'prop-types';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { useFormik } from 'formik';
import { FormikInput, RequiredFieldsLabel, formikHelpers, FormMapper, useRecordForm } from '@gymflow/common';
import * as Yup from 'yup';

const NAME = 'name';

const mapper = new FormMapper();

const schema = Yup.object().shape({
  [NAME]: Yup.string().required().min(3),
});

function StatusAlert({ onCancel, onSubmit, values }) {
  const { initialValues, getValues } = useRecordForm({
    record: values,
    fields: schema.default(),
    mapper,
  });

  const formikProps = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      onSubmit(getValues(values));
    },
  });
  const { errorClass } = formikHelpers(formikProps);

  return (
    <ReactBSAlert
      title={`${values ? 'Edit' : 'Add'} Status`}
      showCancel
      closeOnClickOutside={false}
      onCancel={onCancel}
      onConfirm={formikProps.handleSubmit}
      confirmBtnText={`${values ? 'Edit' : 'Add'}`}
    >
      <Form className="form-horizontal" role="form" onSubmit={(e) => e.preventDefault()}>
        <Row className="text-uppercase text-left">
          <Col>
            <Label tag="h3" htmlFor={NAME}>
              Name *
            </Label>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <FormGroup className={errorClass(NAME)}>
              <FormikInput
                name={NAME}
                type="text"
                data-testid={NAME}
                autoComplete="off"
                maxLength="128"
                formikProps={formikProps}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="text-left">
          <Col>
            <RequiredFieldsLabel />
          </Col>
        </Row>
      </Form>
    </ReactBSAlert>
  );
};

StatusAlert.defaultProps = {
  values: null,
};

StatusAlert.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  values: PropTypes.object,
};

export default StatusAlert;
