import {
  useAppointmentSummaryAsMember,
  useMemberAsMember,
  useMemberPaymentMethodListAsMember,
} from "@gymflow/api";
import { cn, formatCurrency, pluralize } from "@gymflow/helpers";
import { AppointableDTO } from "@gymflow/types";
import { useContext, useEffect, useState } from "react";

import {
  ModalContext,
  useAuthenticatedUser,
  useClubSettings,
} from "../../../providers";
import useGymflowModels from "../../../store";
import {
  ArrowLeftIcon,
  Button,
  Checkbox,
  InputWithSaveButton,
  PlusIcon,
  Spinner,
} from "../../atoms";
import { AddPaymentMethodHostedPagesModal } from "../../molecules";

export function Checkout({
  onBack,
  service,
  onCheckout,
}: {
  onBack: () => void;
  service: AppointableDTO;
  onCheckout: (params: {
    paymentUnit: "CARD" | "CREDITS";
    promoCode?: string;
    paymentMethodId?: string;
  }) => Promise<void>;
}) {
  const [paymentUnit, setPaymentUnit] = useState<"CARD" | "CREDITS">("CARD");
  const [promoCode, setPromoCode] = useState("");
  const { api } = useGymflowModels();
  const { defaultCurrency } = useClubSettings();
  const { id: memberId } = useAuthenticatedUser();
  const { data: summary, isFetching: isLoadingSummary } =
    useAppointmentSummaryAsMember({
      api,
      opts: {
        appointableId: service.id,
        userMemberId: memberId as string,
        appointmentPaymentPostDTO: {
          paidWithSessions: paymentUnit === "CREDITS",
          promotionCode: promoCode,
        },
      },
    });

  const { data: creditsCheck } = useAppointmentSummaryAsMember({
    api,
    opts: {
      appointableId: service.id,
      userMemberId: memberId as string,
      appointmentPaymentPostDTO: {
        paidWithSessions: true,
      },
    },
  });

  useEffect(() => {
    if (
      service.sessionCost &&
      creditsCheck?.paidWithSessions &&
      creditsCheck.sessionPackUsage.result !== "NOT_ENOUGH_SESSIONS"
    ) {
      setPaymentUnit("CREDITS");
    }
  }, [service.sessionCost, creditsCheck]);
  const [paymentMethodId, setPaymentMethodId] = useState<string>();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <div
          className="dark:hover:bg-darkGray-900 dark:border-darkGray-800 cursor-pointer rounded-lg border border-gray-300 px-3 py-2 hover:bg-gray-50"
          onClick={onBack}
        >
          <ArrowLeftIcon pathClassName="stroke-gray-500 dark:stroke-textTertiary-600" />
        </div>
        <div className="text-2xl font-bold dark:text-white">Checkout</div>
      </div>
      <div>
        <div className="dark:border-darkGray-900 flex flex-col gap-4 rounded-t-lg border border-gray-300 p-4">
          <div>
            <div className="mt-4 flex">
              <Button
                onClick={() => setPaymentUnit("CARD")}
                className={cn("w-full bg-white focus:outline-none", {
                  "dark:bg-darkGray-900 dark:text-textSecondary-700 bg-gray-50":
                    paymentUnit === "CARD",
                  "!rounded-r-none": service.sessionCost,
                })}
              >
                Card
              </Button>
              <Button
                onClick={() => setPaymentUnit("CREDITS")}
                className={cn(
                  "w-full !rounded-l-none bg-white focus:outline-none",
                  {
                    "dark:bg-darkGray-900 dark:text-textSecondary-700 bg-gray-50":
                      paymentUnit === "CREDITS",
                    hidden: !service.sessionCost,
                  },
                )}
              >
                Credits
              </Button>
            </div>
          </div>
          {paymentUnit === "CARD" && (
            <CardDetails
              value={paymentMethodId}
              onChange={setPaymentMethodId}
            />
          )}
          {paymentUnit === "CREDITS" && <CreditPackDetails />}
        </div>
        <div className="dark:border-darkGray-900 flex flex-col gap-4 rounded-b-lg border-x border-b border-gray-300  p-4">
          <div
            className={cn("flex flex-col gap-2", {
              hidden: paymentUnit === "CREDITS",
            })}
          >
            <div className="dark:text-textSecondary-700 text-sm">
              Promotional Code
            </div>
            <div>
              <InputWithSaveButton
                onChange={(newPromoCode) => {
                  setPromoCode(newPromoCode);
                }}
                value={
                  promoCode &&
                  summary?.paidWithSessions === false &&
                  summary.cardUsage.validPromotionCode === "YES"
                    ? promoCode
                    : ""
                }
                isLoading={isLoadingSummary}
              />
            </div>
            <div
              className={cn("text-error-600", {
                hidden: !(
                  promoCode &&
                  summary?.paidWithSessions === false &&
                  summary.cardUsage.validPromotionCode !== "YES"
                ),
              })}
            >
              Promotion code is not valid.
            </div>
          </div>
          <div
            className={cn("flex justify-between", {
              hidden: paymentUnit !== "CARD",
            })}
          >
            <div className="text-sm dark:text-white">{service.name}</div>
            <div className="dark:text-darkGray-500 text-sm text-gray-500">
              {summary?.paidWithSessions === false &&
                formatCurrency(
                  summary
                    ? (summary?.cardUsage.upfrontPriceDetails
                        .totalAmountBeforeDiscount as number)
                    : 0,
                  defaultCurrency,
                )}
            </div>
          </div>

          <div
            className={cn("flex justify-between", {
              hidden:
                isLoadingSummary ||
                paymentUnit !== "CARD" ||
                !promoCode ||
                (summary?.paidWithSessions === false &&
                  summary.cardUsage.validPromotionCode !== "YES"),
            })}
          >
            <div className="text-success-600 text-sm dark:text-white">
              Discount
            </div>
            <div className="dark:text-darkGray-500 text-sm text-gray-500">
              {summary?.paidWithSessions === false &&
                formatCurrency(
                  summary
                    ? -(summary?.cardUsage.upfrontPriceDetails
                        .totalAmountDifference as number)
                    : 0,
                  defaultCurrency,
                )}
            </div>
          </div>
          <div
            className={cn("flex flex-col gap-2", {
              hidden: paymentUnit !== "CREDITS",
            })}
          >
            {summary?.paidWithSessions &&
            summary.sessionPackUsage.result !== "NOT_ENOUGH_SESSIONS" ? (
              summary.sessionPackUsage.sessionPackUsageCalculations.map(
                (pack) => (
                  <div
                    key={pack.sessionPack.id}
                    className="flex justify-between"
                  >
                    <div className="text-sm dark:text-white">
                      {pack.sessionPack.name}
                    </div>
                    <div className="dark:text-darkGray-500 text-sm text-gray-500">
                      {`${pack.numberOfSessionsUsed} ${pluralize(
                        "Credit",
                        "Credits",
                        pack.numberOfSessionsUsed,
                      )}
                      `}
                    </div>
                  </div>
                ),
              )
            ) : (
              <div className="text-error-600">
                Not enough credits to book session.
              </div>
            )}
          </div>
          <div className="dark:text-textTertiary-600 flex justify-between font-bold text-gray-700">
            <div>Amount Due</div>
            <div>
              {summary?.paidWithSessions
                ? `${service.sessionCost} ${pluralize(
                    "Credit",
                    "Credits",
                    service.sessionCost,
                  )}`
                : formatCurrency(
                    summary
                      ? (summary?.cardUsage.upfrontPriceDetails
                          .totalAmountToPay as number)
                      : 0,
                    defaultCurrency,
                  )}
            </div>
          </div>
          <div className="flex justify-center">
            <Button
              intent="primary"
              className="w-1/2"
              disabled={
                isLoadingSummary ||
                (paymentUnit === "CREDITS" &&
                  summary?.paidWithSessions === true &&
                  summary.sessionPackUsage.result === "NOT_ENOUGH_SESSIONS") ||
                (paymentUnit === "CARD" && !paymentMethodId)
              }
              onClick={async () => {
                await onCheckout({
                  paymentUnit,
                  paymentMethodId,
                  promoCode,
                });
              }}
            >
              Pay
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function CardDetails({
  value,
  onChange,
}: {
  value?: string;
  onChange: (newValue?: string) => void;
}) {
  const { setModal, hide: hideModal } = useContext(ModalContext);
  const { api } = useGymflowModels();
  const { id: memberId } = useAuthenticatedUser();
  const { clubId, timezone } = useClubSettings();
  const { data: memberDetails } = useMemberAsMember({
    api,
    memberId,
    tz: timezone,
  });

  const { data: paymentMethods, isFetching: isFetchingPaymentMethods } =
    useMemberPaymentMethodListAsMember({
      api,
      memberId: memberId as string,
      clubId,
    });

  const defaultPaymentMethod = paymentMethods?.find(
    (pm) => pm.defaultPaymentMethod,
  );
  useEffect(() => {
    if (defaultPaymentMethod?.id) {
      onChange(defaultPaymentMethod.id);
    }
  }, [defaultPaymentMethod?.id, onChange]);

  const showAddPaymentMethodAlert = () => {
    return setModal(
      <AddPaymentMethodHostedPagesModal
        cardHolderName={`${memberDetails!.firstName} ${
          memberDetails!.lastName
        }`}
        memberId={memberId as string}
        onClose={() => {
          hideModal();
        }}
        onConfirm={async (newPaymentMethodId) => {
          hideModal();

          if (newPaymentMethodId) {
            onChange(newPaymentMethodId);
          }
        }}
      />,
    );
  };

  return (
    <>
      <div className="flex flex-col gap-1">
        <div className="dark:text-darkGray-200 text-sm font-semibold">
          Card details
        </div>
        <div className="dark:text-darkGray-200 text-sm font-normal">
          Select or add a payment method
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          {isFetchingPaymentMethods && <Spinner />}
          {paymentMethods?.map((paymentMethod) => {
            return (
              <div
                className={cn(
                  "ring-primary-300 cursor-pointer rounded-lg border p-4",
                  {
                    "dark:hover:bg-darkGray-900 dark:border-darkGray-800 border-gray-200 hover:bg-gray-50":
                      paymentMethod.id !== value,
                    "ring border-transparent": paymentMethod.id === value,
                  },
                )}
                onClick={() => {
                  onChange(paymentMethod.id);
                }}
                key={paymentMethod.id}
              >
                <div className="flex justify-between">
                  <div className="dark:text-textTertiary-600 text-sm font-bold text-gray-700">
                    Card ending in {paymentMethod.last4Digits}
                  </div>
                  <div>
                    <Checkbox
                      onChange={(newValue) => {
                        if (newValue) {
                          onChange(paymentMethod.id);
                        } else {
                          onChange(undefined);
                        }
                      }}
                      value={value === paymentMethod.id}
                    />
                  </div>
                </div>
                <div className="dark:text-darkGray-600 text-sm font-normal text-gray-600">
                  Expiry {paymentMethod.expMonth}/{paymentMethod.expYear}
                </div>
              </div>
            );
          })}
        </div>
        <div
          className="flex cursor-pointer gap-2"
          onClick={showAddPaymentMethodAlert}
        >
          <div>
            <PlusIcon pathClassName="stroke-primary-600" />
          </div>
          <div className="text-primary-600 text-sm font-semibold">
            Add new payment method
          </div>
        </div>
      </div>
    </>
  );
}

function CreditPackDetails() {
  return (
    <div>
      <div className="dark:text-darkGray-200 text-sm font-semibold text-gray-700">
        Credits
      </div>
      <div className="dark:text-darkGray-200 text-sm font-normal text-gray-600">
        Pay for this appointment with credits available on your account. Credits
        will be deducted from the pack expiring soonest.
      </div>
    </div>
  );
}
