import { QueryKey } from "@tanstack/react-query";

export const activityQueryKeys = {
  all: () => ["activity"] as QueryKey,
  list: (filters?: Readonly<Record<string, any>>) => [...activityQueryKeys.all(), { filters }] as QueryKey,
  details: (activityId?: number) => [...activityQueryKeys.all(), activityId] as QueryKey,
  categories: (filter?: Readonly<Record<string, any>>) => {
    const key = "activity-categories";
    if (filter) {
      return [key];
    } else {
      return [key, filter] as QueryKey;
    }
  },
  categoryDetails: (id: number) => ["activity-category", id] as QueryKey,
};
