import { useQuery } from '@tanstack/react-query';
import { ApiListResponse, PromotionDTO } from '@gymflow/types';

import { promotionQueryKeys } from './promotionQueryKeys';
import { defaultPage } from '../defaultPage';

export function usePromotionList({ api, opts }: {
  api: {
    promotionApi:
    {
      find: (arg0: { [k: string]: any }) => Promise<any>,
    }
  },
  opts: Readonly<{ [key: string]: any }>
}) {
  const result = useQuery({
    queryKey: promotionQueryKeys.list(opts),
    queryFn: async () => {
      const result = await api.promotionApi.find({ ...opts });
      return result.data as ApiListResponse<PromotionDTO>;
    },
    initialData: defaultPage<PromotionDTO>,
  });
  return result;
}
