import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useMutationClubDetails,
  useMutationClubTerms,
  useQueryClubDetails,
} from "@gymflow/api";
import { FormMapper, onlyNumbersProps, useRecordForm } from "@gymflow/common";
import { getDefaultsFromZodSchema } from "apps/portal/src/helpers/zod";
import { useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { useFormik } from "formik";
import FormikInput, {
  LabeledFormikInput,
} from "libs/common/src/lib/components/molecules/FormikInput";
import React, { useState } from "react";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { Button, LabeledForm, UploadArea } from "../../atoms";
import { ClubSchema, createClubSchema } from "./ClubSchema";

export interface ClubFormProps {}

export const ClubForm: React.FC<ClubFormProps> = () => {
  const { api } = useGymflowModels();
  const { postal_code_country, clubId } = useClubSettings();
  const schema = createClubSchema(postal_code_country);
  const { data: club } = useQueryClubDetails({ api, clubId });
  const { mutateAsync: updateClub } = useMutationClubDetails({ api });
  const { mutateAsync: updateClubTerms } = useMutationClubTerms({ api });
  const { initialValues, getPatchedValues } = useRecordForm({
    record: club ?? {},
    fields: getDefaultsFromZodSchema(schema),
    mapper: new FormMapper(),
  });

  const formik = useFormik<ClubSchema>({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      await updateClub({
        clubId,
        payload: getPatchedValues(values),
      });
    },
    validationSchema: toFormikValidationSchema(schema),
  });
  const [isEnabled, setIsEnabled] = useState(false);
  const [file, setFile] = useState<File>();
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-col gap-y-2 p-6">
        <div className="flex flex-col gap-y-4">
          <LabeledFormikInput
            name="name"
            type="text"
            data-testid="name"
            placeholder="Name"
            formikProps={formik}
            disabled={!isEnabled}
          />
          <LabeledForm label="Address">
            <FormikInput
              name="address-line-1"
              type="text"
              data-testid="address-line-1"
              placeholder="Address Line 1"
              formikProps={formik}
              disabled={!isEnabled}
            />
            <FormikInput
              name="address-line-2"
              type="text"
              data-testid="address-line-2"
              placeholder="Address Line 2"
              formikProps={formik}
              disabled={!isEnabled}
            />
          </LabeledForm>
          <LabeledFormikInput
            name="post-code"
            type="text"
            data-testid="post-code"
            placeholder="Post Code"
            formikProps={formik}
            disabled={!isEnabled}
          />
          <LabeledFormikInput
            name="city"
            type="text"
            data-testid="city"
            placeholder="City"
            formikProps={formik}
            disabled={!isEnabled}
          />
          <LabeledFormikInput
            tooltip="All prices are shown in Gymflow are inclusive of tax. Tax rates will be shown on tax invoices and reports."
            name="default-tax-rate"
            type="text"
            data-testid="default-tax-rate"
            placeholder="Default Sales Tax"
            formikProps={formik}
            disabled={!isEnabled}
            {...onlyNumbersProps}
          />
          <LabeledForm
            label="Liability Waiver"
            tooltip="All users must swipe to agree this waiver when creating an account for the first time."
          >
            <div className="flex flex-row items-center gap-x-2">
              {club?.termsConditions && (
                <div className="flex flex-row items-center gap-x-2">
                  <a
                    href={club.termsConditions}
                    className="text-sm font-semibold"
                  >
                    {club.termsConditionsFilename}
                  </a>
                </div>
              )}
              {file && (
                <>
                  <FontAwesomeIcon className="h-3 w-3" icon={faArrowRight} />
                  <div className="flex flex-row items-center gap-x-2">
                    <a
                      href={URL.createObjectURL(file)}
                      className="text-sm font-semibold"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {file.name}
                    </a>
                    <div className="text-sm text-gray-600">
                      {(file.size / 1024 / 1024).toFixed(2)} MB
                    </div>
                  </div>
                </>
              )}
            </div>
            <UploadArea
              disabled={!isEnabled}
              accept="application/pdf"
              description="PDF or DOC (max size 2mb.)"
              allowedExtensions={["pdf", "doc", "docx"]}
              loadHandler={uploadAreaDocumentLoadHandler}
              onChange={({ file }) => {
                setFile(file);
              }}
            />
          </LabeledForm>
        </div>
      </div>
      <div className="border-b border-gray-200" />
      <div className="flex w-full flex-row justify-end gap-4 p-6">
        {!isEnabled && (
          <Button
            onClick={() => {
              formik.resetForm();
              setFile(undefined);
              setIsEnabled(true);
            }}
            className="mt-0"
          >
            <div className="text-sm">Edit</div>
          </Button>
        )}
        {isEnabled && (
          <Button
            disabled={!formik.isValid}
            intent="primary"
            onClick={async () => {
              await formik.submitForm();
              if (file) {
                const formData = new FormData();
                formData.append("file", file);
                await updateClubTerms({
                  clubId,
                  payload: formData,
                });
                setFile(undefined);
              }
              setIsEnabled(false);
            }}
            className="mt-0"
          >
            <div className="text-sm">Save</div>
          </Button>
        )}
      </div>
    </div>
  );
};

export function uploadAreaDocumentLoadHandler({
  setError,
  onChange,
  reader,
  file,
}: {
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  onChange: ({
    name,
    blob,
    file,
  }: {
    name: string;
    blob: string | ArrayBuffer | null;
    file: File;
  }) => void;
  reader: FileReader;
  file: File;
}) {
  const maxFileSize = 2 * 1024 * 1024;
  if (file.size > maxFileSize) {
    setError(`${file.name} is too big.`);
    return;
  }
  setError(null);
  onChange({ name: file.name, blob: reader.result, file });
}
