import {
  faCreditCard,
  faShoppingCart,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AccordionWizard,
  Checkout,
  DATE_FORMAT,
  getMembershipPrice,
  hasEnoughCredits,
  InvoiceStatus,
  MembershipCarousel,
  PARAMETER_DATE_FORMAT,
  PARAMETER_DATE_ONLY_FORMAT,
  SaleType,
  SERVICE_START_DATE_LIMIT,
  ServiceStatus,
  ServiceType,
  tzDateTimeStringToUtc,
  useParseErrors,
  usePaymentSummary,
  useSaleStepTitleBuilder,
} from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import { useStoreState } from "easy-peasy";
import isEmpty from "lodash/isEmpty";
import memoizeOne from "memoize-one";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import qs from "qs";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

import { RequestAggregator } from "../../helpers/RequestAggregator";
import { useFilterParams } from "../../hooks/useFilterParams";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { SaleResult } from "../../hooks/useSaleResult";
import { useSiteMemberships } from "../../hooks/useSiteMemberships";
import { useClubSettings } from "../../providers";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import { BuyMembershipSubRoute } from "../../routes/site/buyMembershipSubRoute";
import useGymflowModels from "../../store";
import CustomStyleContext from "../CustomStyle";
import BuyRoute from "../SalesWizard/BuyRoute";
import calendarIcon from "./../../../assets/img/calendar.svg";
import { UserDetails } from "./UserDetails";

function BuyMembershipAccordion({
  currency,
  dateFormat,
  summary,
  timezone,
  fetchLeadSources,
  membershipType: filteredMembershipType,
  requiredFields,
}) {
  const settings = useClubSettings();
  const { createSiteOrEmbedLink, createClubLink } = usePortalRoutes();
  const { api, EventStore, settingsStore } = useGymflowModels();
  const findByEmail = memoizeOne((email) =>
    api.public.userMemberApi.emailExists(email.toLowerCase()),
  );
  const { stripeApplicationId } = useStoreState(settingsStore);
  const stripeApiKey = settings.stripe_api_key;
  const allowEditingStartDate = settings.allow_customer_selecting_start_date;
  const openingGymDateSetting = settings.gym_opening_date;
  const postCodeFormat = settings.postal_code_country;
  const telephoneFormat = useMemo(
    () => JSON.parse(settings.phone_number_country),
    [settings.phone_number_country],
  );
  const defaultNationality = settings.default_nationality;
  const customStyle = useContext(CustomStyleContext);
  const [activeStep, setActiveStep] = useState(0);
  const { fetchList: fetchMemberships } = useSiteMemberships();
  const [memberships, setMemberships] = useState([]);
  const history = useHistory();
  const search = qs.parse(history?.location?.search, {
    ignoreQueryPrefix: true,
  });
  const location = useLocation();
  const [membershipType, setMembershipType] = useState(filteredMembershipType);
  const parseError = useParseErrors();

  const { fetchById: fetchEventById, clearEditingRecord: clearEvent } =
    EventStore.useStoreActions((actions) => actions);
  const event = EventStore.useStoreState((state) => state.editing);

  const { filters } = useFilterParams();

  const membershipMap = (membership) => ({
    ...membership,
    price: formatCurrency(getMembershipPrice(membership), currency),
    plus: membership.membershipAddonList
      ?.filter((a) => !a.recurring)
      ?.map((addon) => ({
        name: addon.name,
        price: formatCurrency(addon.price, currency),
      })),
    includes: membership.membershipAddonList
      ?.filter((a) => a.recurring)
      .map((addon) => addon.name),
    isRecurring: membership.type === ServiceType.Recurring,
    billingType: membership.billingType,
    billingPeriod: membership.billingPeriod,
    termsAndConditionsLink: membership.termsConditions,
  });

  useEffect(() => {
    const runFetch = async () => {
      const aggregator = new RequestAggregator(fetchMemberships);

      if (isEmpty(filters)) {
        aggregator.queue({
          status: ServiceStatus.Active,
          type: filteredMembershipType,
          extraParams: { unpaged: true },
        });
      } else if (filters?.id) {
        aggregator.queue({
          status: ServiceStatus.Active,
          extraParams: { unpaged: true, ...filters },
        });
      }

      if (filters?.type) {
        let typeArray = [];
        if (typeof filters.type === "string") {
          typeArray.push(filters.type);
        } else {
          typeArray = filters.type.slice(0);
        }
        aggregator.queue({
          status: ServiceStatus.Active,
          type: typeArray,
          extraParams: { unpaged: true },
        });
      }

      setMemberships(await aggregator.run());
    };
    runFetch();
  }, [filteredMembershipType, fetchMemberships, filters]);

  useEffect(() => {
    const processParams = async () => {
      let stepToSelect = 0;
      if (search.membershipId) {
        const { data } = await api.public.serviceApi.findById(
          search.membershipId,
        );
        setCart([membershipMap(data)]);
        stepToSelect += 1;
      }

      if (search.eventId) {
        fetchEventById(search.eventId);
        stepToSelect += 1;
      }
      setActiveStep(stepToSelect);
    };
    processParams();
    return () => {
      clearEvent();
    };
  }, [search.eventId, search.membershipId]);

  const membershipsToSell = useMemo(
    () =>
      memberships
        .filter(
          (membership) =>
            !event ||
            hasEnoughCredits(
              membership,
              event.event.sessionCost,
              event.event.activity.activityCategory.id,
            ),
        )
        .sort((a, b) => getMembershipPrice(a) - getMembershipPrice(b))
        .map(membershipMap),
    [memberships, event],
  );

  const openingGymDate = useMemo(
    () =>
      moment().isBefore(
        moment(openingGymDateSetting, PARAMETER_DATE_ONLY_FORMAT),
      )
        ? moment(openingGymDateSetting, PARAMETER_DATE_ONLY_FORMAT).format(
            DATE_FORMAT,
          )
        : undefined,
    [openingGymDateSetting],
  );

  const [cart, setCart] = useState([]);
  const [user, setUser] = useState(null);

  const {
    summary: paymentSummary,
    onUpdateMembershipSummary: onUpdateSummary,
  } = usePaymentSummary({
    fetchSummary: summary,
    timezone,
    currency,
  });

  const { step1: step1Title, step2: step2Title } = useSaleStepTitleBuilder({
    saleType: SaleType.Membership,
    activeStep,
    membershipType: filteredMembershipType,
    event,
    cart,
    user,
    recurringMembershipLink: createSiteOrEmbedLink(
      RouteFeature.SiteBuyMembership +
        BuyMembershipSubRoute.RecurringMembership,
    ),
    prepaidMembershipLink: createSiteOrEmbedLink(
      RouteFeature.SiteBuyMembership + BuyMembershipSubRoute.PrepaidMembership,
    ),
    sessionPackLink: createSiteOrEmbedLink(RouteFeature.SiteBuySessionPack),
    hideLinksStep1: Object.keys(filters).length,
  });

  useEffect(() => {
    if (!cart.length) {
      return;
    }
    onUpdateSummary({
      membershipId: cart[0].id,
      membershipName: cart[0].name,
      isRecurring: cart[0].isRecurring,
      billingPeriod: cart[0]?.billingPeriod,
      billingType: cart[0]?.billingType,
      calculateProrata: cart[0].calculateProrata,
      weeklyBillingDay: cart[0].weeklyBillingDay,
      monthlyBillingDay: cart[0].monthlyBillingDay,
    });
    setMembershipType(cart[0].type);
  }, [cart]);

  useEffect(() => {
    if (event) {
      if (activeStep === 1) {
        const params = qs.parse(location.search.slice(1));
        delete params.membershipId;
        history.push({ search: qs.stringify(params) });
      }
    } else if (activeStep === 0) {
      const params = qs.parse(location.search.slice(1));
      delete params.membershipId;
      history.push({ search: qs.stringify(params) });
    }
  }, [activeStep, event]);

  const emailExists = async (email) => {
    let existsUrl = createClubLink(
      RouteLayout.Member,
      RouteFeature.CustomerSale,
      membershipType === ServiceType.Recurring
        ? BuyRoute.RecurringMembership
        : BuyRoute.PrepaidMembership,
      `?membershipId=${cart[0]?.id}`,
    );
    if (event) {
      existsUrl += `&eventId=${event.id}`;
    }
    const response = await findByEmail(email);
    if (response.data) {
      return (
        <>
          You&apos;re already registered.{" "}
          <a className="regular-link" target="_parent" href={existsUrl}>
            Click here
          </a>{" "}
          to log in and complete your purchase.
        </>
      );
    }
    return false;
  };

  const triggerGoogleTag = () => {
    const btn = document.createElement("button");
    btn.className = "track-user-form-click invisible";
    document.body.appendChild(btn);
    btn.click();
    btn.remove();
  };

  const steps = [
    {
      title: step1Title,
      icon: <FontAwesomeIcon icon={faShoppingCart} />,
      component: MembershipCarousel,
      props: {
        memberships: membershipsToSell,
        onSelectClick: (membershipId) => {
          let params = {
            membershipId,
            type: search?.type,
            memberships: search?.memberships,
          };
          if (event) {
            params = { ...params, eventId: event.id };
          }
          history.push({
            search: qs.stringify(params),
          });
        },
      },
    },
    {
      title: step2Title,
      icon: <FontAwesomeIcon icon={faUserPlus} />,
      component: UserDetails,
      contentInsideTitleCard: true,
      props: {
        submitBtnText: "Create new user",
        fetchLeadSources,
        postCodeFormat,
        telephoneFormat,
        defaultNationality,
        dateFormat,
        onSubmit: async ({ values, isValid }) => {
          if (!isValid) {
            return;
          }

          await api.public.leadApi.create({
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            mobileNumber: values.mobileNumber,
            emailCommunication: values.emailCommunication,
            smsCommunication: values.smsCommunication,
            sourceId: values.sourceId,
            suppressAutomation: true,
          });
          triggerGoogleTag();

          setUser(values);
          setActiveStep(activeStep + 1);
        },
        findByEmail: emailExists,
        requiredFields,
      },
    },
    {
      title: "3. Checkout",
      icon: <FontAwesomeIcon icon={faCreditCard} />,
      component: Checkout,
      contentInsideTitleCard: true,
      props: {
        stripeApplicationId,
        stripeApiKey,
        userEmail: user?.email,
        cardHoldersName: user ? `${user.firstName} ${user.lastName}` : "",
        lineItems: paymentSummary.lineItems,
        total: paymentSummary.total,
        totalDescription: paymentSummary?.totalDescription,
        discount: paymentSummary.discount,
        dateFormat,
        validateDate: (date) => {
          const todayInGym = moment()
            .tz(settings.timezone)
            .format("YYYY-MM-DD");
          const maxDate = moment(todayInGym, "YYYY-MM-DD")
            .add(SERVICE_START_DATE_LIMIT)
            .format(PARAMETER_DATE_ONLY_FORMAT);
          const minDate = openingGymDate
            ? moment(openingGymDate, DATE_FORMAT)
                .subtract(1, "day")
                .endOf("day")
                .format(PARAMETER_DATE_ONLY_FORMAT)
            : moment(todayInGym, "YYYY-MM-DD")
                .subtract(1, "day")
                .endOf("day")
                .format(PARAMETER_DATE_ONLY_FORMAT);

          return date.isAfter(minDate) && date.isBefore(maxDate);
        },
        onTakePaymentClick: async (paymentDetails) => {
          let response;
          let saleResult = SaleResult.MembershipSold;
          if (event) {
            try {
              response = await api.public.userMemberApi.createAndRsvp(
                {
                  email: user.email,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  mobileNumber: user.mobileNumber,
                  dateBirth: user.dateBirth,
                  postCode: user.postCode,
                  city: user.city,
                  addressLine1: user.addressLine1,
                  addressLine2: user.addressLine2,
                  country: user.country,
                  gender: user.gender,
                  emailCommunication: user.emailCommunication,
                  smsCommunication: user.smsCommunication,
                  sourceId: user.sourceId,
                  personalNumber: user.personalNumber,
                  emergencyContact: user.emergencyContact,
                  emergencyContactName: user.emergencyContactName,
                  nationality: user.nationality,
                  pushCommunication: true,
                  paymentMethod: paymentDetails.paymentMethod,
                  membershipId: cart[0].id,
                  promotionCode: paymentDetails.promoCode,
                  startDate: tzDateTimeStringToUtc(
                    paymentDetails.startDate,
                    timezone,
                    PARAMETER_DATE_FORMAT,
                  ),
                  isClubWaiverAccepted: user.isClubWaiverAccepted,
                },
                event.id,
              );
            } catch (e) {
              saleResult = SaleResult.MembershipSoldButNotBooked;
            }
          } else {
            try {
              response = await api.public.userMemberApi.create({
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                mobileNumber: user.mobileNumber,
                dateBirth: user.dateBirth,
                postCode: user.postCode,
                city: user.city,
                addressLine1: user.addressLine1,
                addressLine2: user.addressLine2,
                country: user.country,
                gender: user.gender,
                emailCommunication: user.emailCommunication,
                smsCommunication: user.smsCommunication,
                sourceId: user.sourceId,
                personalNumber: user.personalNumber,
                emergencyContact: user.emergencyContact,
                emergencyContactName: user.emergencyContactName,
                nationality: user.nationality,
                pushCommunication: true,
                paymentMethod: paymentDetails.paymentMethod,
                membershipId: cart[0].id,
                promotionCode: paymentDetails.promoCode,
                startDate: tzDateTimeStringToUtc(
                  paymentDetails.startDate,
                  timezone,
                  PARAMETER_DATE_FORMAT,
                ),
                isClubWaiverAccepted: user.isClubWaiverAccepted,
              });
            } catch (e) {
              parseError(e.response);
              return;
            }
          }

          if (response?.data?.checkoutSessionId) {
            return;
          }

          if (
            response?.data?.invoice?.status ===
            InvoiceStatus.PaymentAuthorization
          ) {
            history.push({
              pathname: createSiteOrEmbedLink(RouteFeature.SiteProcessingSca),
              search: qs.stringify({
                paymentIntentId:
                  response.data.invoice.paymentIntentIdForAuthorization,
                saleResult,
              }),
            });
          } else {
            history.push({
              pathname: createSiteOrEmbedLink(RouteFeature.SiteSaleComplete),
              search: qs.stringify({ saleResult }),
            });
          }
        },
        onUpdateSummary: (rest) =>
          onUpdateSummary({
            membershipId: cart[0].id,
            membershipName: cart[0].name,
            isRecurring: cart[0].isRecurring,
            billingPeriod: cart[0]?.billingPeriod,
            billingType: cart[0]?.billingType,
            calculateProrata: cart[0].calculateProrata,
            weeklyBillingDay: cart[0].weeklyBillingDay,
            monthlyBillingDay: cart[0].monthlyBillingDay,
            ...rest,
          }),
        termsAndConditionsUrl: cart[0] && cart[0].termsAndConditionsLink,
        showStartDate: !!allowEditingStartDate,
        allowChangingStartDate: !event && allowEditingStartDate,
        skipPaymentMethod:
          membershipType === ServiceType.Prepaid &&
          paymentSummary.total === formatCurrency(0, currency),
        stripeStyle: {
          borderRadius: "0",
          base: {
            "::placeholder": {
              color: customStyle?.fontColor ? customStyle.fontColor : "black",
            },
            color: customStyle?.fontColor ? customStyle.fontColor : "black",
          },
        },
        startDate: openingGymDate,
        timezone: settings.timezone,
      },
    },
  ];

  if (event) {
    steps.unshift({
      title: `Booking: ${moment(event.startDate, PARAMETER_DATE_FORMAT).format(
        `${dateFormat} h:mm a`,
      )} ${event.event.activity.name}`,
      icon: <img src={calendarIcon} alt={"Calendar Icon"} />,
    });
  }
  return (
    <AccordionWizard
      steps={steps}
      activeStep={activeStep}
      onStepTitleClick={(stepClicked) => {
        if (stepClicked === 0 && event) {
          history.push(createSiteOrEmbedLink(RouteFeature.SiteTimetable));
        } else {
          setActiveStep(stepClicked);
        }
      }}
    />
  );
}

BuyMembershipAccordion.defaultProps = {
  membershipType: undefined,
};

BuyMembershipAccordion.propTypes = {
  membershipType: PropTypes.oneOf(Object.values(ServiceType)),
  fetchLeadSources: PropTypes.func.isRequired,
  summary: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default BuyMembershipAccordion;
