import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { leadQueryKeys } from "./leadQueryKeys";

export function useMutationEditLeadStatus(
  {
    api,
  }: {
    api: {
      leadApi: {
        editStatusColumnName: (
          columnId: number,
          newName: string,
        ) => Promise<void>;
      };
    };
  },
  mutationOpts?: UseMutationOptions<
    unknown,
    unknown,
    { columnId: number; newName: string }
  >,
) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      columnId,
      newName,
    }: {
      columnId: number;
      newName: string;
    }) => api.leadApi.editStatusColumnName(columnId, newName),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: leadQueryKeys.all() });
    },
    ...mutationOpts,
  });

  return mutation;
}
