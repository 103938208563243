import {
  useMutationStripeOnboardAccount,
  useQueryStripeAccountSession,
} from "@gymflow/api";
import {
  AppearanceOptions,
  loadConnectAndInitialize,
} from "@stripe/connect-js";
import { useEffect, useState } from "react";

import { useClubSettings } from "../providers";
import useGymflowModels from "../store";

export function useStripeConnect() {
  const { stripe_account_type } = useClubSettings();
  const isOnboarded = stripe_account_type === "CONNECTED";
  const [stripeConnectInstance, setStripeConnectInstance] = useState<any>();

  const { api } = useGymflowModels();
  const { stripe_api_key, clubId } = useClubSettings();
  const mutation = useMutationStripeOnboardAccount({ api });
  const { data: session_key, refetch } = useQueryStripeAccountSession(
    { api, clubId },
    { enabled: isOnboarded },
  );

  const appearance: AppearanceOptions = {
    overlays: "dialog",
    variables: {
      fontSizeBase: "18px",
      colorPrimary: "#8746EC",
    },
  };

  useEffect(() => {
    if (stripe_api_key && !isOnboarded) {
      const fetchClientSecret = async () => {
        const secret = await mutation.mutateAsync({ clubId });
        return secret;
      };

      setStripeConnectInstance(
        loadConnectAndInitialize({
          publishableKey: stripe_api_key,
          fetchClientSecret,
          appearance,
        }),
      );
    }
  }, [clubId, stripe_api_key]);

  useEffect(() => {
    if (stripe_api_key && isOnboarded && session_key) {
      const fetchClientSecret = async () => {
        const response = await refetch();
        return response.data as string;
      };

      setStripeConnectInstance(
        loadConnectAndInitialize({
          publishableKey: stripe_api_key,
          fetchClientSecret,
          appearance,
        }),
      );
    }
  }, [clubId, stripe_api_key, session_key]);

  return stripeConnectInstance;
}
