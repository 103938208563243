import { utcToZonedTime } from '@gymflow/helpers';
import { MembershipReportDTO, ReportMemberDTO } from '@gymflow/types';
import { useQuery } from '@tanstack/react-query';
import merge from 'lodash/merge';

import { reportQueryKeys } from './reportQueryKeys';
import { defaultPage } from '../defaultPage';

export function useMembershipPausedReport({
  api,
  page,
  limit,
  sort,
  tz,
}: {
  api: {
    memberApi: {
      membershipPaused: (params: {
        page?: number;
        limit?: number;
        sort: any;
      }) => Promise<any>;
    };
  };
  page?: number;
  limit?: number;
  sort?: any;
  tz: string;
}) {
  const result = useQuery({
    queryKey: reportQueryKeys.membershipPaused({
      page,
      limit,
      sort,
    }),
    queryFn: async () => {
      const result = await api.memberApi.membershipPaused({
        page,
        limit,
        sort,
      });
      return result.data as MembershipReportDTO;
    },
    placeholderData: () => {
      return defaultPage<ReportMemberDTO>();
    },
    select: (data) => {
      const dto = merge({}, data, {
        content: data.content.map(({ pausedDate, resumeDate, ...rest }) => {
          return {
            pausedDate: pausedDate && utcToZonedTime(pausedDate, tz),
            resumeDate: resumeDate && utcToZonedTime(resumeDate, tz),
            ...rest,
          };
        }),
      });
      return dto;
    },
  });
  return result;
}
