import { ModalWrapper } from "../../../providers";
import { Button } from "../../atoms";
import { EventType } from "../../organisms";

export function ChooseEventTypeModal({
  onClose,
  onClickButton,
}: {
  onClickButton: (eventType: EventType) => void;
  onClose: () => void;
}) {
  return (
    <ModalWrapper onCancel={onClose}>
      <div>Choose what type of event you want to create:</div>
      <div className="flex flex-col">
        <Button
          onClick={() => {
            onClickButton("OCCURRENCE");
          }}
        >
          Class
        </Button>
        <Button
          onClick={() => {
            onClickButton("APPOINTMENT");
          }}
        >
          Appointment
        </Button>
      </div>
    </ModalWrapper>
  );
}
