import {
  AlertContext,
  DATE_FORMAT,
  humanize,
  NotificationContext,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
  UserProfileType,
} from "@gymflow/common";
import classNames from "classnames";
import update from "immutability-helper";
import moment from "moment-timezone";
import { useCallback, useContext, useEffect, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import withScrolling from "react-dnd-scrolling";
import { Button, Col, Row } from "reactstrap";

import AsyncDropdownFilter from "../components/AsyncDropdownFilter";
import DateRangePicker from "../components/forms/DateRangePicker";
import AddLeadAlert from "../components/Leads/AddLeadAlert";
import Lane from "../components/Leads/Lane";
import LeadCard from "../components/Leads/LeadCard";
import OrderLane from "../components/Leads/OrderLane";
import StatusAlert from "../components/Leads/StatusAlert";
import useLeadSourceLoadOptions from "../hooks/useLeadSourceLoadOptions";
import { usePortalRoutes } from "../hooks/usePortalRoutes";
import useQueryParam from "../hooks/useQueryParam";
import useSendEmails from "../hooks/useSendEmails";
import { useClubSettings } from "../providers";
import useGymflowModels from "../store";

const ScrollingComponent = withScrolling("div");

function Leads() {
  const { createMemberLink, createLeadLink } = usePortalRoutes();
  const { LeadStore, TimelineStore, TaskStore } = useGymflowModels();
  const settings = useClubSettings();
  const dateFormat = settings.date_format;
  const { leads, columns, lostColumnId } = LeadStore.useStoreState(
    (state) => state,
  );
  const {
    addColumn,
    changeLeadColumn,
    create: createLead,
    editColumnName,
    editColumnOrder,
    removeColumn,
    fetchLeads,
    fetchColumns,
  } = LeadStore.useStoreActions((actions) => actions);
  const { createLeadNote } = TimelineStore.useStoreActions(
    (actions) => actions,
  );
  const { create: createTask } = TaskStore.useStoreActions(
    (actions) => actions,
  );
  const { setAlert, hide } = useContext(AlertContext);
  const { notify, notifyDanger } = useContext(NotificationContext);
  const [dragging, setDragging] = useState(null);
  const [draggingLane, setDraggingLane] = useState(null);
  const [source, setSource] = useState(null);
  const [orderedColumns, setOrderedColumns] = useState(columns);
  const [dates, setDates] = useQueryParam("dates", "string");
  const { sendEmails } = useSendEmails();
  const leadSourceLoadOptions = useLeadSourceLoadOptions();

  useEffect(() => {
    fetchColumns();
  }, [fetchColumns]);

  useEffect(() => {
    setOrderedColumns(columns);
  }, [columns]);

  useEffect(() => {
    fetchLeads({
      statusIds: columns.map((c) => c.id),
      source: source?.value?.id,
      dateFrom:
        dates?.startDate &&
        moment(dates.startDate, DATE_FORMAT).format(
          PARAMETER_DATE_FORMAT_WITHOUT_TZ,
        ),
      dateTo:
        dates?.endDate &&
        moment(dates.endDate, DATE_FORMAT)
          .endOf("day")
          .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
    });
  }, [columns, fetchLeads, source, dates?.startDate, dates?.endDate]);

  const moveLane = useCallback(
    (dragIndex, hoverIndex) => {
      const dragLane = orderedColumns[dragIndex];
      setOrderedColumns(
        update(orderedColumns, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragLane],
          ],
        }),
      );
    },
    [orderedColumns],
  );

  const lanes = orderedColumns.map(
    ({ id: columnId, name, deletable, presetType }, idx) => {
      const cards = leads
        .filter(({ id: leadId, leadStatus: { id: leadStatusId } }) =>
          dragging
            ? leadStatusId === columnId && dragging === leadId
            : leadStatusId === columnId,
        )
        .map(
          ({
            id,
            firstName,
            lastName,
            email,
            emailCommunication,
            mobileNumber,
            createdDate,
            source,
            userMemberId,
            nextTask,
            profileType,
          }) => (
            <LeadCard
              key={id}
              id={id}
              firstName={firstName}
              lastName={lastName}
              email={email}
              emailCommunication={emailCommunication}
              phoneNumber={mobileNumber}
              created={humanize(createdDate)}
              source={source.name}
              nextTask={nextTask}
              profileLink={
                profileType === UserProfileType.User
                  ? createMemberLink(userMemberId)
                  : createLeadLink(id)
              }
              onDragChange={(isDragging) => {
                if (isDragging) {
                  setDragging(id);
                } else if (dragging === id) {
                  setDragging(null);
                }
              }}
              createLeadNote={createLeadNote}
              sendEmails={sendEmails}
              createTask={createTask}
            />
          ),
        );

      const isLast = idx === columns.length - 1;
      const tooltipMenuItems = [];

      if (!presetType) {
        const alertToRender =
          cards.length === 0 ? (
            <StatusAlert
              onSubmit={async (values) => {
                try {
                  await editColumnName({ id: columnId, name: values["name"] });
                  notify({ message: "Column name changed." });
                } catch (err) {
                  notifyDanger(err);
                }
                hide();
              }}
              onCancel={hide}
              values={{ name }}
            />
          ) : (
            <ReactBSAlert
              title="Cannot Edit"
              type="error"
              closeOnClickOutside={false}
              onConfirm={hide}
            >
              Cannot edit a column that has cards in it!
            </ReactBSAlert>
          );
        tooltipMenuItems.push({
          title: "Edit",
          onClick: () => {
            setAlert(alertToRender);
          },
        });
      }

      if (deletable) {
        const alertToRender =
          cards.length === 0 ? (
            <ReactBSAlert
              title="Are you sure you want to delete this status column?"
              type="warning"
              showCancel
              closeOnClickOutside={false}
              onCancel={hide}
              onConfirm={async () => {
                try {
                  await removeColumn({ id: columnId });
                  notify({ message: "Column deleted." });
                } catch (err) {
                  notifyDanger(err);
                }
                hide();
              }}
              confirmBtnText="Yes"
            />
          ) : (
            <ReactBSAlert
              title="Cannot Delete"
              type="error"
              closeOnClickOutside={false}
              onConfirm={hide}
            >
              Cannot delete a column that has cards in it!
            </ReactBSAlert>
          );

        tooltipMenuItems.push({
          title: (
            <span
              style={{
                color: "#ec250d",
              }}
            >
              Delete
            </span>
          ),
          onClick: () => setAlert(alertToRender),
        });
      }
      return (
        <Col key={columnId}>
          <OrderLane
            style={{
              borderRight: !isLast ? "1px solid rgba(178, 178, 184, 0.3)" : "0",
              paddingRight: !isLast ? "20px" : "0",
              height: isLast ? "50%" : "100%",
            }}
            noDragDrop={idx === 0}
            id={columnId}
            index={idx}
            highlight={idx !== 0 && draggingLane}
            isDragging={columnId === draggingLane}
            moveLane={moveLane}
            onDropLaneToLane={async ({ id, index }) => {
              setDraggingLane(null);
              try {
                if (id !== columns[index].id) {
                  await editColumnOrder({
                    id,
                    statusOrder: columns[index].statusOrder,
                  });
                  notify({ message: "Column order changed." });
                }
              } catch (err) {
                notifyDanger(err);
                fetchColumns();
              }
            }}
          >
            <Lane
              style={{
                height: "100%",
              }}
              title={
                <>
                  {name}{" "}
                  <span className="d-inline-block font-weight-bold mb-0 ml-4">
                    {cards.length}
                  </span>
                </>
              }
              id={columnId}
              index={idx}
              onDragLaneChange={(isDragging) => {
                if (isDragging) {
                  setDraggingLane(columnId);
                } else if (draggingLane === columnId) {
                  setDraggingLane(null);
                }
              }}
              onDrop={(lead) => {
                changeLeadColumn({ id: lead.id, newColumn: columnId });
              }}
              highlight={dragging}
              tooltipMenuItems={tooltipMenuItems}
            >
              <div
                className="flex flex-col py-4"
                style={{
                  height: isLast && dragging ? "50%" : "96%",
                }}
              >
                {cards}
              </div>
            </Lane>
          </OrderLane>
          <div
            className={classNames("h-50", { "d-none": !isLast || !dragging })}
          >
            <Lane
              title="Deal Lost"
              onDrop={(lead) => {
                changeLeadColumn({ id: lead.id, newColumn: lostColumnId });
                setDragging(null);
              }}
              highlight={dragging}
              style={{
                height: "100%",
                minHeight: 120,
              }}
            >
              <div
                className="p-3 text-center"
                style={{
                  height: "100%",
                  border: "1px dashed rgba(178, 178, 184, 0.8)",
                }}
              >
                Drop here to mark lead as Deal Lost.
              </div>
            </Lane>
          </div>
        </Col>
      );
    },
  );

  const handleDateChange = useCallback(
    (newDate) => {
      if (newDate) {
        const { startDate, endDate } = newDate;
        setDates({
          startDate: moment(startDate, dateFormat).format(DATE_FORMAT),
          endDate: moment(endDate, dateFormat).format(DATE_FORMAT),
        });
      } else {
        setDates(null);
      }
    },
    [setDates, dateFormat],
  );

  const handleSourceChange = useCallback(
    (value) => {
      if (value.value === null) {
        setSource(null);
      } else {
        setSource({
          ...value,
          label: value.value.name,
        });
      }
    },
    [setSource],
  );

  return (
    <div className="content d-flex flex-column min-h-[89vh] p-8">
      <Row>
        <Col className="d-none d-sm-flex align-items-center">
          <h2 className="mb-0">Filters</h2>
          <div className="ml-lg-20 ml-3">
            <AsyncDropdownFilter
              placeholder="Source"
              value={typeof source === "undefined" ? null : source}
              onChange={handleSourceChange}
              loadOptions={leadSourceLoadOptions}
              styles={{
                container: () => ({ width: "150px", position: "relative" }),
              }}
            />
          </div>
          <div className="ml-lg-20 ml-3">
            <DateRangePicker
              placeholder="Created Date"
              onChange={handleDateChange}
              value={
                dates
                  ? {
                      startDate: moment(dates.startDate, DATE_FORMAT).format(
                        dateFormat,
                      ),
                      endDate: moment(dates.endDate, DATE_FORMAT).format(
                        dateFormat,
                      ),
                    }
                  : null
              }
              dateFormat={dateFormat}
            />
          </div>
        </Col>

        <Col className="d-flex justify-content-end">
          <Button
            color="primary"
            size="sm"
            className="btn-sm"
            style={{ minWidth: 70, height: "35px" }}
            onClick={() =>
              setAlert(
                <StatusAlert
                  onSubmit={async (values) => {
                    try {
                      await addColumn(values);
                      notify({ message: "Column created." });
                    } catch (err) {
                      notifyDanger(err);
                    }
                    hide();
                  }}
                  onCancel={hide}
                />,
              )
            }
          >
            Add Status
          </Button>
          <Button
            className="btn-sm"
            style={{ minWidth: 70, height: "35px" }}
            onClick={() =>
              setAlert(
                <AddLeadAlert
                  onSubmit={async (values) => {
                    try {
                      await createLead(values);
                      notify({ message: "Lead created." });
                      hide();
                    } catch (err) {
                      notifyDanger(err);
                    }
                  }}
                  onCancel={hide}
                />,
              )
            }
          >
            Add Lead
          </Button>
        </Col>
      </Row>
      <DndProvider backend={HTML5Backend}>
        <div className="mt-lg-20 mt-3 flex h-full !grow">
          <ScrollingComponent
            className="row min-h-full flex-1 flex-nowrap"
            style={{ width: "100%", overflow: "auto" }}
          >
            {lanes}
          </ScrollingComponent>
        </div>
      </DndProvider>
    </div>
  );
}

function LeadsWithProvider() {
  const { LeadStore, TimelineStore, TaskStore } = useGymflowModels();
  return (
    <LeadStore.Provider>
      <TimelineStore.Provider>
        <TaskStore.Provider>
          <Leads />
        </TaskStore.Provider>
      </TimelineStore.Provider>
    </LeadStore.Provider>
  );
}

export default LeadsWithProvider;
