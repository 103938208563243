import { utcToZonedTime, zonedTimeToUtc } from "@gymflow/helpers";
import { MembershipSoldDTO, MembershipSoldReportDTO } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";
import merge from "lodash/merge";

import { defaultPage } from "../defaultPage";
import { reportQueryKeys } from "./reportQueryKeys";

export function useMembershipSoldReport({
  api,
  startDate,
  endDate,
  membershipIncluded,
  membershipExcluded,
  page,
  limit,
  sort,
  tz,
}: {
  api: {
    memberApi: {
      membershipSold: (params: {
        startDate: string;
        endDate: string;
        membershipIncluded?: number[];
        membershipExcluded?: number[];
        page?: number;
        limit?: number;
        sort: any;
      }) => Promise<any>;
    };
  };
  startDate?: string;
  endDate?: string;
  membershipIncluded?: number[];
  membershipExcluded?: number[];
  page?: number;
  limit?: number;
  sort?: any;
  tz: string;
}) {
  const result = useQuery({
    queryKey: reportQueryKeys.membershipSold({
      startDate,
      endDate,
      membershipIncluded,
      membershipExcluded,
      page,
      limit,
      sort,
    }),
    queryFn: async () => {
      const utcStart = zonedTimeToUtc(startDate as string, tz);
      const utcEnd = zonedTimeToUtc(endDate as string, tz);
      const result = await api.memberApi.membershipSold({
        startDate: utcStart,
        endDate: utcEnd,
        membershipIncluded,
        membershipExcluded,
        page,
        limit,
        sort,
      });
      return result.data as MembershipSoldReportDTO;
    },
    placeholderData: () => {
      return {
        membershipSoldDTO: defaultPage<MembershipSoldDTO>(),
        membershipSoldTotal: 0,
      };
    },
    select: (data: MembershipSoldReportDTO) => {
      const dto = merge({}, data.membershipSoldDTO, {
        content: data.membershipSoldDTO.content.map(
          ({ createdDate, ...rest }) => {
            return {
              createdDate: createdDate && utcToZonedTime(createdDate, tz),
              ...rest,
            };
          },
        ),
      });
      return {
        ...data,
        membershipSoldDTO: dto,
      } as MembershipSoldReportDTO;
    },
    enabled: !!startDate && !!endDate && !!tz,
  });
  return result;
}
