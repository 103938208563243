import { zonedTimeToUtc } from "@gymflow/helpers";
import { MembershipType } from "@gymflow/types";
import { useMutation } from "@tanstack/react-query";

export function useMembershipResumingReportCsv({
  api,
  tz,
}: {
  api: {
    memberApi: {
      membershipResumingCsv: (params: {
        dateFrom: string;
        dateTo: string;
        membershipIncluded?: number[];
        membershipExcluded?: number[];
        membershipType?: MembershipType;
      }) => Promise<any>;
    };
  };
  membership?: number[];
  tz: string;
}) {
  const csvMutation = useMutation({
    mutationFn: async ({
      startDate,
      endDate,
      membershipIncluded,
      membershipExcluded,
      membershipType,
    }: {
      startDate: string;
      endDate: string;
      membershipIncluded?: number[];
      membershipExcluded?: number[];
      membershipType?: MembershipType;
    }) => {
      const utcStart = zonedTimeToUtc(startDate as string, tz);
      const utcEnd = zonedTimeToUtc(endDate as string, tz);
      const result = await api.memberApi.membershipResumingCsv({
        dateFrom: utcStart,
        dateTo: utcEnd,
        membershipIncluded,
        membershipExcluded,
        membershipType,
      });
      return result.data as string;
    },
  });
  return csvMutation;
}
