import { cva, type VariantProps } from "class-variance-authority";
import React, { useContext } from "react";

import { cn } from "../../helpers/cn";
import { ModalContext } from "../../providers";
import { ActionMenuModal } from "../SearchGlobal/ActionMenuModal";
import { ShopSidebarContext } from "../Shop/ShopSidebarContext";
import { Button } from "./Button";
import { QuickActionsIcon } from "./icons/QuickActionsIcon";

export interface QuickActionsButtonProps
  extends VariantProps<typeof QuickActionButtonVariants> {
  label?: string;
  userMemberId?: string;
  leadId?: number;
  className?: string;
}
const QuickActionButtonVariants = cva(["mt-0 h-11"], {
  variants: {
    variant: {
      secondary: [
        "bg-secondary-500 hover:bg-secondary-400 active:bg-secondary-600 text-white",
      ],
      default: [],
    },
    size: {
      icon: ["w-11"],
      label: [],
    },
  },
  defaultVariants: {
    variant: "default",
    size: "icon",
  },
});

const QuickButtonIconVariants = cva(["h-5 w-5"], {
  variants: {
    variant: {
      secondary: ["stroke-white"],
      default: [],
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export const QuickActionsButton: React.FC<QuickActionsButtonProps> = ({
  label,
  userMemberId,
  leadId,
  variant,
  className,
  size,
}) => {
  const { setModal } = useContext(ModalContext);

  const { showShop } = useContext(ShopSidebarContext);
  return (
    <Button
      onClick={() => {
        setModal(
          <ActionMenuModal
            onCancel={() => setModal(null)}
            userMemberId={userMemberId}
            leadId={leadId}
            showShop={showShop}
          />,
        );
      }}
      intent={variant}
      className={cn(QuickActionButtonVariants({ variant, size }), className)}
    >
      <div className="flex items-center gap-x-2">
        <QuickActionsIcon
          className={cn(QuickButtonIconVariants({ variant }))}
        />
        {label}
      </div>
    </Button>
  );
};
