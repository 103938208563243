import {
  AppointmentPurchaseSummaryDTO,
  AppointmentPurchaseSummaryPostDTO,
} from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { appointmentQueryKeys } from "./appointmentQueryKeys";

export async function appointmentSummaryQueryFn({
  api,
  filter,
}: {
  api: AppointmentSummaryApi;
  filter: AppointmentPurchaseSummaryPostDTO;
}) {
  const result = await api.appointmentApi.summary(filter);
  return result.data;
}

export function useAppointmentSummary({
  api,
  opts,
}: {
  api: AppointmentSummaryApi;
  opts: AppointmentPurchaseSummaryPostDTO;
}) {
  const result = useQuery({
    queryKey: appointmentQueryKeys.summary(opts),
    initialData: null,
    queryFn: () => appointmentSummaryQueryFn({ api, filter: opts }),
  });
  return result;
}

interface AppointmentSummaryApi {
  appointmentApi: {
    summary: (
      params: AppointmentPurchaseSummaryPostDTO,
    ) => Promise<{ data: AppointmentPurchaseSummaryDTO }>;
  };
}
