import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  clubQueryKeys,
  clubStaleTime,
  useClub,
  useMembership,
  useMembershipCreate,
  useMembershipEdit,
  useMutationKisiUpdate,
  useProductList,
  useQueryKisiGroupList,
  useQueryKisiMemberships,
  useSessionPackList,
} from "@gymflow/api";
import {
  DurationType,
  FormikInput,
  MembershipProrataChoice,
  NotificationContext,
  onlyNumbersProps,
  useRecordForm,
  Weekday,
} from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import { MembershipBean } from "@gymflow/types";
import { useQueryClient } from "@tanstack/react-query";
import { useClubSettings } from "apps/portal/src/providers";
import { useFormik } from "formik";
import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Tooltip } from "react-tooltip";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { getDefaultsFromZodSchema } from "../../../helpers/zod";
import useGymflowModels from "../../../store";
import {
  Button,
  PaginatedSelect,
  PrimaryButton,
  SlideSideBar,
  Spinner,
} from "../../atoms";
import { FileInputLabel } from "../../forms/FileInputLabel";
import { CollapsibleSections } from "../../molecules";
import Switch from "../../Switch";
import { ProductFormSidebarProviderContext } from "../Product/ProductFormSidebarProvider";
import { CreditPackFormSidebarProviderContext } from "../Sessions/CreditPackFormSidebarProvider";
import { AddonItem } from "./AddonItem";
import Mapper from "./MembershipFormMapper";
import {
  RecurringMembershipSchema,
  RecurringMembershipSchemaKeys,
  RecurringMembershipType,
} from "./RecurringMembershipSchema";

const TypedFormikInput = FormikInput as React.FC<{ [key: string]: any }>;
export const RecurringMembershipFormSidebarProviderContext = createContext<{
  showRecurringMembershipForm: ({
    currentRecurringMembership,
    onClose,
  }: {
    currentRecurringMembership?: MembershipBean;
    onClose?: (newMembership?: MembershipBean) => void;
  }) => void;
}>({} as any);
const durationOptions = [
  {
    label: "No contract",
    value: { value: 0, interval: DurationType.NoContract },
  },
  { label: "1 Month", value: { value: 1, interval: DurationType.Monthly } },
  { label: "2 Months", value: { value: 2, interval: DurationType.Monthly } },
  { label: "3 Months", value: { value: 3, interval: DurationType.Monthly } },
  { label: "4 Months", value: { value: 4, interval: DurationType.Monthly } },
  { label: "5 Months", value: { value: 5, interval: DurationType.Monthly } },
  { label: "6 Months", value: { value: 6, interval: DurationType.Monthly } },
  { label: "7 Months", value: { value: 7, interval: DurationType.Monthly } },
  { label: "8 Months", value: { value: 8, interval: DurationType.Monthly } },
  { label: "9 Months", value: { value: 9, interval: DurationType.Monthly } },
  { label: "10 Months", value: { value: 10, interval: DurationType.Monthly } },
  { label: "11 Months", value: { value: 11, interval: DurationType.Monthly } },
  { label: "12 Months", value: { value: 12, interval: DurationType.Monthly } },
];
export const RecurringMembershipFormSidebarProvider: React.FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [sidebarState, setSidebarState] = useState<
    | {
        currentMembership?: MembershipBean;
        isVisible: boolean;
        onClose: (newMembership?: MembershipBean) => void;
      }
    | undefined
  >({
    isVisible: false,
    onClose: () => {},
  });
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { data: club } = useClub(
    { api, clubId: clubId },
    { staleTime: clubStaleTime },
  );
  const { initialValues, getPatchedValues, getValues } = useRecordForm({
    record: sidebarState?.currentMembership ?? {
      "is-trial": false,
      "service-type": "RECURRING",
    },
    fields: getDefaultsFromZodSchema(RecurringMembershipSchema),
    mapper: new Mapper(),
  });
  const creditPacks = useSessionPackList({
    api,
    opts: { extraParams: { unpaged: true } },
  });
  const creditPackOptions = creditPacks?.data.content.map((e) => ({
    label: e.name,
    value: { type: "creditPack", ...e },
  }));
  const products = useProductList({
    api,
    opts: { extraParams: { unpaged: true } },
  });
  const productOptions =
    products?.data?.content.map((e) => ({
      label: e.name,
      value: { type: "product", ...e },
    })) ?? [];
  const formik = useFormik<RecurringMembershipType>({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      if (sidebarState?.currentMembership) {
        await onSubmit({
          id: sidebarState?.currentMembership.id,
          patchedFields: getPatchedValues(values),
        });
      } else {
        await onSubmit(getValues(values));
      }
      if (sidebarState) {
        hide();
      }
    },
    validationSchema: toFormikValidationSchema(RecurringMembershipSchema),
  });

  const hide = useCallback(
    (newMembership?: MembershipBean) => {
      sidebarState?.onClose(newMembership);
      formik.resetForm();
      setSidebarState(undefined);
      setOpenSectionIdx(0);
    },
    [formik, sidebarState],
  );
  const changeMembershipEdit = useMembershipEdit({ api });
  const createMembershipEdit = useMembershipCreate({ api });
  const { notifyDanger } = useContext(NotificationContext);

  const { data: kisiGroups } = useQueryKisiGroupList(
    { api, clubId },
    {
      enabled: !!club && club.kisiStatus !== "DISABLED",
    },
  );

  const { data: kisiData } = useQueryKisiMemberships(
    { clubId, api },
    {
      enabled: !!club && club.kisiStatus !== "DISABLED",
    },
  );
  const [kisiGroupId, setKisiGroupId] = useState<string>();
  useEffect(() => {
    setKisiGroupId(
      kisiData?.content.find(
        (e) => e.id === sidebarState?.currentMembership?.id,
      )?.kisiGroupId,
    );
  }, [kisiData?.content, sidebarState?.currentMembership?.id]);
  const kisiUpdateMutation = useMutationKisiUpdate({ api });

  const queryClient = useQueryClient();
  const kisiUpdateMutationWithTimeout = useCallback(
    async ({
      clubId,
      membershipId,
      kisiGroupId,
    }: Parameters<typeof kisiUpdateMutation.mutateAsync>[0]) => {
      // This timeout updates club query keys after 1s because the /modify does not respond when the update starts.
      setTimeout(
        () =>
          queryClient.invalidateQueries({
            queryKey: clubQueryKeys.all(),
          }),
        1000,
      );
      await kisiUpdateMutation.mutateAsync({
        clubId,
        membershipId,
        kisiGroupId,
      });
      queryClient.invalidateQueries({
        queryKey: clubQueryKeys.all(),
      });
    },
    [kisiUpdateMutation, queryClient],
  );
  const onSubmit = useCallback(
    async (values: any) => {
      try {
        if (sidebarState?.currentMembership) {
          await changeMembershipEdit.mutateAsync({
            membershipId: values.id,
            patchedFields: values.patchedFields,
          });

          const originalKisiGroupId = kisiData?.content.find(
            (e) => e.id === sidebarState?.currentMembership?.id,
          )?.kisiGroupId;
          if (originalKisiGroupId !== kisiGroupId) {
            kisiUpdateMutationWithTimeout({
              clubId,
              membershipId: sidebarState?.currentMembership.id,
              kisiGroupId: kisiGroupId ?? null,
            });
          }
          hide();
        } else {
          const newMembership = await createMembershipEdit.mutateAsync({
            ...values,
            taxRate: club?.defaultTaxRate,
          });
          if (kisiGroupId) {
            kisiUpdateMutationWithTimeout({
              clubId,
              membershipId: newMembership.data.id,
              kisiGroupId: kisiGroupId,
            });
          }
          hide(newMembership.data);
        }
      } catch (e) {
        notifyDanger(e);
      }
    },
    [
      sidebarState?.currentMembership,
      hide,
      changeMembershipEdit,
      kisiData?.content,
      kisiGroupId,
      kisiUpdateMutationWithTimeout,
      clubId,
      createMembershipEdit,
      club?.defaultTaxRate,
      notifyDanger,
    ],
  );

  const { showProductForm } = useContext(ProductFormSidebarProviderContext);
  const { showCreditPackForm } = useContext(
    CreditPackFormSidebarProviderContext,
  );
  const { data: membership } = useMembership({
    api,
    membershipId: sidebarState?.currentMembership?.id,
  });
  const sections = [
    {
      title: "Details",
      body: (
        <>
          <div className="flex flex-col gap-y-1">
            <label className="!m-0 text-gray-700" htmlFor={"name"}>
              Name*
            </label>
            <TypedFormikInput
              placeholder="Enter a membership name."
              autoComplete="off"
              data-testid={"name"}
              maxLength="128"
              name={"name"}
              type="text"
              formikProps={formik}
              className="!p-3 text-gray-700"
            />
          </div>
          <div className="flex h-32 flex-col gap-y-1">
            <label className="!m-0 text-gray-700" htmlFor={"description"}>
              Description
            </label>
            <TypedFormikInput
              placeholder="Write a nice description for customer to read before purchasing."
              autoComplete="off"
              data-testid={"description"}
              maxLength="500"
              name={"description"}
              id={"description"}
              formikProps={formik}
              type="textarea"
              className="flex !h-full !max-h-full !p-3 !text-base text-gray-700"
            />
          </div>
          <div className="flex flex-row items-center justify-between">
            <label
              className="!m-0 flex flex-row items-center gap-x-2 text-gray-700"
              htmlFor={"is-public"}
            >
              Available Online
              <FontAwesomeIcon
                data-tooltip-id="available-online-tooltip"
                data-tooltip-content="Make the membership available for purchase via your website integration and app."
                className="cursor-pointer"
                icon={faQuestionCircle}
              />
              <Tooltip
                className="!bg-primary-700 flex max-w-sm flex-col items-center rounded-lg text-center text-xs"
                id="available-online-tooltip"
              />
            </label>
            <Switch
              checked={formik.values["is-public"]}
              onChange={(value) => {
                formik.setFieldValue("is-public", value);
              }}
            />
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row items-center justify-between">
              <label
                className="!m-0 flex flex-row items-center gap-x-2 text-gray-700"
                htmlFor={"terms-conditions"}
              >
                Terms & Conditions*
                <FontAwesomeIcon
                  data-tooltip-id="terms-tooltip"
                  data-tooltip-content="Typically includes your cancellation, refund and booking terms."
                  className="cursor-pointer"
                  icon={faQuestionCircle}
                />
                <Tooltip
                  className="!bg-primary-700 z-10 flex max-w-xs flex-col items-center rounded-lg text-center text-xs"
                  id="terms-tooltip"
                />
              </label>

              <FileInputLabel
                accept="application/pdf"
                value={formik.values["terms-conditions"]}
                onChange={(file) =>
                  formik.setFieldValue("terms-conditions", file)
                }
              />
            </div>
            <label className="text-error-500">
              {formik.touched["terms-conditions"] &&
                (formik.errors["terms-conditions"] as any)}
            </label>
          </div>
        </>
      ),
    },
    ...(!membership?.hasSubscriptions ||
    formik.values["upfront-addons"]?.length > 0
      ? [
          {
            title: "Joining Fees",
            body: (
              <div className="flex flex-col gap-y-2">
                {!membership?.hasSubscriptions && (
                  <>
                    <label
                      className="!m-0 flex flex-row items-center gap-x-2 text-gray-700"
                      htmlFor={"upfront-addons"}
                    >
                      Select Items
                      <FontAwesomeIcon
                        data-tooltip-id="joining-fees-tooltip"
                        data-tooltip-content="Any items added will be charged once at the time of purchase only (unless free)."
                        className="cursor-pointer"
                        icon={faQuestionCircle}
                      />
                      <Tooltip
                        className="!bg-primary-700 z-10 flex max-w-xs flex-col items-center rounded-lg text-center text-xs"
                        id="joining-fees-tooltip"
                      />
                    </label>

                    <div className="flex flex-row gap-x-2">
                      <PaginatedSelect
                        className="w-full"
                        isSearchable
                        cacheUniqs={[formik.values["upfront-addons"]]}
                        loadOptions={async (inputValue) => ({
                          options: [
                            ...creditPackOptions.filter(
                              (e) =>
                                !formik.values["upfront-addons"]
                                  .filter((e) => e.type === "creditPack")
                                  ?.some((f) => f.id === e.value.id),
                            ),
                            ...productOptions,
                          ].filter((e) =>
                            e.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase()),
                          ),
                        })}
                        value={{ label: "Search", value: null }}
                        onChange={({
                          value,
                        }:
                          | (typeof productOptions)[number]
                          | (typeof creditPackOptions)[number]) => {
                          formik.setFieldValue("upfront-addons", [
                            ...(formik.values["upfront-addons"] ?? []),
                            { ...value },
                          ]);
                        }}
                      />
                      <PaginatedSelect
                        placeholder="Create"
                        loadOptions={async () => ({
                          options: [
                            { label: "Credit Pack", value: "Session Packs" },
                            { label: "Product", value: "Product" },
                          ],
                        })}
                        value={{ label: "Create", value: "Create" }}
                        onChange={({ value }) => {
                          if (value === "Session Packs") {
                            setSidebarState((e) => {
                              if (!e) return e;
                              return {
                                ...e,
                                isVisible: false,
                              };
                            });
                            showCreditPackForm({
                              onClose: (newSessionPack) => {
                                if (newSessionPack) {
                                  formik.setFieldValue("upfront-addons", [
                                    ...(formik.values["upfront-addons"] ?? []),
                                    {
                                      type: "creditPack",
                                      id: newSessionPack.id,
                                      name: newSessionPack.name,
                                      price: newSessionPack.price,
                                      sessionsIncluded:
                                        newSessionPack.sessionsIncluded,
                                      sessionsUnlimited:
                                        newSessionPack.sessionsUnlimited,
                                    },
                                  ]);
                                }
                                setSidebarState((e) => {
                                  if (!e) return e;
                                  return {
                                    ...e,
                                    isVisible: true,
                                  };
                                });
                              },
                            });
                          } else {
                            setSidebarState((e) => {
                              if (!e) return e;
                              return {
                                ...e,
                                isVisible: false,
                              };
                            });
                            showProductForm({
                              onClose: (newProduct) => {
                                if (newProduct) {
                                  formik.setFieldValue("upfront-addons", [
                                    ...(formik.values["upfront-addons"] ?? []),
                                    {
                                      type: "product",
                                      id: newProduct.id,
                                      name: newProduct.name,
                                      price: newProduct.price,
                                      quantity: newProduct.stockQuantity,
                                    },
                                  ]);
                                }
                                setSidebarState((e) => {
                                  if (!e) return e;
                                  return {
                                    ...e,
                                    isVisible: true,
                                  };
                                });
                              },
                            });
                          }
                        }}
                      />
                    </div>
                  </>
                )}
                <div>
                  {formik.values["upfront-addons"]?.map(
                    ({ id, name, price }, idx: number) => {
                      return (
                        <AddonItem
                          key={`${id}-${idx}`}
                          name={name}
                          canBeEdited={!membership?.hasSubscriptions}
                          formattedPrice={formatCurrency(
                            price,
                            club?.defaultCurrency ?? "USD",
                          )}
                          onClick={() => {
                            formik.setFieldValue(
                              "upfront-addons",
                              formik.values["upfront-addons"].filter(
                                (_, i) => i !== idx,
                              ),
                            );
                          }}
                        />
                      );
                    },
                  )}
                </div>
                {formik.values["upfront-addons"]?.length > 0 && (
                  <div className="flex flex-row items-center justify-end text-sm font-bold">
                    <div className="flex w-full">Total Joining Fees</div>
                    <div className="flex w-20">
                      {formatCurrency(
                        formik.values["upfront-addons"].reduce(
                          (acc: number, next: { price: number }) =>
                            acc + next.price,
                          0,
                        ),
                        club?.defaultCurrency ?? "USD",
                      )}
                    </div>
                    <div className="flex min-w-[80px] px-4">&nbsp;</div>
                  </div>
                )}
              </div>
            ),
          },
        ]
      : []),
    ...(!membership?.hasSubscriptions ||
    formik.values["recurring-addons"]?.length > 0
      ? [
          {
            title: "Inclusions",
            body: (
              <div className="flex flex-col gap-y-2">
                {!membership?.hasSubscriptions && (
                  <>
                    <label
                      className="!m-0 flex flex-row items-center gap-x-2 text-gray-700"
                      htmlFor={"recurring-addons"}
                    >
                      Select Items
                      <FontAwesomeIcon
                        data-tooltip-id="inclusions-tooltip"
                        data-tooltip-content="Inclusion are included free of charge within the price of the membership. Any entitlements e.g credits will reset each time the membership bills. "
                        className="cursor-pointer"
                        icon={faQuestionCircle}
                      />
                      <Tooltip
                        className="!bg-primary-700 z-10 flex max-w-xs flex-col items-center rounded-lg text-center text-xs"
                        id="inclusions-tooltip"
                      />
                    </label>
                    <div className="flex flex-row gap-x-2">
                      <PaginatedSelect
                        className="w-full"
                        isSearchable
                        cacheUniqs={[formik.values["recurring-addons"]]}
                        loadOptions={async (inputValue) => ({
                          options: [
                            ...creditPackOptions.filter(
                              (e) =>
                                !formik.values["recurring-addons"]
                                  .filter((e) => e.type === "creditPack")
                                  ?.some((f) => f.id === e.value.id),
                            ),
                            ...productOptions,
                          ].filter((e) =>
                            e.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase()),
                          ),
                        })}
                        value={{ label: "Search", value: null }}
                        onChange={({
                          value,
                        }:
                          | (typeof productOptions)[number]
                          | (typeof creditPackOptions)[number]) => {
                          formik.setFieldValue("recurring-addons", [
                            ...(formik.values["recurring-addons"] ?? []),
                            { ...value },
                          ]);
                        }}
                      />
                      <PaginatedSelect
                        placeholder="Create"
                        loadOptions={async () => ({
                          options: [
                            { label: "Credit Pack", value: "Session Packs" },
                            { label: "Product", value: "Product" },
                          ],
                        })}
                        value={{ label: "Create", value: "Create" }}
                        onChange={({ value }) => {
                          if (value === "Session Packs") {
                            setSidebarState((e) => {
                              if (!e) return e;
                              return {
                                ...e,
                                isVisible: false,
                              };
                            });
                            showCreditPackForm({
                              onClose: (newSessionPack) => {
                                if (newSessionPack) {
                                  formik.setFieldValue("recurring-addons", [
                                    ...(formik.values["recurring-addons"] ??
                                      []),
                                    {
                                      type: "creditPack",
                                      id: newSessionPack.id,
                                      name: newSessionPack.name,
                                      price: newSessionPack.price,
                                      sessionsIncluded:
                                        newSessionPack.sessionsIncluded,
                                      sessionsUnlimited:
                                        newSessionPack.sessionsUnlimited,
                                    },
                                  ]);
                                }
                                setSidebarState((e) => {
                                  if (!e) return e;
                                  return {
                                    ...e,
                                    isVisible: true,
                                  };
                                });
                              },
                            });
                          } else {
                            setSidebarState((e) => {
                              if (!e) return e;
                              return {
                                ...e,
                                isVisible: false,
                              };
                            });
                            showProductForm({
                              onClose: (newProduct) => {
                                if (newProduct) {
                                  formik.setFieldValue("recurring-addons", [
                                    ...(formik.values["recurring-addons"] ??
                                      []),
                                    {
                                      type: "product",
                                      id: newProduct.id,
                                      name: newProduct.name,
                                      price: newProduct.price,
                                      quantity: newProduct.stockQuantity,
                                    },
                                  ]);
                                }
                                setSidebarState((e) => {
                                  if (!e) return e;
                                  return {
                                    ...e,
                                    isVisible: true,
                                  };
                                });
                              },
                            });
                          }
                        }}
                      />
                    </div>
                  </>
                )}
                <div>
                  {formik.values["recurring-addons"]?.map(
                    ({ id, name }, idx: number) => {
                      return (
                        <AddonItem
                          key={`${id}-${idx}`}
                          name={name}
                          canBeEdited={!membership?.hasSubscriptions}
                          onClick={() => {
                            formik.setFieldValue(
                              "recurring-addons",
                              formik.values["recurring-addons"].filter(
                                (_, i) => i !== idx,
                              ),
                            );
                          }}
                        />
                      );
                    },
                  )}
                </div>
              </div>
            ),
          },
        ]
      : []),

    {
      title: "Pricing",
      body: (
        <>
          <div className="flex flex-col gap-y-2">
            <label className="!m-0 text-gray-700" htmlFor={"default-price"}>
              Price*
            </label>
            <TypedFormikInput
              placeholder="Enter the price"
              autoComplete="off"
              data-testid={"default-price"}
              name={"default-price"}
              type="text"
              formikProps={formik}
              className="flex !h-full !max-h-full !p-3 !text-base text-gray-700"
              {...onlyNumbersProps}
            />
          </div>
          <div className="flex flex-col gap-y-2">
            <label className="!m-0 text-gray-700" htmlFor={"period"}>
              Bills Every*
            </label>
            <div className="flex flex-row gap-x-2">
              <PaginatedSelect
                isSearchable
                className="w-full"
                loadOptions={async () => ({
                  options: new Array(36)
                    .fill(0)
                    .map((_, e) => ({ value: e + 1, label: `${e + 1}` })),
                })}
                onChange={({ value }) => {
                  formik.setFieldValue("billing-period", value);
                }}
                value={new Array(36)
                  .fill(0)
                  .map((_, e) => ({ value: e + 1, label: `${e + 1}` }))
                  .find((d) => {
                    return d.value === formik.values["billing-period"];
                  })}
              />
              <PaginatedSelect
                isSearchable
                className="w-full"
                loadOptions={async () => ({
                  options: [
                    {
                      label: "Week",
                      value: DurationType.Weekly,
                    },
                    {
                      label: "Month",
                      value: DurationType.Monthly,
                    },
                  ],
                })}
                onChange={({ value }) => {
                  if (value === DurationType.Weekly) {
                    formik.setFieldValue(
                      "billing-period",
                      Math.min(formik.values["billing-period"], 4),
                    );
                  }
                  formik.setFieldValue("billing-type", value);
                }}
                value={[
                  {
                    label: "Week",
                    value: DurationType.Weekly,
                  },
                  {
                    label: "Month",
                    value: DurationType.Monthly,
                  },
                ].find((d) => {
                  return d.value === formik.values["billing-type"];
                })}
              />
            </div>
            <label className="text-error-500">
              {formik.touched["billing-period"] &&
                formik.errors["billing-period"]}
            </label>
          </div>
          <div className="flex flex-col gap-y-2">
            <label className="!m-0 text-gray-700" htmlFor={"period"}>
              Contract Length
            </label>
            <div className="flex flex-row gap-x-2">
              <PaginatedSelect
                isSearchable
                className="w-full"
                loadOptions={async () => ({
                  options: durationOptions,
                })}
                onChange={({ value }) => {
                  formik.setFieldValue("duration-type", value.interval);
                  formik.setFieldValue("duration", value.value);
                }}
                value={durationOptions.find(
                  ({ value: v }) =>
                    v.value === formik.values.duration &&
                    v.interval === formik.values["duration-type"],
                )}
              />
            </div>
            <label className="text-error-500">
              {formik.touched["duration-type"] &&
                formik.errors["duration-type"]}
            </label>
            <label className="text-error-500">
              {formik.touched["duration"] && formik.errors["duration"]}
            </label>
          </div>
          <div className="flex flex-row items-center justify-between">
            <label
              className="!m-0 flex flex-row items-center gap-x-2 text-gray-700"
              htmlFor={"calculate-prorata"}
            >
              Pro-Rate 1st Payment
              <FontAwesomeIcon
                data-tooltip-id="pro-rate-tooltip"
                data-tooltip-content="Pro-Rating membership will allow you to select a regular billing date for this membership and the first payment will be pro-rated to the chosen billing day."
                className="cursor-pointer"
                icon={faQuestionCircle}
              />
              <Tooltip
                className="!bg-primary-700 flex max-w-sm flex-col items-center rounded-lg text-center text-xs"
                id="pro-rate-tooltip"
              />
            </label>
            <Switch
              checked={
                formik.values["calculate-prorata"] !==
                MembershipProrataChoice.No
              }
              onChange={(value) => {
                if (value) {
                  formik.setFieldValue(
                    "calculate-prorata",
                    MembershipProrataChoice.Charge,
                  );
                } else {
                  formik.setFieldValue(
                    "calculate-prorata",
                    MembershipProrataChoice.No,
                  );
                }
              }}
            />
          </div>
          {formik.values["calculate-prorata"] !==
            MembershipProrataChoice.No && (
            <>
              <div className="flex flex-row items-center justify-between">
                <label className="!m-0 flex flex-row items-center gap-x-2 text-gray-700">
                  Waive Pro-Rata
                </label>
                <Switch
                  checked={
                    formik.values["calculate-prorata"] ===
                    MembershipProrataChoice.Waive
                  }
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue(
                        "calculate-prorata",
                        MembershipProrataChoice.Waive,
                      );
                    } else {
                      formik.setFieldValue(
                        "calculate-prorata",
                        MembershipProrataChoice.Charge,
                      );
                    }
                  }}
                />
              </div>
              <div className="flex flex-col gap-y-2">
                <label className="!m-0 text-gray-700">Billing Day/Date*</label>
                {formik.values["billing-type"] === DurationType.Monthly && (
                  <PaginatedSelect
                    isSearchable
                    className="w-full"
                    loadOptions={async () => ({
                      options: new Array(28).fill(0).map((_, e) => ({
                        value: e + 1,
                        label: `${e + 1}`,
                      })),
                    })}
                    onChange={({ value }) => {
                      formik.setFieldValue("monthly-billing-day", value);
                    }}
                    value={new Array(12)
                      .fill(0)
                      .map((_, e) => ({ value: e + 1, label: `${e + 1}` }))
                      .find((d) => {
                        return d.value === formik.values["monthly-billing-day"];
                      })}
                  />
                )}
                {formik.values["billing-type"] === DurationType.Weekly && (
                  <PaginatedSelect
                    isSearchable
                    className="w-full"
                    loadOptions={async () => ({
                      options: Object.values(Weekday).map((e) => ({
                        value: e,
                        label: `${e.at(0)}${e.substring(1).toLowerCase()}`,
                      })),
                    })}
                    onChange={({ value }) => {
                      formik.setFieldValue("weekly-billing-day", value);
                    }}
                    value={Object.values(Weekday)
                      .map((e) => ({
                        value: e,
                        label: `${e.at(0)}${e.substring(1).toLowerCase()}`,
                      }))
                      .find((d) => {
                        return d.value === formik.values["weekly-billing-day"];
                      })}
                  />
                )}
              </div>
            </>
          )}
        </>
      ),
    },
    ...(!!club && club.kisiStatus !== "DISABLED"
      ? [
          {
            title: "Access",
            body: (
              <div className="flex flex-col gap-y-2">
                <label
                  className="!m-0 flex flex-row items-center gap-x-2 text-gray-700"
                  htmlFor={"period"}
                >
                  Grant Access
                  <FontAwesomeIcon
                    data-tooltip-id="grant-access-tooltip"
                    data-tooltip-content="Select an access group and we’ll automatically grant anyone that buys this membership access."
                    className="cursor-pointer"
                    icon={faQuestionCircle}
                  />
                  <Tooltip
                    className="!bg-primary-700 z-10 flex max-w-xs flex-col items-center rounded-lg text-center text-xs"
                    id="grant-access-tooltip"
                  />
                </label>
                <div className="flex flex-row gap-x-2">
                  {club?.kisiStatus !== "ENABLED" && (
                    <>
                      <Spinner className="!h-11 !w-11" /> Kisi is processing an
                      update, you can come back later to update this membership.
                    </>
                  )}
                  {club?.kisiStatus === "ENABLED" && kisiGroups && (
                    <PaginatedSelect
                      isSearchable
                      isClearable
                      className="w-full"
                      loadOptions={async () => {
                        return {
                          options: kisiGroups.map(({ name, id }) => ({
                            label: name,
                            value: id,
                          })),
                        };
                      }}
                      onChange={(newValue) => {
                        setKisiGroupId(newValue?.value);
                      }}
                      value={kisiGroups
                        .map(({ name, id }) => ({
                          label: name,
                          value: id,
                        }))
                        .find((e) => {
                          return e.value === kisiGroupId;
                        })}
                    />
                  )}
                </div>
              </div>
            ),
          },
        ]
      : []),
  ];
  const [openSectionIdx, setOpenSectionIdx] = useState(0);
  const showRecurringMembershipForm = useCallback(
    (params: {
      currentRecurringMembership?: MembershipBean;
      onClose?: (newMembership?: MembershipBean) => void;
    }) => {
      if (
        params.currentRecurringMembership?.id !==
          sidebarState?.currentMembership?.id ||
        (!params.currentRecurringMembership && !sidebarState?.isVisible)
      ) {
        setSidebarState({
          isVisible: true,
          currentMembership: params?.currentRecurringMembership,
          onClose: (newMembership) => params?.onClose?.(newMembership),
        });
        if (params.currentRecurringMembership) {
          formik.setValues(params.currentRecurringMembership as any);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sidebarState?.currentMembership?.id, sidebarState?.isVisible],
  );
  return (
    <RecurringMembershipFormSidebarProviderContext.Provider
      value={{
        showRecurringMembershipForm,
      }}
    >
      {children}
      <SlideSideBar
        isOpen={!!sidebarState?.isVisible}
        hide={hide}
        className="!w-[32rem] max-w-full"
        isLoading={false}
      >
        <div className="flex h-full max-h-full flex-col overflow-hidden">
          <div className="flex flex-col justify-between border-b border-gray-200 p-8">
            <div className="mb-1 flex flex-row items-center justify-between">
              <div className="text-xl font-semibold text-gray-900">
                {sidebarState?.currentMembership
                  ? "Update Recurring Membership"
                  : "Create Recurring Membership"}
              </div>

              <FontAwesomeIcon
                onClick={() => {
                  hide();
                }}
                className="cursor-pointer text-xl text-gray-600"
                icon={faClose}
              />
            </div>
            <div className="text-sm font-medium text-gray-600">
              {sidebarState?.currentMembership
                ? "Update a membership that bills automatically ongoing until it’s cancelled or overdue."
                : "Create a membership that bills automatically ongoing until it’s cancelled or overdue."}
            </div>
          </div>
          <div className="flex h-full flex-col overflow-y-auto overflow-x-hidden">
            <CollapsibleSections
              sections={sections}
              onChangeSection={(_, sectionIdx) => {
                setOpenSectionIdx(sectionIdx);
              }}
              openSectionIndex={openSectionIdx}
            />
          </div>
          <div className="flex h-20 w-full flex-row items-center justify-end gap-x-2 border-t border-gray-200 bg-gray-50 px-6 py-4">
            <Button
              className="bg-gray-25 !mt-0 flex h-11 w-full cursor-pointer items-center justify-center !rounded-lg border-gray-300 px-4 font-semibold capitalize text-gray-500 ring-1 ring-gray-300 hover:bg-gray-100"
              onClick={() => {
                hide();
              }}
            >
              Cancel
            </Button>
            <PrimaryButton
              className="bg-primary-600 text-gray-25 hover:bg-primary-300 mt-0 flex h-11 w-full cursor-pointer items-center justify-center rounded-lg px-4 font-semibold capitalize"
              onClick={() => {
                try {
                  const errors = RecurringMembershipSchema.safeParse(
                    formik.values,
                  );
                  if (!errors.success) {
                    const flatErrors = Object.keys(
                      errors.error.flatten().fieldErrors,
                    );
                    let fieldsPerSection = [
                      ["name", "description", "is-public", "terms-conditions"],
                      ["upfront-addons"],
                      ["recurring-addons"],
                      [
                        "default-price",
                        "billing-period",
                        "billing-type",
                        "calculate-prorata",
                        "monthly-billing-day",
                        "weekly-billing-day",
                        "duration",
                        "duration-type",
                      ],
                      ["kisi-group-id"],
                    ] as RecurringMembershipSchemaKeys[][];
                    for (const [idx, fields] of fieldsPerSection.entries()) {
                      if (fields.some((e) => flatErrors.includes(e))) {
                        setOpenSectionIdx(idx);
                        break;
                      }
                    }
                  }
                  formik.handleSubmit();
                } catch (err) {
                  notifyDanger(err);
                }
              }}
            >
              {sidebarState?.currentMembership ? "Update" : "Create"}
            </PrimaryButton>
          </div>
        </div>
      </SlideSideBar>
    </RecurringMembershipFormSidebarProviderContext.Provider>
  );
};
