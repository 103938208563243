import { cn } from "@gymflow/helpers";
import { useMutation } from "@tanstack/react-query";
import { cva, VariantProps } from "class-variance-authority";
import omit from "lodash/omit";
import { MouseEventHandler, useMemo } from "react";

import { Spinner } from "./Spinner";

export const buttonVariants = cva(
  "flex min-w-[2.375rem] cursor-pointer items-center justify-center !rounded-lg px-4 py-2 font-semibold capitalize",
  {
    variants: {
      size: {
        small: "mt-0 h-[2.375rem] text-sm",
        medium: "mt-2 h-11",
      },
      intent: {
        default:
          "bg-gray-25 dark:border-darkGray-800 dark:bg-darkModeFill dark:hover:!bg-darkGray-900 dark:text-darkGray-400 dark:ring-darkGray-800 border-gray-300 text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-100",
        primary: "hover:bg-primary-300 bg-primary-600 text-gray-25",
        warning: "hover:bg-warning-500 bg-warning-600 text-white",
        danger: "hover:bg-error-500 bg-error-600 text-white",
        secondary: "bg-secondary-500 hover:bg-secondary-600 text-white",
        // bg-[#FFFFFF] here is a template workaround
        secondaryWithoutBg:
          "text-secondary-500 border-secondary-500 ring-secondary-500 bg-[#FFFFFF] ring-1 ring-inset hover:bg-gray-50",
        link: "text-primary-600 mt-0 bg-transparent p-0",
        linkWarning: "text-warning-600 mt-0 bg-transparent p-0",
      },
    },
    defaultVariants: {
      size: "medium",
      intent: "default",
    },
  },
);

const disabledButtonVariants = cva(["cursor-default", "opacity-50"], {
  variants: {
    intent: {
      default: "hover:bg-gray-25",
      primary: "hover:bg-primary-600",
      warning: "hover:bg-warning-600",
      danger: "hover:bg-error-500",
      secondary: "hover:bg-secondary",
      secondaryWithoutBg: "",
      link: "text-primary-200 cursor-not-allowed",
      linkWarning: "text-warning-200 cursor-not-allowed",
    },
  },
  defaultVariants: {
    intent: "default",
  },
});

interface ButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, "type" | "size">,
    VariantProps<typeof buttonVariants> {
  showSpinner?: boolean;
  type?: "button" | "submit";
}

export function Button({
  children,
  className,
  showSpinner,
  size,
  intent,
  type = "button",
  ...props
}: ButtonProps) {
  const buttonMutation = useMutation({
    mutationFn: async ({
      params,
    }: {
      params: Parameters<MouseEventHandler<HTMLButtonElement>>;
    }) => {
      await props.onClick?.(...params);
    },
  });
  const buttonProps = useMemo(
    () => omit(props, ["disabled", "onClick"]),
    [props],
  );

  return (
    <button
      type={type}
      className={cn(
        buttonVariants({ size, intent }),
        {
          [disabledButtonVariants({ intent })]:
            props.disabled || buttonMutation.isLoading,
        },
        className,
      )}
      onClick={(...e) => buttonMutation.mutate({ params: e })}
      disabled={props.disabled || buttonMutation.isLoading}
      {...buttonProps}
    >
      <Spinner
        className={cn(
          {
            hidden: !(buttonMutation.isLoading || showSpinner),
          },
          "text-white",
        )}
      />
      <div
        className={cn("flex", {
          hidden: buttonMutation.isLoading || showSpinner,
        })}
      >
        {children}
      </div>
    </button>
  );
}
