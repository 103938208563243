import { useAbility } from "@casl/react";
import {
  leadStatusListQueryFn,
  useClub,
  useMutationLeadStatus,
  useQueryLeadStatus,
  useTaskCreate,
} from "@gymflow/api";
import { AlertContext, NotificationContext } from "@gymflow/common";
import { useContext } from "react";

import addNoteIcon from "../../../../assets/img/add-note.svg";
import createTaskIcon from "../../../../assets/img/create-task.svg";
import sendEmailIcon from "../../../../assets/img/send-email.svg";
import useSendEmails from "../../../hooks/useSendEmails";
import { AbilityContext, Subject, Verb } from "../../../permissions";
import { ModalWrapper, useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import {
  Button,
  PaginatedSelect,
  ThumbsDownIcon,
  ThumbsUpIcon,
} from "../../atoms";
import TaskAlert from "../../Task/TaskAlert";
import { NoteFormSideBarProviderContext } from "../../UserMember/Notes/NoteFormSideBarProvider";
import SendEmailAlertWithProvider from "../../UserMember/SendEmails/SendEmailAlert";

export interface LeadCardActionsModalProps {
  onCancel: () => void;
  userMemberId?: string;
  leadId: number;
  firstName: string;
  lastName: string;
  emailCommunication: boolean;
  leadSourceName: string;
}

export function LeadCardActionsModal({
  onCancel,
  userMemberId,
  leadId,
  firstName,
  lastName,
  emailCommunication,
  leadSourceName,
}: LeadCardActionsModalProps) {
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { data: club } = useClub({ api, clubId: settings.clubId });
  const ability = useAbility(AbilityContext);
  const { setAlert, hide } = useContext(AlertContext);
  const createTaskMutation = useTaskCreate({ api, tz: settings.timezone });
  const { sendEmails } = useSendEmails();
  const { notify } = useContext(NotificationContext);
  const { triggerNewNote } = useContext(NoteFormSideBarProviderContext);
  const { data: statusList } = useQueryLeadStatus({ api });
  const dealWonStatus = statusList?.find(
    (status) => status.presetType === "DEAL_CLOSED",
  );
  const dealLostStatus = statusList?.find(
    (status) => status.presetType === "DEAL_CLOSED",
  );
  const {
    changeLeadStatusMutation: { mutateAsync: changeStatus },
  } = useMutationLeadStatus({ api });
  return (
    <ModalWrapper className="flex flex-col gap-y-4 !p-6 " onCancel={onCancel}>
      <>
        <div className="flex w-full flex-row items-start justify-between">
          <div className="flex flex-col">
            <div className="flex text-lg font-semibold text-gray-900">
              Action Menu
            </div>
            <div className="flex text-sm text-gray-600">
              Choose an action for&nbsp;
              <b>
                {firstName} {lastName}
              </b>
            </div>
          </div>
        </div>
        {ability.can(Verb.Create, Subject.Email) && (
          <Button
            onClick={async () => {
              // TODO: Refactor this so that the email sending logic is handled by the email creation component and not it's parent, similar to the strategy for note creation
              setAlert(
                <SendEmailAlertWithProvider
                  allowMarketing={emailCommunication}
                  from={club?.email!}
                  to={`${firstName} ${lastName}`}
                  onSubmit={(values: any) => {
                    const emailRecipientList = [];
                    if (userMemberId) {
                      emailRecipientList.push({
                        userMemberId,
                      });
                    } else {
                      emailRecipientList.push({
                        leadId,
                      });
                    }

                    const bcc = values.bcc ? values.bcc.split(",") : [];
                    return sendEmails(
                      values.subject,
                      values.body,
                      emailRecipientList,
                      values.marketing,
                      bcc,
                    );
                  }}
                  onCancel={hide}
                />,
              );
              onCancel();
            }}
            className="!mt-0 flex"
          >
            <div className="flex flex-row items-center gap-x-2">
              <img src={sendEmailIcon} alt="quick-actions" />
              Email
            </div>
          </Button>
        )}

        <Button
          onClick={() => {
            setAlert(
              <TaskAlert
                defaultRelatedUsers={
                  userMemberId
                    ? [
                        {
                          id: userMemberId,
                          firstName,
                          lastName,
                        },
                      ]
                    : []
                }
                defaultRelatedLeads={
                  !userMemberId
                    ? [
                        {
                          id: userMemberId,
                          firstName,
                          lastName,
                        },
                      ]
                    : []
                }
                onCancel={hide}
                onSubmit={async (values) => {
                  await createTaskMutation.mutateAsync(values);
                  notify({ message: "Task Created" });
                  hide();
                }}
              />,
            );
            onCancel();
          }}
          className="!mt-0 flex"
        >
          <div className="flex flex-row items-center gap-x-2">
            <img src={createTaskIcon} alt="quick-actions" />
            Create Task
          </div>
        </Button>
        <Button
          onClick={() => {
            triggerNewNote({
              ...(userMemberId
                ? {
                    userMemberId,
                  }
                : {
                    leadId,
                  }),
              name: `${firstName} ${lastName}`,
            });
            onCancel();
          }}
          className="!mt-0 flex"
        >
          <div className="flex flex-row items-center gap-x-2">
            <img src={addNoteIcon} alt="quick-actions" />
            Add Note
          </div>
        </Button>
        <div className="flex items-center justify-center gap-4">
          <div>Change step to:</div>
          <div className="flex-1">
            <PaginatedSelect
              value={{ label: leadSourceName }}
              onChange={async (newValue) => {
                await changeStatus({ leadId, newColumn: newValue.value });
                onCancel();
              }}
              loadOptions={async () => {
                const response = await leadStatusListQueryFn({ api });
                return {
                  options: response.map((source) => ({
                    value: source.id,
                    label: source.name,
                  })),
                  hasMore: false,
                };
              }}
            />
          </div>
        </div>
        <Button
          onClick={async () => {
            await changeStatus({ leadId, newColumn: dealWonStatus!.id });
            onCancel();
          }}
          className="!mt-0 flex"
        >
          <div className="flex flex-row items-center gap-x-2">
            <ThumbsUpIcon pathClassName="stroke-[#079455]" />
            Deal Won
          </div>
        </Button>

        <Button
          onClick={async () => {
            await changeStatus({ leadId, newColumn: dealLostStatus!.id });
            onCancel();
          }}
          className="!mt-0 flex"
        >
          <div className="flex flex-row items-center gap-x-2">
            <ThumbsDownIcon pathClassName="stroke-[#F04438]" />
            Deal Lost
          </div>
        </Button>

        <Button intent="primary" onClick={onCancel} className="mt-0 ">
          Close
        </Button>
      </>
    </ModalWrapper>
  );
}
