import endpoints from "./endpoints";
import { canFind } from "./features/apiResource";

const accessApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    http: axiosInstance,
    baseUrl: `${clubPrefix}${endpoints.access}`,
    apiUrl,
  };

  return Object.assign(state, canFind(state), {
    csv(filters) {
      const url = clubPrefix + `${endpoints.access}/csv`;
      return state.http.get(url, {
        params: filters,
      });
    },
    getCheckIn(userMemberId) {
      const url = `${clubPrefix}${endpoints.access}/check-in/:memberId/status`.replace(":memberId", userMemberId);
      return state.http.get(url);
    },
    checkIn(userMemberId, payload) {
      const url = `${clubPrefix}${endpoints.access}/check-in/:memberId`.replace(":memberId", userMemberId);
      return state.http.put(url, payload);
    },
    checkOut(userMemberId) {
      const url = `${clubPrefix}${endpoints.access}/check-out/:memberId`.replace(":memberId", userMemberId);
      return state.http.put(url);
    },
  });
};

export default accessApi;
