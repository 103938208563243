import { useMutationCompany, useQueryCompany } from "@gymflow/api";
import { FormMapper, useRecordForm } from "@gymflow/common";
import { getDefaultsFromZodSchema } from "apps/portal/src/helpers/zod";
import { useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { useFormik } from "formik";
import FormikInput, {
  LabeledFormikInput,
} from "libs/common/src/lib/components/molecules/FormikInput";
import React, { useState } from "react";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { Button, LabeledForm } from "../../atoms";
import { CompanySchema, createCompanySchema } from "./CompanySchema";

export interface ClubFormProps {}

export const CompanyForm: React.FC<ClubFormProps> = () => {
  const { api } = useGymflowModels();
  const { postal_code_country, clubId } = useClubSettings();
  const schema = createCompanySchema(postal_code_country);
  const { data: club } = useQueryCompany({ api, clubId });
  const { mutateAsync: updateCompany } = useMutationCompany({ api });
  const { initialValues, getPatchedValues } = useRecordForm({
    record: club ?? {},
    fields: getDefaultsFromZodSchema(schema),
    mapper: new FormMapper(),
  });

  const formik = useFormik<CompanySchema>({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      await updateCompany({
        clubId,
        payload: getPatchedValues(values),
      });
    },
    validationSchema: toFormikValidationSchema(schema),
  });
  const [isEnabled, setIsEnabled] = useState(false);
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-col gap-y-2 p-6">
        <div className="flex flex-col gap-y-4">
          <LabeledFormikInput
            name="name"
            type="text"
            data-testid="name"
            placeholder="Name"
            formikProps={formik}
            disabled={!isEnabled}
          />
          <LabeledFormikInput
            name="company-number"
            type="text"
            data-testid="company-number"
            placeholder="Registration Number"
            formikProps={formik}
            disabled={!isEnabled}
          />

          <LabeledFormikInput
            name="vat-number"
            type="text"
            tooltip="Will be shown on all tax invoices."
            data-testid="vat-number"
            placeholder="Sales Tax Number"
            formikProps={formik}
            disabled={!isEnabled}
          />
          <LabeledForm label="Address">
            <FormikInput
              name="address-line-1"
              type="text"
              data-testid="address-line-1"
              placeholder="Address Line 1"
              formikProps={formik}
              disabled={!isEnabled}
            />
            <FormikInput
              name="address-line-2"
              type="text"
              data-testid="address-line-2"
              placeholder="Address Line 2"
              formikProps={formik}
              disabled={!isEnabled}
            />
          </LabeledForm>
          <LabeledFormikInput
            name="post-code"
            type="text"
            data-testid="post-code"
            placeholder="Post Code"
            formikProps={formik}
            disabled={!isEnabled}
          />
          <LabeledFormikInput
            name="city"
            type="text"
            data-testid="city"
            placeholder="City"
            formikProps={formik}
            disabled={!isEnabled}
          />
        </div>
      </div>
      <div className="border-b border-gray-200" />
      <div className="flex w-full flex-row justify-end gap-4 p-6">
        {!isEnabled && (
          <Button
            onClick={() => {
              formik.resetForm();
              setIsEnabled(true);
            }}
            className="mt-0"
          >
            <div className="text-sm">Edit</div>
          </Button>
        )}
        {isEnabled && (
          <Button
            disabled={!formik.isValid}
            intent="primary"
            onClick={async () => {
              await formik.submitForm();
              setIsEnabled(false);
            }}
            className="mt-0"
          >
            <div className="text-sm">Save</div>
          </Button>
        )}
      </div>
    </div>
  );
};
