import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useMemberInvoice(
  { api }: {
    api: {
      memberApi:
      {
        creditInvoice: (
          memberId: string,
          clubId: number,
          invoiceNumber: string,
          amount: number
        ) => Promise<any>,
        debitInvoice: (
          memberId: string,
          clubId: number,
          invoiceNumber: string,
          amount: number
        ) => Promise<any>,
        collectInvoice: (
          memberId: string,
          clubId: number,
          invoiceNumber: string,
          amount: number
        ) => Promise<any>,
        refundInvoice: (
          memberId: string,
          clubId: number,
          invoiceNumber: string,
        ) => Promise<any>,
        writeOffInvoice: (
          memberId: string,
          clubId: number,
          invoiceNumber: string,
        ) => Promise<any>,
      }
    }
  }
) {
  const queryClient = useQueryClient();

  const creditInvoiceMutation = useMutation({
    mutationFn: ({
      memberId,
      clubId,
      invoiceNumber,
      amount,
    }: {
      memberId: string,
      clubId: number,
      invoiceNumber: string,
      amount: number
    }) => api.memberApi.creditInvoice(
      memberId, clubId, invoiceNumber, amount,
    ),
    onSuccess: (_, { memberId }) => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.invoices(memberId) })
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.details(memberId) })
    }
  });

  const debitInvoiceMutation = useMutation({
    mutationFn: ({
      memberId,
      clubId,
      invoiceNumber,
      amount,
    }: {
      memberId: string,
      clubId: number,
      invoiceNumber: string,
      amount: number
    }) => api.memberApi.debitInvoice(
      memberId, clubId, invoiceNumber, amount,
    ),
    onSuccess: (_, { memberId }) => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.invoices(memberId) })
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.details(memberId) })
    }
  });

  const collectInvoiceMutation = useMutation({
    mutationFn: ({
      memberId,
      clubId,
      invoiceNumber,
      amount,
    }: {
      memberId: string,
      clubId: number,
      invoiceNumber: string,
      amount: number
    }) => api.memberApi.collectInvoice(
      memberId, clubId, invoiceNumber, amount,
    ),
    onSuccess: (_, { memberId }) => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.invoices(memberId) })
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.details(memberId) })
    }
  });

  const refundInvoiceMutation = useMutation({
    mutationFn: ({
      memberId,
      clubId,
      invoiceNumber,
    }: {
      memberId: string,
      clubId: number,
      invoiceNumber: string,
    }) => api.memberApi.refundInvoice(
      memberId, clubId, invoiceNumber,
    ),
    onSuccess: async (_, { memberId }) => {
      await queryClient.invalidateQueries({ queryKey: memberQueryKeys.invoices(memberId) })
      await queryClient.invalidateQueries({ queryKey: memberQueryKeys.details(memberId) })
    }
  });

  const writeOffInvoiceMutation = useMutation({
    mutationFn: ({
      memberId,
      clubId,
      invoiceNumber,
    }: {
      memberId: string,
      clubId: number,
      invoiceNumber: string,
    }) => api.memberApi.writeOffInvoice(
      memberId, clubId, invoiceNumber,
    ),
    onSuccess: (_, { memberId }) => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.invoices(memberId) })
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.details(memberId) })
    }
  });

  return {
    creditInvoiceMutation,
    debitInvoiceMutation,
    collectInvoiceMutation,
    refundInvoiceMutation,
    writeOffInvoiceMutation,
  };
}
