import {
  faCreditCard,
  faShoppingCart,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AccordionWizard,
  Checkout,
  DATE_FORMAT,
  MembershipCarousel,
  PARAMETER_DATE_FORMAT,
  PARAMETER_DATE_ONLY_FORMAT,
  SaleType,
  SERVICE_START_DATE_LIMIT,
  ServiceType,
  usePaymentSummary,
  useSaleStepTitleBuilder,
} from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import { useStoreState } from "easy-peasy";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import qs from "qs";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";

import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useValidateMemberDetails } from "../../hooks/useValidateMemberDetails";
import { useClubSettings } from "../../providers";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import useGymflowModels from "../../store";
import calendarIcon from "./../../../assets/img/calendar.svg";
import BuyRoute from "./BuyRoute";
import MemberUserDetailsUpdate from "./MemberUserDetailsUpdate";

export function BuyMembershipAccordion({
  memberships,
  membershipType,
  purchase,
  currency,
  fetchPaymentMethods,
  fetchLeadSources,
  dateFormat,
  postCodeFormat,
  defaultNationality,
  telephoneFormat,
  summary,
  timezone,
  userMemberId,
  userDetails,
  findByEmail,
  onUserSubmit,
  cardHoldersName,
  event,
  initialCart,
  requiredFields,
}) {
  const { createClubLink } = usePortalRoutes();
  const { settingsStore } = useGymflowModels();
  const { stripeApplicationId } = useStoreState(settingsStore);
  const settings = useClubSettings();

  const openingGymDateSetting = settings.gym_opening_date;
  const openingGymDate = useMemo(
    () =>
      moment().isBefore(
        moment(openingGymDateSetting, PARAMETER_DATE_ONLY_FORMAT),
      )
        ? moment(openingGymDateSetting, PARAMETER_DATE_ONLY_FORMAT).format(
            DATE_FORMAT,
          )
        : undefined,
    [openingGymDateSetting],
  );

  const stripeApiKey = settings.stripe_api_key;
  const allowEditingStartDate = settings.allow_customer_selecting_start_date;
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();

  const [cart, setCart] = useState(initialCart);
  const [user, setUser] = useState(null);

  const {
    summary: paymentSummary,
    onUpdateMembershipSummary: onUpdateSummary,
  } = usePaymentSummary({
    fetchSummary: summary,
    timezone,
    currency,
  });

  useEffect(() => {
    if (event) {
      if (activeStep === 1) {
        const params = qs.parse(location.search.slice(1));
        delete params.membershipId;
        history.push({ search: qs.stringify(params) });
      }
    } else if (activeStep === 0) {
      const params = qs.parse(location.search.slice(1));
      delete params.membershipId;
      history.push({ search: qs.stringify(params) });
    }
  }, [activeStep]);

  const { validateMemberDetails } = useValidateMemberDetails({
    sellerUserType: "MEMBER",
  });

  useEffect(() => {
    if (!cart.length) {
      return;
    }
    onUpdateSummary({
      membershipId: cart[0].id,
      userMemberId,
      membershipName: cart[0].name,
      isRecurring: cart[0].isRecurring,
      billingPeriod: cart[0]?.billingPeriod,
      billingType: cart[0]?.billingType,
      calculateProrata: cart[0].calculateProrata,
      weeklyBillingDay: cart[0].weeklyBillingDay,
      monthlyBillingDay: cart[0].monthlyBillingDay,
    });
  }, [cart]);

  useEffect(() => {
    setCart(initialCart);
    const moveToCorrectStep = async () => {
      const isUserDataComplete = await validateMemberDetails({
        ...userDetails,
        source: {
          id: userDetails?.lead?.source.id,
          name: userDetails?.lead?.source.name,
        },
      });

      let stepToSelect = 0;
      if (event) {
        stepToSelect += 1;
      }
      if (initialCart.length > 0) {
        stepToSelect += 1;
        if (isUserDataComplete) {
          stepToSelect += 1;
        }
      }

      setActiveStep(stepToSelect);
    };
    moveToCorrectStep();
  }, [event, initialCart]);

  const { step1: step1Title } = useSaleStepTitleBuilder({
    saleType: SaleType.Membership,
    activeStep,
    membershipType,
    event,
    cart,
    recurringMembershipLink: createClubLink(
      RouteLayout.Member,
      RouteFeature.CustomerSale,
      BuyRoute.RecurringMembership,
    ),
    prepaidMembershipLink: createClubLink(
      RouteLayout.Member,
      RouteFeature.CustomerSale,
      BuyRoute.PrepaidMembership,
    ),
    sessionPackLink: createClubLink(
      RouteLayout.Member,
      RouteFeature.CustomerSale,
      BuyRoute.SessionsPack,
    ),
  });

  const steps = [
    {
      title: step1Title,
      icon: <FontAwesomeIcon icon={faShoppingCart} />,
      component: MembershipCarousel,
      props: {
        memberships,
        onSelectClick: (membershipId) => {
          let params = `?membershipId=${membershipId}`;
          if (event) {
            params += `&eventId=${event.id}`;
          }
          history.push({
            search: params,
          });
        },
      },
    },
    {
      title: "2. Update your details",
      icon: <FontAwesomeIcon icon={faUserPlus} />,
      component: MemberUserDetailsUpdate,
      contentInsideTitleCard: true,
      props: {
        fetchLeadSources,
        initialValues: userDetails,
        onUserSelectClick: async (userSelected) => {
          const updatedUser = await onUserSubmit(userSelected);
          if (!updatedUser) {
            return;
          }
          setUser(updatedUser);
          setActiveStep(activeStep + 1);
        },
        postCodeFormat,
        telephoneFormat,
        defaultNationality,
        dateFormat,
        findByEmail,
        requiredFields,
      },
    },
    {
      title: "3. Checkout",
      icon: <FontAwesomeIcon icon={faCreditCard} />,
      component: Checkout,
      contentInsideTitleCard: true,
      props: {
        stripeApplicationId,
        stripeApiKey,
        userEmail: userDetails.email,
        cardHoldersName,
        lineItems: paymentSummary.lineItems,
        total: paymentSummary.total,
        totalDescription: paymentSummary?.totalDescription,
        discount: paymentSummary.discount,
        fetchPaymentMethods,
        dateFormat,
        validateDate: (date) => {
          const todayInGym = moment()
            .tz(settings.timezone)
            .format("YYYY-MM-DD");
          const maxDate = moment(todayInGym, "YYYY-MM-DD")
            .add(SERVICE_START_DATE_LIMIT)
            .format(PARAMETER_DATE_ONLY_FORMAT);
          const minDate = openingGymDate
            ? moment(openingGymDate, DATE_FORMAT)
                .subtract(1, "day")
                .endOf("day")
                .format(PARAMETER_DATE_ONLY_FORMAT)
            : moment(todayInGym, "YYYY-MM-DD")
                .subtract(1, "day")
                .endOf("day")
                .format(PARAMETER_DATE_ONLY_FORMAT);

          return date.isAfter(minDate) && date.isBefore(maxDate);
        },
        onTakePaymentClick: (paymentDetails) => {
          return purchase({ ...paymentDetails, user, cart });
        },
        onUpdateSummary: (rest) =>
          onUpdateSummary({
            membershipId: cart[0].id,
            userMemberId,
            membershipName: cart[0].name,
            isRecurring: cart[0].isRecurring,
            billingPeriod: cart[0]?.billingPeriod,
            billingType: cart[0]?.billingType,
            calculateProrata: cart[0].calculateProrata,
            weeklyBillingDay: cart[0].weeklyBillingDay,
            monthlyBillingDay: cart[0].monthlyBillingDay,
            ...rest,
          }),
        termsAndConditionsUrl: cart[0] && cart[0].termsAndConditionsLink,
        showStartDate: !!allowEditingStartDate,
        allowChangingStartDate: !event && allowEditingStartDate,
        skipPaymentMethod:
          membershipType === ServiceType.Prepaid &&
          paymentSummary.total === formatCurrency(0, currency),
        startDate: openingGymDate,
        timezone: settings.timezone,
      },
    },
  ];

  if (event) {
    const dateFormat = settings.date_format;
    steps.unshift({
      title: `Booking: ${moment(event.startDate, PARAMETER_DATE_FORMAT).format(
        `${dateFormat} h:mm a`,
      )} ${event.event.activity.name}`,
      icon: <img src={calendarIcon} alt={"Calendar Icon"} />,
    });
  }

  return (
    <AccordionWizard
      steps={steps}
      activeStep={activeStep}
      onStepTitleClick={(stepClicked) => {
        if (stepClicked === 0 && event) {
          return;
        }
        setActiveStep(stepClicked);
      }}
    />
  );
}

BuyMembershipAccordion.defaultProps = {
  memberships: [],
  event: null,
  initialCart: [],
};

BuyMembershipAccordion.propTypes = {
  userMemberId: PropTypes.string.isRequired,
  cardHoldersName: PropTypes.string,
  memberships: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      price: PropTypes.string.isRequired,
      isRecurring: PropTypes.bool,
      includes: PropTypes.arrayOf(PropTypes.string),
      plus: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          price: PropTypes.string.isRequired,
        }),
      ),
      termsAndConditionsLink: PropTypes.string.isRequired,
    }),
  ),
  userDetails: PropTypes.object.isRequired,
  membershipType: PropTypes.oneOf(Object.values(ServiceType)).isRequired,
  purchase: PropTypes.func.isRequired,
  summary: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  fetchPaymentMethods: PropTypes.func.isRequired,
  fetchLeadSources: PropTypes.func.isRequired,
  postCodeFormat: PropTypes.string.isRequired,
  telephoneFormat: PropTypes.string.isRequired,
  defaultNationality: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  onUserSubmit: PropTypes.func.isRequired,
  event: PropTypes.object,
  initialCart: PropTypes.array,
};
