import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useAppointable,
  useAppointableCreate,
  useAppointableEdit,
} from "@gymflow/api";
import { AppointablePostDTO } from "@gymflow/types";
import { useClubSettings } from "apps/portal/src/providers";
import { useState } from "react";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";

import { usePortalRoutes } from "../../../hooks/usePortalRoutes";
import { RouteFeature } from "../../../routes/feature";
import { RouteLayout } from "../../../routes/layout";
import useGymflowModels from "../../../store";
import { StepWizard, WizardState } from "../../organisms/StepWizard";
import { SettingsRoute } from "../SettingsRoute";
import { AppointableWizardStep1 } from "./AppointableWizardStep1";
import { AppointableWizardStep2 } from "./AppointableWizardStep2";
import {
  AppointableWizardStep3,
  createFacilityOption,
  createStaffOption,
} from "./AppointableWizardStep3";

export function AppointableWizardRoutes() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:appointableId`}>
        <AppointableWizard />
      </Route>
      <Route path={`${match.path}`}>
        <AppointableWizard />
      </Route>
    </Switch>
  );
}

export function AppointableWizard() {
  const params = useParams<{ appointableId?: string }>();
  const history = useHistory();
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { createClubLink } = usePortalRoutes();
  const { api } = useGymflowModels();
  const createAppointableMutation = useAppointableCreate({ api });
  const updateAppointableMutation = useAppointableEdit({ api });
  const [submitError, setSubmitError] = useState("");

  const { data: appointable } = useAppointable({
    api,
    appointableId: params?.appointableId ? +params.appointableId : null,
  });

  const isEditing = appointable && params?.appointableId;
  if (params.appointableId && appointable === null) {
    return null;
  }
  return (
    <div className="flex h-full flex-col bg-white p-5">
      <div>
        <div className="flex justify-between">
          <div className="flex">
            <div className="flex items-center font-semibold">
              <div className="text-2xl">
                {isEditing ? "Edit Appointment" : "Create Appointment"}
              </div>
            </div>
          </div>
          <div className="mr-5 flex items-center">
            <FontAwesomeIcon
              onClick={() => {
                history.push(
                  createClubLink(
                    RouteLayout.Staff,
                    RouteFeature.Settings,
                    SettingsRoute.Appointable,
                  ),
                );
              }}
              className="cursor-pointer text-xl text-gray-600"
              icon={faClose}
            />
          </div>
        </div>
        <div className="py-5 text-gray-600">
          {isEditing ? "Edit the" : "Create a"} appointment that you want to
          offer your customers. Appointments can be used internally only or made
          available to be booked online and you have complete control over
          availability and payment options.
        </div>
      </div>
      <StepWizard
        initialState={
          appointable
            ? {
                appointableId: appointable.id,
                details: {
                  name: appointable.name,
                  description: appointable.description,
                  termsAndConditions: appointable.termsAndConditions,
                  duration: appointable.duration,
                  capacity: appointable.capacity,
                  category: appointable.appointableCategory.id,
                },
                availability: {
                  availabilityType: appointable.availabilityType,
                  blockedTime: appointable.blockedTime,
                  allowOnlineBookings: appointable.allowOnlineBookings,
                  allowAppBookings: appointable.allowAppBookings,
                  appointableHostsIdList:
                    appointable.appointableHosts.map(createStaffOption),
                  appointableFacilitiesIdList:
                    appointable?.appointableFacilities?.map(
                      createFacilityOption,
                    ) || [],
                },
                pricing: {
                  pricingModel: appointable.pricingModel,
                  sessionCost: appointable.sessionCost,
                  price: appointable.price,
                  taxRate: appointable.taxRate,
                  paymentUpfront: appointable.paymentUpfront,
                  cardUpfront: appointable.cardUpfront,
                },
              }
            : undefined
        }
        onSubmit={async ({ wizardState }) => {
          try {
            if (wizardState["appointableId"]) {
              await updateAppointableMutation.mutateAsync({
                appointableId: wizardState["appointableId"],
                patchedFields: getMutationParametersFromWizardState(
                  wizardState,
                  clubId,
                ),
              });
            } else {
              await createAppointableMutation.mutateAsync(
                getMutationParametersFromWizardState(wizardState, clubId),
              );
            }

            history.push(
              createClubLink(
                RouteLayout.Staff,
                RouteFeature.Settings,
                SettingsRoute.Appointable,
              ),
            );
          } catch (e: any) {
            setSubmitError(
              e?.response?.data?.error_message || "Something went wrong.",
            );

            return false;
          }
          return true;
        }}
        steps={[
          {
            title: "Details",
            explanation: "Add some details about your appointment.",
            component: AppointableWizardStep1,
            onValidate: ({ wizardState }) => {
              return wizardState["isValid"];
            },
          },
          {
            title: "Pricing",
            component: AppointableWizardStep2,
            explanation:
              "Setup how much and when users pay for your appointment",
            onValidate: ({ wizardState }) => {
              return wizardState["isValid"];
            },
          },
          {
            title: "Availability",
            explanation: "Set the availability of your appointment.",
            component: AppointableWizardStep3,
            onValidate: ({ wizardState }) => {
              return wizardState["isValid"];
            },
            props: {
              submitError,
            },
          },
        ]}
      />
    </div>
  );
}

function getMutationParametersFromWizardState(
  wizardState: WizardState,
  clubId: number,
) {
  return {
    name: wizardState["details"].name,
    description: wizardState["details"].description,
    termsAndConditions: wizardState["details"].termsAndConditions,
    availabilityType: wizardState["availability"].availabilityType,
    duration: wizardState["details"].duration,
    blockedTime: wizardState["availability"].blockedTime,
    capacity: wizardState["details"].capacity,
    pricingModel: wizardState["pricing"].pricingModel,
    price: wizardState["pricing"].price,
    sessionCost: wizardState["pricing"].sessionCost,
    taxRate: wizardState["pricing"].taxRate,
    paymentUpfront: wizardState["pricing"].paymentUpfront,
    cardUpfront: wizardState["pricing"].cardUpfront,
    allowOnlineBookings: wizardState["availability"].allowOnlineBookings,
    allowAppBookings: wizardState["availability"].allowAppBookings,
    appointableCategoryId: wizardState["details"].category,
    appointableHostsIdList: wizardState[
      "availability"
    ].appointableHostsIdList.map((staff: { label: string; value: string }) => {
      return staff.value;
    }),
    appointableFacilitiesIdList: wizardState[
      "availability"
    ].appointableFacilitiesIdList.map(
      (facility: { label: string; value: string }) => {
        return facility.value;
      },
    ),
    clubId,
    status: "ACTIVE",
  } as AppointablePostDTO;
}
