import { cn } from "@gymflow/helpers";

interface TextInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "type"> {
  type: "email" | "number" | "text" | "password" | "search" | "tel" | "url";
}

export const TextInputClassNames =
  "block h-11 w-full flex-1 rounded-lg py-2.5 px-3.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset sm:text-sm sm:leading-6 dark:bg-darkModeFill dark:placeholder:text-darkGray-500 dark:!text-darkGray-50 dark:!ring-darkGray-700 border-0";

export function TextInput({ className, type, ...props }: TextInputProps) {
  return (
    <input
      type={type}
      className={cn(TextInputClassNames, className, {
        "bg-gray-100": props?.disabled,
      })}
      {...props}
    />
  );
}

TextInput.defaultProps = {
  type: "text",
};
