import {
  useMemberPaymentMethodList,
  useQueryCreditPackSummary,
  useQueryMembershipSummary,
  useQueryProductSummary,
} from "@gymflow/api";
import { formatCurrency, LUXON_DATE_FORMAT } from "@gymflow/helpers";
import { UserMemberSearchByFullNameResult } from "@gymflow/types";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";

import { ModalContext, useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { Button, LabeledForm, Spinner } from "../atoms";
import { AddPaymentMethodModal } from "../molecules";
import { DatePicker } from "../molecules/DatePicker";
import { PaymentMethodPicker } from "../molecules/PaymentMethodPicker";
import { ShopCartType } from "./ShopTypes";

export type ShopCheckoutProps = {
  cart: ShopCartType;
  selectedUser: UserMemberSearchByFullNameResult;
  setSidebarState: React.Dispatch<
    React.SetStateAction<
      | ({
          isVisible: boolean;
          currentShopTab?: number;
        } & {
          onClose?: () => void;
        })
      | undefined
    >
  >;
  currentPaymentMethodId: string | undefined;
  setCurrentPaymentMethodId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  promotionCode: string | undefined;
  setPromotionCode: React.Dispatch<React.SetStateAction<string | undefined>>;
  startDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
};

export const ShopCheckout: React.FC<ShopCheckoutProps> = ({
  cart,
  selectedUser,
  setSidebarState,
  currentPaymentMethodId,
  setCurrentPaymentMethodId,
  promotionCode,
  setPromotionCode,
  startDate,
  setStartDate,
}) => {
  const { api } = useGymflowModels();
  const clubSettings = useClubSettings();

  const [promotionCodeInput, setPromotionCodeInput] = useState<string>();
  const {
    data: paymentMethods,
    isSuccess: paymentMethodsIsSuccess,
    isFetching,
  } = useMemberPaymentMethodList({
    api,
    memberId: selectedUser.id,
    clubId: clubSettings.clubId,
  });
  useEffect(() => {
    if (paymentMethodsIsSuccess) {
      setCurrentPaymentMethodId(
        paymentMethods?.find((e) => e.defaultPaymentMethod)?.id,
      );
    }
  }, [paymentMethods, paymentMethodsIsSuccess, setCurrentPaymentMethodId]);

  const { data: membershipSummary, isFetching: membershipSummaryIsFetching } =
    useQueryMembershipSummary({
      api,
      opts: {
        membershipId: cart.type === "MEMBERSHIP" ? cart.payload.id : 0,
        startDate:
          DateTime.fromFormat(startDate, LUXON_DATE_FORMAT)
            .setZone(clubSettings.timezone)
            .startOf("day")
            .setZone("utc")
            .toISO()
            ?.split(".")[0] + "Z",
        promotionCode: promotionCode,
        userMemberId: selectedUser.id,
      },
      enabled: cart.type === "MEMBERSHIP",
    });
  const { data: creditPackSummary, isFetching: creditPackSummaryIsFetching } =
    useQueryCreditPackSummary({
      api,
      opts: {
        creditPackId: cart.type === "CREDIT_PACK" ? cart.payload.id : 0,
        promotionCode: promotionCode,
        userMemberId: selectedUser.id,
      },
      enabled: cart.type === "CREDIT_PACK",
    });

  const { data: productSummary, isFetching: productSummaryIsFetching } =
    useQueryProductSummary({
      api,
      opts: {
        products:
          cart.type === "PRODUCT"
            ? cart.payload
                .filter((e) => e.quantity > 0)
                .map((e) => ({
                  productId: e.product.id,
                  quantity: e.quantity,
                }))
            : [],
        promotionCode: promotionCode,
        userMemberId: selectedUser.id,
      },
      enabled: cart.type === "PRODUCT",
    });
  const isSummaryFetching =
    membershipSummaryIsFetching ||
    creditPackSummaryIsFetching ||
    productSummaryIsFetching;
  const summary = membershipSummary || creditPackSummary || productSummary;

  const { setModal, hide: hideModal } = useContext(ModalContext);
  const showAddPaymentMethodAlert = () => {
    setSidebarState((e) => ({
      ...e,
      isVisible: false,
    }));
    return setModal(
      <AddPaymentMethodModal
        cardHolderName={`${selectedUser.firstName} ${selectedUser.lastName}`}
        memberId={selectedUser.id}
        onClose={() => {
          hideModal();
          setSidebarState((e) => ({
            ...e,
            isVisible: true,
          }));
        }}
        onConfirm={async (newPaymentMethodId) => {
          hideModal();
          setSidebarState((e) => ({
            ...e,
            isVisible: true,
          }));

          if (newPaymentMethodId) {
            setCurrentPaymentMethodId(newPaymentMethodId);
          }
        }}
      />,
    );
  };

  return (
    <div className="flex flex-col gap-4">
      {isFetching && <Spinner />}
      {paymentMethods && !isFetching && (
        <PaymentMethodPicker
          paymentMethods={paymentMethods}
          currentPaymentMethodId={currentPaymentMethodId}
          onChange={setCurrentPaymentMethodId}
          showAddPaymentMethodAlert={() => {
            showAddPaymentMethodAlert();
          }}
          allowEmpty={summary?.upfrontPriceDetails.totalAmountToPay === 0}
        />
      )}
      <div className="-mx-8 flex w-[200%] border-b border-gray-200" />
      {cart.type === "MEMBERSHIP" && (
        <LabeledForm label="Membership Start Date">
          <DatePicker
            label={DateTime.fromFormat(startDate, LUXON_DATE_FORMAT)
              .setZone("local", { keepLocalTime: true })
              .toLocaleString(DateTime.DATE_MED)}
            mode="single"
            hidden={{
              before: DateTime.now().toJSDate(),
            }}
            selected={DateTime.fromFormat(startDate, LUXON_DATE_FORMAT)
              .setZone("local", { keepLocalTime: true })
              .toJSDate()}
            handleDateSave={(newDate) => {
              setStartDate(
                DateTime.fromJSDate(newDate).toFormat(LUXON_DATE_FORMAT),
              );
            }}
          />
        </LabeledForm>
      )}
      <LabeledForm label="Promotional Code">
        <div className="inline-flex h-10 flex-row items-start justify-start overflow-hidden rounded-lg border border-gray-300 bg-white shadow-sm">
          <div className="flex h-full flex-1 items-center justify-start gap-2 border-r border-gray-300 bg-white ">
            <input
              placeholder="Enter Code"
              className="h-full w-full px-3 py-2 text-gray-500 outline-none"
              value={promotionCodeInput}
              onChange={(e) => {
                setPromotionCodeInput(e.target.value);
              }}
              disabled={promotionCode !== undefined}
            />
          </div>
          <Button
            onClick={() => {
              if (!promotionCode) {
                setPromotionCode(promotionCodeInput);
              } else {
                setPromotionCode(undefined);
                setPromotionCodeInput("");
              }
            }}
            className="mt-0 h-full min-h-0 !rounded-none !border-none !ring-0 focus:!outline-none"
          >
            {isSummaryFetching ? (
              <Spinner />
            ) : promotionCode === undefined ? (
              "Apply"
            ) : (
              "Remove"
            )}
          </Button>
        </div>
      </LabeledForm>
      <div className="flex w-full flex-col gap-2">
        {summary && cart.type !== "PRODUCT" && (
          <div className="flex w-full flex-row justify-between">
            <div>{cart.payload.name}</div>
            <div className="font-semibold">
              {formatCurrency(
                summary.upfrontPriceDetails.totalAmountBeforeDiscount,
                clubSettings.defaultCurrency,
              )}
            </div>
          </div>
        )}
        {summary &&
          cart.type === "PRODUCT" &&
          cart.payload.map((e) => (
            <div
              key={e.product.id}
              className="flex w-full flex-row justify-between"
            >
              <div>{e.product.name}</div>
              <div className="flex flex-row gap-1">
                <div>{e.quantity} x</div>
                <div className="font-semibold">
                  {formatCurrency(
                    e.product.price,
                    clubSettings.defaultCurrency,
                  )}
                </div>
              </div>
            </div>
          ))}
        {!!summary &&
          "promotionUpfrontAmount" in summary &&
          summary.promotionUpfrontAmount !== undefined && (
            <div className="text-success-500 flex w-full flex-row justify-between">
              <div className="font-semibold">Upfront Discount</div>
              <div className="font-semibold">
                {summary.promotionUpfrontDiscountType === "CURRENCY"
                  ? formatCurrency(
                      -summary.promotionUpfrontAmount,
                      clubSettings.defaultCurrency,
                    )
                  : `-${summary.promotionUpfrontAmount}%`}
              </div>
            </div>
          )}
        {!!summary &&
          "promotionRecurringAmount" in summary &&
          summary.promotionRecurringAmount !== undefined && (
            <div className="text-success-500 flex w-full flex-row justify-between">
              <div className="font-semibold">
                Recurring Discount (each cycle)
              </div>
              <div className="font-semibold">
                {summary.promotionRecurringDiscountType === "CURRENCY"
                  ? formatCurrency(
                      -summary.promotionRecurringAmount,
                      clubSettings.defaultCurrency,
                    )
                  : `-${summary.promotionRecurringAmount}%`}
              </div>
            </div>
          )}

        {summary && (
          <div className="flex w-full flex-row justify-between">
            <div className="font-bold">Amount Due</div>
            <div className="font-bold">
              {formatCurrency(
                summary.upfrontPriceDetails.totalAmountToPay,
                clubSettings.defaultCurrency,
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
