import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useActivityDisable,
  useActivityEnable,
  useActivityList,
} from "@gymflow/api";
import { isMobile } from "@gymflow/common";
import { ActivityDTO, ActivityStatus } from "@gymflow/types";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import { ActivitySideBarContext } from "apps/portal/src/components/Settings/Activity/ActivitySideBarForm";
import { usePageSize } from "apps/portal/src/hooks";
import { useContext, useEffect, useRef, useState } from "react";
import { useWindowSize } from "usehooks-ts";

import useGymflowModels from "../../../store";
import { Badge, Button, PaginatedSelect } from "../../atoms";
import { PaginatedTable } from "../../organisms";
import { SettingsContainer } from "../SettingsContainer";

export function ClassesTab() {
  const [currentPage, setCurrentPage] = useState(0);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const pageSize = usePageSize({
    tableContainerRef,
    rowHeight: 56,
  });
  const [sort, setSort] = useState<SortingState>([]);
  const [statusOptionSelected, setStatusOptionSelected] =
    useState<ActivityStatusOption>({
      label: "Enabled",
      value: "ACTIVE",
    });

  const { api } = useGymflowModels();
  const { data, isLoading } = useActivityList({
    api,
    opts: {
      page: currentPage,
      limit: pageSize,
      sort: sort?.[0]
        ? {
            field: sort[0].id === "card" ? "name" : sort[0].id,
            desc: sort[0].desc,
          }
        : { field: "createdDate", desc: true },
      extraParams: {
        status: statusOptionSelected.value,
      },
    },
  });
  const enableActivityMutation = useActivityEnable({ api });
  const disableActivityMutation = useActivityDisable({ api });

  const [editingActivity, setEditingActivity] = useState<ActivityDTO>();
  const { showActivityForm } = useContext(ActivitySideBarContext);

  useEffect(() => {
    if (editingActivity) {
      showActivityForm({
        activityId: editingActivity.id,
        onClose: () => {
          setEditingActivity(undefined);
        },
      });
    }
  }, [editingActivity, showActivityForm]);
  const window = useWindowSize({ debounceDelay: 100 });
  const columnHelper =
    createColumnHelper<NonNullable<typeof data>["content"][number]>();
  const actionButtons = (item: NonNullable<typeof data>["content"][number]) => (
    <>
      <Button
        intent="link"
        className="h-6 w-fit min-w-0"
        onClick={() => {
          setEditingActivity(item);
        }}
      >
        Edit
      </Button>
      <Button
        intent="linkWarning"
        className="h-6"
        showSpinner={
          disableActivityMutation.isLoading || enableActivityMutation.isLoading
        }
        onClick={async () => {
          if (statusOptionSelected.value === "ACTIVE") {
            await disableActivityMutation.mutateAsync(item.id);
          } else {
            await enableActivityMutation.mutateAsync(item.id);
          }
        }}
      >
        {statusOptionSelected.value === "ACTIVE" ? "Disable" : "Enable"}
      </Button>
    </>
  );
  const desktopColumns = [
    columnHelper.accessor("name", {}),

    columnHelper.accessor("activityCategory.name", {
      header: "Category",
      cell: (column) => <Badge>{column.getValue()}</Badge>,
    }),

    columnHelper.accessor((e) => e, {
      id: "actions",
      header: "Actions",
      cell: (column) => {
        return (
          <div className="flex items-center justify-start">
            <div className="flex flex-wrap items-center justify-end gap-x-2">
              {actionButtons(column.getValue())}
            </div>
          </div>
        );
      },
      enableSorting: false,
    }),
  ];

  const mobileColumns = [
    columnHelper.accessor((e) => e, {
      id: "card",
      header: "Name",
      cell: (column) => {
        const item = column.getValue();
        return (
          <div className="flex w-full flex-col justify-center gap-y-2 py-2">
            <div className="text-lg font-semibold">{item.name}</div>
            <div className="text-sm text-gray-600">
              <Badge>{item.activityCategory.name}</Badge>
            </div>
            <div className="flex flex-row items-center gap-x-3">
              {actionButtons(item)}
            </div>
          </div>
        );
      },
    }),
  ];
  return (
    <SettingsContainer
      title="Classes"
      subTitle="Create, Update and manage your available classes list."
      actions={
        <>
          <PaginatedSelect
            className="h-11 bg-white"
            placeholder="Enabled"
            value={statusOptionSelected}
            onChange={(value) => setStatusOptionSelected(value)}
            loadOptions={async () => {
              return {
                options: [
                  {
                    label: "Enabled",
                    value: "ACTIVE",
                  },
                  {
                    label: "Disabled",
                    value: "DISABLED",
                  },
                ] satisfies ActivityStatusOption[],
              };
            }}
          />
          <Button
            intent="secondary"
            className="mt-0 text-white"
            onClick={() => {
              showActivityForm({});
            }}
          >
            <div className="flex flex-row items-center gap-x-2">
              <FontAwesomeIcon icon={faPlus} />
              <div>Class</div>
            </div>
          </Button>
        </>
      }
    >
      <PaginatedTable
        tableProps={{
          data: data?.content ?? [],
          columns: !isMobile(window.width) ? desktopColumns : mobileColumns,
          pageCount: data?.totalPages,
          pageIndex: data?.number,
          onSortingChange: setSort,
          sort: sort,
          isFetching: isLoading,
          pageSize: pageSize,
          tableContainerRef,
        }}
        hasPreviousPage={!!data && data?.number > 0}
        hasNextPage={!!data && data?.number < data?.totalPages - 1}
        goToNextPage={() => {
          setCurrentPage((e) => e + 1);
        }}
        goToPreviousPage={() => {
          setCurrentPage((e) => e - 1);
        }}
      />
    </SettingsContainer>
  );
}

interface ActivityStatusOption {
  readonly value: ActivityStatus;
  readonly label: string;
}
