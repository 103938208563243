import { assert } from 'check-types';
import axios from 'axios';

import { canTrimFields, canFind, canFindById } from './features/apiResource';
import endpoints from './endpoints';
import canSignUp from './features/canSignUp';

const profileEndpoints = {
  createUserFullDetails: `${endpoints.customerUserMembers}`,
  findByEmail: `${endpoints.customerUserMembers}/email/`,
  resumeSubscription: `${endpoints.customerUserMembers}/service/resume/:subscriptionId`,
  pauseSubscription: `${endpoints.customerUserMembers}/service/pause/:subscriptionId`,
  cancelSubscription: `${endpoints.customerUserMembers}/service/cancel/:subscriptionId`,
  paymentMethods: `${endpoints.customerUserMembers}/payment/methods`,
  attachPaymentMethod: `${endpoints.customerUserMembers}/payment/attach`,
  attachPaymentMethodBacs: `${endpoints.customerUserMembers}/payment/attach-bacs`,
  removePaymentMethod: `${endpoints.customerUserMembers}/payment/remove`,
  defaultPaymentMethod: `${endpoints.customerUserMembers}/payment/default`,
  findInvoices: `${endpoints.customerUserMembers}/invoices`,
  payInvoice: `${endpoints.customerUserMembers}/pay-invoice/`,
  invoiceFile: `${endpoints.customerUserMembers}/invoice-file/:invoiceNumber`,
};

const profileApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: [],
    baseUrl: `${clubPrefix}${endpoints.customerUserMembers}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(
    state,
    canTrimFields(state),
    canFind(state),
    canFindById(state),
    canSignUp(state),
    {
      create(newResource) {
        const trimmedData = state.trimFields(newResource);

        return state.http.post(
          `${clubPrefix}${profileEndpoints.createUserFullDetails}`,
          state.createRequestBody(trimmedData)
        );
      },

      update(_, clubId, patchedFields) {
        assert.number(clubId, 'clubId must be a number');
        return state.http.patch(`${state.baseUrl}`, state.createRequestBody(state.trimFields(patchedFields)));
      },

      findById() {
        return this.http.get(`${state.baseUrl}`);
      },

      async updatePicture(_, { blob, name }) {
        const blobResponse = await axios.get(blob, {
          responseType: 'blob',
        });
        const formData = new FormData();
        formData.append('file', blobResponse.data, name);
        return this.http.post(`/${this.baseUrl}/picture`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      },

      fetchPaymentMethods(_, clubId) {
        assert.number(clubId, 'clubId must be a number');

        const url = `${clubPrefix}${profileEndpoints.paymentMethods}`;
        return this.http.get(url);
      },

      attachPaymentMethod(_, clubId, paymentMethod) {
        assert.number(clubId, 'clubId must be a number');

        const url = `${clubPrefix}${profileEndpoints.attachPaymentMethod}`;
        return this.http.post(url, paymentMethod, { headers: { 'Content-Type': 'text/plain' } });
      },

      attachPaymentMethodBacs(_, clubId) {
        assert.number(clubId, 'clubId must be a number');

        const url = `${clubPrefix}${profileEndpoints.attachPaymentMethodBacs}`;
        return state.http.post(url, { headers: { 'Content-Type': 'text/plain' } });
      },

      removePaymentMethod(_, clubId, paymentMethod) {
        assert.number(clubId, 'clubId must be a number');

        const url = `${clubPrefix}${profileEndpoints.removePaymentMethod}`;
        return this.http.post(url, paymentMethod, { headers: { 'Content-Type': 'text/plain' } });
      },

      assignDefaultPaymentMethod(_, clubId, paymentMethod) {
        assert.number(clubId, 'clubId must be a number');

        const url = `${clubPrefix}${profileEndpoints.defaultPaymentMethod}`;
        return this.http.put(url, paymentMethod, { headers: { 'Content-Type': 'text/plain' } });
      },

      resumeSubscription(_, subscriptionId) {
        const url = `${clubPrefix}${profileEndpoints.resumeSubscription}`.replace(':subscriptionId', subscriptionId);

        return this.http.post(url);
      },

      pauseSubscription(_, subscriptionId) {
        const url = `${clubPrefix}${profileEndpoints.pauseSubscription}`.replace(':subscriptionId', subscriptionId);

        return this.http.post(url);
      },

      cancelSubscription(_, subscriptionId) {
        const url = `${clubPrefix}${profileEndpoints.cancelSubscription}`.replace(':subscriptionId', subscriptionId);

        return this.http.post(url);
      },

      findInvoices(_, { page = 0, limit = 10, extraParams = {}, sort }) {
        const url = `${clubPrefix}${profileEndpoints.findInvoices}`;

        assert.maybe.number(page, 'page must be number or undefined');
        assert.maybe.number(limit, 'limit must be number or undefined');

        const config = {
          params: {
            page,
            size: limit,
            ...extraParams,
          },
        };
        if (sort) {
          assert.string(sort.field, 'sort.field must be a string if sort is specified.');
          config.params.sort = this.createSortParam(sort.field, sort.desc);
        }

        return this.http.get(url, config);
      },

      payInvoice(clubId, invoiceNumber, amount) {
        assert.number(clubId, 'clubId must be a number');
        assert.string(invoiceNumber, 'invoiceNumber must be a string');
        assert.number(amount, 'amount must be a number');

        const url = `${clubPrefix}${profileEndpoints.payInvoice}` + invoiceNumber;
        return state.http.post(url, {
          amountToPay: amount,
        });
      },

      invoiceFile(clubId, invoiceNumber) {
        assert.number(clubId, 'clubId must be a number');
        assert.string(invoiceNumber, 'invoiceNumber must be a string');

        const url = `${clubPrefix}${profileEndpoints.invoiceFile}`
          .replace(':invoiceNumber', invoiceNumber);

        return state.http.get(url);
      },
      updateEmailAndPassword(payload) {
        const url = `${clubPrefix}customer/user/member/account`;
        return state.http.patch(url, payload);
      },
    }
  );
};

export default profileApi;
