import { useAbility } from "@casl/react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useClub,
  useInfiniteQueryMemberList,
  useMutationMemberListCsv,
  useQueryMemberListCount,
  UserMemberListFilter,
} from "@gymflow/api";
import { AlertContext, isMobile } from "@gymflow/common";
import { cn, formatCurrency, pluralize } from "@gymflow/helpers";
import { UserMemberListItem } from "@gymflow/types";
import { createColumnHelper } from "@tanstack/react-table";
import { capitalize } from "lodash";
import React, { useContext, useRef, useState } from "react";
import { Send } from "react-feather";
import { useWindowSize } from "usehooks-ts";

import filter from "../../assets/img/filter-lines.svg";
import {
  Badge,
  Button,
  Checkbox,
  DownloadIcon,
  Spinner,
} from "../components/atoms";
import { QuickActionsButton } from "../components/atoms/QuickActionsButton";
import { NewUserSideBarProviderContext } from "../components/molecules";
import { UserMemberAvatar } from "../components/molecules/UserMemberAvatar";
import { PaginatedTable } from "../components/organisms";
import { SettingsContainer } from "../components/Settings/SettingsContainer";
import SendEmailAlert from "../components/UserMember/SendEmails/SendEmailAlert";
import { usePageSize } from "../hooks";
import useSendEmails from "../hooks/useSendEmails";
import { AbilityContext, Subject, Verb } from "../permissions";
import { useClubSettings } from "../providers";
import useGymflowModels from "../store";
import { UserMemberListFilterSidebar } from "./UserMemberListFilterSidebar";

export interface UserMemberListNewProps {}

export const UserMemberListNew: React.FC<UserMemberListNewProps> = () => {
  const ability = useAbility(AbilityContext);
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { data: club } = useClub({ api, clubId: settings.clubId });
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState<UserMemberListFilter>({});
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const pageSize = usePageSize({
    tableContainerRef,
    rowHeight: 56,
  });
  const { data, isLoading, fetchNextPage } = useInfiniteQueryMemberList({
    api,
    pagination: {
      limit: pageSize,
    },
    filters,
    tz: settings.timezone,
  });

  const { data: count, isLoading: isLoadingCount } = useQueryMemberListCount({
    api,
    filters,
    tz: settings.timezone,
  });
  const { mutateAsync: downloadCsv } = useMutationMemberListCsv({
    api,
    tz: settings.timezone,
  });

  const [sort, setSort] = React.useState<any>([]);
  const { sendEmails } = useSendEmails();

  const columnHelper = createColumnHelper<UserMemberListItem>();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const alert = useContext(AlertContext);
  const actionColumn = columnHelper.accessor("userMemberId", {
    id: "actions",
    header: "Actions",
    cell: (cell) => {
      return <QuickActionsButton userMemberId={cell.getValue()} />;
    },
    enableSorting: false,
  });
  const selectColumn = columnHelper.accessor("userMemberId", {
    id: "selected",
    enableSorting: false,
    header: () => (
      <Checkbox
        className="pr-4"
        value={
          selectedUsers.length === data?.pages[currentPage]?.content.length
        }
        onChange={function (checked) {
          if (checked) {
            setSelectedUsers((e) => [
              ...(data?.pages[currentPage]?.content.map(
                (e) => e.userMemberId,
              ) ?? []),
            ]);
          } else {
            setSelectedUsers([]);
          }
        }}
      />
    ),
    cell: (cell) => {
      return (
        <Checkbox
          className="pr-4"
          value={selectedUsers.some((e) => e === cell.getValue())}
          onChange={function (checked) {
            if (checked) {
              setSelectedUsers((e) => [...e, cell.getValue()]);
            } else {
              setSelectedUsers(
                selectedUsers.filter((e) => e !== cell.getValue()),
              );
            }
          }}
        />
      );
    },
  });
  const desktopColumns = [
    selectColumn,
    columnHelper.accessor("userMemberId", {
      id: "firstName",
      header: "User",
      enableSorting: false,
      cell: (cell) => {
        return <UserMemberAvatar {...cell.row.original} id={cell.getValue()} />;
      },
    }),
    columnHelper.accessor(
      (e) => ({
        membershipName: e.membershipName,
        membershipPrice: e.membershipPrice,
      }),
      {
        id: "membershipName",
        header: "Current Membership",
        enableSorting: false,
        cell: (cell) => {
          if (!cell.getValue().membershipName) return <div>No Membership</div>;
          return (
            <div className="flex flex-col">
              <div className="text-sm font-medium">
                {cell.getValue().membershipName}
              </div>
              <div className="text-sm font-normal text-gray-600">
                {formatCurrency(
                  cell.getValue().membershipPrice,
                  settings.defaultCurrency,
                )}
              </div>
            </div>
          );
        },
      },
    ),
    columnHelper.accessor("membershipStatus", {
      id: "membershipStatus",
      header: "Membership Status",
      enableSorting: false,
      cell: (cell) => {
        if (!cell.getValue()) return "-";
        return (
          <Badge
            intent={
              (
                {
                  ACTIVE: "success",
                  PENDING: "default",
                  PAUSED: "warning",
                  CANCELLED: "default",
                  EXPIRED: "error",
                  DELETED: "error",
                  OVERDUE: "error",
                } as const
              )[cell.getValue()]
            }
          >
            {capitalize(cell.getValue())}
          </Badge>
        );
      },
    }),
    columnHelper.accessor(
      (e) => ({
        creditsRemaining: e.creditsRemaining,
        creditsUnlimited: e.creditsUnlimited,
      }),
      {
        header: "Credits",
        enableSorting: false,
        cell: (cell) => {
          if (!cell.getValue()) return "-";
          if (cell.getValue().creditsUnlimited) return <div>Unlimited</div>;
          if (cell.getValue().creditsRemaining)
            return <div>{cell.getValue().creditsRemaining}</div>;
          return "-";
        },
      },
    ),
    actionColumn,
  ];
  const mobileColumns = [
    selectColumn,
    columnHelper.accessor("userMemberId", {
      id: "firstName",
      header: "User",
      enableSorting: false,
      cell: (cell) => {
        return (
          <UserMemberAvatar
            className="w-full"
            {...cell.row.original}
            id={cell.getValue()}
          />
        );
      },
    }),
    actionColumn,
  ];
  const windowSize = useWindowSize({ debounceDelay: 100 });
  const [filtersAreVisible, setFiltersAreVisible] = useState(false);
  const { open } = useContext(NewUserSideBarProviderContext);
  return (
    <div className="flex h-full max-h-full w-full p-4 lg:p-8">
      <UserMemberListFilterSidebar
        isVisible={filtersAreVisible}
        onClose={() => setFiltersAreVisible(false)}
        value={filters}
        onChange={setFilters}
      />
      <SettingsContainer
        title={isLoadingCount ? <Spinner /> : `${count?.count} Users`}
        subTitle="All users that have accounts setup."
        actions={
          <>
            <Button
              onClick={async () => await downloadCsv(filters)}
              className="mt-0"
            >
              <DownloadIcon pathClassName="stroke-gray-500" />
            </Button>
            <Button
              className={cn("mt-0", {
                hidden:
                  selectedUsers.length === 0 ||
                  !ability.can(Verb.Create, Subject.Email),
              })}
              onClick={() => {
                alert.setAlert(
                  <SendEmailAlert
                    allowMarketing
                    from={club?.email ?? ""}
                    to={`${
                      selectedUsers.length === pageSize
                        ? count?.count
                        : selectedUsers.length
                    } ${pluralize(
                      "User",
                      "Users",
                      selectedUsers.length === pageSize
                        ? count?.count ?? 0
                        : selectedUsers.length,
                    )}`}
                    onSubmit={(values) => {
                      const bcc = values.bcc ? values.bcc.split(",") : [];
                      return sendEmails(
                        values.subject,
                        values.body,
                        selectedUsers,
                        values.marketing,
                        bcc,
                      );
                    }}
                    onCancel={alert.hide}
                  />,
                );
              }}
            >
              <Send className="h-5 w-5" />
            </Button>

            <Button className="mt-0" onClick={() => setFiltersAreVisible(true)}>
              <div className="inline-flex items-center gap-x-2">
                <img src={filter} alt="filter icon" />
                <div className={cn("hidden sm:flex")}>Filters</div>
              </div>
            </Button>
            <Button
              className="mt-0"
              onClick={() => {
                open();
              }}
              intent="secondary"
            >
              <div className="inline-flex items-center gap-x-2">
                <FontAwesomeIcon icon={faPlus} />
                <div className={cn("hidden sm:flex")}>User</div>
              </div>
            </Button>
          </>
        }
      >
        <PaginatedTable
          tableProps={{
            data: data?.pages[currentPage]?.content ?? [],
            columns: !isMobile(windowSize.width)
              ? desktopColumns
              : mobileColumns,
            onSortingChange: setSort,
            sort: sort,
            isFetching: isLoading,
            pageSize: pageSize,
            tableContainerRef,
          }}
          hasPreviousPage={!!data && currentPage > 0}
          hasNextPage={!!data && !!data.pages[currentPage]?.nextPageToken}
          goToNextPage={() => {
            setCurrentPage((e) => e + 1);
            if (!data?.pages[currentPage + 1]) {
              fetchNextPage();
            }
          }}
          goToPreviousPage={() => {
            setCurrentPage((e) => e - 1);
          }}
        />
      </SettingsContainer>
    </div>
  );
};
