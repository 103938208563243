import { Col, FormGroup, Label, Row } from 'reactstrap';
import StripeCard from '../atoms/StripeCard';
import { getErrorClass } from '../../helpers/formik';
import FormikInput from './FormikInput';

import ServicePaymentFormFields from '../organisms/ServicePaymentForm/constants';

const { CARD_HOLDERS_NAME, NEW_PAYMENT_METHOD } = ServicePaymentFormFields;

const StripeCardForm = ({ formikProps, cardError, setCardError, stripeStyle }) => {
  const errorClass = getErrorClass(formikProps);
  return (
    <>
      <Row className="text-uppercase">
        <Col>
          <Label htmlFor={CARD_HOLDERS_NAME}>Cardholder&apos;s name *</Label>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup className={errorClass(CARD_HOLDERS_NAME)}>
            <FormikInput
              name={CARD_HOLDERS_NAME}
              type="text"
              autoComplete="off"
              maxLength="128"
              placeholder="Cardholder's name"
              data-testid={CARD_HOLDERS_NAME}
              formikProps={formikProps}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="text-uppercase">
        <Col>
          <Label htmlFor={NEW_PAYMENT_METHOD}>Card Details *</Label>
        </Col>
      </Row>

      <Row>
        <Col>
          <StripeCard
            onChange={(e) => {
              if (e.error) {
                setCardError(e.error.message);
              } else {
                setCardError(null);
              }
            }}
            style={stripeStyle}
          />
        </Col>
      </Row>
      <Row>
        <Col>{cardError ? <p style={{ color: '#ec250d' }}>{cardError}</p> : ''}</Col>
      </Row>
    </>
  );
};

export default StripeCardForm;
