import { useStoreState } from "easy-peasy";
import { formatCurrency } from "@gymflow/helpers";

import useGymflowModels from "../store";

/**
 * @deprecated
 */
const useCreditPackLoadOptions = () => {
  const { api, settingsStore } = useGymflowModels();
  const { defaultCurrency: currency } = useStoreState(settingsStore);
  const loadOptions = async (_, __, { page }) => {
    const results = await api.creditPackApi.find({ page });

    const options = results.data.content.map(({ id, name, price }) => ({
      value: { id, name },
      label: (
        <>
          {name} <span className="text-muted">{`(${formatCurrency(price, currency)})`}</span>
        </>
      ),
    }));

    if (page === 0) {
      options.unshift({
        label: "All",
        value: null,
      });
    }

    return {
      options,
      hasMore: !results.data.last,
      additional: {
        page: page + 1,
      },
    };
  };
  return loadOptions;
};

export default useCreditPackLoadOptions;
