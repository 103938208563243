import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatCurrency, membershipHelper, ordinal } from "@gymflow/helpers";
import { UserMemberSubscriptionBeanWithMembership } from "@gymflow/types";
import { ModalContext, useClubSettings } from "apps/portal/src/providers";
import { DateTime } from "luxon";
import React, { useContext } from "react";

import { ExpireMembershipModal } from "./MembershipModal/ExpireMembershipModal";
import { UpdateBillingMembershipModal } from "./MembershipModal/UpdateBillingMembershipModal";
import { UpdateContractEndDateMembershipModal } from "./MembershipModal/UpdateContractEndDateMembershipModal";
import { UpdateStartDateMembershipModal } from "./MembershipModal/UpdateStartDateMembershipModal";

const billingTexts = ({
  price,
  billingDay,
  billingPeriod,
}: {
  price: string;
  billingDay: string;
  billingPeriod: string;
}) => ({
  WEEKLY: {
    singular: `${price} every ${billingPeriod} week on ${billingDay}`,
    plural: `${price} every ${billingPeriod} weeks on ${billingDay}`,
  },
  MONTHLY: {
    singular: `${price} every ${billingPeriod} month on the ${ordinal(
      Number(billingDay),
    )}`,
    plural: `${price} every ${billingPeriod} months on the ${ordinal(
      Number(billingDay),
    )}`,
  },
});

export type MembershipInformationLinesProps = {
  membership: UserMemberSubscriptionBeanWithMembership;
  memberId: string;
};

export const MembershipInformationLines: React.FC<
  MembershipInformationLinesProps
> = ({ membership, memberId }) => {
  const clubSettings = useClubSettings();
  const { setModal } = useContext(ModalContext);
  const billingLine = {
    title: "Billing",
    description: membership.billingType
      ? billingTexts({
          billingDay: membershipHelper.getBillingDay(membership),
          price: formatCurrency(membership.price, clubSettings.defaultCurrency),
          billingPeriod: `${membership.billingPeriod}`,
        })[membership.billingType][
          membership.billingPeriod === 1 ? "singular" : "plural"
        ]
      : "",
    onClick:
      "ACTIVE" === membership.status
        ? () => {
            setModal(
              <UpdateBillingMembershipModal
                membership={membership}
                memberId={memberId}
              />,
            );
          }
        : undefined,
  };
  const startDateLine = {
    title: "Start Date",
    description:
      membership.startDate &&
      DateTime.fromISO(membership.startDate).toLocaleString(DateTime.DATE_MED),
    onClick:
      "PENDING" === membership.status
        ? () => {
            setModal(
              <UpdateStartDateMembershipModal
                memberId={memberId}
                membership={membership}
              />,
            );
          }
        : undefined,
  };
  const contractEndDateLine = {
    title: "Contract End Date",
    description:
      membership.membershipBean.durationType === "NO_CONTRACT"
        ? "No Contract"
        : membership.endContractDate &&
          DateTime.fromISO(membership.endContractDate).toLocaleString(
            DateTime.DATE_MED,
          ),
    onClick:
      membership.membershipBean.durationType === "NO_CONTRACT"
        ? undefined
        : () =>
            setModal(
              <UpdateContractEndDateMembershipModal membership={membership} />,
            ),
  };

  const expiryDateLine = {
    title: "Expiry Date",
    description:
      membership.endContractDate &&
      DateTime.fromISO(membership.endContractDate).toLocaleString(
        DateTime.DATE_MED,
      ),
    onClick:
      membership.status === "ACTIVE"
        ? () =>
            setModal(
              <ExpireMembershipModal
                memberId={memberId}
                membership={membership}
              />,
            )
        : undefined,
  };

  const trialEndsLine = {
    title: "Trial Ends",
    description:
      membership.endDate &&
      DateTime.fromISO(membership.endDate).toLocaleString(DateTime.DATE_MED),
    onClick: () =>
      setModal(
        <ExpireMembershipModal memberId={memberId} membership={membership} />,
      ),
  };

  let lines = [];
  if (membership.membershipBean.type === "RECURRING") {
    lines.push(billingLine);
  }
  lines.push(startDateLine);
  if (membership.membershipBean.type === "RECURRING") {
    lines.push(contractEndDateLine);
  }
  if (
    membership.membershipBean.type === "PREPAID" &&
    !membership.membershipBean.isTrial
  ) {
    lines.push(expiryDateLine);
  }
  if (
    membership.membershipBean.type === "PREPAID" &&
    membership.membershipBean.isTrial
  ) {
    lines.push(trialEndsLine);
  }
  return (
    <>
      {lines.map((e) => (
        <MembershipInformationLine key={e.title} {...e} />
      ))}
    </>
  );
};

export interface MembershipInformationLineProps {
  title: string;
  description: string;
  onClick?: () => void;
}

const MembershipInformationLine: React.FC<MembershipInformationLineProps> = ({
  title,
  description,
  onClick,
}) => {
  return (
    <div
      key={title}
      className="flex w-full flex-row items-center justify-between"
    >
      <div className="inline-flex flex-row text-sm font-normal text-gray-600">
        <div className="font-semibold">{title}:&nbsp;</div>
        {description}
      </div>
      {onClick && (
        <div onClick={onClick} className="flex w-3 cursor-pointer justify-end">
          <FontAwesomeIcon
            className="cursor-pointer text-gray-600"
            icon={faEllipsisV}
          />
        </div>
      )}
    </div>
  );
};
