import { z } from "zod";

export const OverdueMemberPaymentMethod = {
  Card: "CARD",
  BacsDirectDebit: "BACS_DIRECT_DEBIT",
} as const;

export const OverdueMemberPaymentMethodZodSchema = z.enum([
  OverdueMemberPaymentMethod.Card,
  OverdueMemberPaymentMethod.BacsDirectDebit,
]);

Object.setPrototypeOf(OverdueMemberPaymentMethod, {
  humanize: (
    value: (typeof OverdueMemberPaymentMethod)[keyof typeof OverdueMemberPaymentMethod],
  ) => {
    switch (value) {
      case OverdueMemberPaymentMethod.Card:
        return "Card";
      case OverdueMemberPaymentMethod.BacsDirectDebit:
        return "BACS Direct Debit";
      default:
        throw new Error("Unknown OverdueMemberPaymentMethod.");
    }
  },
});
