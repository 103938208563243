import { LeadDTO, UserMemberBean } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export const memberSearchQueryFn = async (
  api: MemberSearchApi,
  {
    term,
    fields,
    size,
  }: {
    term: string;
    fields?: "name" | "email";
    size: number;
  },
) => {
  const result = await api.memberApi.globalSearch(term, fields, size);
  return result.data as (UserMemberBean | LeadDTO)[];
};

export function useMemberSearch({
  api,
  term,
  size,
  fields,
}: {
  api: MemberSearchApi;
  term: string;
  size: number;
  fields?: "name" | "email";
}) {
  const result = useQuery({
    queryKey: memberQueryKeys.search(term, size),
    queryFn: () => memberSearchQueryFn(api, { term, fields, size }),
    enabled: !!term,
  });
  return result;
}

type MemberSearchApi = {
  memberApi: {
    globalSearch: (
      term: string,
      fields: "name" | "email" | undefined,
      size: number,
    ) => Promise<any>;
  };
};
