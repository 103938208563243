import { cn } from "@gymflow/helpers";
import React from "react";

import { ShopCreditPackSelect } from "./ShopCreditPackSelect";
import { ShopMembershipSelect } from "./ShopMembershipSelect";
import { ShopProductSelect } from "./ShopProductSelect";
import { ShopCartType } from "./ShopTypes";

export type ShopItemPickerProps = {
  cart?: ShopCartType;
  setCart: (value: ShopCartType, stayInSection?: boolean) => void;
  hideShopSidebar: () => void;
  showShopSidebar: () => void;
  sidebarState:
    | ({
        isVisible: boolean;
        currentShopTab?: number;
      } & {
        onClose?: () => void;
      })
    | undefined;
  setSidebarState: (
    param:
      | {
          isVisible: boolean;
          currentShopTab?: number;
        }
      | undefined,
  ) => void;
  userHasActiveMembershipSubscription: boolean;
};

export const ShopItemPicker: React.FC<ShopItemPickerProps> = ({
  cart,
  setCart,
  hideShopSidebar,
  showShopSidebar,
  sidebarState,
  setSidebarState,
  userHasActiveMembershipSubscription,
}) => {
  const tabs = [
    {
      title: "Recurring",
      body: (
        <ShopMembershipSelect
          type="recurring"
          cart={cart}
          setCart={setCart}
          hideShopSidebar={hideShopSidebar}
          showShopSidebar={showShopSidebar}
          userHasActiveMembershipSubscription={
            userHasActiveMembershipSubscription
          }
        />
      ),
    },
    {
      title: "Pre-Paid",
      body: (
        <ShopMembershipSelect
          type="prepaid"
          cart={cart}
          setCart={setCart}
          hideShopSidebar={hideShopSidebar}
          showShopSidebar={showShopSidebar}
          userHasActiveMembershipSubscription={
            userHasActiveMembershipSubscription
          }
        />
      ),
    },
    {
      title: "Trials",
      body: (
        <ShopMembershipSelect
          type="trial"
          cart={cart}
          setCart={setCart}
          hideShopSidebar={hideShopSidebar}
          showShopSidebar={showShopSidebar}
          userHasActiveMembershipSubscription={
            userHasActiveMembershipSubscription
          }
        />
      ),
    },
    {
      title: "Credit Packs",
      body: (
        <ShopCreditPackSelect
          cart={cart}
          setCart={setCart}
          hideShopSidebar={hideShopSidebar}
          showShopSidebar={showShopSidebar}
        />
      ),
    },
    {
      title: "Products",
      body: (
        <ShopProductSelect
          cart={cart}
          setCart={setCart}
          hideShopSidebar={hideShopSidebar}
          showShopSidebar={showShopSidebar}
        />
      ),
    },
  ];
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-row items-center justify-evenly bg-white">
        {tabs.map((tab, idx) => {
          return (
            <div
              className={cn(
                "hover:bg-primary-50 -mb-px flex w-full flex-1 cursor-pointer items-center justify-center border-b border-gray-200 py-4 text-sm font-semibold text-gray-500 transition-all duration-100 ease-in-out",
                {
                  "border-secondary-500 text-secondary-500 -mb-[2px] border-b-2":
                    idx === (sidebarState?.currentShopTab ?? 0),
                },
              )}
              onClick={() => {
                setSidebarState({
                  ...sidebarState,
                  isVisible: sidebarState?.isVisible ?? false,
                  currentShopTab: idx,
                });
              }}
              key={idx}
            >
              {tab.title}
            </div>
          );
        })}
      </div>
      {tabs[sidebarState?.currentShopTab ?? 0].body}
    </div>
  );
};
