import { CardElement } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";

function StripeCard({ onChange, style }) {
  const stripeWrapperStyle = {
    padding: "10px 18px",
    height: "35.15px",
  };

  return (
    <div
      className="focus:ring-insetring-gray-300stripe-card dark:bg-darkModeFill dark:placeholder:text-darkGray-500 dark:text-darkGray-50 dark:ring-darkGray-700 rounded-lg border-gray-300 text-gray-900 ring-1 ring-inset focus:ring-1"
      style={stripeWrapperStyle}
    >
      <div style={{ marginTop: "-2px" }}>
        <CardElement
          onChange={onChange}
          options={{ style: { ...stripeStyle, ...style } }}
        />
      </div>
    </div>
  );
}

const stripeStyle = {
  base: {
    "::placeholder": {
      color: "#adb5bd",
    },
  },
};

StripeCard.defaultProps = {
  style: {},
};

StripeCard.propTypes = {
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default StripeCard;
