import {
  clubStaleTime,
  useClubHostedPagesSettingsAsPublic,
  useQueryClubFeatureFlagsAsPublic,
} from "@gymflow/api";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { useState } from "react";
import { matchPath, NavLink, useRouteMatch } from "react-router-dom";

import { usePortalRoutes } from "../../hooks";
import { useAuthenticatedUser, useClubSettings } from "../../providers";
import { useLoginOverlay } from "../../providers/authentication/loginOverlay/context";
import {
  generateSiteWithAuthRoutes,
  RouteFeature,
  RouteLayout,
} from "../../routes";
import useGymflowModels from "../../store";
import { Button, MenuIcon } from "../atoms";
import { BuyMembership as BuyMembershipSite } from "../site/BuyMembership";
import { BuySessionPack as BuySessionPackSite } from "../site/BuySessionPack";
import { Enquiry } from "../site/Enquiry";
import { Timetable } from "../site/Timetable";

export function SiteNavHeader() {
  const match = useRouteMatch();
  const settings = useClubSettings();
  const auth = useAuthenticatedUser();
  const { showLoginOverlay, showSignUpOverlay } = useLoginOverlay();

  const { api } = useGymflowModels();
  const { data: featureFlags } = useQueryClubFeatureFlagsAsPublic({
    api,
    clubId: settings.clubId,
  });

  const { data: hostedPagesSettings } = useClubHostedPagesSettingsAsPublic(
    { api, clubId: settings.clubId },
    { staleTime: clubStaleTime },
  );
  const routesWithAuth = generateSiteWithAuthRoutes(
    featureFlags,
    hostedPagesSettings?.settings,
  );

  const activeRoute = routesWithAuth.find((r) =>
    matchPath(match.url + r.path, window.location.pathname),
  );

  const [isNavDropdownOpen, setIsNavDropdownOpen] = useState(false);
  const { createClubLink } = usePortalRoutes();

  const routesForLinks = routesWithAuth.concat([
    {
      path: RouteFeature.SiteBuyMembership,
      name: "Memberships",
      component: BuyMembershipSite,
      invisible: !hostedPagesSettings?.settings?.membershipsNavEnabled,
    },
    {
      path: RouteFeature.SiteBuySessionPack,
      name: "Credit Packs",
      component: BuySessionPackSite,
      invisible: !hostedPagesSettings?.settings?.creditPacksNavEnabled,
    },
    {
      path: RouteFeature.SiteTimetable,
      name: "Schedule",
      component: Timetable,
      invisible: !hostedPagesSettings?.settings?.timetableNavEnabled,
    },

    {
      path: RouteFeature.SiteEnquiry,
      name: "Contact Us",
      component: Enquiry,
      invisible: !hostedPagesSettings?.settings?.leadCaptureNavEnabled,
    },
  ]);
  return (
    <>
      <div className="dark:border-b-darkGray-900 flex h-[4.75rem] content-between items-center justify-between border-b border-b-gray-300 px-4 md:hidden">
        <div className="text-primary-600 pl-2 text-sm font-medium">
          {activeRoute?.name}
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsNavDropdownOpen(!isNavDropdownOpen);
          }}
        >
          <MenuIcon />
        </div>
      </div>
      <Transition
        show={isNavDropdownOpen}
        enter="transition ease duration-300 transform"
        enterFrom="opacity-0 -translate-y-12"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease duration-300 transform"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-12"
      >
        <div className="dark:border-b-darkGray-900 flex flex-col gap-4 border-b border-b-gray-300 px-4 pb-4">
          {hostedPagesSettings?.settings?.yourWebsiteUrl && (
            <div className="text-sm">
              <a
                className={classNames(
                  "hover:!text-primary-600 text-sm font-medium dark:text-white",
                )}
                href={hostedPagesSettings.settings.yourWebsiteUrl}
              >
                Home
              </a>
            </div>
          )}
          {routesForLinks.map((r) => {
            const isActive = activeRoute?.path === r.path;
            if (r.invisible) {
              return null;
            }
            return (
              <div key={r.path} className="text-sm">
                <NavLink
                  className={classNames(
                    "hover:!text-primary-600 text-sm font-medium dark:text-white",
                    { "text-primary-600": isActive },
                  )}
                  activeClassName="!text-primary-600"
                  to={createClubLink(RouteLayout.Site, r.path)}
                >
                  {r.name}
                </NavLink>
              </div>
            );
          })}
          {auth.id ? (
            <div className="text-sm">
              <div
                className="hover:!text-primary-600 cursor-pointer text-sm font-medium dark:text-white"
                onClick={() => {
                  auth.logout();
                }}
              >
                Logout
              </div>
            </div>
          ) : (
            <>
              <div className="text-sm">
                <div
                  className="hover:!text-primary-600 cursor-pointer text-sm font-medium dark:text-white"
                  onClick={async () => {
                    await showLoginOverlay();
                    setIsNavDropdownOpen(false);
                  }}
                >
                  Login
                </div>
              </div>
              <div className="text-sm">
                <div
                  className="hover:!text-primary-600 cursor-pointer text-sm font-medium dark:text-white"
                  onClick={async () => {
                    await showSignUpOverlay();
                    setIsNavDropdownOpen(false);
                  }}
                >
                  Sign Up
                </div>
              </div>
            </>
          )}
        </div>
      </Transition>
      <div className="dark:border-b-darkGray-900 hidden h-[4.75rem] content-between items-center justify-around border-b border-b-gray-300 px-4 md:flex">
        {hostedPagesSettings?.settings?.logo ? (
          <div className="flex h-10 w-48 justify-center">
            {hostedPagesSettings?.settings?.yourWebsiteUrl ? (
              <a
                className="flex justify-center"
                href={hostedPagesSettings.settings.yourWebsiteUrl}
              >
                <img src={hostedPagesSettings.settings.logo} alt="logo" />
              </a>
            ) : (
              <img src={hostedPagesSettings.settings.logo} alt="logo" />
            )}
          </div>
        ) : (
          <div className="bg-gymflow-logo h-10 w-48 bg-contain bg-center bg-no-repeat"></div>
        )}
        <div className="flex items-center gap-4">
          {hostedPagesSettings?.settings?.yourWebsiteUrl && (
            <div className="p-4 text-center text-sm">
              <a
                className={classNames(
                  "hover:!text-primary-600 text-sm font-medium dark:text-white",
                )}
                href={hostedPagesSettings?.settings?.yourWebsiteUrl}
              >
                Home
              </a>
            </div>
          )}
          {routesForLinks.map((r) => {
            const isActive = activeRoute?.path === r.path;
            if (r.invisible) {
              return null;
            }
            return (
              <div key={r.path} className="p-4 text-center text-sm">
                <NavLink
                  className={classNames(
                    "hover:!text-primary-600 text-sm font-medium dark:text-white",
                    { "text-primary-600": isActive },
                  )}
                  activeClassName="!text-primary-600"
                  to={createClubLink(RouteLayout.Site, r.path)}
                >
                  {r.name}
                </NavLink>
              </div>
            );
          })}
        </div>
        <div className="flex items-center gap-4">
          {auth.id ? (
            <Button
              intent="primary"
              className="mt-0"
              onClick={() => auth.logout()}
            >
              Logout
            </Button>
          ) : (
            <>
              <div
                className="cursor-pointer text-sm dark:text-white"
                onClick={() => {
                  showLoginOverlay();
                }}
              >
                Sign In
              </div>
              <Button
                intent="primary"
                className="mt-0"
                onClick={() => {
                  showSignUpOverlay();
                }}
              >
                Sign Up
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
