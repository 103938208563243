import { CheckInDTO } from "@gymflow/types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { reportQueryKeys } from "../report";
import { accessQueryKeys } from "./accessQueryKeys";

export function useCheckInQuery({
  api,
  userMemberId,
}: {
  api: {
    accessApi: {
      getCheckIn: (userMemberId: string) => Promise<any>;
    };
  };
  userMemberId?: string;
}) {
  return useQuery({
    queryKey: accessQueryKeys.member(userMemberId),
    queryFn: async () => {
      const response = await api.accessApi.getCheckIn(userMemberId!);
      return response.data as {
        isCheckedIn: boolean;
      };
    },
    enabled: !!userMemberId,
  });
}

export function useCheckInMutation(
  {
    api,
  }: {
    api: {
      accessApi: {
        checkIn: (
          userMemberId: string,
          payload: { guest: boolean },
        ) => Promise<any>;
      };
    };
  },
  mutationOptions?: { onSuccess?: () => void },
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      userMemberId,
      payload,
    }: {
      userMemberId: string;
      payload: { guest: boolean };
    }) => {
      const response = await api.accessApi.checkIn(userMemberId, payload);
      return response.data as CheckInDTO;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: accessQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: reportQueryKeys.allAccess() });
      mutationOptions?.onSuccess?.();
    },
  });
}
