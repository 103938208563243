import {
  eventOccurrenceListQueryFn,
  eventOccurrenceListSelectFn,
} from "@gymflow/api";
import { useStoreState } from "easy-peasy";
import PropTypes from "prop-types";
import { ChevronDown, ChevronUp } from "react-feather";
import { AsyncPaginate } from "react-select-async-paginate";

import useGymflowModels from "../../store";
import EventCard from "./EventCard";

function EventSelect({ value, onChange, filters }) {
  const { api, settingsStore } = useGymflowModels();
  const { timezone } = useStoreState(settingsStore);
  const loadOptions = async (_, __, { page }) => {
    const data = await eventOccurrenceListQueryFn({
      filter: {
        dateFrom: filters?.dateFrom,
        dateTo: filters?.dateTo,
        "event.activity.activityCategory.id":
          filters?.["event.activity.activityCategory.id"],
        "event.isBookable": filters?.["event.isBookable"],
        page,
      },
      api,
      tz: timezone,
    });
    const options = eventOccurrenceListSelectFn(data).content.map((event) => {
      const {
        event: {
          activity: { name },
          userEventHost: { firstName, lastName },
          capacity,
          isFullDayEvent,
        },
        startDate,
        endDate,
        bookedCount,
      } = event;

      return {
        value: event,
        label: (
          <EventCard
            name={name}
            startDate={startDate}
            endDate={endDate}
            isFullDayEvent={isFullDayEvent}
            hostName={`${firstName} ${lastName}`}
            bookedCount={bookedCount}
            capacity={capacity}
          />
        ),
        isDisabled: value?.value?.id === event.id,
      };
    });
    return {
      hasMore: !data.last,
      options,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <AsyncPaginate
      additional={{
        page: 0,
      }}
      components={{
        DropdownIndicator: ({ selectProps: { menuIsOpen } }) => {
          if (menuIsOpen) {
            return <ChevronUp color="#31436e" />;
          }
          return <ChevronDown color="#31436e" />;
        },
      }}
      styles={{
        control: (provided) => ({
          ...provided,
          height: "57px",
          fontSize: "12px",
          border: "1px solid #f0f0f0",
          boxShadow: "0 5px 10px 0px #f5f6f6",
        }),
        option: (provided) => ({
          ...provided,
          fontSize: "12px",
          height: "57px",
          padding: "0px",
        }),
        valueContainer: (provided) => ({
          ...provided,
          fontSize: "12px",
          height: "55px",
          width: "100%",
          padding: "0px",
        }),
        singleValue: (provided) => ({
          ...provided,
          width: "100%",
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: "none",
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          marginRight: "8px",
        }),
      }}
      isSearchable={false}
      loadOptions={loadOptions}
      placeholder="Select Event"
      value={value}
      onChange={onChange}
      isOptionSelected={({ value: { isDisabled } }) => isDisabled}
      cacheUniqs={[value, filters]}
    />
  );
}

EventSelect.defaultProps = {
  value: null,
};

EventSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
  onChange: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    "event.activity.activityCategory.id": PropTypes.number,
    "event.isBookable": PropTypes.bool,
  }),
};

export default EventSelect;
