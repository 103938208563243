import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@gymflow/helpers";
import { cva, VariantProps } from "class-variance-authority";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { components } from "react-select";
import {
  ActionMeta,
  GroupBase,
  OptionsOrGroups,
  SetValueAction,
} from "react-select/dist/declarations/src/types";
import { AsyncPaginate } from "react-select-async-paginate";
import { twMerge } from "tailwind-merge";

import CogIcon from "../../../assets/img/cog.svg";

export interface PaginatedSelectOption {
  value: any;
  label: ReactNode;
  isDisabled?: boolean;
}

export interface PaginatedSelectAdditional {
  page: number;
}

const paginatedSelectVariants = cva("", {
  variants: {
    size: {
      small: "h-[2.375rem] text-sm",
      medium: "",
    },
    color: {
      default: "dark:!border-darkGray-700 border-gray-300",
      accent: "bg-accent hover:bg-accent border-accent",
    },
  },
  defaultVariants: {
    size: "medium",
    color: "default",
  },
});

export type PaginatedSelectVariantProps = VariantProps<
  typeof paginatedSelectVariants
>;

interface LoadOptionsResult {
  readonly options: PaginatedSelectOption[];
  readonly hasMore?: boolean;
  readonly additional?: PaginatedSelectAdditional;
}

export interface SelectAllClickArgs {
  isAllSelected: boolean;
  fetchAllOptions: (searchTerm?: string) => Promise<{
    allOptions: PaginatedSelectOption[];
    pageCount: number;
  }>;
  setValue: (newValue: any, action: SetValueAction, option?: any) => void;
}

export interface PaginatedSelectProps extends PaginatedSelectVariantProps {
  value: any;
  onChange: (newValue: any, action: ActionMeta<any>) => void;
  className?: string;
  loadOptions: (
    inputValue: string,
    loadedOptions: readonly PaginatedSelectOption[],
    additional: PaginatedSelectAdditional,
  ) => Promise<LoadOptionsResult>;
  isSearchable?: boolean;
  isMulti?: boolean;
  placeholder?: string;
  cacheUniqs?: ReadonlyArray<any>;
  isDisabled?: boolean;
  isClearable?: boolean;
  icon?: ReactNode;
  compact?: boolean;
  menuWidthShouldMatchText?: boolean;
  showSelectAll?: boolean;
  selectAllClick?: (params: SelectAllClickArgs) => Promise<boolean | void>;
  showMenuFooterButton?: boolean;
  menuFooterText?: ReactNode;
  menuFooterClick?: () => Promise<void>;
  refetchOnMenuOpen?: boolean;
}

export function PaginatedSelect({
  value,
  onChange: onChangeParam,
  className,
  loadOptions: loadOptionsParam,
  isSearchable = false,
  isMulti = false,
  placeholder,
  cacheUniqs: cacheUniqsParam,
  isDisabled = false,
  isClearable,
  icon,
  size,
  color,
  compact,
  menuWidthShouldMatchText,
  showSelectAll,
  selectAllClick: selectAllClickParam,
  showMenuFooterButton,
  menuFooterText,
  menuFooterClick,
  refetchOnMenuOpen,
}: PaginatedSelectProps) {
  const optionRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const hasAlreadyFocusedValue = useRef(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  useEffect(() => {
    if (!isMenuOpen) {
      hasAlreadyFocusedValue.current = false;
    }
  }, [isMenuOpen]);

  const loadOptions = useCallback(
    async (
      inputValue: string,
      options: OptionsOrGroups<any, GroupBase<any>>,
      additional:
        | {
            page: number;
          }
        | undefined,
    ) => {
      const result = await loadOptionsParam(
        inputValue,
        options,
        additional || { page: 0 },
      );

      return {
        additional: { page: 0 },
        ...result,
      };
    },
    [loadOptionsParam],
  );

  const onChange = useCallback(
    (newValue: any, action: ActionMeta<any>) => {
      onChangeParam(newValue, action);
      if (isMulti && showSelectAll && isAllSelected) {
        setIsAllSelected(false);
      }
    },
    [isAllSelected, isMulti, onChangeParam, showSelectAll],
  );

  const cacheUniqs = useMemo(() => {
    let deps;
    if (refetchOnMenuOpen) {
      deps = [cacheUniqsParam, isMenuOpen];
    } else if (cacheUniqsParam) {
      deps = cacheUniqsParam;
    }

    return deps;
  }, [cacheUniqsParam, isMenuOpen, refetchOnMenuOpen]);

  // TODO: fix menu closing on mobile when an item is selected even if
  // closeOnSelect is false
  return (
    <AsyncPaginate
      loadOptions={loadOptions}
      components={{
        DropdownIndicator: ({ selectProps: { menuIsOpen } }: any) => {
          if (compact) {
            return null;
          }
          if (menuIsOpen) {
            return (
              <FontAwesomeIcon
                icon={faAngleUp}
                className={cn(dropdownIndicatorVariants({ color, size }))}
              />
            );
          }
          return (
            <FontAwesomeIcon
              icon={faAngleDown}
              className={cn(dropdownIndicatorVariants({ color, size }))}
            />
          );
        },
        IndicatorSeparator: () => {
          return null;
        },
        Option: ({ children, ...props }) => {
          if (!hasAlreadyFocusedValue.current) {
            hasAlreadyFocusedValue.current = true;
            setTimeout(() => {
              if (optionRef.current) {
                optionRef.current?.parentElement?.scroll({
                  top: optionRef.current.offsetTop,
                });
              }
            }, 10);
          }
          return (
            <div
              ref={
                (props as any).value === value?.value ? optionRef : undefined
              }
            >
              <components.Option {...props}>
                <div
                  className={cn("flex items-center gap-2", {
                    "text-gray-200": props.isDisabled,
                  })}
                >
                  <div
                    className={cn("flex h-6 items-center", {
                      hidden: !isMulti,
                    })}
                  >
                    <input
                      checked={props.isSelected || isAllSelected}
                      type="checkbox"
                      className="text-secondary-600 focus:ring-secondary-600 h-4 w-4 border-gray-300"
                      onChange={() => {}}
                    />
                  </div>
                  <div className="flex-1">{children}</div>
                </div>
              </components.Option>
            </div>
          );
        },
        ValueContainer: ({ children, className, ...props }) => {
          return (
            <>
              <div className={cn("pl-2", { hidden: !icon })}>{icon}</div>
              <components.ValueContainer
                className={twMerge(
                  cn(
                    "!py-0 !pl-0 text-left font-semibold",
                    valueContainerVariants({ color }),
                    { "!pr-4": !compact },
                    { "ml-4": !icon },
                    className,
                  ),
                )}
                {...props}
              >
                {children}
              </components.ValueContainer>
            </>
          );
        },
        Menu: ({ children, ...props }) => {
          return (
            <components.Menu {...props}>
              <div
                className={cn(
                  optionVariants({ size }),
                  "flex gap-2 border-b border-b-gray-200 p-3",
                  { hidden: !isMulti || !showSelectAll },
                )}
                onClick={async () => {
                  if (!selectAllClickParam) {
                    return;
                  }
                  const fetchAllOptions = async (searchTerm?: string) => {
                    let allOptions: PaginatedSelectOption[] = [];
                    let allLoaded = false;
                    let page = 0;

                    do {
                      const result = await loadOptions(searchTerm || "", [], {
                        page: page++,
                      });
                      allLoaded = !result.hasMore;
                      allOptions = allOptions.concat(result.options);
                    } while (!allLoaded);
                    return { allOptions, pageCount: page };
                  };
                  const result = await selectAllClickParam({
                    isAllSelected,
                    fetchAllOptions,
                    setValue: props.setValue,
                  });
                  if (result === undefined) {
                    setIsAllSelected(!isAllSelected);
                  } else {
                    setIsAllSelected(result);
                  }
                }}
              >
                <div className="flex h-6 items-center">
                  <input
                    defaultChecked={isAllSelected}
                    type="checkbox"
                    className="text-secondary-600 focus:ring-secondary-600 h-4 w-4 border-gray-300"
                    onChange={() => {}}
                  />
                </div>
                <div>{isAllSelected ? "Deselect All" : "Select All"}</div>
              </div>
              {children}
              <div
                className={cn(
                  optionVariants({ size }),
                  "flex gap-2 border-t border-t-gray-200 px-3 py-3",
                  { hidden: !showMenuFooterButton },
                )}
                onClick={() => {
                  if (menuFooterClick) {
                    menuFooterClick();
                  }
                }}
              >
                <img alt="" src={CogIcon} />
                {menuFooterText ?? "Manage"}
              </div>
            </components.Menu>
          );
        },
      }}
      classNames={{
        control: ({ isFocused, menuIsOpen }) =>
          cn(
            "!border-none bg-transparent !outline-0 !shadow-none !flex !flex-row justify-between w-full !flex-nowrap min-h-0",
            {
              "ring ring-secondary-600": isFocused && menuIsOpen,
            },
          ),
        menu: () =>
          cn("!z-[9999] !rounded-lg dark:bg-darkModeFill", {
            "-ml-[4.2rem]": compact,
            "w-auto": compact || menuWidthShouldMatchText,
          }),
        option: ({ isSelected }: { isSelected: boolean }) =>
          cn(optionVariants({ size }), {
            "font-semibold": isSelected,
          }),
        valueContainer: () =>
          cn("border-none shadow-none cursor-pointer", {
            "!ml-2": compact,
          }),
      }}
      className={cn(
        "dark:hover:bg-darkGray-900 relative flex min-w-fit cursor-pointer items-center !rounded-lg border border-gray-300 text-center hover:bg-gray-100",
        paginatedSelectVariants({ size, color }),
        { "bg-gray-100": isDisabled },
        {
          "after:bg-secondary-200 after:border-secondary-300 after:contents-[123] relative after:absolute after:right-0 after:top-0 after:flex after:aspect-square after:h-6 after:p-1 after:-translate-y-1/2 after:translate-x-1/2 after:items-center after:justify-center after:rounded-full after:border after:text-sm":
            isMulti && value?.length > 0,
        },
        className,
      )}
      styles={{
        container: () => ({
          "&:after":
            isMulti && value?.length > 0
              ? { content: `"${value?.length ?? 0}"` }
              : {},
        }),
      }}
      value={value}
      onChange={onChange}
      isSearchable={isSearchable}
      isMulti={isMulti}
      placeholder={placeholder}
      additional={{ page: 0 }}
      cacheUniqs={cacheUniqs}
      isDisabled={isDisabled}
      isClearable={isClearable}
      menuPlacement="auto"
      menuIsOpen={isMenuOpen}
      onMenuOpen={() => {
        // Fix for menu instantly closing with touch events
        setTimeout(() => {
          setIsMenuOpen(true);
        }, 1);
      }}
      onMenuClose={() => {
        setIsMenuOpen(false);
      }}
      closeMenuOnSelect={!isMulti}
      hideSelectedOptions={false}
      controlShouldRenderValue={!isMulti}
    />
  );
}

const dropdownIndicatorVariants = cva("mr-4 cursor-pointer", {
  variants: {
    color: {
      default: "dark:text-darkGray-50 text-sm font-semibold text-gray-700",
      accent: "text-white",
    },
    size: {
      small: "mt-px",
      medium: "",
    },
  },
  defaultVariants: {
    color: "default",
    size: "medium",
  },
});

const valueContainerVariants = cva("", {
  variants: {
    color: {
      default:
        "dark:text-darkGray-50 dark:[&>div]:text-darkGray-50 text-sm font-semibold text-gray-700 [&>div]:text-gray-700",
      accent: "text-white [&>div]:text-white",
    },
  },
  defaultVariants: {
    color: "default",
  },
});

const optionVariants = cva(
  "dark:hover:!bg-darkGray-900 dark:!bg-darkModeFill dark:!text-darkGray-50 !cursor-pointer !bg-white text-left !text-sm font-medium !text-gray-700 hover:!bg-gray-50",
  {
    variants: {
      size: {
        small: "",
        medium: "",
      },
    },
    defaultVariants: {
      size: "medium",
    },
  },
);

export async function eagerSelectAll({
  fetchAllOptions,
  setValue,
  isAllSelected,
}: SelectAllClickArgs) {
  if (isAllSelected) {
    setValue([], "deselect-option");
    return;
  }
  const { allOptions } = await fetchAllOptions();
  setValue(allOptions, "select-option");
}
