import endpoints from './endpoints';

const emailEndpoints = {
  noFilter: `${endpoints.sendEmail}/no-filter`,
  filter: `${endpoints.sendEmail}/filter`,
};

const emailApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    http: axiosInstance,
    baseUrl: endpoints.sendEmail,
    apiUrl,
  };

  return Object.assign(state, {
    sendEmail(clubId, from, subject, body, emailRecipientList, isMarketing, bccList = []) {
      return state.http.post(`${clubPrefix}${emailEndpoints.noFilter}`, {
        emailRecipientList,
        body,
        subject,
        fromEmail: from,
        bccList,
        isMarketing,
        clubId,
      });
    },
    sendEmailFilter(
      clubId,
      from,
      subject,
      body,
      filters,
      isMarketing,
      leadIdsToExclude = [],
      userMemberIdsToExclude = [],
      bccList = []
    ) {
      return state.http.post(`${clubPrefix}${emailEndpoints.filter}`, {
        body,
        subject,
        fromEmail: from,
        emailSendingFilters: filters,
        emailsToExclude: {
          leadIdList: leadIdsToExclude,
          userMemberIdList: userMemberIdsToExclude,
        },
        bccList,
        isMarketing,
        clubId,
      });
    },
  });
};

export default emailApi;
