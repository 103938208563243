import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberAsMemberQueryKeys } from "./memberAsMemberQueryKeys";

export function useMemberInvoiceAsMember({
  api,
}: {
  api: {
    profileApi: {
      payInvoice: (
        clubId: number,
        invoiceNumber: string,
        amount: number,
      ) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const payInvoiceMutation = useMutation({
    mutationFn: ({
      clubId,
      invoiceNumber,
      amount,
    }: {
      clubId: number;
      invoiceNumber: string;
      amount: number;
    }) => api.profileApi.payInvoice(clubId, invoiceNumber, amount),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: memberAsMemberQueryKeys.all(),
      });
    },
  });

  return {
    payInvoiceMutation,
  };
}
