import PropTypes from 'prop-types';

function PaymentMethodOption({ type, last4Digits, expMonth, expYear }) {
  let icon;
  let text;
  if (type === PaymentMethodTypes.Card) {
    icon = 'far fa-credit-card';
    text = (
      <>
        Card ending in <b>{last4Digits}</b> (expires <b>{`${expMonth}/${expYear}`}</b>)
      </>
    );
  } else if (type === PaymentMethodTypes.Bacs) {
    icon = 'fa fa-university';
    text = (
      <>
        Direct debit account ending in <b>{last4Digits}</b>
      </>
    );
  } else {
    throw new Error('Payment type not supported');
  }

  return (
    <div className="align-text-top">
      <i className={icon} />
      <div className="d-inline-block ml-2">{text}</div>
    </div>
  );
}

const PaymentMethodTypes = {
  Card: 'card',
  Bacs: 'bacs_debit',
};

PaymentMethodOption.propTypes = {
  type: PropTypes.oneOf([PaymentMethodTypes.Card, PaymentMethodTypes.Bacs]).isRequired,
  last4Digits: PropTypes.string,
  expMonth: PropTypes.number,
  expYear: PropTypes.number,
};

export default PaymentMethodOption;
