import { ApiResolverServer, ApiResolverUrls } from "@gymflow/types";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosInstance, HttpStatusCode } from "axios";
import { useEffect, useState } from "react";
import { Mutable } from "utility-types";

import { getServer, searchClubs } from "../ApiResolver";
import { TokenStorage } from "../TokenStorage";

export interface ApiMeta {
  apiUrl: string;
  keycloakRealm: string;
  keycloakUrl: string;
  appId?: string;
}

const storageKey = "gym-route-id";
export interface UseApiResolverProps {
  api: AxiosInstance;
  tokenStorage: TokenStorage;
  brand?: string;
  brandedAppClubRoute?: string;
  apiResolverUlr?: string;
  sentryCaptureMessageOnCacheInvalidation?: () => void;
}
export const useApiResolver = ({
  api,
  tokenStorage,
  brand,
  brandedAppClubRoute,
  apiResolverUlr,
  sentryCaptureMessageOnCacheInvalidation,
}: UseApiResolverProps) => {
  const [isApiSelected, setIsApiSelected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState<string>();
  const [apiMeta, setApiMeta] = useState<ApiMeta>();
  const [clubId, setClubId] = useState<number>();
  const { data: options } = useQuery({
    queryKey: ["clubOptions", query],
    queryFn: async () => {
      if (query === undefined) return null;
      const result = (
        await searchClubs({
          query,
          paginationOptions: { page: 0, limit: 6 },
          apiResolverUlr,
          brand,
        })
      ).results;
      return result;
    },
  });
  const getServerCached = async (
    currentRoute: string,
    apiResolverUlr?: string,
  ): Promise<ApiResolverServer> => {
    let startTime = performance.now();
    const key = `${currentRoute}-${apiResolverUlr}`;
    const cached = await AsyncStorage.getItem(key);
    if (cached) {
      const result = JSON.parse(cached) as ApiResolverServer;
      if (!result.urls.auth.startsWith("https://keycloak-")) {
        const updateCache = async () => {
          try {
            const server = await getServer(currentRoute, apiResolverUlr);
            await AsyncStorage.setItem(key, JSON.stringify(server));
          } catch (e) {
            if (e instanceof AxiosError) {
              if (e.response?.status === HttpStatusCode.NotFound) {
                await AsyncStorage.removeItem(key);
              }
            }
          }
        };
        updateCache();
        console.info(
          `retrieve club time: ${performance.now() - startTime}ms (cached)`,
        );
        return result;
      } else {
        console.info(cached);
        sentryCaptureMessageOnCacheInvalidation?.();
      }
    }
    let server = await getServer(currentRoute, apiResolverUlr);
    await AsyncStorage.setItem(key, JSON.stringify(server));
    console.info(
      `retrieve club time: ${performance.now() - startTime}ms (not cached)`,
    );
    return server;
  };
  useEffect(() => {
    (async () => {
      const currentRoute =
        brandedAppClubRoute ?? (await tokenStorage.get(storageKey));
      if (currentRoute) {
        try {
          const server = await getServerCached(currentRoute, apiResolverUlr);
          (server.urls as Mutable<typeof server.urls>).api =
            server.urls.api + "/club/" + server.clubId;
          setClubId(server.clubId);
          api.defaults.baseURL = server.urls.api;
          delete api.defaults.headers.common["Authorization"];
          setApiMeta({
            apiUrl: server.urls.api,
            keycloakUrl: server.urls.auth,
            keycloakRealm: server.authRealm,
          });
          setIsApiSelected(true);
        } catch {
          await tokenStorage.remove(storageKey);
        }
      }
      setLoading(false);
    })();
  }, [api, tokenStorage, apiResolverUlr, brandedAppClubRoute]);
  return {
    isApiSelected,
    refreshApiOptions: (newQuery: string) => {
      setQuery(newQuery);
    },
    apiOptions: options,
    selectApiOption: async (index?: number) => {
      if (options && index !== undefined) {
        await tokenStorage.set(storageKey, options[index].routeId);
        const startTime = performance.now();

        const key = `${options[index].routeId}-${apiResolverUlr}`;
        const server = await getServer(options[index].routeId, apiResolverUlr);
        await AsyncStorage.setItem(key, JSON.stringify(server));

        console.info(`retrieve club time: ${performance.now() - startTime}ms`);

        (server.urls as Mutable<ApiResolverUrls>).api =
          server.urls.api + "/club/" + server.clubId;
        setClubId(server.clubId);
        api.defaults.baseURL = server.urls.api;
        delete api.defaults.headers.common["Authorization"];
        setApiMeta({
          apiUrl: server.urls.api,
          keycloakUrl: server.urls.auth,
          keycloakRealm: server.authRealm,
        });
        setIsApiSelected(true);
      }
      if (index === undefined) {
        delete api.defaults.baseURL;
        delete api.defaults.headers.common["Authorization"];
        await tokenStorage.remove(storageKey);
        setIsApiSelected(false);
        setApiMeta(undefined);
      }
      setQuery(undefined);
    },
    apiMeta,
    loading,
    clubId,
  };
};
