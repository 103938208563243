import { MouseEventHandler, ReactNode, useEffect, useState } from "react";

import { Button } from "./Button";

export function TextInputWithButton({
  onTextChange,
  value,
  buttonText,
  onButtonClick,
}: {
  onTextChange?: (newValue: string) => void;
  onButtonClick: MouseEventHandler<HTMLButtonElement>;
  buttonText: ReactNode;
  value: string;
}) {
  const [text, setText] = useState(value);
  useEffect(() => {
    setText(value);
  }, [value]);
  return (
    <div className="flex rounded-md shadow-sm">
      <div className="relative flex grow items-stretch focus-within:z-10">
        <input
          type="text"
          disabled={!!value}
          className="-mr-px block h-11 w-full flex-1 rounded-none rounded-l-lg border-0 px-3.5 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset sm:text-sm sm:leading-6"
          value={text}
          onChange={({ currentTarget: { value: newValue } }) => {
            if (onTextChange) {
              onTextChange(newValue);
            }
          }}
        />
      </div>
      <Button
        className="mt-0 !rounded-l-none ring-inset"
        onClick={onButtonClick}
      >
        {buttonText}
      </Button>
    </div>
  );
}
