import { cn } from "@gymflow/helpers";
import { Dialog, Transition } from "@headlessui/react";
import {
  createContext,
  Fragment,
  ReactNode,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

import { Button, CheckCircleIcon, DangerIcon } from "../components/atoms";

export const ModalContext = createContext<{
  setModal: (modal: ReactNode) => void;
  hide: () => void;
  stackModal: (modal: ReactNode) => void;
  popModal: () => void;
}>({
  setModal: () => {
    throw Error("ModalContext not initialized.");
  },
  hide: () => {
    throw Error("ModalContext not initialized.");
  },
  stackModal: () => {
    throw Error("ModalContext not initialized.");
  },
  popModal: () => {
    throw Error("ModalContext not initialized.");
  },
});

export function ModalProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [modalStack, setModalStack] = useState<ReactNode[] | null>(null);
  const hide = useCallback(() => {
    setModalStack(null);
  }, []);

  const setModal = useCallback(
    (modal: ReactNode) => {
      setModalStack([modal]);
    },
    [setModalStack],
  );

  const stackModal = useCallback(
    (modal: ReactNode) => {
      setModalStack((prev) => {
        if (prev) {
          return [...prev, modal];
        } else {
          return [modal];
        }
      });
    },
    [setModalStack],
  );

  const popModal = useCallback(() => {
    setModalStack((prev) => {
      if (prev) {
        return prev.slice(0, prev.length - 1);
      } else {
        return null;
      }
    });
  }, [setModalStack]);

  return (
    <ModalContext.Provider
      value={{
        setModal,
        stackModal,
        popModal,
        hide,
      }}
    >
      {modalStack !== null &&
        modalStack.map((m, idx) => (
          <div
            key={idx}
            className={cn({
              hidden: idx !== modalStack.length - 1,
            })}
          >
            {m}
          </div>
        ))}
      {children}
    </ModalContext.Provider>
  );
}

export function ModalWrapper({
  onCancel,
  children,
  className,
}: {
  onCancel: () => void;
  children: ReactNode;
  className?: string;
}) {
  const dialogRef = useRef(null);

  return (
    <Transition.Root show as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[80000]"
        onClose={onCancel}
        initialFocus={dialogRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="bg-primary-700/10 fixed inset-0 backdrop-blur-[2px] transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-[1051] overflow-y-auto">
          <div className="flex min-h-[150dvh] items-end justify-center p-4 pb-[calc(50dvh+1rem)] text-center sm:min-h-full sm:items-center sm:p-0 sm:pb-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={cn(
                  "dark:bg-darkModeFill relative w-full rounded-lg bg-white p-4 text-left shadow-xl transition-all sm:my-8 sm:max-w-sm sm:p-6",
                  className,
                )}
                ref={dialogRef}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export function ErrorModal({
  children,
  onClose,
  title = "Error",
}: {
  children: ReactNode;
  onClose?: () => void;
  title?: string;
}) {
  const { hide } = useContext(ModalContext);
  const cancelHandler = onClose || hide;

  return (
    <ModalWrapper onCancel={cancelHandler}>
      <div className="bg-error-50 flex h-11 w-11 items-center  justify-center self-center rounded-full">
        <div className="bg-error-100 flex h-8 w-8 items-center justify-center self-center rounded-full">
          <DangerIcon className="h-5 w-5" pathClassName="stroke-error-500" />
        </div>
      </div>
      <div className="dark:text-darkGray-50 text-lg font-semibold text-gray-900">
        {title}
      </div>
      <div className="dark:text-darkGray-400 mt-2 text-sm text-gray-600">
        {children}
      </div>
      <div className="mt-5 flex items-center justify-center">
        <Button intent="primary" className="w-1/2" onClick={cancelHandler}>
          Ok
        </Button>
      </div>
    </ModalWrapper>
  );
}

export function SuccessModal({
  children,
  onClose,
  title,
}: {
  children: ReactNode;
  onClose?: () => void;
  title: string;
}) {
  const { hide } = useContext(ModalContext);
  const cancelHandler = onClose || hide;

  return (
    <ModalWrapper onCancel={cancelHandler}>
      <div className="bg-success-50 flex h-11 w-11 items-center  justify-center self-center rounded-full">
        <div className="bg-success-100 flex h-8 w-8 items-center justify-center self-center rounded-full">
          <CheckCircleIcon
            className="h-5 w-5"
            pathClassName="stroke-success-500"
          />
        </div>
      </div>
      <div className="dark:text-darkGray-50 text-lg font-semibold text-gray-900">
        {title}
      </div>
      <div className="dark:text-darkGray-400 mt-2 text-sm text-gray-600">
        {children}
      </div>
      <div className="mt-5 flex items-center justify-center">
        <Button intent="primary" className="w-1/2" onClick={cancelHandler}>
          Ok
        </Button>
      </div>
    </ModalWrapper>
  );
}
