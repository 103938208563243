import classNames from "classnames";
import { Field, useFormikContext } from "formik";

import { TextAreaInputClassNames } from "./TextAreaInput";

interface FormikTextInputProps
  extends Omit<React.HTMLProps<HTMLTextAreaElement>, "type"> {
  innerRef?: (instance: any) => void;
}

export function FormikTextAreaInput({
  className,
  ...props
}: FormikTextInputProps) {
  const { touched, errors }: any = useFormikContext();
  const fieldName = props.name as string;
  const hasErrors = touched[fieldName] && errors[fieldName];
  return (
    <div className="w-full">
      <div className="flex w-full">
        <Field
          type="textarea"
          as="textarea"
          className={classNames(TextAreaInputClassNames, className)}
          {...props}
        />
      </div>
      <div className="flex w-full">
        {hasErrors && (
          <div className="text-error-600 ml-3.5 mt-2">{errors[fieldName]}</div>
        )}
      </div>
    </div>
  );
}
