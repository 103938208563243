import {
  useClubFeatureFlags,
  useRuleList,
  useRuleValues,
  useRuleValuesEdit,
} from "@gymflow/api";
import { currencies, pluralize } from "@gymflow/helpers";
import { WindowType } from "@gymflow/types";
import { useClubSettings } from "apps/portal/src/providers";
import { useEffect, useState } from "react";

import useGymflowModels from "../../../store";
import { Button, Spinner } from "../../atoms";
import { SettingsContainer } from "../SettingsContainer";
import {
  mapRuleClubDTOListToUIRuleState,
  RuleSchema,
  UIRuleState,
  UIRuleStateToMapRuleClubDTOList,
} from "./ruleHelper";
import { RuleSection } from "./RuleSection";

const generateNOptions = (n: number) =>
  new Array(n).fill(0).map((_, i) => ({
    value: i,
    label: `${i}`,
  }));

const pickerOptions = {
  windowType: [
    {
      label: "Days",
      value: "DAYS" as WindowType,
    },
    {
      label: "Months",
      value: "MONTHS" as WindowType,
    },
    {
      label: "Hours",
      value: "HOURS" as WindowType,
    },
  ],
  windowValue: {
    DAYS: generateNOptions(32),
    MONTHS: generateNOptions(13),
    HOURS: generateNOptions(25),
  },
  yesNo: [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ],
  subscriptionCancellation: generateNOptions(6).map((option) => {
    if (option.value === 0) {
      return {
        label: `No Notice Period`,
        value: 0,
      };
    }
    return {
      label: `${option.label} Billing Period`,
      value: option.value,
    };
  }),
};

export function RuleForm() {
  const { api } = useGymflowModels();

  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { data: featureFlags } = useClubFeatureFlags({ api, clubId });
  const {
    data: { content: ruleDefinitions },
    isFetching: isRuleDefinitionsFetching,
  } = useRuleList({
    api,
  });
  const { data: fetchedValues, isFetching: isRuleValuesFetching } =
    useRuleValues({ api, clubId });
  const updateValuesMutation = useRuleValuesEdit({ api });

  const [form, setForm] = useState<UIRuleState>();
  const handleSubmit = async () => {
    if (form) {
      return updateValuesMutation.mutateAsync({
        clubId,
        patchedFields: UIRuleStateToMapRuleClubDTOList(form, ruleDefinitions),
      });
    }
  };
  useEffect(() => {
    if (!isRuleValuesFetching && ruleDefinitions.length > 0) {
      setForm(mapRuleClubDTOListToUIRuleState(fetchedValues!, ruleDefinitions));
    }
  }, [fetchedValues, isRuleValuesFetching, ruleDefinitions]);
  const errors = RuleSchema.safeParse(form);
  return (
    <SettingsContainer title="Club Rules" subTitle="Set rules for your club.">
      <div className="relative flex h-full max-h-full w-full flex-col rounded-xl border border-gray-200 bg-white p-6 shadow-sm">
        <div className="absolute inset-6 flex flex-col">
          <div className="flex h-full max-h-full flex-col overflow-y-auto">
            {!form && <Spinner className="!h-16 !w-16" />}
            {form && (
              <>
                <RuleSection
                  title="Advanced Bookings"
                  subtitle="Set how far in advance a customer can book your classes."
                  rules={[
                    {
                      description: "How far in advance do you accept bookings:",
                      fields: [
                        {
                          value: pickerOptions.windowValue[
                            form.advancedBookings.windowType
                          ].find(
                            (e) =>
                              e.value === form.advancedBookings.windowValue,
                          ),
                          options:
                            pickerOptions.windowValue[
                              form.advancedBookings.windowType
                            ],
                          onChange: (newValue) => {
                            setForm((oldForm) => {
                              if (oldForm === undefined) return undefined;
                              let newForm = { ...oldForm };
                              newForm.advancedBookings.windowValue = newValue;
                              return newForm;
                            });
                          },
                        },
                        {
                          value: pickerOptions.windowType.find(
                            (e) => e.value === form.advancedBookings.windowType,
                          ),
                          options: pickerOptions.windowType,
                          onChange: (newValue) => {
                            setForm((oldForm) => {
                              if (oldForm === undefined) return undefined;
                              let newForm = { ...oldForm };
                              newForm.advancedBookings.windowType = newValue;
                              return newForm;
                            });
                          },
                        },
                      ],
                    },
                  ]}
                />
                <RuleSection
                  title="Cancellations"
                  subtitle="Set how close to the class starting cancellations can be made. Cancellations outside this window are consider late cancellation and any credits used to book the class are not return and a fee can be automatically charged."
                  rules={[
                    {
                      description:
                        "How close to the class start time are regular cancellations allowed:",
                      fields: [
                        {
                          value: pickerOptions.windowValue[
                            form.cancellations.windowRule.windowType
                          ].find(
                            (e) =>
                              e.value ===
                              form.cancellations.windowRule.windowValue,
                          ),
                          options:
                            pickerOptions.windowValue[
                              form.cancellations.windowRule.windowType
                            ],
                          onChange: (newValue) => {
                            setForm((oldForm) => {
                              if (oldForm === undefined) return undefined;
                              let newForm = { ...oldForm };
                              newForm.cancellations.windowRule.windowValue =
                                newValue;
                              return newForm;
                            });
                          },
                        },
                        {
                          value: pickerOptions.windowType.find(
                            (e) =>
                              e.value ===
                              form.cancellations.windowRule.windowType,
                          ),
                          options: pickerOptions.windowType,
                          onChange: (newValue) => {
                            setForm((oldForm) => {
                              if (oldForm === undefined) return undefined;
                              let newForm = { ...oldForm };
                              newForm.cancellations.windowRule.windowType =
                                newValue;
                              return newForm;
                            });
                          },
                        },
                      ],
                    },
                    {
                      description: `Would you like to charge a late cancellation fee(${
                        currencies[
                          settings.defaultCurrency as keyof typeof currencies
                        ].symbol
                      }):`,
                      fields: [
                        {
                          value: pickerOptions.yesNo.find(
                            (e) => e.value === form.cancellations.fee.enabled,
                          ),
                          options: pickerOptions.yesNo,
                          onChange: (newValue) => {
                            setForm((oldForm) => {
                              if (oldForm === undefined) return undefined;
                              let newForm = { ...oldForm };
                              newForm.cancellations.fee.enabled = newValue;
                              return newForm;
                            });
                          },
                        },
                        ...(form.cancellations.fee.enabled
                          ? [
                              {
                                error: !errors.success
                                  ? errors.error.errors.find(
                                      (e) =>
                                        e.path[0] === "cancellations" &&
                                        e.path[1] === "fee" &&
                                        e.path[2] === "value",
                                    )?.message
                                  : undefined,
                                value: form.cancellations.fee.value,
                                onChange: (newValue: any) => {
                                  setForm((oldForm) => {
                                    if (oldForm === undefined) return undefined;
                                    let newForm = { ...oldForm };
                                    newForm.cancellations.fee.value = newValue;
                                    return newForm;
                                  });
                                },
                              },
                            ]
                          : []),
                      ],
                    },
                  ]}
                />
                <RuleSection
                  title="No Shows"
                  subtitle="Set a fee that can be collected in the event a customer books but does not show up for a class. No show fees are not automatically collected, they can be applied when class attendance is taken or anytime after a class is finished."
                  rules={[
                    {
                      description: `Would you like to charge a no show fee(${
                        currencies[
                          settings.defaultCurrency as keyof typeof currencies
                        ].symbol
                      }):`,
                      fields: [
                        {
                          value: pickerOptions.yesNo.find(
                            (e) => e.value === form.noShows.enabled,
                          ),
                          options: pickerOptions.yesNo,
                          onChange: (newValue) => {
                            setForm((oldForm) => {
                              if (oldForm === undefined) return undefined;
                              let newForm = { ...oldForm };
                              newForm.noShows.enabled = newValue;
                              return newForm;
                            });
                          },
                        },
                        ...(form.noShows.enabled
                          ? [
                              {
                                value: form.noShows.value,
                                onChange: (newValue: any) => {
                                  setForm((oldForm) => {
                                    if (oldForm === undefined) return undefined;
                                    let newForm = { ...oldForm };
                                    newForm.noShows.value = newValue;
                                    return newForm;
                                  });
                                },
                              },
                            ]
                          : []),
                      ],
                    },
                  ]}
                />
                <RuleSection
                  title="Automatic Checkout"
                  subtitle="Set a time period to automatically check out users who have checked into the club after a certain period of time"
                  rules={[
                    {
                      description: "Check users out after:",
                      fields: [
                        {
                          value: pickerOptions.windowValue["HOURS"]
                            .slice(1)
                            .map((e, i) => ({
                              ...e,
                              label: `${e.label} ${pluralize(
                                "hour",
                                "hours",
                                i + 1,
                              )}`,
                            }))
                            .find(
                              (e) =>
                                e.value === form.automaticCheckout.windowValue,
                            ),
                          options: pickerOptions.windowValue["HOURS"]
                            .slice(1)
                            .map((e, i) => ({
                              ...e,
                              label: `${e.label} ${pluralize(
                                "hour",
                                "hours",
                                i + 1,
                              )}`,
                            })),
                          onChange: (newValue: number) => {
                            setForm((oldForm) => {
                              if (oldForm === undefined) return undefined;
                              let newForm = { ...oldForm };
                              newForm.automaticCheckout.windowValue = newValue;
                              return newForm;
                            });
                          },
                        },
                      ],
                    },
                  ]}
                />

                {featureFlags?.featureFlags.MOBILE_MEMBERSHIP_REDESIGN && (
                  <RuleSection
                    title="Self Service Membership Cancellation"
                    subtitle="Allow members to process their own membership cancellations in the Members App."
                    rules={[
                      {
                        description: "Allow Cancellations",
                        fields: [
                          {
                            value: pickerOptions.yesNo.find(
                              (e) =>
                                e.value ===
                                form.subscriptionCancellation.enabled,
                            ),
                            options: pickerOptions.yesNo,
                            onChange: (newValue) => {
                              setForm((oldForm) => {
                                if (oldForm === undefined) return undefined;
                                let newForm = { ...oldForm };
                                newForm.subscriptionCancellation.enabled =
                                  newValue;
                                return newForm;
                              });
                            },
                          },
                        ],
                      },
                      ...(form.subscriptionCancellation.enabled
                        ? [
                            {
                              description: `What notice period do you require:`,
                              fields: [
                                ...(form.subscriptionCancellation.enabled
                                  ? [
                                      {
                                        value:
                                          pickerOptions.subscriptionCancellation.find(
                                            (e) =>
                                              e.value ===
                                              form.subscriptionCancellation
                                                .value,
                                          ),
                                        options:
                                          pickerOptions.subscriptionCancellation,
                                        onChange: (newValue: number) => {
                                          setForm((oldForm) => {
                                            if (oldForm === undefined)
                                              return undefined;
                                            let newForm = { ...oldForm };
                                            newForm.subscriptionCancellation.value =
                                              newValue;
                                            return newForm;
                                          });
                                        },
                                      },
                                    ]
                                  : []),
                              ],
                            },
                          ]
                        : []),
                    ]}
                  />
                )}
              </>
            )}
          </div>
          <div className="flex flex-row justify-end">
            <Button
              intent="primary"
              disabled={
                !errors.success ||
                !form ||
                isRuleDefinitionsFetching ||
                isRuleValuesFetching
              }
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </SettingsContainer>
  );
}
