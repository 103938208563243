import { activityCategoryListQueryFn } from "@gymflow/api";
import { ReactNode, useContext } from "react";

import CogIcon from "../../../assets/img/cog.svg";
import { ModalContext } from "../../providers";
import useGymflowModels from "../../store";
import { ActivityCategoryModal } from "../Settings/Activity/ActivityCategoryModal";
import { PaginatedSelect } from ".";

export interface ActivityCategorySelectOption {
  label: string;
  value: number;
}

interface ActivityCategorySelectProps {
  value: ActivityCategorySelectOption[];
  onChange: (
    newValue: (
      | ActivityCategorySelectOption
      | { value: "create"; label: ReactNode }
    )[],
  ) => void;
  allowMultipleSelection?: boolean;
  placeholder?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  showManageOption?: boolean;
}

export function ActivityCategorySelect({
  value,
  onChange,
  allowMultipleSelection,
  placeholder,
  isClearable,
  isSearchable,
  showManageOption,
}: ActivityCategorySelectProps) {
  const { api } = useGymflowModels();
  const { stackModal, popModal } = useContext(ModalContext);
  return (
    <PaginatedSelect
      isMulti={allowMultipleSelection}
      isSearchable={isSearchable}
      placeholder={placeholder}
      loadOptions={async (term, _, { page }) => {
        const categoryList = await activityCategoryListQueryFn({
          api,
          filter: {
            page: page,
            limit: 100,
            status: ["ACTIVE"],
          },
        });
        const options: { label: ReactNode; value: number | "create" }[] =
          categoryList.content
            .map((category) => ({
              label: category.name,
              value: category.id,
            }))
            .filter((category) =>
              category.label.toLowerCase().includes(term.toLowerCase()),
            );
        if (showManageOption) {
          options.push({
            value: "create",
            label: (
              <div className="hover:!bg-primary-50 !-m-2 flex !cursor-pointer flex-row items-center justify-center gap-x-2 border-t border-gray-300 !bg-white p-2 !text-gray-800">
                <img alt="" src={CogIcon} />
                Create new category
              </div>
            ),
          });
        }
        return {
          options,
        };
      }}
      value={value}
      onChange={(newValue) => {
        if (
          showManageOption &&
          ((!allowMultipleSelection && newValue?.value === "create") ||
            (allowMultipleSelection &&
              newValue?.some((v: any) => v.value === "create")))
        ) {
          stackModal(<ActivityCategoryModal onCancel={() => popModal()} />);
        } else {
          onChange(allowMultipleSelection ? newValue : [newValue]);
        }
      }}
      isClearable={isClearable}
      refetchOnMenuOpen={showManageOption}
    />
  );
}
