import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useMutationMemberUpdateEmailAndPassword({
  api,
}: {
  api: {
    memberApi: {
      updateEmailAndPassword: (
        memberId: string,
        payload: { email?: string; password?: string },
      ) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      memberId,
      payload,
    }: {
      memberId: string;
      payload: { email?: string; password?: string };
    }) => {
      await api.memberApi.updateEmailAndPassword(memberId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
    },
  });
}
