import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

import { memberQueryKeys } from './memberQueryKeys';

export function useMemberDelete(
  { api }: {
    api: {
      memberApi:
      {
        accountDelete: (memberId: string, clubId: number) => Promise<any>,
      }
    }
  },
  mutationOpts?: UseMutationOptions<unknown, unknown, { memberId: string, clubId: number }>
) {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (
      { memberId, clubId }: { memberId: string, clubId: number }
    ) => api.memberApi.accountDelete(memberId, clubId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() })
    },
    ...mutationOpts
  });
  return mutation;
}
