import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import { Tooltip } from "react-tooltip";

import { Button } from "../../atoms";

export interface AddonItemProps {
  name: string;
  formattedPrice?: string;
  onClick?: () => void;
  canBeEdited: boolean;
}

export const AddonItem: React.FC<AddonItemProps> = ({
  name,
  formattedPrice,
  onClick,
  canBeEdited,
}) => {
  return (
    <div className="flex flex-row items-center justify-end text-sm font-medium">
      <div className="flex w-full">{name}</div>
      <div className="flex w-20">{formattedPrice ?? ""}</div>
      {
        <Button
          className={classNames(
            "btn-link text-primary-700 !mt-0 flex w-20 items-center justify-center focus:!outline-0",
            {
              "cursor-pointer": canBeEdited,
              "hover:text-primary-700/40 text-primary-700/40 !cursor-default":
                !canBeEdited,
            },
          )}
          onClick={canBeEdited ? onClick : undefined}
        >
          <div className="flex h-full flex-row items-center gap-x-2">
            Remove
            {!canBeEdited && (
              <div className="text-primary-700 flex h-full items-center justify-center">
                <FontAwesomeIcon
                  data-tooltip-id="can-edit-after-sale"
                  data-tooltip-content="Addons can't be edited after the membership has been sold"
                  className="cursor-pointer"
                  icon={faQuestionCircle}
                />
                <Tooltip
                  className="!bg-primary-700 z-10 flex max-w-xs flex-col items-center rounded-lg text-center text-xs"
                  id="can-edit-after-sale"
                />
              </div>
            )}
          </div>
        </Button>
      }
    </div>
  );
};
