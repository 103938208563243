import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

import {
  ActivitySelect,
  Button,
  PrimaryButton,
  SlideSideBar,
  StaffSelect,
} from "../atoms";

export interface ClassesRunFilters {
  eventHost?: { id: string; name: string }[];
  activity?: { id: number; name: string }[];
}

export interface ClassesRunFiltersSideBarProps {
  isVisible: boolean;
  onClose: () => void;
  value: ClassesRunFilters;
  onChange: (newFilters: ClassesRunFilters) => void;
}

export const ClassesRunFilterSidebar: React.FC<
  ClassesRunFiltersSideBarProps
> = ({ value, onChange, isVisible, onClose }) => {
  const [newFilters, setNewFilters] = useState<ClassesRunFilters>(value);

  useEffect(() => {
    setNewFilters(value);
  }, [value]);

  return (
    <SlideSideBar isOpen={isVisible} hide={onClose}>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="flex-1 overflow-y-auto">
          <div className="flex flex-col justify-between border-b border-gray-200 px-6 py-4">
            <div className="mb-1 flex flex-row items-center justify-between">
              <div className="text-xl font-semibold text-gray-900">Filters</div>
              <FontAwesomeIcon
                onClick={() => {
                  onClose();
                }}
                className="cursor-pointer text-xl text-gray-600"
                icon={faClose}
              />
            </div>
            <div className="text-sm font-medium text-gray-600">
              Apply filters to table data
            </div>
          </div>

          <div className="flex flex-col px-6">
            <div className="mb-2 mt-6 flex text-sm font-medium text-gray-700">
              Trainer
            </div>
            <div>
              <StaffSelect
                placeholder={newFilters?.eventHost?.length ? "Trainer" : "All"}
                onChange={(newValue) => {
                  setNewFilters((previous) => ({
                    ...previous,
                    eventHost: newValue
                      ? newValue.map((h) => ({ name: h.label, id: h.value }))
                      : undefined,
                  }));
                }}
                value={
                  newFilters?.eventHost
                    ? newFilters.eventHost.map((h) => ({
                        label: h.name,
                        value: h.id,
                      }))
                    : []
                }
                allowMultipleSelection
              />
            </div>
            <div className="mb-2 mt-6 flex text-sm font-medium text-gray-700">
              Class Name
            </div>
            <div>
              <ActivitySelect
                placeholder={
                  newFilters?.activity?.length ? "Class Name" : "All"
                }
                onChange={(newValue) => {
                  setNewFilters((previous) => ({
                    ...previous,
                    activity: newValue
                      ? newValue.map((activity) => ({
                          name: activity.label,
                          id: activity.value,
                        }))
                      : undefined,
                  }));
                }}
                value={
                  newFilters?.activity
                    ? newFilters.activity.map((activity) => ({
                        label: activity.name,
                        value: activity.id,
                      }))
                    : []
                }
                allowMultipleSelection
              />
            </div>
          </div>
        </div>
        <div className="flex h-20 flex-row items-center justify-end border-t border-gray-200 px-6">
          <Button onClick={() => onClose()} className="mr-2 w-full">
            Cancel
          </Button>
          <PrimaryButton
            className="w-full"
            onClick={() => {
              if (newFilters) {
                onChange(newFilters);
              }
              onClose();
            }}
          >
            Apply
          </PrimaryButton>
        </div>
      </div>
    </SlideSideBar>
  );
};
