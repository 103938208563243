import { useMemberInvoiceAsMember } from "@gymflow/api";
import {
  AlertContext,
  AsyncButton,
  GuestStatus,
  InvoiceStatus,
  NotificationContext,
  PaymentConfirmationStatus,
  useParseErrors,
  usePaymentAuthorizationAlert,
} from "@gymflow/common";
import { EventRsvpStatus, InvoiceDTO } from "@gymflow/types";
import classNames from "classnames";
import { useContext } from "react";
import Alert from "react-bootstrap-sweetalert";

import useMemberRules from "../hooks/useMemberRules";
import { ModalContext, useClubSettings } from "../providers";
import useGymflowModels from "../store/index.js";
import { ConfirmModal } from "./templates";

function RsvpButton({
  eventCapacity,
  onClick,
  startDate,
  bookedCount,
  waitingCount,
  waitListCapacity,
  rsvpStatus,
}: {
  onClick: ({
    status,
  }: {
    status: EventRsvpStatus;
  }) => Promise<{ data: { invoiceNumber?: string } }>;
  eventCapacity: number;
  waitListCapacity: number;
  bookedCount: number;
  waitingCount: number;
  startDate: string;
  rsvpStatus?: string;
}) {
  const { setAlert, hide } = useContext(AlertContext);
  let text;
  let className;
  let newStatus: EventRsvpStatus;
  const { setModal, hide: hideModal } = useContext(ModalContext);
  const alert = useContext(AlertContext);
  const { show: showPaymentAuthorizationAlert } =
    usePaymentAuthorizationAlert();

  const { validateMemberBooking, validateMemberCancel } = useMemberRules();
  const isFull = bookedCount >= eventCapacity;
  let title = "";
  let onClickButton = (e: MouseEvent) => {
    e.stopPropagation();
    const onClickArguments = {
      status: newStatus,
    };
    onClick(onClickArguments);
  };

  const settings = useClubSettings();
  const { api } = useGymflowModels();
  const { payInvoiceMutation } = useMemberInvoiceAsMember({ api });
  const toast = useContext(NotificationContext);
  const parseError = useParseErrors();
  if (
    !rsvpStatus ||
    rsvpStatus === GuestStatus.Cancelled ||
    rsvpStatus === GuestStatus.LateCanceled
  ) {
    const bookingError = validateMemberBooking(startDate);
    if (bookingError) {
      onClickButton = (e: MouseEvent) => {
        e.stopPropagation();
        setAlert(
          //@ts-ignore
          <Alert
            title="Cannot book"
            type="danger"
            closeOnClickOutside={false}
            style={{
              width: "39em",
            }}
            onConfirm={hide}
          >
            {bookingError}
          </Alert>,
        );
      };
    }

    if (!isFull) {
      className = "btn-primary";
      text = "Book Now";
      newStatus = GuestStatus.Booked;
    } else if (waitingCount < waitListCapacity) {
      className = "btn-warning";
      text = "Join Waitlist";
      newStatus = GuestStatus.Booked;
    } else {
      className = "btn-danger";
      text = "Event Full";
    }
  } else {
    text = "Cancel";
    if (rsvpStatus === GuestStatus.Waiting) {
      className = "btn-warning";
      text = "Cancel Waitlist";
    }
    const cancelError =
      rsvpStatus === GuestStatus.Booked
        ? validateMemberCancel(startDate)
        : null;
    if (cancelError !== null) {
      onClickButton = (e) => {
        e.stopPropagation();
        setModal(
          <ConfirmModal
            onCancel={() => {
              hideModal();
            }}
            onConfirm={async () => {
              const { invoiceNumber } = (
                await onClick({
                  status: GuestStatus.LateCanceled,
                })
              ).data;
              if (!invoiceNumber) {
                hideModal();
                return;
              }
              const invoice = (
                await api.profileApi.findInvoices(undefined, {
                  page: 0,
                  limit: 1,
                  extraParams: {
                    number: invoiceNumber,
                  },
                })
              ).data.content[0] as InvoiceDTO;
              try {
                const paymentConfirmationResult = await (async () => {
                  const response = await payInvoiceMutation.mutateAsync({
                    clubId: settings.clubId,
                    invoiceNumber,
                    amount: invoice.totalAmount,
                  });

                  if (
                    response.data?.status === InvoiceStatus.PaymentAuthorization
                  ) {
                    return showPaymentAuthorizationAlert({
                      paymentIntentIdForAuthorization:
                        response.data.paymentIntentIdForAuthorization,
                      confirmPayment:
                        api.strongCustomerAuthorizationApi.confirmPayment,
                      messageText: (
                        <>
                          The user must authorize this payment before it will be
                          processed.
                          <br />
                          Please ask the user to authorize the payment by
                          clicking the link sent to their email.
                        </>
                      ),
                      hideCancel: true,
                    });
                  }
                })();
                hideModal();

                if (
                  !paymentConfirmationResult ||
                  paymentConfirmationResult.status ===
                    PaymentConfirmationStatus.Success
                ) {
                  toast.notify({
                    message: "Invoice was paid successfully.",
                  });
                } else if (
                  paymentConfirmationResult.status ===
                  PaymentConfirmationStatus.Waiting
                ) {
                  toast.notify({
                    message: "Awaiting payment, check later.",
                    type: "warning",
                  });
                } else if (
                  paymentConfirmationResult.status ===
                  PaymentConfirmationStatus.Failed
                ) {
                  toast.notify({
                    message: "Payment Failed.",
                    type: "danger",
                  });
                }
              } catch (e: any) {
                alert.hide();
                if (e.response) {
                  await parseError(e.response);
                }
              }
            }}
            confirmText="Cancel Booking"
            type="default"
            title="Late Cancellation Fee"
          >
            {cancelError}
          </ConfirmModal>,
        );
      };
    } else {
      newStatus = GuestStatus.Cancelled;
    }
  }

  return (
    <AsyncButton
      className={classNames(className, "btn-link p-0")}
      size="sm"
      onClick={onClickButton}
      style={{ overflowWrap: "normal" }}
      title={title}
    >
      {text}
    </AsyncButton>
  );
}

export default RsvpButton;
