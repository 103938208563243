import { clubStaleTime, useClub, useMemberPaymentMethod } from "@gymflow/api";
import {
  formikHelpers,
  NotificationContext,
  StripeCard,
} from "@gymflow/common";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import classNames from "classnames";
import { Formik, useFormikContext } from "formik";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { getDefaultsFromZodSchema } from "../../helpers/zod";
import { ModalWrapper, useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { Button, FormikTextInput, PrimaryButton } from "../atoms";

export const AddPaymentMethodModal = ({
  cardHolderName,
  memberId,
  onClose,
  onConfirm,
}: {
  cardHolderName: string;
  memberId: string;
  onClose: () => void;
  onConfirm: (newPaymentMethodId?: string) => Promise<void>;
}) => {
  const cardRef = useRef();
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { data: club } = useClub({ api, clubId }, { staleTime: clubStaleTime });

  const { attachPaymentMethodMutation } = useMemberPaymentMethod({ api });
  const { notifyDanger, notify } = useContext(NotificationContext);

  const [stripePromise] = useState(
    loadStripe(settings.stripe_api_key, {
      stripeAccount: club?.stripeApplicationId,
    }),
  );
  const validationSchema = createValidationSchema(cardHolderName);

  return (
    <ModalWrapper onCancel={onClose}>
      <div className="mb-5 text-lg font-semibold text-gray-900">
        Add Payment Method
      </div>
      <Elements stripe={stripePromise}>
        <Formik
          enableReinitialize
          initialValues={
            getDefaultsFromZodSchema(validationSchema) as PaymentMethodFormType
          }
          validationOnBlur
          validationSchema={toFormikValidationSchema(validationSchema)}
          onSubmit={async () => {
            //@ts-expect-error
            const values = await cardRef.current!.getValues();
            if (values.isValid) {
              try {
                await attachPaymentMethodMutation.mutateAsync({
                  paymentMethodId: values.paymentMethod.id,
                  memberId,
                  clubId,
                });
                await onConfirm(values.paymentMethod.id);
                notify({ message: "Payment method added." });
              } catch (e) {
                await onConfirm();
                notifyDanger(e);
              }
            }
          }}
        >
          <AddPaymentMethodForm
            ref={cardRef}
            cardHolderName={cardHolderName}
            onCancel={onClose}
          />
        </Formik>
      </Elements>
    </ModalWrapper>
  );
};

const AddPaymentMethodForm = forwardRef(
  (
    {
      cardHolderName,
      onCancel,
    }: { cardHolderName: string; onCancel: () => void },
    ref: any,
  ) => {
    const stripe = useStripe();
    const elements = useElements();

    const formikProps = useFormikContext<PaymentMethodFormType>();
    const { errorClass } = formikHelpers(formikProps);
    const { values, isValid, setFieldValue, touched, validateForm } =
      formikProps;

    const [cardError, setCardError] = useState("");

    useEffect(() => {
      validateForm();
    }, []);

    useImperativeHandle(
      ref,
      () => {
        return {
          getValues: async () => {
            if (!isValid) {
              return { isValid, name: values.name };
            }
            const stripeCard = await ref.current.stripe.createPaymentMethod({
              type: "card",
              card: elements!.getElement(CardElement),
              // eslint-disable-next-line camelcase
              billing_details: { name: values.name },
            });

            return {
              isValid,
              name: values.name,
              paymentMethod: stripeCard.paymentMethod,
            };
          },
          stripe,
        };
      },
      [stripe, isValid],
    );

    return (
      <div className="flex flex-col gap-2">
        <div>
          <div className={errorClass("name")}>
            <FormikTextInput
              placeholder="Card Holder's Name"
              autoComplete="off"
              maxLength={128}
              name="name"
            />
          </div>
        </div>
        <div>
          <div className={classNames({ "has-danger": cardError })}>
            <StripeCard
              onChange={(card) => {
                if (card.error) {
                  setFieldValue("isCardValid", false);
                  if (card.error) {
                    setCardError(card.error.message);
                  }
                } else {
                  setFieldValue("isCardValid", true);
                  setCardError("");
                }
              }}
            />
            {
              <label
                style={{
                  visibility:
                    touched["isCardValid"] && cardError ? "hidden" : "visible",
                }}
                className="text-error-600"
              >
                {cardError}
              </label>
            }
          </div>
        </div>

        <div className="flex gap-4">
          <Button className="flex-1" onClick={onCancel}>
            Cancel
          </Button>

          <PrimaryButton
            className="flex-1"
            onClick={async () => {
              await formikProps.submitForm();
            }}
          >
            Save
          </PrimaryButton>
        </div>
      </div>
    );
  },
);

AddPaymentMethodForm.displayName = "AddPaymentMethodForm";

function createValidationSchema(cardHolderName: string) {
  return z.object({
    name: z
      .string()
      .min(3, "Must be at least 3 characters")
      .default(cardHolderName),
    isCardValid: z.literal<boolean>(true).default(false),
  });
}

type PaymentMethodFormType = z.infer<ReturnType<typeof createValidationSchema>>;
