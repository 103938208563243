import { zonedTimeToUtc } from "@gymflow/helpers";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { membershipQueryKeys } from "../membership";
import { memberQueryKeys } from "./memberQueryKeys";

export function useMemberSubscription(
  {
    api,
    tz,
  }: {
    api: {
      memberApi: {
        resumeSubscriptionEarly: (
          memberId: string,
          subscriptionId: number,
          resumeDate: string,
          isWaived: boolean,
          paymentMethodId?: string,
        ) => Promise<any>;
        expireSubscription: (
          memberId: string,
          subscriptionId: number,
          expiredDate?: string,
        ) => Promise<any>;
        changeSubscriptionPrice: (
          clubId: number,
          memberId: string,
          subscriptionId: number,
          newSubscriptionPrice: number,
        ) => Promise<any>;
        pauseSubscription: (
          memberId: string,
          subscriptionId: number,
          immediately: boolean,
          pauseDate: string | undefined,
          resumeDate: string,
        ) => Promise<any>;
        cancelPauseSubscription: (
          memberId: string,
          subscriptionId: number,
        ) => Promise<any>;
        cancelSubscription: (
          memberId: string,
          subscriptionId: number,
          immediately: boolean,
          when?: string,
        ) => Promise<any>;
        updatePendingDate: (
          memberId: string,
          subscriptionId: number,
          immediately: boolean,
          when?: string,
        ) => Promise<any>;
        revokeCancellation: (
          memberId: string,
          subscriptionId: number,
        ) => Promise<any>;
        adjustSessionPack: (
          memberId: string,
          subscriptionId: number,
          newAmount: number,
        ) => Promise<any>;
        changeSubscription: (
          clubId: number,
          memberId: string,
          newMembershipId: number,
          paymentMethod?: string,
          isWaived?: boolean,
        ) => Promise<any>;
        changeBillingDate: (
          clubId: number,
          subscriptionId: number,
          newDate: string,
        ) => Promise<any>;
        changeContractEndDate: (
          subscriptionId: number,
          newDate: string,
        ) => Promise<any>;
      };
    };
    tz: string;
  },
  mutationOpts?: UseMutationOptions<unknown, unknown, unknown, unknown>,
) {
  const queryClient = useQueryClient();

  const resumeSubscriptionEarlyMutation = useMutation({
    mutationFn: ({
      memberId,
      subscriptionId,
      resumeDate,
      isWaived,
      paymentMethodId,
    }: {
      memberId: string;
      subscriptionId: number;
      resumeDate: string;
      isWaived: boolean;
      paymentMethodId?: string;
    }) => {
      const resumeDateUtc = zonedTimeToUtc(resumeDate, tz);

      return api.memberApi.resumeSubscriptionEarly(
        memberId,
        subscriptionId,
        resumeDateUtc,
        isWaived,
        paymentMethodId,
      );
    },
    onSuccess: (_, { memberId }: { memberId: string }) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.timeline(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: membershipQueryKeys.statusHistory(memberId),
      });
    },
    ...mutationOpts,
  });

  const expireSubscriptionMutation = useMutation({
    mutationFn: ({
      memberId,
      subscriptionId,
      expiredDate,
    }: {
      memberId: string;
      subscriptionId: number;
      expiredDate?: string;
    }) => {
      let expiredDateUtc;
      if (expiredDate) {
        expiredDateUtc = zonedTimeToUtc(expiredDate, tz);
      }
      return api.memberApi.expireSubscription(
        memberId,
        subscriptionId,
        expiredDateUtc,
      );
    },
    onSuccess: (_, { memberId }: { memberId: string }) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.timeline(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: membershipQueryKeys.statusHistory(memberId),
      });
    },
    ...mutationOpts,
  });

  const changeSubscriptionPriceMutation = useMutation({
    mutationFn: ({
      clubId,
      memberId,
      subscriptionId,
      newSubscriptionPrice,
    }: {
      clubId: number;
      memberId: string;
      subscriptionId: number;
      newSubscriptionPrice: number;
    }) =>
      api.memberApi.changeSubscriptionPrice(
        clubId,
        memberId,
        subscriptionId,
        newSubscriptionPrice,
      ),
    onSuccess: (_, { memberId }: { memberId: string }) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.timeline(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: membershipQueryKeys.statusHistory(memberId),
      });
    },
    ...mutationOpts,
  });

  const pauseSubscriptionMutation = useMutation({
    mutationFn: ({
      memberId,
      subscriptionId,
      immediately,
      pauseDate,
      resumeDate,
    }: {
      memberId: string;
      subscriptionId: number;
      immediately: boolean;
      pauseDate?: string;
      resumeDate: string;
    }) => {
      let pauseDateUtc;
      if (!immediately) {
        pauseDateUtc = zonedTimeToUtc(pauseDate!, tz);
      }
      const resumeDateUtc = zonedTimeToUtc(resumeDate, tz);

      return api.memberApi.pauseSubscription(
        memberId,
        subscriptionId,
        immediately,
        pauseDateUtc,
        resumeDateUtc,
      );
    },
    onSuccess: (_, { memberId }: { memberId: string }) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.timeline(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: membershipQueryKeys.statusHistory(memberId),
      });
    },
    ...mutationOpts,
  });

  const cancelPauseSubscriptionMutation = useMutation({
    mutationFn: ({
      memberId,
      subscriptionId,
    }: {
      memberId: string;
      subscriptionId: number;
    }) => api.memberApi.cancelPauseSubscription(memberId, subscriptionId),
    onSuccess: (_, { memberId }: { memberId: string }) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.timeline(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: membershipQueryKeys.statusHistory(memberId),
      });
    },
    ...mutationOpts,
  });

  const cancelSubscriptionMutation = useMutation({
    mutationFn: ({
      memberId,
      subscriptionId,
      immediately,
      when,
    }: {
      memberId: string;
      subscriptionId: number;
      immediately: boolean;
      when?: string;
    }) => {
      const whenUtc = when && zonedTimeToUtc(when, tz);
      return api.memberApi.cancelSubscription(
        memberId,
        subscriptionId,
        immediately,
        whenUtc,
      );
    },
    onSuccess: (_, { memberId }: { memberId: string }) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.timeline(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: membershipQueryKeys.statusHistory(memberId),
      });
    },
    ...mutationOpts,
  });

  const updateSubscriptionPendingDateMutation = useMutation({
    mutationFn: ({
      memberId,
      subscriptionId,
      immediately,
      when,
    }: {
      memberId: string;
      subscriptionId: number;
      immediately: boolean;
      when?: string;
    }) => {
      const whenUtc = immediately ? undefined : zonedTimeToUtc(when!, tz);
      return api.memberApi.updatePendingDate(
        memberId,
        subscriptionId,
        immediately,
        whenUtc,
      );
    },
    onSuccess: (_, { memberId }: { memberId: string }) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.timeline(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: membershipQueryKeys.statusHistory(memberId),
      });
    },
    ...mutationOpts,
  });

  const revokeCancellationMutation = useMutation({
    mutationFn: ({
      memberId,
      subscriptionId,
    }: {
      memberId: string;
      subscriptionId: number;
    }) => api.memberApi.revokeCancellation(memberId, subscriptionId),
    onSuccess: (_, { memberId }: { memberId: string }) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.timeline(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: membershipQueryKeys.statusHistory(memberId),
      });
    },
    ...mutationOpts,
  });

  const adjustSessionPackMutation = useMutation({
    mutationFn: ({
      memberId,
      subscriptionId,
      newAmount,
    }: {
      memberId: string;
      subscriptionId: number;
      newAmount: number;
    }) => api.memberApi.adjustSessionPack(memberId, subscriptionId, newAmount),
    onSuccess: (_, { memberId }: { memberId: string }) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.timeline(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: membershipQueryKeys.statusHistory(memberId),
      });
    },
    ...mutationOpts,
  });

  const changeSubscriptionMutation = useMutation({
    mutationFn: ({
      clubId,
      memberId,
      newMembershipId,
      paymentMethodId,
      isWaived,
    }: {
      clubId: number;
      memberId: string;
      newMembershipId: number;
      paymentMethodId: string;
      isWaived?: boolean;
    }) =>
      api.memberApi.changeSubscription(
        clubId,
        memberId,
        newMembershipId,
        paymentMethodId,
        isWaived,
      ),
    onSuccess: (_, { memberId }: { memberId: string }) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.timeline(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: membershipQueryKeys.statusHistory(memberId),
      });
    },
    ...mutationOpts,
  });

  const changeBillingDateMutation = useMutation({
    mutationFn: ({
      clubId,
      subscriptionId,
      newDate,
    }: {
      clubId: number;
      subscriptionId: number;
      newDate: string;
    }) => api.memberApi.changeBillingDate(clubId, subscriptionId, newDate),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: membershipQueryKeys.all() });

      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
    },
    ...mutationOpts,
  });

  const changeContractEndDateMutation = useMutation({
    mutationFn: ({
      subscriptionId,
      newDate,
    }: {
      subscriptionId: number;
      newDate: string;
    }) => {
      return api.memberApi.changeContractEndDate(subscriptionId, newDate);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
    },
    ...mutationOpts,
  });
  return {
    resumeSubscriptionEarlyMutation,
    expireSubscriptionMutation,
    changeSubscriptionPriceMutation,
    pauseSubscriptionMutation,
    cancelPauseSubscriptionMutation,
    cancelSubscriptionMutation,
    updateSubscriptionPendingDateMutation,
    revokeCancellationMutation,
    adjustSessionPackMutation,
    changeSubscriptionMutation,
    changeBillingDateMutation,
    changeContractEndDateMutation,
  };
}
