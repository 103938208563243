import { useContext } from 'react';
import startCase from 'lodash/startCase';

import { AlertContext } from '../providers/Alert';
import { GenericValidatorParser } from '../helpers/GenericValidatorParser';

const useParseErrors = () => {
  const alert = useContext(AlertContext);
  const parseError = async (response) => {
    const notificationTitle = 'Error';
    switch (response.status) {
      case 400:
      case 409:
        if (response.data?.error_message) {
          await alert.showError(response.data.error_message, {
            title: notificationTitle,
          });
        } else {
          const parser = new GenericValidatorParser(response.data.errors);
          const fieldErrors = parser.getFieldErrors();
          const fieldErrorString = Object.keys(fieldErrors)
            .map((field) => `${startCase(field)}: ${fieldErrors[field]}`)
            .join('; ');
          const errorText = (parser.getGlobalError() ? parser.getGlobalError() + <br /> : '') + fieldErrorString;
          await alert.showError(errorText, {
            title: notificationTitle,
          });
        }
        break;
      default:
        await alert.showError('Something unexpected happened.', {
          title: notificationTitle,
        });
    }
  };

  return parseError;
};

export default useParseErrors;
