export const RouteLayout = {
  Authentication: "/authentication",
  Kiosk: "/kiosk",
  Staff: "/staff",
  Member: "/member",
  Site: "/site",
  Embed: "/embed",
  Link: "/link",
} as const;

export type RouteLayoutValue = (typeof RouteLayout)[keyof typeof RouteLayout];
