import PropTypes from "prop-types";
import { Button, Col, Row } from "reactstrap";

import { PaymentMethod } from "../../constants/PaymentMethod";

function PaymentMethodSwitch({ value, setValue, availableValues }) {
  return (
    <Row>
      {availableValues.includes(PaymentMethod.Card) && (
        <Col className="d-flex justify-content-center">
          <Button
            size="sm"
            color={value === PaymentMethod.Card ? "primary" : "secondary"}
            onClick={(e) => {
              e.preventDefault();
              setValue(PaymentMethod.Card);
            }}
          >
            Credit Card
          </Button>
        </Col>
      )}
      {availableValues.includes(PaymentMethod.BacsDirectDebit) && (
        <Col className="d-flex justify-content-center">
          <Button
            size="sm"
            color={value === PaymentMethod.BacsDirectDebit ? "primary" : "secondary"}
            onClick={(e) => {
              e.preventDefault();
              setValue(PaymentMethod.BacsDirectDebit);
            }}
          >
            Direct Debit
          </Button>
        </Col>
      )}
    </Row>
  );
}

PaymentMethodSwitch.propTypes = {
  value: PropTypes.oneOf(Object.values(PaymentMethod)),
  setValue: PropTypes.func.isRequired,
  availableValues: PropTypes.arrayOf(PropTypes.oneOf(Object.values(PaymentMethod))),
};

export default PaymentMethodSwitch;
