import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { BootstrapServerRepository, RouteResultPage } from "../../repositories";
import { bootstrapQueryKeys } from "./bootstrapQueryKeys";
import { defaultRoutePage } from "./defaultRoutePage";

export function useRoutesByBrand(
  {
    serverUrl,
    search,
    brand,
    company,
    clubIds,
    routeId,
    page,
    limit,
  }:
    {
      serverUrl: string,
      search?: string,
      brand: string,
      company?: string,
      clubIds?: number[],
      routeId?: string,
      page: number,
      limit: number,
    }, opts?: UseQueryOptions<RouteResultPage>) {
  const repository = useMemo(() => new BootstrapServerRepository(serverUrl), [serverUrl]);

  const result = useQuery({
    queryKey: bootstrapQueryKeys.listByBrand({
      serverUrl, search, brand, company, clubIds, routeId, page, limit
    }),
    queryFn: async () => {
      const result = await repository.getRoutesByBrand({
        search, brand, company, clubIds, routeId, page, limit
      });
      return result as RouteResultPage;
    },
    initialData: defaultRoutePage,
    ...opts,
  });
  return result;
}
