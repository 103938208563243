import classNames from 'classnames';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { formatCurrency } from '@gymflow/helpers';

function PaymentDetails({ basePrice, basePriceTitle, currency, lineItems, totalPayment, discount }) {
  const renderBasePrice = () => {
    if (!basePrice) {
      return null;
    }
    return (
      <Row>
        <Col>
          {basePriceTitle}: {formatCurrency(basePrice, currency)}{' '}
        </Col>
      </Row>
    );
  };

  const renderAddons = () => {
    if (!lineItems || lineItems.length === 0) {
      return null;
    }

    return (
      <>
        {lineItems.map(({ name, quantity, total }) => (
          <Row key={name}>
            <Col>
              {name}
              <span className={classNames('text-muted', { 'd-none': quantity === 1 })}> x{quantity}</span>
              {`: ${formatCurrency(total, currency)}`}
            </Col>
          </Row>
        ))}
      </>
    );
  };

  const renderDiscount = () => {
    if (discount === 0) {
      return;
    }

    return (
      <Row>
        <Col style={{ color: '#ec250d' }}>Discount: - {formatCurrency(discount, currency)} </Col>
      </Row>
    );
  };

  return (
    <>
      <Row className="mt-3">
        <Col className="text-uppercase font-weight-bold">Payment Summary</Col>
      </Row>
      {renderBasePrice()}
      {renderAddons()}
      {renderDiscount()}
      <Row className="mt-2">
        <Col className="text-uppercase font-weight-bold">
          Total Payable Today: {formatCurrency(totalPayment, currency)}
        </Col>
      </Row>
    </>
  );
};

PaymentDetails.defaultProps = {
  basePriceTitle: 'Price',
  discount: 0,
  lineItems: [],
  nextMonthlyPayment: null,
  proRataPayment: null,
};

PaymentDetails.propTypes = {
  currency: PropTypes.string.isRequired,
  basePriceTitle: PropTypes.string,
  basePrice: PropTypes.number,
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      quantity: PropTypes.number,
      total: PropTypes.number.isRequired,
    })
  ),
  totalPayment: PropTypes.number.isRequired,
  discount: PropTypes.number,
};

export default PaymentDetails;
