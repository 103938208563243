import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { leadSourceListQueryFn, leadStatusListQueryFn } from "@gymflow/api";
import { LUXON_DATE_FORMAT } from "@gymflow/helpers";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

import useGymflowModels from "../../../store";
import { Button, PaginatedSelect, SlideSideBar } from "../../atoms";
import { DatePicker } from "../../molecules";

type SelectOption<T extends number | string = number> = {
  label: string;
  value: T;
};

export type LeadFilters = {
  createdFrom?: string;
  createdTo?: string;
  leadStatus?: SelectOption[];
  leadSource?: SelectOption[];
};

export interface CalendarFilterSidebarProps {
  isVisible: boolean;
  onVisibilityChange: (newVisibility: boolean) => void;
  value: LeadFilters;
  onChange: (newFilters: LeadFilters) => void;
}

export const LeadPipelineFilterSidebar = ({
  value,
  onChange,
  isVisible,
  onVisibilityChange,
}: CalendarFilterSidebarProps) => {
  const [filters, setFilters] = useState(value);
  const hide = () => {
    onVisibilityChange(false);
  };

  useEffect(() => {
    setFilters(value);
  }, [value]);

  const { api } = useGymflowModels();

  return (
    <SlideSideBar isOpen={isVisible} hide={hide}>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="flex flex-col justify-between border-b border-gray-200 px-6 py-4">
          <div className="mb-1 flex flex-row items-center justify-between">
            <div className="text-xl font-semibold text-gray-900">Filters</div>
            <FontAwesomeIcon
              onClick={() => {
                hide();
              }}
              className="cursor-pointer text-xl text-gray-600"
              icon={faClose}
            />
          </div>
          <div className="text-sm font-medium text-gray-600">
            Apply one or more filters
          </div>
        </div>

        <div className="flex h-full flex-col gap-4 overflow-y-auto p-6">
          <div className="flex flex-col">
            <DatePicker
              label="Lead Created Date"
              mode="range"
              isClearable
              selected={dateStringRangeToDateRange(
                filters.createdFrom,
                filters.createdTo,
              )}
              handleDateSave={(newRange) => {
                if (!newRange) {
                  setFilters((previous) => ({
                    ...previous,
                    createdFrom: undefined,
                    createdTo: undefined,
                  }));
                  return;
                }
                if (!newRange.from) return;
                const createdFrom = DateTime.fromJSDate(
                  newRange.from ?? newRange.to,
                ).toFormat(LUXON_DATE_FORMAT);
                const createdTo = DateTime.fromJSDate(
                  newRange.to ?? newRange.from,
                ).toFormat(LUXON_DATE_FORMAT);
                setFilters((previous) => ({
                  ...previous,
                  createdFrom,
                  createdTo,
                }));
              }}
            />
          </div>
          <div>
            <PaginatedSelect
              placeholder="Lead Status"
              value={filters.leadStatus}
              isMulti
              onChange={(newValue) => {
                setFilters({ ...filters, leadStatus: newValue });
              }}
              loadOptions={async () => {
                const data = await leadStatusListQueryFn({
                  api,
                });

                return {
                  options: data.map((status) => ({
                    label: status.name,
                    value: status.id,
                  })),
                  hasMore: false,
                };
              }}
            />
          </div>
          <div>
            <PaginatedSelect
              placeholder="Lead Source"
              value={filters.leadSource}
              isMulti
              onChange={(newValue) => {
                setFilters({ ...filters, leadSource: newValue });
              }}
              loadOptions={async (_, __, { page }) => {
                const data = await leadSourceListQueryFn({
                  api,
                  opts: {
                    page,
                  },
                });

                return {
                  options: data.content.map((source) => ({
                    label: source.name,
                    value: source.id,
                  })),
                  hasMore: !data.last,
                  additional: {
                    page: page + 1,
                  },
                };
              }}
            />
          </div>
        </div>
        <div className="flex h-20 flex-row items-center justify-end border-t border-gray-200 px-6">
          <Button onClick={() => hide()} className="mr-2 w-full">
            Cancel
          </Button>
          <Button
            intent="primary"
            className="w-full"
            onClick={() => {
              onChange(filters);
              hide();
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </SlideSideBar>
  );
};

const dateStringRangeToDateRange = (from?: string, to?: string) => {
  if (!from) return;
  return {
    from: DateTime.fromFormat(from, LUXON_DATE_FORMAT)
      .setZone("local", { keepLocalTime: true })
      .toJSDate(),
    ...(to
      ? {
          to: DateTime.fromFormat(to, LUXON_DATE_FORMAT)
            .setZone("local", { keepLocalTime: true })
            .toJSDate(),
        }
      : {}),
  };
};
