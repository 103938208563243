import { useAbility } from "@casl/react";
import { useClubFeatureFlags, useStaff } from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import classNames from "classnames";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";

import searchIcon from "../../../assets/img/search-lg.svg";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { AbilityContext, Can, Subject, Verb } from "../../permissions";
import { AuthenticatedUserContext, useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { UserMemberPage } from "../../views/UserMember";
import { Button, MarkerPinIcon, NavigateToClubSelect } from "../atoms";
import { NewUserSideBarProviderContext } from "../molecules";
import { SearchGlobal } from "../SearchGlobal/SearchGlobal";
import { ShopSidebarContext } from "../Shop/ShopSidebarContext";
import addUser from "./../../../assets/img/add-user.svg";
import calendarIcon from "./../../../assets/img/calendar.svg";
import shopIcon from "./../../../assets/img/shop-icon.svg";
import { CalendarSideBar } from "./Notification/CalendarSideBar";

export interface TopNavbarContentsProps {
  navigationIconOnly?: boolean;
}

export const TopNavbarContents: React.FC<TopNavbarContentsProps> = ({
  navigationIconOnly,
}) => {
  const history = useHistory();
  const { createMemberLink, createLeadLink } = usePortalRoutes();
  const [areEventsOpened, setAreEventsOpened] = useState(false);
  const { open: openNewUserSideBar } = useContext(
    NewUserSideBarProviderContext,
  );
  const { showShop } = useContext(ShopSidebarContext);
  const [searchClubSelectorToggle, setSearchClubSelectorToggle] =
    useState(false);

  const { api } = useGymflowModels();
  const { id } = useContext(AuthenticatedUserContext);
  const { data: currentStaff } = useStaff({ api, staffId: id });
  const showPicker =
    currentStaff && currentStaff?.clubAccessibleList.length > 1;
  const { clubId } = useClubSettings();
  const { data: featureFlags } = useClubFeatureFlags({ api, clubId });

  const ability = useAbility(AbilityContext);
  return (
    <>
      {(!navigationIconOnly || !searchClubSelectorToggle) && (
        <div className="flex h-full w-full flex-row justify-between">
          <Can I={Verb.View} a={Subject.GlobalSearch}>
            <SearchGlobal />
          </Can>
        </div>
      )}
      {showPicker && (!navigationIconOnly || searchClubSelectorToggle) && (
        <div
          className={classNames("h-full lg:min-w-fit", {
            "w-full": searchClubSelectorToggle,
          })}
        >
          <NavigateToClubSelect />
        </div>
      )}

      <div className="flex h-full flex-row">
        {showPicker && navigationIconOnly && (
          <div
            className={classNames(
              "flex h-full w-14 cursor-pointer items-center justify-center border-l border-gray-300 hover:bg-gray-100",
            )}
            onClick={() => {
              setSearchClubSelectorToggle((e) => !e);
            }}
          >
            {!searchClubSelectorToggle ? (
              <MarkerPinIcon className="stroke-primary-700 h-6 w-6" />
            ) : (
              <img src={searchIcon} alt="Search Icon" />
            )}
          </div>
        )}
        <div
          className={classNames(
            "flex h-full w-14 cursor-pointer items-center justify-center border-l border-gray-300 hover:bg-gray-100",
          )}
          onClick={() => {
            setAreEventsOpened(true);
          }}
        >
          <img src={calendarIcon} alt="Calendar Icon" />
        </div>
        {featureFlags?.featureFlags.PORTAL_SHOP_REDESIGN && (
          <Can I={Verb.View} a={Subject.Sales}>
            <Button
              className={cn(
                "mt-0 flex h-full w-14 cursor-pointer items-center justify-center !rounded-none !border-l border-gray-300 !bg-white !ring-0 hover:!bg-gray-100 focus:outline-none",
              )}
              onClick={async () => {
                await showShop();
              }}
            >
              <img src={shopIcon} alt="Shop Icon" />
            </Button>
          </Can>
        )}
        <div
          className={classNames(
            "flex h-full w-14 cursor-pointer items-center justify-center border-l border-gray-300 hover:bg-gray-100",
            { hidden: !ability.can(Verb.View, Subject.Sales) },
          )}
          onClick={() => {
            openNewUserSideBar({
              onClose: async (arg) => {
                if (arg?.userType === "MEMBER") {
                  history.push(
                    createMemberLink(arg.userMemberId, UserMemberPage.Profile),
                  );
                } else if (arg?.userType === "LEAD") {
                  history.push(createLeadLink(arg.leadId));
                }
              },
            });
          }}
        >
          <img src={addUser} alt="Add User Icon" />
        </div>

        <CalendarSideBar
          isCalendarOpened={areEventsOpened}
          hide={() => setAreEventsOpened(false)}
        />
      </div>
    </>
  );
};
