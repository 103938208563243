import { MembershipBean } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useMutationMembershipLinkMember({
  api,
}: {
  api: {
    memberApi: {
      membershipLinkMember: (
        primarySubscriptionId: number,
        secondaryMemberId: string,
      ) => Promise<MembershipBean>;
    };
  };
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      primarySubscriptionId,
      secondaryMemberId,
    }: {
      primarySubscriptionId: number;
      secondaryMemberId: string;
    }) => {
      await api.memberApi.membershipLinkMember(
        primarySubscriptionId,
        secondaryMemberId,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(memberQueryKeys.all());
    },
  });
}
