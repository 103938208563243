import {
  formikHelpers,
  FormikInput,
  MobileNumberInput,
  renderErrorRowOnTouch,
  RequiredFieldsLabel,
  useRecordForm,
} from "@gymflow/common";
import classNames from "classnames";
import { useFormik } from "formik";
import { useMemo } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { AsyncPaginate } from "react-select-async-paginate";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import useLeadSourceLoadOptions from "../../hooks/useLeadSourceLoadOptions";
import { useClubSettings } from "../../providers";
import createLeadMapper from "./mapper";
import createLeadSchema, {
  EMAIL,
  EMAIL_COMMUNICATION,
  FIRST_NAME,
  LAST_NAME,
  PHONE_NUMBER,
  SMS_COMMUNICATION,
  SOURCE_ID,
} from "./schema";

function AddLeadAlert({ onSubmit, onCancel }) {
  const settings = useClubSettings();

  const mapper = useMemo(() => createLeadMapper(), []);
  const schema = useMemo(() => createLeadSchema(), []);
  const { initialValues, getValues } = useRecordForm({
    record: null,
    fields: schema.default(),
    mapper,
  });
  const leadSourceLoadOptions = useLeadSourceLoadOptions();

  const formikProps = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      onSubmit(getValues(values));
    },
  });
  const { setFieldValue, values, touched, errors } = formikProps;
  const { errorClass } = formikHelpers(formikProps);

  return (
    <ReactBSAlert
      title="Add Lead"
      showCancel
      closeOnClickOutside={false}
      onCancel={onCancel}
      onConfirm={formikProps.handleSubmit}
      confirmBtnText="Add"
    >
      <Form className="form-horizontal" role="form">
        <Row className="text-uppercase text-left">
          <Col>
            <Label tag="h3" htmlFor={FIRST_NAME}>
              First Name *
            </Label>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <FormGroup className={errorClass(FIRST_NAME)}>
              <FormikInput
                name={FIRST_NAME}
                type="text"
                data-testid={FIRST_NAME}
                autoComplete="off"
                maxLength="128"
                formikProps={formikProps}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="text-uppercase text-left">
          <Col>
            <Label tag="h3" htmlFor={LAST_NAME}>
              Last Name *
            </Label>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <FormGroup className={errorClass(LAST_NAME)}>
              <FormikInput
                name={LAST_NAME}
                type="text"
                data-testid={LAST_NAME}
                autoComplete="off"
                maxLength="128"
                formikProps={formikProps}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="text-uppercase text-left">
          <Col>
            <Label tag="h3" htmlFor={EMAIL}>
              Email *
            </Label>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <FormGroup className={errorClass(EMAIL)}>
              <FormikInput
                name={EMAIL}
                type="text"
                data-testid={EMAIL}
                autoComplete="off"
                maxLength="128"
                formikProps={formikProps}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="text-uppercase text-left">
          <Col>
            <Label tag="h3" htmlFor={PHONE_NUMBER}>
              Phone Number
            </Label>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <FormGroup className={errorClass(PHONE_NUMBER)}>
              <FormikInput
                name={PHONE_NUMBER}
                component={MobileNumberInput}
                type="text"
                maxLength="32"
                phone_number_country={settings.phone_number_country}
                formikProps={formikProps}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="text-uppercase text-left">
          <Col>
            <Label tag="h3" htmlFor={SOURCE_ID}>
              Source *
            </Label>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <FormGroup
              className={classNames("dropdown-group", errorClass(SOURCE_ID))}
              data-testid={SOURCE_ID}
            >
              <AsyncPaginate
                className="react-select info'"
                classNamePrefix="react-select"
                additional={{
                  hasAll: false,
                  page: 0,
                }}
                placeholder="Select Source"
                loadOptions={leadSourceLoadOptions}
                onChange={(value) => {
                  setFieldValue(SOURCE_ID, value.value.id);
                }}
              />
              {renderErrorRowOnTouch(SOURCE_ID, touched, errors)}
            </FormGroup>
          </Col>
        </Row>
        <Row className="text-uppercase text-left">
          <Col>
            <Label tag="h3" htmlFor={PHONE_NUMBER}>
              Communication Preferences
            </Label>
          </Col>
        </Row>
        <Row className="text-left">
          <Col className="checkbox-radios">
            <FormGroup check className={errorClass(EMAIL_COMMUNICATION)}>
              <Label check>
                <Input
                  name={EMAIL_COMMUNICATION}
                  type="checkbox"
                  id={EMAIL_COMMUNICATION}
                  data-testid="email-communication"
                  checked={values[EMAIL_COMMUNICATION]}
                  onChange={({ target: { checked } }) =>
                    setFieldValue(EMAIL_COMMUNICATION, checked)
                  }
                />
                <span className="form-check-sign" />
                Email
              </Label>
            </FormGroup>
            <FormGroup check className={errorClass(SMS_COMMUNICATION)}>
              <Label check>
                <Input
                  name={SMS_COMMUNICATION}
                  type="checkbox"
                  data-testid="sms-communication"
                  checked={values[SMS_COMMUNICATION]}
                  onChange={({ target: { checked } }) =>
                    setFieldValue(SMS_COMMUNICATION, checked)
                  }
                />
                <span className="form-check-sign" />
                SMS
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row className="text-left">
          <Col>
            <RequiredFieldsLabel />
          </Col>
        </Row>
      </Form>
    </ReactBSAlert>
  );
}

export default AddLeadAlert;
