import { zonedTimeToUtc } from "@gymflow/helpers";
import { AppointmentDTO, AppointmentPostDTO } from "@gymflow/types";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { availabilityQueryKeys } from "../availability";
import { calendarQueryKeys } from "../calendar";
import { appointmentQueryKeys } from "./appointmentQueryKeys";

export function useAppointmentCreate(
  {
    api,
    tz,
  }: {
    api: {
      appointmentApi: {
        create: (
          fields: AppointmentPostDTO,
        ) => Promise<{ data: AppointmentDTO }>;
      };
    };
    tz?: string;
  },
  mutationOpts?: UseMutationOptions<
    AppointmentDTO,
    unknown,
    AppointmentPostDTO
  >,
) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (newAppointment) => {
      const response = await api.appointmentApi.create({
        ...newAppointment,
        startDate: zonedTimeToUtc(newAppointment.startDate, tz!),
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: appointmentQueryKeys.all() });
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.appointments(),
      });
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.hostCalculateAvailability(),
      });
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.hostCalculateAvailabilityMappedByHost(),
      });
    },
    ...mutationOpts,
  });

  return mutation;
}
