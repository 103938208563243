import {
  kisiQueryKeys,
  useMutationKisiSaveApiKey,
  useQueryKisiApiKeyExists,
  useQueryKisiPlaceList,
} from "@gymflow/api";
import { KisiPlace } from "@gymflow/types";
import { useQueryClient } from "@tanstack/react-query";
import { useClubSettings } from "apps/portal/src/providers";
import classNames from "classnames";
import { useContext, useEffect, useMemo, useState } from "react";

import useGymflowModels from "../../../store";
import { Button, Select, TextInput } from "../../atoms";
import { WizardContext, WizardState } from "../StepWizard";

export function KisiIntegrationStep1() {
  const { api } = useGymflowModels();
  const queryClient = useQueryClient();

  const settings = useClubSettings();
  const clubId = settings.clubId as number;
  const {
    data: places,
    error,
    isFetching,
  } = useQueryKisiPlaceList({ api, clubId });
  const saveKisiKeyMutation = useMutationKisiSaveApiKey({ api });
  const { data: apiKeyCheck } = useQueryKisiApiKeyExists({ api, clubId });
  const [validationError, setValidationError] = useState("");

  const { setWizardState, wizardState } = useContext(WizardContext);

  const placeOptions = useMemo(() => {
    return (places ?? []).map((place: any) => ({
      label: place.name,
      value: place,
    }));
  }, [places]);

  useEffect(() => {
    if (!isFetching && apiKeyCheck && error) {
      setValidationError(
        "No Kisi places are available. Make sure the API Key is correct and at least one Kisi place is available.",
      );
    } else if (wizardState.isWizardStepValid === false) {
      if (!apiKeyCheck) {
        setValidationError("Kisi API Key needs to be saved.");
      } else if (!wizardState["kisiPlace"]) {
        setValidationError("A Kisi place needs to be selected.");
      }
    } else {
      setValidationError("");
    }
  }, [
    apiKeyCheck,
    wizardState.isWizardStepValid,
    wizardState["kisiPlace"],
    placeOptions.length,
    error,
    isFetching,
  ]);

  useEffect(() => {
    if (isFetching) {
      setWizardState((prev: WizardState) => ({
        ...prev,
        kisiPlace: undefined,
      }));
    }
  }, [isFetching, setWizardState]);

  return (
    <div>
      <div>
        <div>Kisi API Key</div>
        <div className="mt-2 flex">
          <TextInput
            className="rounded-r-none"
            onChange={({ currentTarget: { value } }) => {
              setWizardState({
                ...wizardState,
                kisiApiKey: value,
              });
            }}
            value={wizardState["kisiApiKey"]}
            placeholder={apiKeyCheck ? "*".repeat(32) : undefined}
          />
          <Button
            className="mt-0 !rounded-l-none"
            onClick={async () => {
              await saveKisiKeyMutation.mutateAsync({
                apiKey: wizardState["kisiApiKey"],
                clubId,
              });

              setWizardState({
                ...wizardState,
                kisiPlace: undefined,
              });

              queryClient.invalidateQueries({
                queryKey: kisiQueryKeys.places(),
              });
            }}
            disabled={!wizardState["kisiApiKey"]}
          >
            Save
          </Button>
        </div>
        <div className="mt-2 text-gray-600">
          You can generate your Kisi API key by logging into your Kisi dashboard
          and heading to your account settings.
        </div>
      </div>
      <div
        className={classNames("mt-2", {
          invisible: !apiKeyCheck,
        })}
      >
        <div>Kisi Place</div>
        <div className="mt-2">
          <Select
            value={
              wizardState?.["kisiPlace"]
                ? {
                    label: wizardState["kisiPlace"].name,
                    value: wizardState["kisiPlace"],
                  }
                : null
            }
            options={error || isFetching ? [] : placeOptions}
            onChange={({ value }: { label: string; value: KisiPlace }) => {
              setWizardState({
                ...wizardState,
                kisiPlace: value,
              });
            }}
          />
        </div>
      </div>
      <div className="text-error-500 mt-2">{validationError}</div>
    </div>
  );
}
