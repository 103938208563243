import { QueryKey } from "@tanstack/react-query";

export const reportQueryKeys = {
  eventAttendance: (filters: Readonly<Record<string, any>>) =>
    ["event-attendance-report", { filters }] as QueryKey,
  classesRun: (filters: Readonly<Record<string, any>>) =>
    ["classes-run-report", { filters }] as QueryKey,
  membershipSold: (filters: Readonly<Record<string, any>>) =>
    ["membership-sold-report", { filters }] as QueryKey,
  membershipPausing: (filters: Readonly<Record<string, any>>) =>
    ["membership-pausing-report", { filters }] as QueryKey,
  membershipResuming: (filters: Readonly<Record<string, any>>) =>
    ["membership-resuming-report", { filters }] as QueryKey,
  membershipOverdue: (filters: Readonly<Record<string, any>>) =>
    ["membership-overdue-report", { filters }] as QueryKey,
  membershipPending: (filters: Readonly<Record<string, any>>) =>
    ["membership-pending-report", { filters }] as QueryKey,
  membershipEnding: (filters: Readonly<Record<string, any>>) =>
    ["membership-ending-report", { filters }] as QueryKey,
  membershipPaused: (filters: Readonly<Record<string, any>>) =>
    ["membership-paused-report", { filters }] as QueryKey,
  revenueAccountsReceivable: (filters: Readonly<Record<string, any>>) =>
    ["revenue-accounts-receivable", { filters }] as QueryKey,
  revenueAccountsReceivableTotal: (filters: Readonly<Record<string, any>>) =>
    ["revenue-accounts-receivable-total", { filters }] as QueryKey,
  allAccess: () => ["access"] as QueryKey,
  access: (filters?: Readonly<Record<string, any>>) =>
    ["access", { filters }] as QueryKey,
  lead: (filters: Readonly<Record<string, any>>) =>
    ["lead-report", { filters }] as QueryKey,
  noShows: (filters: Readonly<Record<string, any>>) =>
    ["no-shows", { filters }] as QueryKey,
  lateCancellations: (filters: Readonly<Record<string, any>>) =>
    ["late-cancellations", { filters }] as QueryKey,
};
