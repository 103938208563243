export function resetTailwindColors() {
  const root = document.querySelector(":root");
  if (root) {
    const setProperty = (root as HTMLElement).style.setProperty.bind(
      (root as HTMLElement).style,
    );
    // Colors should match tailwind-base.css
    setProperty("--ring-color", "rgb(59 130 246 / 0.5)");
    setProperty("--primary-color-25", "#F9FAFF");
    setProperty("--primary-color-50", "#F7F8FF");
    setProperty("--primary-color-100", "#D8DBFF");
    setProperty("--primary-color-200", "#B6BCFC");
    setProperty("--primary-color-300", "#404681");
    setProperty("--primary-color-400", "#333A78");
    setProperty("--primary-color-500", "#262E70");
    setProperty("--primary-color-600", "#1A2168");
    setProperty("--primary-color-700", "#000957");
    setProperty("--primary-color-800", "#000957");
    setProperty("--primary-color-900", "#00084C");
  }
}
