import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useMemberPaymentMethod(
  {
    api,
  }: {
    api: {
      memberApi: {
        attachPaymentMethod: (
          memberId: string,
          clubId: number,
          paymentMethodId: string,
        ) => Promise<{ data: unknown }>;
        removePaymentMethod: (
          memberId: string,
          clubId: number,
          paymentMethodId: string,
        ) => Promise<{ data: unknown }>;
        assignDefaultPaymentMethod: (
          memberId: string,
          clubId: number,
          paymentMethodId: string,
        ) => Promise<{ data: unknown }>;
      };
    };
  },
  mutationOpts?: UseMutationOptions<
    { data: unknown },
    unknown,
    unknown,
    unknown
  >,
) {
  const queryClient = useQueryClient();

  const attachPaymentMethodMutation = useMutation({
    mutationFn: ({
      memberId,
      clubId,
      paymentMethodId,
    }: {
      memberId: string;
      clubId: number;
      paymentMethodId: string;
    }) => api.memberApi.attachPaymentMethod(memberId, clubId, paymentMethodId),
    onSuccess: (
      _,
      { memberId, clubId }: { memberId: string; clubId: number },
    ) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.paymentMethods(clubId, memberId),
      });
    },
    ...mutationOpts,
  });

  const removePaymentMethodMutation = useMutation({
    mutationFn: ({
      memberId,
      clubId,
      paymentMethodId,
    }: {
      memberId: string;
      clubId: number;
      paymentMethodId: string;
    }) => api.memberApi.removePaymentMethod(memberId, clubId, paymentMethodId),
    onSuccess: (
      _,
      { memberId, clubId }: { memberId: string; clubId: number },
    ) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.paymentMethods(clubId, memberId),
      });
    },
    ...mutationOpts,
  });

  const assignDefaultPaymentMethodMutation = useMutation({
    mutationFn: ({
      memberId,
      clubId,
      paymentMethodId,
    }: {
      memberId: string;
      clubId: number;
      paymentMethodId: string;
    }) =>
      api.memberApi.assignDefaultPaymentMethod(
        memberId,
        clubId,
        paymentMethodId,
      ),
    onSuccess: (
      _,
      { memberId, clubId }: { memberId: string; clubId: number },
    ) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.paymentMethods(clubId, memberId),
      });
    },
    ...mutationOpts,
  });

  return {
    attachPaymentMethodMutation,
    removePaymentMethodMutation,
    assignDefaultPaymentMethodMutation,
  };
}
