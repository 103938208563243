import { useMemberInvoice } from "@gymflow/api";
import {
  AlertContext,
  InvoiceStatus,
  NotificationContext,
  PaymentConfirmationStatus,
  StrongAuthorizationPaymentStatus,
  useParseErrors,
  usePaymentAuthorizationAlert,
} from "@gymflow/common";
import PropTypes from "prop-types";
import { useCallback, useContext } from "react";
import { Col, Row } from "reactstrap";

import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import CancelAuthorizationAlert from "./CancelAuthorizationAlert";
import UserMemberBilling from "./PaymentMethods/UserMemberBilling";
import UserMemberInvoices from "./UserMemberInvoices";

function UserPayments({
  loadingPaymentMethods,
  user,
  paymentMethods,
  fetchPaymentMethods,
  addPaymentMethod,
  removePaymentMethod,
  assignDefaultPaymentMethod,
  loadingInvoices,
  fetchInvoices,
  refreshInvoices,
  invoices,
  invoicesPage,
  invoicesPageCount,
  currency,
}) {
  const { api } = useGymflowModels();
  const { id } = user;
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const alert = useContext(AlertContext);
  const toast = useContext(NotificationContext);
  const { show: showPaymentAuthorizationAlert } =
    usePaymentAuthorizationAlert();
  const {
    creditInvoiceMutation,
    debitInvoiceMutation,
    collectInvoiceMutation,
    refundInvoiceMutation,
    writeOffInvoiceMutation,
  } = useMemberInvoice({ api });
  const parseErrors = useParseErrors();

  const creditInvoice = useCallback(
    (memberId, clubId, invoiceNumber, amount) => {
      return creditInvoiceMutation.mutateAsync({
        memberId,
        clubId,
        invoiceNumber,
        amount,
      });
    },
    [creditInvoiceMutation],
  );

  const debitInvoice = useCallback(
    (memberId, clubId, invoiceNumber, amount) => {
      return debitInvoiceMutation.mutateAsync({
        memberId,
        clubId,
        invoiceNumber,
        amount,
      });
    },
    [debitInvoiceMutation],
  );

  const collectInvoice = useCallback(
    (memberId, clubId, invoiceNumber, amount) => {
      return collectInvoiceMutation.mutateAsync({
        memberId,
        clubId,
        invoiceNumber,
        amount,
      });
    },
    [collectInvoiceMutation],
  );

  const refundInvoice = useCallback(
    (memberId, clubId, invoiceNumber) => {
      return refundInvoiceMutation.mutateAsync({
        memberId,
        clubId,
        invoiceNumber,
      });
    },
    [refundInvoiceMutation],
  );

  const writeOffInvoice = useCallback(
    (memberId, clubId, invoiceNumber) => {
      return writeOffInvoiceMutation.mutateAsync({
        memberId,
        clubId,
        invoiceNumber,
      });
    },
    [writeOffInvoiceMutation],
  );

  return (
    <div className="relative flex h-full w-full">
      <div className="absolute inset-0 overflow-y-auto md:overflow-x-hidden">
        <Row>
          <Col>
            <UserMemberBilling
              fetchPaymentMethods={() =>
                fetchPaymentMethods({ userMemberId: id, clubId })
              }
              onAddPaymentMethodClick={
                addPaymentMethod &&
                ((method) =>
                  addPaymentMethod({ userMemberId: id, clubId, ...method }))
              }
              onRemovePaymentMethodClick={(method) =>
                removePaymentMethod({ userMemberId: id, clubId, ...method })
              }
              onAssignDefaultPaymentMethodClick={(method) =>
                assignDefaultPaymentMethod({
                  userMemberId: id,
                  clubId,
                  ...method,
                })
              }
              isLoading={loadingPaymentMethods}
              paymentMethods={paymentMethods}
              defaultCardholdersName={`${user.firstName} ${user.lastName}`}
              fetchBacsCheckoutId={() =>
                api.memberApi.attachPaymentMethodBacs(id, clubId)
              }
            />
          </Col>
        </Row>
        <Row className="mt-lg-20 mt-4">
          <Col>
            <UserMemberInvoices
              fetchInvoices={(params) =>
                fetchInvoices({ ...params, userId: id })
              }
              refreshInvoices={refreshInvoices}
              invoices={invoices}
              isLoading={loadingInvoices}
              page={invoicesPage}
              pageCount={invoicesPageCount}
              userMemberId={id}
              currency={currency}
              requestAuthorization={async ({
                number,
                paymentIntentIdForAuthorization,
              }) => {
                const result =
                  await api.strongCustomerAuthorizationApi.confirmPayment(
                    paymentIntentIdForAuthorization,
                  );
                if (
                  result.data.result ===
                  StrongAuthorizationPaymentStatus.AuthorizationNotRequired
                ) {
                  await alert.show("The payment was confirmed.", {
                    title: "Success!",
                    showCancel: false,
                  });
                } else {
                  try {
                    await api.strongCustomerAuthorizationApi.sendEmail(number);
                  } catch (e) {
                    if (e.response.data.error_message) {
                      alert.showError(e.response.data.error_message);
                      return;
                    }
                  }

                  const paymentConfirmationResult =
                    await showPaymentAuthorizationAlert({
                      paymentIntentIdForAuthorization,
                      confirmPayment:
                        api.strongCustomerAuthorizationApi.confirmPayment,
                      messageText: (
                        <>
                          The user must authorize this payment before it will be
                          processed.
                          <br />
                          Please ask the user to authorize the payment by
                          clicking the link sent to their email.
                        </>
                      ),
                    });
                  if (
                    paymentConfirmationResult.status ===
                    PaymentConfirmationStatus.Success
                  ) {
                    alert.show("The payment was confirmed.", {
                      title: "Success!",
                      showCancel: false,
                    });
                  } else if (
                    paymentConfirmationResult.status ===
                    PaymentConfirmationStatus.Waiting
                  ) {
                    toast.notify({
                      message: "Awaiting payment, check later.",
                      type: "warning",
                    });
                  } else if (
                    paymentConfirmationResult.status ===
                    PaymentConfirmationStatus.Failed
                  ) {
                    toast.notify({
                      message: "Payment Failed.",
                      type: "danger",
                    });
                  }
                  refreshInvoices();
                }
              }}
              cancelAuthorization={({ paymentIntentIdForAuthorization }) => {
                alert.setAlert(
                  <CancelAuthorizationAlert
                    onConfirm={async () => {
                      try {
                        await api.strongCustomerAuthorizationApi.cancelAuthorization(
                          paymentIntentIdForAuthorization,
                        );
                        alert.hide();
                        await refreshInvoices();
                        toast.notify({ message: "Authorization cancelled." });
                      } catch (e) {
                        parseErrors(e.response);
                      }
                    }}
                    onCancel={alert.hide}
                  />,
                );
              }}
              getInvoiceFile={(clubId, invoiceNumber) =>
                api.memberApi.invoiceFile(id, clubId, invoiceNumber)
              }
              creditInvoice={creditInvoice}
              debitInvoice={debitInvoice}
              collectInvoice={collectInvoice}
              refundInvoice={refundInvoice}
              writeOffInvoice={writeOffInvoice}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

UserPayments.defaultProps = {
  fetchInvoices: () => Promise.resolve({ pageCount: 1 }),
  fetchPaymentMethods: () => Promise.resolve(),
  invoices: [],
  loadingInvoices: false,
  loadingPaymentMethods: false,
  paymentMethods: [],
  refreshInvoices: () => Promise.resolve(),
};

UserPayments.propTypes = {
  removePaymentMethod: PropTypes.func,
  fetchPaymentMethods: PropTypes.func,
  assignDefaultPaymentMethod: PropTypes.func,
  addPaymentMethod: PropTypes.func,
  user: PropTypes.object,
  loadingPaymentMethods: PropTypes.bool,
  paymentMethods: PropTypes.array,
  loadingInvoices: PropTypes.bool,
  fetchInvoices: PropTypes.func,
  refreshInvoices: PropTypes.func,
  invoicesPage: PropTypes.number.isRequired,
  invoicesPageCount: PropTypes.number.isRequired,
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.string.isRequired,
      invoiceUrl: PropTypes.string.isRequired,
      totalAmount: PropTypes.number.isRequired,
      status: PropTypes.oneOf(Object.values(InvoiceStatus)).isRequired,
      issueDate: PropTypes.string.isRequired,
    }),
  ).isRequired,
  currency: PropTypes.string.isRequired,
};

export default UserPayments;
