import { useState } from "react";
import { useStaffList } from "@gymflow/api";

import useGymflowModels from "../store";

/**
 * @deprecated
 */
const useStaffLoadOptions = ({ allowNull, nullLabel } = {}) => {
  const { api } = useGymflowModels();
  const [page, setPage] = useState(0);
  const { refetch } = useStaffList({ api, opts: { page } });
  const loadOptions = async (_, __, { page }) => {
    setPage(page);
    const results = await refetch();
    const options = results.data.content.map(({ id, firstName, lastName }) => {
      return {
        value: { id, firstName, lastName },
        label: `${firstName} ${lastName}`,
      };
    });

    if (allowNull) {
      options.unshift({ label: nullLabel || "All", value: null });
    }

    return {
      options,
      hasMore: !results.data.last,
      additional: {
        page: page + 1,
      },
    };
  };
  return loadOptions;
};

export default useStaffLoadOptions;
