export function PublicFooter() {
  return (
    <div className="dark:border-t-darkGray-900 dark:text-darkGray-600 flex h-14 items-center justify-center border-t border-t-gray-300 px-4 text-sm text-gray-600">
      Powered by&nbsp;
      <a
        className="text-primary-600 hover:!text-primary-600 underline"
        href="https://gymflow.io"
        target="_blank"
        rel="noreferrer"
      >
        Gymflow.io
      </a>
    </div>
  );
}
