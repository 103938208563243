import { zonedTimeToUtc } from "@gymflow/helpers";
import {
  AccessReportItemDTO,
  ApiListResponse,
  PaginationOptions,
} from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";
import { utcToZonedTime } from "date-fns-tz";
import merge from "lodash/merge";

import { defaultPage } from "../defaultPage";
import { reportQueryKeys } from "./reportQueryKeys";

export interface UseAccessReportProps {
  api: {
    accessApi: {
      find: (params: {
        limit: number;
        page: number;
        sort?: PaginationOptions["sort"];
        extraParams: {
          dateFrom?: string;
          dateTo?: string;
          membershipIncluded?: string[];
          onSite?: boolean;
          guest?: boolean;
        };
      }) => Promise<any>;
    };
  };
  filter?: {
    dateFrom?: string;
    dateTo?: string;
    membershipIncluded?: string[];
    onSite?: boolean;
    guest?: boolean;
  };
  paginationOptions: PaginationOptions;
  tz: string;
}

export function useAccessReport({
  api,
  paginationOptions,
  filter,
  tz,
}: UseAccessReportProps) {
  const result = useQuery({
    queryKey: reportQueryKeys.access({
      paginationOptions,
      filter,
    }),
    queryFn: async () => {
      const result = await api.accessApi.find({
        page: paginationOptions.page,
        limit: paginationOptions.size,
        sort: paginationOptions.sort,
        extraParams: {
          ...(filter?.dateFrom !== undefined
            ? { dateFrom: zonedTimeToUtc(filter?.dateFrom, tz) }
            : {}),
          ...(filter?.dateTo !== undefined
            ? { dateTo: zonedTimeToUtc(filter?.dateTo, tz) }
            : {}),
          membershipIncluded: filter?.membershipIncluded,
          onSite: filter?.onSite,
          guest: filter?.guest,
        },
      });
      return result.data as ApiListResponse<AccessReportItemDTO>;
    },
    placeholderData: () => {
      return defaultPage<AccessReportItemDTO>();
    },
    select: (data) => {
      const dto = merge({}, data, {
        content: data.content.map(({ checkInDate, checkOutDate, ...rest }) => {
          return {
            checkInDate: checkInDate && utcToZonedTime(checkInDate, tz),
            checkOutDate: checkOutDate && utcToZonedTime(checkOutDate, tz),
            ...rest,
          };
        }),
      });
      return dto;
    },
    enabled: !!tz && !!filter?.dateFrom && !!filter?.dateTo,
  });
  return result;
}
