import { PaymentMethodDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { memberAsMemberQueryKeys } from "./memberAsMemberQueryKeys";

export function useMemberPaymentMethodListAsMember(
  {
    api,
    memberId,
    clubId,
  }: {
    api: {
      profileApi: {
        fetchPaymentMethods: (_: unknown, clubId: number) => Promise<any>;
      };
    };
    memberId: string;
    clubId: number;
  },
  queryOpts?: UseQueryOptions<PaymentMethodDTO[]>,
) {
  const result = useQuery({
    queryKey: memberAsMemberQueryKeys.paymentMethods(clubId, memberId),
    queryFn: async () => {
      const result = await api.profileApi.fetchPaymentMethods(memberId, clubId);
      return result.data as PaymentMethodDTO[];
    },
    initialData: [],
    ...queryOpts,
  });
  return result;
}
