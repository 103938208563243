import { useAbility } from "@casl/react";
import { useClub, useLead, useMember, useTaskCreate } from "@gymflow/api";
import { AlertContext, NotificationContext } from "@gymflow/common";
import { LeadDTO, UserMemberBean } from "@gymflow/types";
import React, { useContext } from "react";

import addNoteIcon from "../../../assets/img/add-note.svg";
import createTaskIcon from "../../../assets/img/create-task.svg";
import sendEmailIcon from "../../../assets/img/send-email.svg";
import salesIcon from "../../../assets/img/shopping-bag-icon-grey.svg";
import useSendEmails from "../../hooks/useSendEmails";
import { AbilityContext, Subject, Verb } from "../../permissions";
import { ModalWrapper, useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { Button, PrimaryButton, Spinner } from "../atoms";
import { CheckInButton } from "../atoms/CheckInButton";
import TaskAlert from "../Task/TaskAlert";
import { NoteFormSideBarProviderContext } from "../UserMember/Notes/NoteFormSideBarProvider";
import SendEmailAlertWithProvider from "../UserMember/SendEmails/SendEmailAlert";

export interface ActionMenuModalProps {
  onCancel: () => void;
  userMemberId?: string;
  leadId?: number;
  showShop: (user?: UserMemberBean | LeadDTO) => Promise<void>;
}

export const ActionMenuModal: React.FC<ActionMenuModalProps> = ({
  onCancel,
  userMemberId,
  leadId,
  showShop,
}) => {
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { data: club } = useClub({ api, clubId: settings.clubId });
  const ability = useAbility(AbilityContext);
  const { setAlert, hide } = useContext(AlertContext);
  const createTaskMutation = useTaskCreate({ api, tz: settings.timezone });
  const { sendEmails } = useSendEmails();
  const { notify } = useContext(NotificationContext);
  const { triggerNewNote } = useContext(NoteFormSideBarProviderContext);
  const { data: memberContainer } = useMember({
    api,
    memberId: userMemberId,
    tz: settings.timezone,
  });
  const { data: lead } = useLead({ api, leadId: leadId });
  const user = memberContainer?.user || lead;
  return (
    <ModalWrapper className="flex flex-col gap-y-4 !p-6 " onCancel={onCancel}>
      {!user && <Spinner />}
      {user && (
        <>
          <div className="flex w-full flex-row items-start justify-between">
            <div className="flex flex-col">
              <div className="flex text-lg font-semibold text-gray-900">
                Action Menu
              </div>
              <div className="flex text-sm text-gray-600">
                Choose an action for&nbsp;
                <b>
                  {user.firstName} {user.lastName}
                </b>
              </div>
            </div>
          </div>
          <Button
            className="!mt-0"
            onClick={() => {
              showShop(user);
              onCancel();
            }}
          >
            <div className="flex flex-row items-center gap-x-2">
              <img src={salesIcon} alt="sell-something" />
              Sell Something
            </div>
          </Button>
          {user.profileType === "USER" && (
            <CheckInButton
              onCheckInOutFinished={onCancel}
              userMemberId={user.id as any}
            />
          )}
          {ability.can(Verb.Create, Subject.Email) && (
            <Button
              onClick={async () => {
                // TODO: Refactor this so that the email sending logic is handled by the email creation component and not it's parent, similar to the strategy for note creation
                setAlert(
                  <SendEmailAlertWithProvider
                    allowMarketing={user.emailCommunication}
                    from={club?.email!}
                    to={`${user.firstName} ${user.lastName}`}
                    onSubmit={(values: any) => {
                      const emailRecipientList = [];
                      if (user.profileType === "LEAD") {
                        emailRecipientList.push({
                          leadId: user.id,
                        });
                      } else {
                        emailRecipientList.push({
                          userMemberId: user.id,
                        });
                      }

                      const bcc = values.bcc ? values.bcc.split(",") : [];
                      return sendEmails(
                        values.subject,
                        values.body,
                        emailRecipientList,
                        values.marketing,
                        bcc,
                      );
                    }}
                    onCancel={hide}
                  />,
                );
                onCancel();
              }}
              className="!mt-0 flex"
            >
              <div className="flex flex-row items-center gap-x-2">
                <img src={sendEmailIcon} alt="quick-actions" />
                Send Email
              </div>
            </Button>
          )}
          <Button
            onClick={() => {
              triggerNewNote({
                ...(typeof user.id === "number"
                  ? {
                      leadId: user.id,
                    }
                  : {
                      userMemberId: user.id,
                    }),
                name: `${user.firstName} ${user.lastName}`,
              });
              onCancel();
            }}
            className="!mt-0 flex"
          >
            <div className="flex flex-row items-center gap-x-2">
              <img src={addNoteIcon} alt="quick-actions" />
              Add Note
            </div>
          </Button>
          <Button
            // TODO: Refactor this so that the email sending logic is handled by the email creation component and not it's parent, similar to the strategy for note creation
            onClick={() => {
              setAlert(
                <TaskAlert
                  defaultRelatedUsers={
                    user.profileType !== "LEAD"
                      ? [
                          {
                            id: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                          },
                        ]
                      : []
                  }
                  defaultRelatedLeads={
                    user.profileType === "LEAD"
                      ? [
                          {
                            id: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                          },
                        ]
                      : []
                  }
                  onCancel={hide}
                  onSubmit={async (values) => {
                    await createTaskMutation.mutateAsync(values);
                    notify({ message: "Task Created" });
                    hide();
                  }}
                />,
              );
              onCancel();
            }}
            className="!mt-0 flex"
          >
            <div className="flex flex-row items-center gap-x-2">
              <img src={createTaskIcon} alt="quick-actions" />
              Create Task
            </div>
          </Button>

          <PrimaryButton onClick={onCancel} className="mt-0 ">
            Close
          </PrimaryButton>
        </>
      )}
    </ModalWrapper>
  );
};
