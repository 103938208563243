import { UserMemberBean } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useQueryMembershipLinkedMembers(
  {
    api,
    primarySubscriptionId,
  }: {
    api: {
      memberApi: {
        listMembershipLinkedMembers: (
          primarySubscriptionId: number,
        ) => Promise<any>;
      };
    };
    primarySubscriptionId?: number;
  },
  queryOptions?: UseQueryOptions<UserMemberBean[]>,
) {
  return useQuery({
    queryKey: memberQueryKeys.membershipLinkedMembers(primarySubscriptionId),
    queryFn: async () => {
      const result = await api.memberApi.listMembershipLinkedMembers(
        primarySubscriptionId!,
      );
      return result.data as UserMemberBean[];
    },
    enabled:
      !!primarySubscriptionId &&
      (queryOptions === undefined || queryOptions.enabled),
    initialData: null,
  });
}
