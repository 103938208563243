import { useAppointmentCreateAsMember } from "@gymflow/api";
import { PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import {
  AppointableDTO,
  FacilityDTO,
  UserStaffPublicDTO,
} from "@gymflow/types";
import moment from "moment-timezone";
import { useContext, useState } from "react";
import { useHistory } from "react-router";

import { requestErrorParser } from "../../helpers/requestErrorParser";
import { useRedirectUrl } from "../../hooks";
import {
  ErrorModal,
  ModalContext,
  SuccessModal,
  useApiResolverContext,
  useAuthenticatedUser,
  useClubSettings,
} from "../../providers";
import { useLoginOverlay } from "../../providers/authentication/loginOverlay/context";
import useGymflowModels from "../../store";
import {
  Checkout,
  Confirm,
  SelectFacilityHost,
  SelectService,
  SelectStaffHost,
  SelectTime,
} from "../organisms";

export function ScheduleAppointment() {
  const [step, setStep] = useState<
    "SELECT_SERVICE" | "SELECT_HOST" | "SELECT_TIME" | "CONFIRM" | "CHECKOUT"
  >("SELECT_SERVICE");
  const [service, setService] = useState<AppointableDTO>();
  const [staffHost, setStaffHost] = useState<UserStaffPublicDTO | null>(null);
  const [facilityHost, setFacilityHost] = useState<FacilityDTO | null>();
  const [timeSlot, setTimeSlot] = useState<{
    startTime: Date;
    hosts: string[];
  }>();
  const { api } = useGymflowModels();
  const { showSignUpOverlay } = useLoginOverlay();
  const { reinitialize } = useApiResolverContext();
  const { id } = useAuthenticatedUser();
  const { timezone } = useClubSettings();
  const bookAppointmentMutation = useAppointmentCreateAsMember({
    api,
    tz: timezone,
  });
  const history = useHistory();
  const { setModal } = useContext(ModalContext);

  const { redirectToCustomizedWebsiteOr } = useRedirectUrl();

  return (
    <div className="flex items-center justify-center p-6">
      <div className="w-[50rem] max-w-[50rem] ">
        {step === "SELECT_SERVICE" && (
          <SelectService
            onSelect={({ service }) => {
              setService(service);
              setStep("SELECT_HOST");
            }}
          />
        )}
        {step === "SELECT_HOST" && service?.availabilityType === "STAFF" && (
          <SelectStaffHost
            hosts={service?.appointableHosts}
            serviceCategory={service?.appointableCategory.name ?? ""}
            onSelectHost={async ({ host }) => {
              if (id) {
                setStaffHost(host);
                setStep("SELECT_TIME");
              } else {
                const result = await showSignUpOverlay();
                if (!result.loggedIn) {
                  return;
                }
                setStaffHost(host);
                setStep("SELECT_TIME");
              }

              reinitialize("MEMBER");
            }}
            onBack={() => {
              setStep("SELECT_SERVICE");
            }}
          />
        )}
        {step === "SELECT_HOST" && service?.availabilityType === "FACILITY" && (
          <SelectFacilityHost
            facilities={service?.appointableFacilities}
            serviceCategory={service?.appointableCategory.name ?? ""}
            onSelectHost={async ({ host }) => {
              if (id) {
                setFacilityHost(host);
                setStep("SELECT_TIME");
              } else {
                const result = await showSignUpOverlay();
                if (!result.loggedIn) {
                  return;
                }
                setFacilityHost(host);
                setStep("SELECT_TIME");
              }

              reinitialize("MEMBER");
            }}
            onBack={() => {
              setStep("SELECT_SERVICE");
            }}
          />
        )}
        {step === "SELECT_TIME" && service && (
          <SelectTime
            serviceId={service.id}
            staffHost={staffHost}
            facilityHost={facilityHost}
            availabilityType={service.availabilityType}
            onTimeSelect={(slot) => {
              setTimeSlot(slot);
              setStep("CONFIRM");
            }}
            onBack={() => {
              setStep("SELECT_HOST");
            }}
            slotSizeInMinutes={15}
            serviceDuration={service.duration + (service.blockedTime ?? 0)}
          />
        )}
        {step === "CONFIRM" && service && timeSlot && (
          <Confirm
            service={service}
            onConfirm={async ({ requiresPayment }) => {
              if (requiresPayment) {
                setStep("CHECKOUT");
              } else {
                try {
                  await bookAppointmentMutation.mutateAsync({
                    status: "BOOKED",
                    startDate: moment(timeSlot?.startTime).format(
                      PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                    ),
                    appointableId: service.id,
                    appointmentUserId: id as string,
                    appointmentHostId:
                      service.availabilityType === "STAFF" && staffHost?.id
                        ? staffHost.id
                        : undefined,
                    appointmentFacilityId:
                      service.availabilityType === "FACILITY" &&
                      facilityHost?.id
                        ? facilityHost.id
                        : undefined,
                  });

                  setModal(
                    <SuccessModal
                      title="Appointment Saved"
                      onClose={() => {
                        const { result } = redirectToCustomizedWebsiteOr();
                        if (result === "NOT_REDIRECT") {
                          history.go(0);
                        }
                      }}
                    >
                      Your appointment was saved successfully.
                    </SuccessModal>,
                  );
                } catch (e: any) {
                  if (e?.response?.data) {
                    setModal(<ErrorModal>{requestErrorParser(e)}</ErrorModal>);
                  } else {
                    setModal(
                      <ErrorModal>Something unexpected happened.</ErrorModal>,
                    );
                  }
                }
              }
            }}
            onBack={() => {
              setStep("SELECT_TIME");
            }}
            appointmentStart={timeSlot.startTime}
            hostName={
              staffHost
                ? `${staffHost.firstName} ${staffHost.lastName}`
                : facilityHost?.name
            }
          />
        )}
        {step === "CHECKOUT" && service && (
          <Checkout
            onBack={() => {
              setStep("CONFIRM");
            }}
            service={service}
            onCheckout={async ({ paymentUnit, promoCode, paymentMethodId }) => {
              try {
                await bookAppointmentMutation.mutateAsync({
                  status: "BOOKED",
                  startDate: moment(timeSlot?.startTime).format(
                    PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                  ),
                  appointableId: service.id,
                  appointmentUserId: id as string,
                  appointmentHostId:
                    service.availabilityType === "STAFF" && staffHost?.id
                      ? staffHost.id
                      : undefined,
                  appointmentFacilityId:
                    service.availabilityType === "FACILITY" && facilityHost?.id
                      ? facilityHost.id
                      : undefined,
                  appointmentPayment:
                    paymentUnit === "CREDITS"
                      ? {
                          paidWithSessions: true,
                        }
                      : {
                          paidWithSessions: false,
                          paymentMethodId: paymentMethodId as string,
                          promotionCode: promoCode ? promoCode : undefined,
                        },
                });

                setModal(
                  <SuccessModal
                    title="Appointment Saved"
                    onClose={() => {
                      const { result } = redirectToCustomizedWebsiteOr();
                      if (result === "NOT_REDIRECT") {
                        history.go(0);
                      }
                    }}
                  >
                    Your appointment was saved successfully.
                  </SuccessModal>,
                );
              } catch (e: any) {
                if (e?.response?.data) {
                  setModal(<ErrorModal>{requestErrorParser(e)}</ErrorModal>);
                } else {
                  setModal(
                    <ErrorModal>Something unexpected happened.</ErrorModal>,
                  );
                }
              }
            }}
          />
        )}
      </div>
    </div>
  );
}
