import React from "react";
import styled from "styled-components";
import { useDrop } from "react-dnd";

import TooltipMenu from "./TooltipMenu";
import LaneHeader from "../../components/Leads/LaneHeader";

/**
 * @deprecated rewrite in tailwind
 */
const Container = styled.div`
  width: 100%;
`;

const Lane = ({
  id,
  index,
  onDragLaneChange,
  title,
  onDrop,
  children,
  highlight,
  tooltipMenuItems,
  style,
}) => {
  const [, drop] = useDrop(() => ({
    accept: "card",
    drop: onDrop,
  }));

  const renderMenu = () => {
    if (tooltipMenuItems.length === 0) {
      return null;
    }

    return (
      <div
        className="d-inline-block text-muted"
        style={{ marginRight: "-15px" }}
      >
        <TooltipMenu items={tooltipMenuItems} />
      </div>
    );
  };

  return (
    <Container ref={drop} className="h-100">
      <LaneHeader
        id={id}
        index={index}
        noDragDrop={index === 0}
        onDragChange={onDragLaneChange}
      >
        <div className="d-inline-block">
          <h2>{title}</h2>
        </div>
        {renderMenu()}
      </LaneHeader>
      <Container
        style={{
          border: highlight ? "2px dashed #3b73af" : "none",
          background: highlight ? "#ebf2f9" : "none",
          maxHeight: "calc(100% - 40px)",
          overflowX: "hidden",
          overflowY: "auto",
          ...style,
        }}
      >
        {children}
      </Container>
    </Container>
  );
};

Lane.defaultProps = {
  leads: [],
  style: {},
  tooltipMenuItems: [],
};

export default Lane;
