import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, UncontrolledPopover, PopoverBody } from 'reactstrap';
import uniqueId from 'lodash/uniqueId';

const TooltipMenu = ({ items }) => {
  const [tooltipId] = useState(uniqueId('tooltip-icon-'));
  const dummyRef = useRef(null);

  const links = items.map(({ title, onClick }, idx) => (
    <div key={idx}>
      <Button
        className="btn-link"
        onClick={() => {
          dummyRef.current.click();
          onClick();
        }}
      >
        {title}
      </Button>
    </div>
  ));

  return (
    <>
      <div id={tooltipId} className="d-flex justify-content-center mt-1" style={{ width: '25px', cursor: 'pointer' }}>
        <i className="fas fa-ellipsis-v" />
      </div>
      <div ref={dummyRef} />
      <UncontrolledPopover placement="bottom" target={tooltipId} trigger="legacy">
        <PopoverBody>{links}</PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

TooltipMenu.defaultProps = {
  items: [],
};

TooltipMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
};

export default TooltipMenu;
