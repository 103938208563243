import { useCallback, useReducer } from 'react';
import isEqual from 'lodash/isEqual';

import { UserStatus } from '@gymflow/common';

const Action = {
  Toggle: 'TOGGLE',
  ToggleAll: 'TOGGLE_ALL',
  Select: 'SELECT',
};

const createSelectAction = (item) => {
  return { type: Action.Select, payload: item };
};

const createToggleAction = (item) => {
  return { type: Action.Toggle, payload: item };
};

const createToggleAllAction = (selectedLength) => {
  return { type: Action.ToggleAll, payload: selectedLength };
};

const isInCollection = (collection, item) => {
  return collection.some((s) => isEqual(s, createItem(item)));
};

const createItem = ({ userType, leadId, userMemberId }) => {
  const newItem = {};
  if (userType === UserStatus.Lead) {
    newItem.id = UserStatus.Lead + leadId;
    newItem.leadId = leadId;
  } else {
    newItem.id = userMemberId;
    newItem.userMemberId = userMemberId;
  }
  return newItem;
};

const reducer = (state, action) => {
  switch (action.type) {
    case Action.Toggle: {
      const newItem = createItem(action.payload);
      if (state.areAllSelected) {
        if (isInCollection(state.excluded, action.payload)) {
          const excluded = state.excluded.filter((s) => !isEqual(s, newItem));

          return { ...state, excluded, selectedLength: state.selectedLength + 1 };
        }
        const excluded = [...state.excluded, newItem];
        return { ...state, excluded, selectedLength: state.selectedLength - 1 };
      }
      if (isInCollection(state.selected, action.payload)) {
        const selected = state.selected.filter((s) => !isEqual(s, newItem));
        return { ...state, selected, selectedLength: selected.length };
      }
      const selected = [...state.selected, newItem];
      return { ...state, selected, selectedLength: selected.length };
    }
    case Action.ToggleAll:
      return {
        ...state,
        areAllSelected: !state.areAllSelected,
        selected: [],
        excluded: [],
        selectedLength: state.areAllSelected ? 0 : action.payload,
      };
    case Action.Select:
      const newItem = createItem(action.payload);
      if (state.areAllSelected || isInCollection(state.selected, action.payload)) {
        return state;
      }
      const selected = [...state.selected, newItem];
      return { ...state, selected, selectedLength: selected.length };

    default:
      throw new Error('Unknown action');
  }
};

const initialState = {
  selected: [],
  areAllSelected: false,
  excluded: [],
  selectedLength: 0,
};

const useSelectableMembers = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isSelected = useCallback(
    (key) => {
      if (state.areAllSelected) {
        return !state.excluded.some((s) => s.id === key);
      }
      return state.selected.some((s) => s.id === key);
    },
    [state]
  );

  return {
    areAllSelected: state.areAllSelected,
    isSelected,
    toggleSelection: (item) => dispatch(createToggleAction(item)),
    toggleAll: (selectedLength) => dispatch(createToggleAllAction(selectedLength)),
    select: (item) => dispatch(createSelectAction(item)),
    selected: state.selected,
    excluded: state.excluded,
    selectedLength: state.selectedLength,
  };
};

export default useSelectableMembers;
