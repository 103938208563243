import { QueryKey } from "@tanstack/react-query";

import { MembershipListFilter } from "./MembershipListFilter";

export const membershipQueryKeys = {
  all: () => ["membership"] as QueryKey,
  list: (filters?: MembershipListFilter) =>
    [...membershipQueryKeys.all(), { filters }] as QueryKey,
  summary: (opts?: {
    membershipId: number;
    startDate: string;
    promotionCode?: string;
    userMemberId: string;
  }) => [...membershipQueryKeys.all(), { opts }] as QueryKey,
  details: (membershipId?: number) =>
    [...membershipQueryKeys.all(), membershipId] as QueryKey,
  statusHistory: (memberId?: string) =>
    [...membershipQueryKeys.all(), memberId] as QueryKey,
};
