import { useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Badge, Button, Card, CardHeader, CardTitle, FormGroup, Input, Label, UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import {
  AsyncButton,
  CardBodyWithSpinner,
  isMobile,
  AlertContext,
  NotificationContext,
  useGenerateElementId,
  PaymentMethod,
  PaymentMethodStatus,
} from '@gymflow/common';

import AddPaymentMethodWrapper from './AddPaymentMethodWrapper';

function UserMemberBilling({
  fetchPaymentMethods,
  onRemovePaymentMethodClick,
  onAddPaymentMethodClick,
  onAssignDefaultPaymentMethodClick,
  isLoading,
  defaultCardholdersName,
  paymentMethods,
  fetchBacsCheckoutId,
}) {
  const { notifyDanger } = useContext(NotificationContext);
  const alert = useContext(AlertContext);

  const generateElementId = useGenerateElementId();

  const renderRemoveButton = (id, isDefault) => {
    const removeButtonId = generateElementId({ prefix: 'btn-remove', key: id });
    return (
      <>
        <div id={removeButtonId} className="d-inline">
          <Button
            onClick={() => {
              alert.showWarning('Removing it will make it unavailable for future use.', {
                title: 'Are you sure you want to remove this method of payment?',
                onConfirm: async () => {
                  alert.hide();
                  try {
                    await onRemovePaymentMethodClick({ paymentMethod: id });
                  } catch (e) {
                    notifyDanger(e);
                  }
                },
                confirmBtnText: 'Yes, remove it!',
                confirmBtnBsStyle: 'danger',
              });
            }}
            size="sm"
            color="danger"
            className="btn-round"
            disabled={isDefault}
          >
            Delete
          </Button>
        </div>
        {isDefault && (
          <UncontrolledTooltip target={removeButtonId}>Cannot remove default payment method.</UncontrolledTooltip>
        )}
      </>
    );
  };

  const renderPrimaryMethod = (paymentMethod, isPrimaryMethod) => {
    return (
      <FormGroup check style={{ textAlign: 'center' }}>
        <Label check>
          <Input
            type="checkbox"
            disabled={isPrimaryMethod}
            checked={isPrimaryMethod}
            onChange={async () => {
              await alert.showWarning('You sure you want to make this the default payment method?', {
                title: 'Confirmation',
                onConfirm: async () => {
                  alert.hide();
                  try {
                    await onAssignDefaultPaymentMethodClick({ paymentMethod });
                  } catch (e) {
                    notifyDanger(e);
                  }
                },
                confirmBtnText: 'Yes, change it!',
              });
            }}
          />
          <span className="form-check-sign" />
        </Label>
      </FormGroup>
    );
  };

  const rows = paymentMethods.map((method) => {
    let expiredText;
    let statusColor;

    if (method.expired) {
      expiredText = (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div>
            <Badge color="secondary">Expired</Badge>
          </div>
        </div>
      );
    } else {
      expiredText = (
        <>
          <span className="text-muted">*****</span> {method.last4Digits}
        </>
      );
    }

    switch (method.paymentMethodStatus) {
      case PaymentMethodStatus.Active:
        statusColor = 'primary';
        break;
      case PaymentMethodStatus.Removed:
      case PaymentMethodStatus.Invalid:
        statusColor = 'danger';
        break;
      default:
        throw new Error('Payment method status not supported');
    }

    return {
      billingMethod: method.type === 'card' ? 'Card' : 'Direct Debit',
      accountNo: expiredText,
      expiry: method.expMonth && `${method.expMonth}/${method.expYear}`,
      status: (
        <div>
          <div>
            <Badge color={statusColor}>{method.paymentMethodStatus}</Badge>
          </div>
        </div>
      ),
      isPrimaryMethod: renderPrimaryMethod(method.id, method.defaultPaymentMethod),
      actions: <div>{renderRemoveButton(method.id, method.defaultPaymentMethod)}</div>,
    };
  });

  const cardRef = useRef();
  const onAddPaymentMethod = async () => {
    const values = await cardRef.current.getValues();
    if (values.paymentType === PaymentMethod.BacsDirectDebit) {
      alert.hide();
    } else if (values.isValid) {
      try {
        await onAddPaymentMethodClick({ paymentMethod: values.paymentMethod.id });
        alert.hide();
      } catch (e) {
        alert.hide();
        notifyDanger(e);
      }
    } else {
      await alert.showError('Invalid name or card number. Try again');
      showAddPaymentMethodAlert(values.name);
    }
  };

  const renderPaymentButtons = () => {
    return (
      <>
        <button
          type="button"
          onClick={alert.hide}
          className="btn btn-sm btn-outline-primary"
          style={{ marginRight: '8px' }}
        >
          Cancel
        </button>
        <AsyncButton
          type="button"
          className="btn btn-sm btn-primary"
          onClick={onAddPaymentMethod}
          style={{
            marginRight: '8px',
            color: 'white',
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgba(0, 0, 0, 0.075) 0px 1px 1px inset, rgb(165, 202, 234) 0px 0px 8px',
          }}
        >
          Add
        </AsyncButton>
      </>
    );
  };

  const showAddPaymentMethodAlert = (cardHoldersName) => {
    return alert.setAlert(
      <ReactBSAlert
        customButtons={renderPaymentButtons()}
        title="Add Payment Method"
        showCancel
        confirmBtnText="Add"
        onConfirm={onAddPaymentMethod}
        onCancel={alert.hide}
        closeOnClickOutside={false}
      >
        <AddPaymentMethodWrapper
          cardHolderName={cardHoldersName}
          ref={cardRef}
          fetchBacsCheckoutId={fetchBacsCheckoutId}
        />
      </ReactBSAlert>
    );
  };

  return (
    <Card className="floating-table">
      <CardHeader>
        <CardTitle className="d-flex justify-content-between">
          <h2 className="mb-0">Payment methods</h2>
          <Button
            size="sm"
            className={classNames('btn-round', { 'd-none': !onAddPaymentMethodClick })}
            onClick={() => showAddPaymentMethodAlert(defaultCardholdersName)}
          >
            Add Method
          </Button>
        </CardTitle>
      </CardHeader>
      <CardBodyWithSpinner isLoading={isLoading}>
        <ReactTable
          manual
          data={rows}
          pages={1}
          onFetchData={fetchPaymentMethods}
          columns={[
            {
              Header: <h3>Type</h3>,
              accessor: 'billingMethod',
            },
            {
              Header: <h3>Account No</h3>,
              accessor: 'accountNo',
            },
            {
              Header: <h3>Expiry</h3>,
              accessor: 'expiry',
              show: !isMobile(),
            },
            {
              Header: <h3>Status</h3>,
              accessor: 'status',
            },
            {
              Header: <h3>Primary</h3>,
              accessor: 'isPrimaryMethod',
            },
            {
              Header: <h3>Actions</h3>,
              accessor: 'actions',
              sortable: false,
              filterable: false,
              maxWidth: 160,
              minWidth: 160,
            },
          ]}
          defaultPageSize={5}
          minRows={0}
          className="table -highlight floating-table"
          minHeight={300}
          getTrGroupProps={() => ({
            style: {
              maxHeight: '50px',
            },
          })}
          showPagination={false}
        />
      </CardBodyWithSpinner>
    </Card>
  );
};

UserMemberBilling.propTypes = {
  fetchPaymentMethods: PropTypes.func.isRequired,
  onAddPaymentMethodClick: PropTypes.func,
  fetchBacsCheckoutId: PropTypes.func.isRequired,
  onRemovePaymentMethodClick: PropTypes.func.isRequired,
  onAssignDefaultPaymentMethodClick: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      expired: PropTypes.bool.isRequired,
      last4Digits: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      expMonth: PropTypes.number,
      expYear: PropTypes.number,
      defaultPaymentMethod: PropTypes.bool.isRequired,
    })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  defaultCardholdersName: PropTypes.string.isRequired,
};

export default UserMemberBilling;
