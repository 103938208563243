import { z } from "zod";

export const RuleName = {
  CancellationWindow: "EVENT_CANCELLATION_WINDOW",
  BookingWindow: "BOOKING_LIMIT_WINDOW",
  UserFormRequirementConfig: "USER_FORM_REQUIREMENT_CONFIG",
  NoShowFee: "NO_SHOW_FEE",
} as const;

export const RuleNameZodSchema = z.enum([
  RuleName.CancellationWindow,
  RuleName.BookingWindow,
  RuleName.UserFormRequirementConfig,
  RuleName.NoShowFee,
]);
