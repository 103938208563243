import { utcToZonedTime, zonedTimeToUtc } from "@gymflow/helpers";
import { CalendarEventOccurrence } from "@gymflow/types";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Optional } from "utility-types";

import { calendarQueryKeys } from "./calendarQueryKeys";

export function useInfiniteQueryCalendarEventOccurrences(
  {
    api,
    tz,
    filters,
  }: {
    api: {
      eventApi: {
        calendarEventOccurrences: ({
          filters,
        }: {
          filters: CalendarEventOccurrenceFilter;
        }) => Promise<{ data: CalendarEventOccurrenceResult }>;
      };
    };
    tz: string;
    filters: Optional<CalendarEventOccurrenceFilter, "dateFrom" | "dateTo">;
  },
  opts: { enabled?: boolean } = { enabled: true },
) {
  return useInfiniteQuery({
    queryKey: calendarQueryKeys.eventOccurrences({ tz, filters }),
    queryFn: async ({ pageParam }) => {
      const result = await api.eventApi.calendarEventOccurrences({
        filters: createApiParams({
          filters: {
            ...(filters as CalendarEventOccurrenceFilter),
            nextPageToken: pageParam,
          },
          tz,
        }),
      });
      return result.data;
    },
    getNextPageParam: (lastPage) => lastPage.nextPageToken,
    select: (data) => calendarEventOccurrencesSelectFn(data, tz),
    enabled:
      opts.enabled &&
      !!tz &&
      (!!filters?.["dateFrom"] || !!filters?.["dateTo"]),
  });
}

const createApiParams = ({
  filters,
  tz,
}: {
  filters: CalendarEventOccurrenceFilter;
  tz: string;
}) => {
  const parsed: { dateFrom?: string; dateTo?: string } = {};

  if (filters?.["dateFrom"]) {
    parsed["dateFrom"] = zonedTimeToUtc(filters["dateFrom"], tz);
  }

  if (filters?.["dateTo"]) {
    parsed["dateTo"] = zonedTimeToUtc(filters["dateTo"], tz);
  }

  return {
    ...filters,
    ...parsed,
  };
};

export function calendarEventOccurrencesSelectFn(
  data: {
    pages: CalendarEventOccurrenceResult[];
    pageParams: unknown[];
  },
  tz: string,
) {
  return {
    ...data,
    pages: data.pages.map((page) =>
      page.content.map((eventOccurrence) => ({
        ...eventOccurrence,
        startDate: utcToZonedTime(eventOccurrence.startDate, tz),
        endDate: utcToZonedTime(eventOccurrence.endDate, tz),
      })),
    ),
  };
}

interface CalendarEventOccurrenceFilter {
  dateFrom: string;
  dateTo: string;
  limit: number;
  nextPageToken?: string;
  includeBookedCounts?: boolean;
  includeWaitingCounts?: boolean;
  eventHostId?: string[];
  facilityId?: number[];
  activityId?: number[];
  isPublicEvent?: boolean;
  activityCategoryId?: number[];
}

interface CalendarEventOccurrenceResult {
  content: CalendarEventOccurrence[];
  nextPageToken?: string;
}
