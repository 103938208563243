import { DurationType } from "@gymflow/common";
import z from "zod";

import { AddonSchema } from "./AddonSchema";

export const PrepaidMembershipSchema = z.object({
  name: z
    .string()
    .min(3, "Must be at least 3 characters")
    .max(100, "Must be shorter than 100 characters")
    .default(""),
  description: z
    .string()
    .max(500, "Must be shorter than 500 characters")
    .optional(),
  "upfront-addons": z.array(AddonSchema).default([]),
  "default-price": z.coerce.number({
    invalid_type_error: "Must include price",
    required_error: "Must include price",
  }),
  "is-public": z.boolean().default(false),
  "duration-type": z.enum([
    DurationType.Daily,
    DurationType.Weekly,
    DurationType.Monthly,
    DurationType.NoContract,
  ]),
  duration: z.number(),
  "is-trial": z.literal(false),
  "terms-conditions": z.object(
    { name: z.string() },
    {
      invalid_type_error: "Terms and Conditions are required",
      required_error: "Terms and Conditions are required",
    },
  ),
});

export type PrepaidMembershipType = z.infer<typeof PrepaidMembershipSchema>;
export type PrepaidMembershipSchemaKeys = keyof z.infer<
  typeof PrepaidMembershipSchema
>;
