import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";

import { Button, MembershipSelect, SlideSideBar } from "../atoms";

export interface MembershipPausingFilters {
  membershipIncluded?: { id: number; name: string }[];
  membershipExcluded?: { id: number; name: string }[];
}

export interface MembershipPausingFiltersSideBarProps {
  isVisible: boolean;
  onClose: () => void;
  value: MembershipPausingFilters;
  onChange: (newFilters: MembershipPausingFilters) => void;
}

export const MembershipPausingFilterSidebar: React.FC<
  MembershipPausingFiltersSideBarProps
> = ({ value, onChange, isVisible, onClose }) => {
  const [newFilters, setNewFilters] = useState<MembershipPausingFilters>(value);

  useEffect(() => {
    setNewFilters(value);
  }, [value]);

  const selectedIds = useMemo(() => {
    let ids: number[] = [];
    if (newFilters?.membershipIncluded) {
      ids = ids.concat(newFilters.membershipIncluded.map(({ id }) => id));
    }

    if (newFilters?.membershipExcluded) {
      ids = ids.concat(newFilters.membershipExcluded.map(({ id }) => id));
    }
    return ids;
  }, [newFilters.membershipIncluded, newFilters.membershipExcluded]);

  return (
    <SlideSideBar isOpen={isVisible} hide={onClose}>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="flex-1 overflow-y-auto">
          <div className="flex flex-col justify-between border-b border-gray-200 px-6 py-4">
            <div className="mb-1 flex flex-row items-center justify-between">
              <div className="text-xl font-semibold text-gray-900">Filters</div>
              <FontAwesomeIcon
                onClick={() => {
                  onClose();
                }}
                className="cursor-pointer text-xl text-gray-600"
                icon={faClose}
              />
            </div>
            <div className="text-sm font-medium text-gray-600">
              Apply filters to table data
            </div>
          </div>

          <div className="flex flex-col px-6">
            <div className="mb-2 mt-6 flex text-sm font-medium text-gray-700">
              Include Memberships
            </div>
            <div>
              <MembershipSelect
                onChange={(newMembershipOption) => {
                  setNewFilters((previous) => ({
                    ...previous,
                    membershipIncluded: newMembershipOption.map((m) => {
                      return { name: m.label, id: m.value };
                    }),
                  }));
                }}
                value={
                  newFilters?.membershipIncluded
                    ? newFilters.membershipIncluded.map(({ id, name }) => ({
                        label: name,
                        value: id,
                      }))
                    : []
                }
                disableMembershipIds={selectedIds.filter(
                  (id) =>
                    newFilters?.membershipExcluded &&
                    newFilters.membershipExcluded.some((m) => m.id === id),
                )}
                isMulti
              />
            </div>
            <div className="mb-2 mt-6 flex text-sm font-medium text-gray-700">
              Exclude Memberships
            </div>
            <div>
              <MembershipSelect
                onChange={(newMembershipOption) => {
                  setNewFilters((previous) => ({
                    ...previous,
                    membershipExcluded: newMembershipOption.map((m) => {
                      return { name: m.label, id: m.value };
                    }),
                  }));
                }}
                value={
                  newFilters?.membershipExcluded
                    ? newFilters.membershipExcluded.map(({ id, name }) => ({
                        label: name,
                        value: id,
                      }))
                    : []
                }
                disableMembershipIds={selectedIds.filter(
                  (id) =>
                    newFilters?.membershipIncluded &&
                    newFilters.membershipIncluded.some((m) => m.id === id),
                )}
                isMulti
              />
            </div>
          </div>
        </div>
        <div className="flex h-20 flex-row items-center justify-end border-t border-gray-200 px-6">
          <Button onClick={() => onClose()} className="mr-2 w-full">
            Cancel
          </Button>
          <Button
            intent="primary"
            className="w-full"
            onClick={() => {
              if (newFilters) {
                onChange(newFilters);
              }
              onClose();
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </SlideSideBar>
  );
};
