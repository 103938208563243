import {
  EventStatisticField,
  FeatureFlags,
  LeadStatisticField,
  MembershipStatisticField,
  RevenueStatisticField,
} from "@gymflow/types";

import EmptyReportWidgetCard from "../ReportWidgetCard/EmptyReportWidgetCard";
import { ReportEventAttendanceWidget } from "./Widgets/Events/Attendance";
import { ReportEventBookingPercentageWidget } from "./Widgets/Events/BookingPercentage";
import { ReportClassesRunWidget } from "./Widgets/Events/ClassesRun";
import { ReportLateCancellationsWidget } from "./Widgets/Events/LateCancellations";
import { ReportNoShowsWidget } from "./Widgets/Events/NoShows";
import { ReportLeadsClosedWidget } from "./Widgets/Leads/Closed";
import { ReportLeadsClosedRatioWidget } from "./Widgets/Leads/CloseRatio";
import { ReportLeadsCreatedWidget } from "./Widgets/Leads/Created";
import { ReportLeadsLostWidget } from "./Widgets/Leads/Lost";
import { ReportLeadsOpenedWidget } from "./Widgets/Leads/Opened";
import { ReportMembersActiveWidget } from "./Widgets/Members/Active";
import { ReportMembersEndingWidget } from "./Widgets/Members/Ending";
import { ReportMembersSoldWidget } from "./Widgets/Members/MembershipSold";
import { ReportMembersOverdueWidget } from "./Widgets/Members/Overdue";
import { ReportMembersPausedWidget } from "./Widgets/Members/Paused";
import { ReportMembersPausingWidget } from "./Widgets/Members/Pausing";
import { ReportMembersPendingWidget } from "./Widgets/Members/Pending";
import { ReportMembersResumingWidget } from "./Widgets/Members/Resuming";
import { ReportRevenueOtherAccountReceivableWidget } from "./Widgets/Revenue/Other/AccountsReceivable";
import { ReportRevenueOtherScheduleBillingWidget } from "./Widgets/Revenue/Other/ScheduleBilling";
import { ReportRevenueSalesSummaryMembershipWidget } from "./Widgets/Revenue/SalesSummary/Membership";
import { ReportRevenueSalesSummaryProductWidget } from "./Widgets/Revenue/SalesSummary/Product";
import { ReportRevenueSalesSummarySessionWidget } from "./Widgets/Revenue/SalesSummary/Sessions";
import { ReportRevenueSalesSummaryTotalWidget } from "./Widgets/Revenue/SalesSummary/Total";
import { ReportRevenueTransactionDetailsAwaitingAuthWidget } from "./Widgets/Revenue/TransactionDetails/AwaitingAuth";
import { ReportRevenueTransactionDetailsCreditAppliedWidget } from "./Widgets/Revenue/TransactionDetails/CreditApplied";
import { ReportRevenueTransactionDetailsRefundsWidget } from "./Widgets/Revenue/TransactionDetails/Refunds";
import { ReportRevenueTransactionDetailsWriteOffWidget } from "./Widgets/Revenue/TransactionDetails/WriteOff";

export type ReportWidgetProps = {
  [key: string]: ReportWidget;
};

export type ReportWidgetComponent = React.FC<{ parentRoute: string }>;

export type ReportWidget = {
  name: string;
  content: ReportWidgetComponent | ReportWidgetProps;
};

const total = {
  name: "Total Revenue",
  route: ["revenue", "salesSummary", "total"],
  content: ReportRevenueSalesSummaryTotalWidget,
};
const membership = {
  name: "Membership Revenue",
  route: ["revenue", "salesSummary", "membership"],
  content: ReportRevenueSalesSummaryMembershipWidget,
};
const sessions = {
  name: "Credit Packs Revenue",
  route: ["revenue", "salesSummary", "sessions"],
  content: ReportRevenueSalesSummarySessionWidget,
};
const product = {
  name: "Product Revenue",
  route: ["revenue", "salesSummary", "product"],
  content: ReportRevenueSalesSummaryProductWidget,
};
const creditsApplied = {
  name: "Credits Applied",
  route: ["revenue", "transactionDetails", "creditsApplied"],
  content: ReportRevenueTransactionDetailsCreditAppliedWidget,
};
const writeOffs = {
  name: "Write Offs",
  route: ["revenue", "transactionDetails", "writeOffs"],
  content: ReportRevenueTransactionDetailsWriteOffWidget,
};
const refunds = {
  name: "Refunds",
  route: ["revenue", "transactionDetails", "refunds"],
  content: ReportRevenueTransactionDetailsRefundsWidget,
};
const awaitingAuthorization = {
  name: "Awaiting Authorization",
  route: ["revenue", "transactionDetails", "awaitingAuthorization"],
  content: ReportRevenueTransactionDetailsAwaitingAuthWidget,
};

const scheduledBilling = {
  name: "Scheduled Billing",
  route: ["revenue", "other", "scheduledBilling"],
  content: ReportRevenueOtherScheduleBillingWidget,
};
const accountsReceivable = {
  name: "Accounts Receivable",
  route: ["revenue", "other", "accountsReceivable"],
  content: ReportRevenueOtherAccountReceivableWidget,
};

const membershipsSold = {
  name: "Memberships Sold",
  route: ["members", "membershipsSold"],
  content: ReportMembersSoldWidget,
};
const membershipsEnding = {
  name: "Memberships Ending",
  route: ["members", "membershipsEnding"],
  content: ReportMembersEndingWidget,
};
const membershipGrowth = {
  name: "Membership Growth",
  route: ["members", "membershipGrowth"],
  content: EmptyReportWidgetCard,
};
const totalMembership = {
  name: "Total Membership",
  route: ["members", "totalMembership"],
  content: EmptyReportWidgetCard,
};
const activeMemberships = {
  name: "Active Memberships",
  route: ["members", "activeMemberships"],
  content: ReportMembersActiveWidget,
};
const overdueMemberships = {
  name: "Overdue Memberships",
  route: ["members", "overdueMemberships"],
  content: ReportMembersOverdueWidget,
};
const pausedMemberships = {
  name: "Paused Memberships",
  route: ["members", "pausedMemberships"],
  content: ReportMembersPausedWidget,
};
const pausingMemberships = {
  name: "Pausing Memberships",
  route: ["members", "pausingMemberships"],
  content: ReportMembersPausingWidget,
};
const resumingMemberships = {
  name: "Resuming Memberships",
  route: ["members", "resumingMemberships"],
  content: ReportMembersResumingWidget,
};
const pendingMemberships = {
  name: "Pending Memberships",
  route: ["members", "pendingMemberships"],
  content: ReportMembersPendingWidget,
};
const newLeads = {
  name: "New Leads",
  route: ["leads", "newLeads"],
  content: ReportLeadsCreatedWidget,
};
const openLeads = {
  name: "Open Leads",
  route: ["leads", "openLeads"],
  content: ReportLeadsOpenedWidget,
};
const dealsClosed = {
  name: "Deals Closed",
  route: ["leads", "dealsClosed"],
  content: ReportLeadsClosedWidget,
};
const dealsLost = {
  name: "Deals Lost",
  route: ["leads", "dealsLost"],
  content: ReportLeadsLostWidget,
};
const closeRatio = {
  name: "Close Ratio",
  route: ["leads", "closeRatio"],
  content: ReportLeadsClosedRatioWidget,
};
const attendance = {
  name: "Attendance",
  route: ["events", "attendance"],
  content: ReportEventAttendanceWidget,
};
const bookingVsCapacity = {
  name: "Booking Vs Capacity",
  route: ["events", "bookingVsCapacity"],
  content: ReportEventBookingPercentageWidget,
};

const classesRun = {
  name: "Classes Run",
  route: ["events", "classesRun"],
  content: ReportClassesRunWidget,
};

const noShows = {
  name: "No Shows",
  route: ["events", "noShows"],
  content: ReportNoShowsWidget,
};

const lateCancellations = {
  name: "Late Cancellations",
  route: ["events", "lateCancellations"],
  content: ReportLateCancellationsWidget,
};

export function getReportWidgetList(featureFlags: FeatureFlags) {
  return [
    {
      title: "Revenue",
      components: [
        total,
        membership,
        sessions,
        product,
        creditsApplied,
        writeOffs,
        refunds,
        awaitingAuthorization,
        scheduledBilling,
        accountsReceivable,
      ],
    },
    {
      title: "Members",
      components: [
        membershipsSold,
        membershipsEnding,
        /* membershipGrowth, */
        /* totalMembership, */
        activeMemberships,
        overdueMemberships,
        pausedMemberships,
        pendingMemberships,
        pausingMemberships,
        resumingMemberships,
      ],
    },
    {
      title: "Leads",
      components: [newLeads, openLeads, dealsClosed, dealsLost, closeRatio],
    },
    {
      title: "Classes",
      components: [
        attendance,
        bookingVsCapacity,
        classesRun,
        noShows,
        lateCancellations,
      ],
    },
  ];
}

export function getReportWidgetsProps(featureFlags?: FeatureFlags | null) {
  const widgetProps = {
    revenue: {
      name: "Revenue",
      content: {
        salesSummary: {
          name: "Sales Summary",
          content: {
            total,
            membership,
            sessions,
            product,
          },
        },

        transactionDetails: {
          name: "Transaction Details",
          content: {
            creditsApplied,
            writeOffs,
            refunds,
            awaitingAuthorization,
          },
        },
        other: {
          name: "Other",
          content: {
            scheduledBilling,
            accountsReceivable,
          },
        },
      },
    },
    members: {
      name: "Members",
      content: {
        membershipsSold,
        membershipsEnding,
        membershipGrowth,
        totalMembership,
        activeMemberships,
        overdueMemberships,
        pausedMemberships,
        pendingMemberships,
        pausingMemberships,
        resumingMemberships,
      },
    },
    leads: {
      name: "Leads",
      content: {
        newLeads,
        openLeads,
        dealsClosed,
        dealsLost,
        closeRatio,
      },
    },
    events: {
      name: "Classes",
      content: {
        attendance,
        bookingVsCapacity,
        classesRun,
        noShows,
        lateCancellations,
      },
    },
  } as ReportWidgetProps;

  return widgetProps;
}

export const routeToFieldsMap: {
  route: string[];
  fields: {
    revenueFields?: RevenueStatisticField[];
    membershipFields?: MembershipStatisticField[];
    eventFields?: EventStatisticField[];
    leadFields?: LeadStatisticField[];
  } | null;
}[] = [
  {
    route: ["revenue", "salesSummary", "total"],
    fields: {
      revenueFields: ["total"],
    },
  },
  {
    route: ["revenue", "salesSummary", "membership"],
    fields: {
      revenueFields: ["membership"],
    },
  },
  {
    route: ["revenue", "salesSummary", "sessions"],
    fields: {
      revenueFields: ["sessionPack"],
    },
  },
  {
    route: ["revenue", "salesSummary", "product"],
    fields: {
      revenueFields: ["product"],
    },
  },

  {
    route: ["revenue", "transactionDetails", "creditsApplied"],
    fields: {
      revenueFields: ["credits"],
    },
  },
  {
    route: ["revenue", "transactionDetails", "writeOffs"],
    fields: {
      revenueFields: ["writeOff"],
    },
  },
  {
    route: ["revenue", "transactionDetails", "refunds"],
    fields: {
      revenueFields: ["refunds"],
    },
  },
  {
    route: ["revenue", "transactionDetails", "awaitingAuthorization"],
    fields: {
      revenueFields: ["awaitingAuth"],
    },
  },

  {
    route: ["revenue", "other", "scheduledBilling"],
    fields: null,
  },
  {
    route: ["revenue", "other", "accountsReceivable"],
    fields: null,
  },

  {
    route: ["members", "membershipsSold"],
    fields: {
      membershipFields: ["signUps"],
    },
  },
  {
    route: ["members", "membershipsEnding"],
    fields: {
      membershipFields: ["ending"],
    },
  },
  {
    route: ["members", "membershipGrowth"],
    fields: null,
    /* fields: { */
    /*   membershipFields: ["growth"], */
    /* }, */
  },
  {
    route: ["members", "totalMembership"],
    fields: null,
    /* fields: { */
    /*   membershipFields: [ */
    /*     "total", */
    /*     "active", */
    /*     "overdue", */
    /*     "paused", */
    /*     "pending", */
    /*     "ending", */
    /*     "signUps", */
    /*     "growth", */
    /*   ], */
    /* }, */
  },
  {
    route: ["members", "activeMemberships"],
    fields: {
      membershipFields: ["active"],
    },
  },
  {
    route: ["members", "overdueMemberships"],
    fields: {
      membershipFields: ["overdue"],
    },
  },
  {
    route: ["members", "pausedMemberships"],
    fields: {
      membershipFields: ["paused"],
    },
  },
  {
    route: ["members", "pausingMemberships"],
    fields: {
      membershipFields: ["pausing"],
    },
  },
  {
    route: ["members", "resumingMemberships"],
    fields: {
      membershipFields: ["resuming"],
    },
  },
  {
    route: ["members", "pendingMemberships"],
    fields: {
      membershipFields: ["pending"],
    },
  },

  {
    route: ["leads", "newLeads"],
    fields: {
      leadFields: ["created"],
    },
  },
  {
    route: ["leads", "openLeads"],
    fields: {
      leadFields: ["opened"],
    },
  },
  {
    route: ["leads", "dealsClosed"],
    fields: {
      leadFields: ["closed"],
    },
  },
  {
    route: ["leads", "dealsLost"],
    fields: {
      leadFields: ["lost"],
    },
  },
  {
    route: ["leads", "closeRatio"],
    fields: {
      leadFields: ["closeRatio"],
    },
  },

  {
    route: ["events", "attendance"],
    fields: {
      eventFields: ["attendance"],
    },
  },
  {
    route: ["events", "bookingVsCapacity"],
    fields: {
      eventFields: ["bookingPercentage"],
    },
  },
  {
    route: ["events", "classesRun"],
    fields: {
      eventFields: ["classesRun"],
    },
  },
  {
    route: ["events", "noShows"],
    fields: {
      eventFields: ["noShows"],
    },
  },
  {
    route: ["events", "lateCancellations"],
    fields: {
      eventFields: ["lateCancellations"],
    },
  },
];
