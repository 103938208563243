export const Weekday = {
  Sunday: 'SUNDAY',
  Monday: 'MONDAY',
  Tuesday: 'TUESDAY',
  Wednesday: 'WEDNESDAY',
  Thursday: 'THURSDAY',
  Friday: 'FRIDAY',
  Saturday: 'SATURDAY',
};

export const humanizeWeekday = (value: keyof typeof Weekday) =>
  Weekday[value].charAt(0).toUpperCase() +
  Weekday[value].slice(1).toLowerCase();

export const toNumberWeekday = (value?: string) => {
  switch (value) {
    case Weekday.Sunday:
      return 0;
    case Weekday.Monday:
      return 1;
    case Weekday.Tuesday:
      return 2;
    case Weekday.Wednesday:
      return 3;
    case Weekday.Thursday:
      return 4;
    case Weekday.Friday:
      return 5;
    case Weekday.Saturday:
      return 6;
    default:
      throw new Error('Invalid Weekday.');
  }
};
