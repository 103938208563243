import {
  AppointmentPurchaseSummaryDTO,
  AppointmentPurchaseSummaryPostDTO,
} from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { appointmentAsMemberQueryKeys } from "./appointmentAsMemberQueryKeys";

export async function appointmentSummaryAsMemberQueryFn({
  api,
  filter,
}: {
  api: AppointmentSummaryAsMemberApi;
  filter: AppointmentPurchaseSummaryPostDTO;
}) {
  const result = await api.customerAppointmentApi.summary(filter);
  return result.data;
}

export function useAppointmentSummaryAsMember({
  api,
  opts,
}: {
  api: AppointmentSummaryAsMemberApi;
  opts: AppointmentPurchaseSummaryPostDTO;
}) {
  const result = useQuery({
    queryKey: appointmentAsMemberQueryKeys.summary(opts),
    initialData: null,
    queryFn: () => appointmentSummaryAsMemberQueryFn({ api, filter: opts }),
  });
  return result;
}

interface AppointmentSummaryAsMemberApi {
  customerAppointmentApi: {
    summary: (
      params: AppointmentPurchaseSummaryPostDTO,
    ) => Promise<{ data: AppointmentPurchaseSummaryDTO }>;
  };
}
