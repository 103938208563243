import { cn } from "@gymflow/helpers";
import classNames from "classnames";

export function Checkbox({
  className,
  onChange,
  value,
}: {
  className?: string;
  onChange: (newValue: boolean) => void;
  value: boolean;
}) {
  return (
    <div className={cn("flex h-6 items-center", className)}>
      <input
        type="checkbox"
        className={classNames(
          "text-primary-600 focus:ring-primary-600 dark:bg-darkModeFill dark:border-darkGray-700 h-4 w-4 border-gray-300",
          {
            "!bg-primary-600 !border-primary-600": value,
          },
        )}
        checked={value}
        onChange={({ currentTarget: { checked } }) => {
          onChange(checked);
        }}
      />
    </div>
  );
}
