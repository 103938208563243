import {
  ExpiryType,
  PARAMETER_DATE_FORMAT,
  ServiceStatus,
  tzDateTimeStringToUtc,
  useParseErrors,
  useUserFormFieldConfiguration,
} from "@gymflow/common";
import { formatCurrency, pluralize } from "@gymflow/helpers";
import { useStoreState } from "easy-peasy";
import capitalize from "lodash/capitalize";
import { useEffect } from "react";

import useProcessSaleStaff from "../../hooks/useProcessSaleStaff";
import useSessionPacks from "../../hooks/useSessionPacks";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import SellSessionPackAccordion from "./SellSessionPackAccordion";
import useStaffOnUserSubmit from "./useStaffOnUserSubmit";

const sort = { field: "price,name,id", asc: true };
function SellSessionPack() {
  const { api, MemberStore, settingsStore } = useGymflowModels();
  const settings = useClubSettings();
  const dateFormat = settings.date_format;
  const clubId = settings.clubId;
  const { defaultCurrency: currency, timezone } = useStoreState(settingsStore);
  const { fetchList: fetchSessionPacks, rows: sessionPacks } =
    useSessionPacks();
  const { fetchPaymentMethods } = MemberStore.useStoreActions(
    (actions) => actions,
  );
  const { processExistingMemberCreditPack } = useProcessSaleStaff();
  const onUserSubmit = useStaffOnUserSubmit({
    create: api.memberApi.create,
    update: api.memberApi.update,
    clubId,
  });
  const parseError = useParseErrors();
  const { data: requiredFields } = useUserFormFieldConfiguration({
    api,
    clubId,
  });

  useEffect(() => {
    fetchSessionPacks({
      status: ServiceStatus.Active,
      sort,
      extraParams: { unpaged: true },
    });
  }, []);

  return (
    <div className="content h-full overflow-y-auto p-8">
      <SellSessionPackAccordion
        sessionPacks={sessionPacks
          .sort((a, b) => a.price - b.price)
          .map(
            ({
              id,
              name,
              description,
              price,
              sessionsIncluded,
              sessionsUnlimited,
              expiryValue,
              expiryType,
              termsConditions,
            }) => ({
              id,
              name,
              description,
              price: formatCurrency(price, currency),
              sessionsIncluded: sessionsUnlimited
                ? `Unlimited Credits`
                : `${sessionsIncluded} ${pluralize(
                    "Credit",
                    "Credits",
                    sessionsIncluded,
                  )}`,
              expiry:
                expiryType == ExpiryType.NA
                  ? ""
                  : `${expiryValue} ${capitalize(
                      ExpiryType.format(expiryType, expiryValue > 2),
                    )} Expiry`,
              termsAndConditionsLink: termsConditions,
            }),
          )}
        searchUsers={api.memberApi.globalSearch}
        currency={currency}
        fetchLeadSources={() =>
          api.leadSourceApi.find({ extraParams: { activeSource: true } })
        }
        fetchPaymentMethods={({ userMemberId }) =>
          fetchPaymentMethods({ userMemberId, clubId })
        }
        dateFormat={dateFormat}
        summary={api.creditPackApi.summary}
        onUserSubmit={onUserSubmit}
        purchase={async (purchaseValues) => {
          try {
            await processExistingMemberCreditPack(purchaseValues.id, clubId, {
              paymentMethod: purchaseValues.paymentMethod,
              sessionPackId: purchaseValues.cart[0].id,
              promotionCode: purchaseValues.promoCode,
              startDate: tzDateTimeStringToUtc(
                purchaseValues.startDate,
                timezone,
                PARAMETER_DATE_FORMAT,
              ),
            });
          } catch (e) {
            parseError(e.response);
          }
        }}
        requiredFields={requiredFields}
      />
    </div>
  );
}

function SellSessionPackWithProviders(props) {
  const { CreditPackStore, MemberStore } = useGymflowModels();

  return (
    <CreditPackStore.Provider>
      <MemberStore.Provider>
        <SellSessionPack {...props} />
      </MemberStore.Provider>
    </CreditPackStore.Provider>
  );
}

export default SellSessionPackWithProviders;
