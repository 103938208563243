import { utcToZonedTime } from "@gymflow/helpers";
import { AppointmentDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { cloneDeep } from "lodash";
import { Mutable } from "utility-types";

import { appointmentQueryKeys } from "./appointmentQueryKeys";

export async function appointmentQueryFn({
  appointmentId,
  api,
}: {
  appointmentId: number;
  api: AppointmentApi;
}) {
  const result = await api.appointmentApi.findById(appointmentId);
  return result.data;
}

export function appointmentSelectFn(data: AppointmentDTO | null, tz: string) {
  if (!data) return null;
  const utcData: Mutable<AppointmentDTO> = cloneDeep(data);
  utcData.startDate = utcToZonedTime(data.startDate, tz);
  utcData.endDate = utcToZonedTime(data.endDate, tz);

  utcData.noteList = utcData.noteList.map(({ createdDate, ...rest }) => {
    return { ...rest, createdDate: utcToZonedTime(createdDate, tz) };
  });

  return utcData;
}

export function useAppointment(
  {
    api,
    appointmentId,
    tz,
  }: {
    api: AppointmentApi;
    appointmentId?: number;
    tz?: string;
  },
  opts?: UseQueryOptions<AppointmentDTO | null>,
) {
  const result = useQuery({
    queryKey: appointmentQueryKeys.details(appointmentId),
    queryFn: () => {
      return appointmentQueryFn({
        appointmentId: appointmentId as number,
        api,
      });
    },
    select: (data) => appointmentSelectFn(data, tz as string),
    enabled: !!tz && !!appointmentId,
    initialData: null,
    ...opts,
  });

  return result;
}

interface AppointmentApi {
  appointmentApi: {
    findById: (arg0: number) => Promise<{ data: AppointmentDTO }>;
  };
}
