import axios from "axios";
import { assert } from "check-types";

import endpoints from "./endpoints";
import {
  canCheckIfEmailExists,
  canCreate,
  canFind,
  canFindById,
  canTrimFields,
} from "./features/apiResource";
import canSignUp from "./features/canSignUp";

const userMemberEndpoints = {
  createUserFullDetails: `${endpoints.userMembers}/full`,
  findByEmail: `${endpoints.userMembers}/email/`,
  catalogEmails: "catalog/user/email",
  findByFullName: "catalog/user/search",
  findGlobal: "catalog/global/search",
  resumeEarlySubscription: `${endpoints.userMembers}/:memberId/membership/resume-early/:subscriptionId`,
  calculateProRata: `${endpoints.userMembers}/:memberId/calculate-prorata-resume-subscription/:subscriptionId`,
  expireSubscription: `${endpoints.userMembers}/:memberId/membership/expire/:subscriptionId`,
  changeSubscriptionPrice: `${endpoints.userMembers}/:memberId/change-subscription-price`,
  pauseSubscription: `${endpoints.userMembers}/:memberId/membership/pause/:subscriptionId`,
  cancelPauseSubscription: `${endpoints.userMembers}/:memberId/membership/unschedule-pause/:subscriptionId`,
  cancelSubscription: `${endpoints.userMembers}/:memberId/membership/cancel/:subscriptionId`,
  updatePendingDate: `${endpoints.userMembers}/:memberId/membership/update-pending-date/:subscriptionId`,
  revokeCancellation: `${endpoints.userMembers}/:memberId/membership/revoke-cancellation/:subscriptionId`,
  updateCredits: `${endpoints.userMembers}/:memberId/membership/update-sessions/:subscriptionId`,
  changeSubscription: `${endpoints.userMembers}/:memberId/change-subscription`,
  calculatePriceChangeSubscription: `${endpoints.userMembers}/:memberId/calculate-price-change-subscription/:membershipId`,
  paymentMethods: `${endpoints.userMembers}/:memberId/payment/methods`,
  attachPaymentMethod: `${endpoints.userMembers}/:memberId/payment/attach`,
  attachPaymentMethodBacs: `${endpoints.userMembers}/:memberId/payment/attach-bacs`,
  removePaymentMethod: `${endpoints.userMembers}/:memberId/payment/remove`,
  defaultPaymentMethod: `${endpoints.userMembers}/:memberId/payment/default`,
  findInvoices: `${endpoints.userMembers}/:memberId/invoices`,
  creditInvoice: `${endpoints.userMembers}/:memberId/credit-invoice/`,
  debitInvoice: `${endpoints.userMembers}/:memberId/debit-invoice/`,
  collectInvoice: `${endpoints.userMembers}/:memberId/collect-invoice/`,
  refundInvoice: `${endpoints.userMembers}/:memberId/refund-invoice/`,
  writeOffInvoice: `${endpoints.userMembers}/:memberId/write-off-invoice/`,
  invoiceFile: `${endpoints.userMembers}/:memberId/invoice-file/:invoiceNumber`,
  overdueMembersTotal: `${endpoints.userMembers}/past-due/total`,
  membershipOverdue: `${endpoints.userMembers}/membership-overdue`,
  membershipEnding: `${endpoints.userMembers}/membership-ending`,
  membershipEndingCsv: `${endpoints.userMembers}/membership-ending/csv`,
  membershipPending: `${endpoints.userMembers}/membership-pending`,
  membershipPaused: `${endpoints.userMembers}/membership-paused`,
  eventAttendance: `${endpoints.eventOccurrence}/rsvp/attendance`,
  eventAttendanceCsv: `${endpoints.eventOccurrence}/rsvp/attendance/csv`,
  eventAttendanceTotal: `${endpoints.eventOccurrence}/rsvp/attendance/total`, // TODO: remove
  classesRun: `${endpoints.eventOccurrence}/classes-run`,
  classesRunCsv: `${endpoints.eventOccurrence}/classes-run/csv`,
  noShows: `${endpoints.eventOccurrence}/no-shows`,
  noShowsCsv: `${endpoints.eventOccurrence}/no-shows/csv`,
  lateCancellations: `${endpoints.eventOccurrence}/late-cancellations`,
  lateCancellationsCsv: `${endpoints.eventOccurrence}/late-cancellations/csv`,
  memberStatusList: `${endpoints.userMembers}/member-status`,
  memberStatusListCsv: `${endpoints.userMembers}/member-status/csv`,
  changeBillingDate: `${endpoints.userMembers}/change-subscription-billing-date/:subscriptionId`,
  assignStaff: `${endpoints.userMembers}/:memberId/assign-staff/:staffId`,
  membershipSold: `${endpoints.userMembers}/membership-sold`,
  membershipSoldCsv: `${endpoints.userMembers}/membership-sold/csv`,
  membershipPausing: `${endpoints.userMembers}/membership-pausing`,
  membershipPausingCsv: `${endpoints.userMembers}/membership-pausing/csv`,
  membershipResuming: `${endpoints.userMembers}/membership-resuming`,
  membershipResumingCsv: `${endpoints.userMembers}/membership-resuming/csv`,
  accountDelete: `${endpoints.userMembers}/:memberId/account-delete`,
};

const userMemberApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: [
      "firstName",
      "lastName",
      "mobileNumber",
      "dateBirth",
      "postCode",
      "addressLine1",
      "addressLine2",
      "city",
      "email",
      "emergencyContact",
      "emergencyContactName",
      "username",
    ],
    baseUrl: `${clubPrefix}${endpoints.userMembers}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(
    state,
    canTrimFields(state),
    canFind(state),
    canFindById(state),
    canCheckIfEmailExists(state, clubId),
    canSignUp(state),
    {
      update(id, clubId, patchedFields) {
        assert.string(id, "id must be string");
        assert.number(clubId, "clubId must be a number");
        return state.http.patch(
          `${state.baseUrl}/${id}`,
          state.createRequestBody(state.trimFields(patchedFields)),
        );
      },
      checkout(id, clubId, patchedFields) {
        assert.string(id, "id must be string");
        assert.number(clubId, "clubId must be a number");
        return state.http.patch(
          `${state.baseUrl}/${id}`,
          state.trimFields(patchedFields),
        );
      },
      findByEmail(email) {
        assert.string(email, "email must be a string");

        return state.http
          .get(
            `${clubPrefix}${
              userMemberEndpoints.findByEmail
            }${email.toLowerCase()}`,
          )
          .catch((err) => {
            if (err.response.status === 404) {
              return null;
            }
            throw err;
          });
      },

      findByFullName(term, { page = 0, limit = 10 }) {
        assert.string(term, "term must be a string");

        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.findByFullName}`,
          {
            params: { q: term, page, size: limit },
          },
        );
      },

      catalogEmails(email) {
        assert.string(email, "email must be a string");

        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.catalogEmails}`,
          { params: { q: email } },
        );
      },

      globalSearch(term, fields, size = 5) {
        assert.string(term, "term must be a string");

        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.findGlobal}`,
          { params: { q: term, fields, size } },
        );
      },

      create(newResource) {
        const trimmedData = state.trimFields(newResource);

        return state.http.post(
          `${clubPrefix}${userMemberEndpoints.createUserFullDetails}`,
          state.createRequestBody(trimmedData),
        );
      },

      createWithMinimumDetails(newMember) {
        return canCreate(state).create.bind(state)(
          state.createRequestBody(newMember),
        );
      },

      async updatePicture(id, { blob, name }) {
        const blobResponse = await axios.get(blob, {
          responseType: "blob",
        });
        const formData = new FormData();
        formData.append("file", blobResponse.data, name);
        return state.http.post(`${state.baseUrl}/${id}/picture`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      },

      membershipOverdue({ page = 0, limit = 10, extraParams = {}, sort }) {
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");

        const config = {
          params: {
            page,
            size: limit,
            ...extraParams,
          },
        };
        if (sort) {
          assert.string(
            sort.field,
            "sort.field must be a string if sort is specified.",
          );
          config.params.sort = state.createSortParam(sort.field, sort.desc);
        }

        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.membershipOverdue}`,
          config,
        );
      },

      membershipOverdueTotal() {
        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.membershipOverdueTotal}`,
        );
      },

      membershipPaused({ page = 0, limit = 10, extraParams = {}, sort }) {
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");

        const config = {
          params: {
            page,
            size: limit,
            ...extraParams,
          },
        };
        if (sort) {
          assert.string(
            sort.field,
            "sort.field must be a string if sort is specified.",
          );
          config.params.sort = state.createSortParam(sort.field, sort.desc);
        }

        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.membershipPaused}`,
          config,
        );
      },

      calculateProRata(memberId, subscriptionId, resumeDate) {
        assert.string(memberId, "memberId has to be specified");
        assert.number(subscriptionId, "subscriptionId has to be specified");
        assert.string(resumeDate, "startDate has to be specified");
        const url = `${clubPrefix}${userMemberEndpoints.calculateProRata}`
          .replace(":memberId", memberId)
          .replace(":subscriptionId", subscriptionId);
        return state.http.get(url, { params: { resumeDate } });
      },

      resumeSubscriptionEarly(
        memberId,
        subscriptionId,
        resumeDate,
        isWaived,
        paymentMethodId,
      ) {
        assert.string(memberId, "memberId has to be specified");
        assert.number(subscriptionId, "subscriptionId has to be specified");
        assert.string(resumeDate, "resumeDate has to be specified");

        const url =
          `${clubPrefix}${userMemberEndpoints.resumeEarlySubscription}`
            .replace(":memberId", memberId)
            .replace(":subscriptionId", subscriptionId);

        const body = { isWaived, earlyPauseEndDate: resumeDate };
        if (!isWaived) {
          body.paymentMethodId = paymentMethodId;
        }
        return state.http.post(url, body);
      },

      membershipEnding({
        startDate,
        endDate,
        page = 0,
        limit = 10,
        extraParams = {},
        sort,
        membershipIncluded,
        membershipExcluded,
        membershipType,
      }) {
        assert.string(startDate, "startDate has to be specified");
        assert.string(endDate, "endDate has to be specified");
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");
        assert.maybe.array(
          membershipIncluded,
          "membershipIncluded must be array or undefined",
        );
        assert.maybe.array(
          membershipExcluded,
          "membershipExcluded must be array or undefined",
        );
        assert.maybe.string(
          membershipType,
          "membershipType must be string or undefined",
        );

        const config = {
          params: {
            dateFrom: startDate,
            dateTo: endDate,
            page,
            size: limit,
            membershipIncluded,
            membershipExcluded,
            membershipType,
            sort: ["subscriptionId"],
            ...extraParams,
          },
        };
        if (sort) {
          assert.string(
            sort.field,
            "sort.field must be a string if sort is specified.",
          );
          config.params.sort.push(state.createSortParam(sort.field, sort.desc));
        }

        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.membershipEnding}`,
          config,
        );
      },

      membershipEndingCsv(filters) {
        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.membershipEndingCsv}`,
          {
            params: filters,
          },
        );
      },

      membershipResuming({
        startDate,
        endDate,
        page = 0,
        limit = 10,
        extraParams = {},
        sort,
        membershipIncluded,
        membershipExcluded,
        membershipType,
      }) {
        assert.string(startDate, "startDate has to be specified");
        assert.string(endDate, "endDate has to be specified");
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");
        assert.maybe.array(
          membershipIncluded,
          "membershipIncluded must be array or undefined",
        );
        assert.maybe.array(
          membershipExcluded,
          "membershipExcluded must be array or undefined",
        );
        assert.maybe.string(
          membershipType,
          "membershipType must be string or undefined",
        );

        const config = {
          params: {
            dateFrom: startDate,
            dateTo: endDate,
            page,
            size: limit,
            membershipIncluded,
            membershipExcluded,
            membershipType,
            sort: ["subscriptionId"],
            ...extraParams,
          },
        };
        if (sort) {
          assert.string(
            sort.field,
            "sort.field must be a string if sort is specified.",
          );
          config.params.sort.push(state.createSortParam(sort.field, sort.desc));
        }

        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.membershipResuming}`,
          config,
        );
      },

      membershipResumingCsv(filters) {
        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.membershipResumingCsv}`,
          {
            params: filters,
          },
        );
      },

      membershipPausing({
        startDate,
        endDate,
        page = 0,
        limit = 10,
        extraParams = {},
        sort,
        membershipIncluded,
        membershipExcluded,
        membershipType,
      }) {
        assert.string(startDate, "startDate has to be specified");
        assert.string(endDate, "endDate has to be specified");
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");
        assert.maybe.array(
          membershipIncluded,
          "membershipIncluded must be array or undefined",
        );
        assert.maybe.array(
          membershipExcluded,
          "membershipExcluded must be array or undefined",
        );
        assert.maybe.string(
          membershipType,
          "membershipType must be string or undefined",
        );

        const config = {
          params: {
            dateFrom: startDate,
            dateTo: endDate,
            page,
            size: limit,
            membershipIncluded,
            membershipExcluded,
            membershipType,
            sort: ["subscriptionId"],
            ...extraParams,
          },
        };
        if (sort) {
          assert.string(
            sort.field,
            "sort.field must be a string if sort is specified.",
          );
          config.params.sort.push(state.createSortParam(sort.field, sort.desc));
        }

        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.membershipPausing}`,
          config,
        );
      },

      membershipPausingCsv(filters) {
        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.membershipPausingCsv}`,
          {
            params: filters,
          },
        );
      },

      membershipPending({ page = 0, limit = 10, extraParams = {}, sort }) {
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");

        const config = {
          params: {
            page,
            size: limit,
            ...extraParams,
          },
        };
        if (sort) {
          assert.string(
            sort.field,
            "sort.field must be a string if sort is specified.",
          );
          config.params.sort = state.createSortParam(sort.field, sort.desc);
        }

        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.membershipPending}`,
          config,
        );
      },

      eventAttendance({
        startDate,
        endDate,
        gt,
        lt,
        eq,
        membership,
        page = 0,
        limit = 10,
        extraParams = {},
        sort,
      }) {
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");

        const config = {
          params: {
            dateFrom: startDate,
            dateTo: endDate,
            gt,
            lt,
            eq,
            membership,
            page,
            size: limit,
            ...extraParams,
          },
        };
        if (sort) {
          assert.string(
            sort.field,
            "sort.field must be a string if sort is specified.",
          );
          config.params.sort = state.createSortParam(sort.field, sort.desc);
        }

        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.eventAttendance}`,
          config,
        );
      },

      eventAttendanceCsv(filters) {
        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.eventAttendanceCsv}`,
          {
            params: filters,
          },
        );
      },

      classesRun({
        startDate,
        endDate,
        activityId,
        eventHostId,
        page = 0,
        limit = 10,
        sort,
      }) {
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");
        assert.maybe.array(sort, "sort must be an array");

        const config = {
          params: {
            dateFrom: startDate,
            dateTo: endDate,
            activityId,
            eventHost: eventHostId,
            page,
            size: limit,
          },
        };
        if (sort) {
          const parsedSort = sort.reduce((acc, v) => {
            assert.string(
              v.field,
              "sort.field must be a string if sort is specified.",
            );
            acc.push(state.createSortParam(v.field, v.desc));
            return acc;
          }, []);
          config.params.sort = parsedSort;
        }

        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.classesRun}`,
          config,
        );
      },

      classesRunCsv(filters) {
        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.classesRunCsv}`,
          {
            params: filters,
          },
        );
      },

      noShows({ startDate, endDate, page = 0, limit = 10, sort }) {
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");
        assert.maybe.array(sort, "sort must be an array");

        const config = {
          params: {
            dateFrom: startDate,
            dateTo: endDate,
            page,
            size: limit,
          },
        };
        if (sort) {
          const parsedSort = sort.reduce((acc, v) => {
            assert.string(
              v.field,
              "sort.field must be a string if sort is specified.",
            );
            acc.push(state.createSortParam(v.field, v.desc));
            return acc;
          }, []);
          config.params.sort = parsedSort;
        }

        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.noShows}`,
          config,
        );
      },

      noShowsCsv(filters) {
        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.noShowsCsv}`,
          {
            params: filters,
          },
        );
      },

      lateCancellations({ startDate, endDate, page = 0, limit = 10, sort }) {
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");
        assert.maybe.array(sort, "sort must be an array");

        const config = {
          params: {
            dateFrom: startDate,
            dateTo: endDate,
            page,
            size: limit,
          },
        };
        if (sort) {
          const parsedSort = sort.reduce((acc, v) => {
            assert.string(
              v.field,
              "sort.field must be a string if sort is specified.",
            );
            acc.push(state.createSortParam(v.field, v.desc));
            return acc;
          }, []);
          config.params.sort = parsedSort;
        }

        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.lateCancellations}`,
          config,
        );
      },

      lateCancellationsCsv(filters) {
        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.lateCancellationsCsv}`,
          {
            params: filters,
          },
        );
      },

      // TODO: Remove
      eventAttendanceTotal(startDate, endDate) {
        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.eventAttendanceTotal}`,
          {
            params: {
              dateFrom: startDate,
              dateTo: endDate,
            },
          },
        );
      },

      memberStatusList({ page = 0, limit = 10, extraParams = {}, sort }) {
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");

        const config = {
          params: {
            page,
            size: limit,
            ...extraParams,
          },
        };
        if (sort) {
          assert.string(
            sort.field,
            "sort.field must be a string if sort is specified.",
          );
          config.params.sort = state.createSortParam(sort.field, sort.desc);
        }

        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.memberStatusList}`,
          config,
        );
      },

      memberStatusListCsv(filters) {
        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.memberStatusListCsv}`,
          {
            params: filters,
          },
        );
      },

      fetchPaymentMethods(memberId, clubId) {
        assert.string(memberId, "memberId must be a string");
        assert.number(clubId, "clubId must be a number");

        const url =
          `${clubPrefix}${userMemberEndpoints.paymentMethods}`.replace(
            ":memberId",
            memberId,
          );
        return state.http.get(url);
      },

      attachPaymentMethodBacs(memberId, clubId) {
        assert.string(memberId, "memberId must be a string");
        assert.number(clubId, "clubId must be a number");

        const url =
          `${clubPrefix}${userMemberEndpoints.attachPaymentMethodBacs}`.replace(
            ":memberId",
            memberId,
          );
        return state.http.post(url, {
          headers: { "Content-Type": "text/plain" },
        });
      },

      attachPaymentMethod(memberId, clubId, paymentMethod) {
        assert.string(memberId, "memberId must be a string");
        assert.number(clubId, "clubId must be a number");

        const url =
          `${clubPrefix}${userMemberEndpoints.attachPaymentMethod}`.replace(
            ":memberId",
            memberId,
          );
        return state.http.post(url, paymentMethod, {
          headers: { "Content-Type": "text/plain" },
        });
      },

      removePaymentMethod(memberId, clubId, paymentMethod) {
        assert.string(memberId, "memberId must be a string");
        assert.number(clubId, "clubId must be a number");

        const url =
          `${clubPrefix}${userMemberEndpoints.removePaymentMethod}`.replace(
            ":memberId",
            memberId,
          );
        return state.http.post(url, paymentMethod, {
          headers: { "Content-Type": "text/plain" },
        });
      },

      assignDefaultPaymentMethod(memberId, clubId, paymentMethod) {
        assert.string(memberId, "memberId must be a string");
        assert.number(clubId, "clubId must be a number");

        const url =
          `${clubPrefix}${userMemberEndpoints.defaultPaymentMethod}`.replace(
            ":memberId",
            memberId,
          );
        return state.http.put(url, paymentMethod, {
          headers: { "Content-Type": "text/plain" },
        });
      },

      expireSubscription(memberId, subscriptionId, expiredDate) {
        const url = `${clubPrefix}${userMemberEndpoints.expireSubscription}`
          .replace(":memberId", memberId)
          .replace(":subscriptionId", subscriptionId);

        const body = {};
        if (expiredDate) {
          body.expirationDateTime = expiredDate;
        }

        return state.http.patch(url, body);
      },

      pauseSubscription(
        memberId,
        subscriptionId,
        immediately,
        pauseDate,
        resumeDate,
      ) {
        const url = `${clubPrefix}${userMemberEndpoints.pauseSubscription}`
          .replace(":memberId", memberId)
          .replace(":subscriptionId", subscriptionId);

        const body = {
          pauseEndDate: resumeDate,
        };

        if (immediately) {
          body.effectiveImmediately = immediately;
        } else {
          body.pauseStartDate = pauseDate;
        }

        return state.http.post(url, body);
      },

      cancelPauseSubscription(memberId, subscriptionId) {
        const url =
          `${clubPrefix}${userMemberEndpoints.cancelPauseSubscription}`
            .replace(":memberId", memberId)
            .replace(":subscriptionId", subscriptionId);

        return state.http.post(url);
      },

      cancelSubscription(memberId, subscriptionId, immediately, when) {
        const url = `${clubPrefix}${userMemberEndpoints.cancelSubscription}`
          .replace(":memberId", memberId)
          .replace(":subscriptionId", subscriptionId);

        const body = {};
        if (immediately) {
          body.effectiveImmediately = true;
        } else if (when) {
          body.cancellationDate = when;
        }

        return state.http.post(url, body);
      },

      updatePendingDate(memberId, subscriptionId, immediately, when) {
        const url = `${clubPrefix}${userMemberEndpoints.updatePendingDate}`
          .replace(":memberId", memberId)
          .replace(":subscriptionId", subscriptionId);

        const body = {};
        if (immediately) {
          body.startSubscriptionEffectiveImmediately = true;
        } else if (when) {
          body.startSubscriptionEffectiveImmediately = false;
          body.pendingStartDate = when;
        }

        return state.http.post(url, body);
      },

      revokeCancellation(memberId, subscriptionId) {
        const url = `${clubPrefix}${userMemberEndpoints.revokeCancellation}`
          .replace(":memberId", memberId)
          .replace(":subscriptionId", subscriptionId);

        return state.http.post(url);
      },

      changeSubscription(
        clubId,
        memberId,
        newMembershipId,
        paymentMethod,
        isWaived = false,
      ) {
        assert.number(clubId, "clubId must be a number");
        assert.string(memberId, "memberId must be a string");
        assert.number(newMembershipId, "newMembershipId must be a number");
        assert.string(paymentMethod, "paymentMethod must be a string");
        assert.boolean(isWaived, "isWaived must  be a boolean");

        const url =
          `${clubPrefix}${userMemberEndpoints.changeSubscription}`.replace(
            ":memberId",
            memberId,
          );

        const body = {
          paymentMethod,
          subscriptionSignUpBean: {
            isWaived,
            membershipId: newMembershipId,
          },
        };

        return state.http.post(url, body);
      },

      adjustSessionPack(memberId, subscriptionId, newAmount) {
        assert.string(memberId, "memberId must be a string");
        assert.number(subscriptionId, "subscriptionId must be a string");
        assert.number(newAmount, "newAmount must be a number");

        const url = `${clubPrefix}${userMemberEndpoints.updateCredits}`
          .replace(":memberId", memberId)
          .replace(":subscriptionId", subscriptionId);

        return state.http.patch(url, { newAmount });
      },

      calculateChangeSubscriptionPrice(memberId, membershipId) {
        assert.string(memberId, "memberId has to be specified");
        assert.number(membershipId, "membershipId has to be specified");
        const url =
          `${clubPrefix}${userMemberEndpoints.calculatePriceChangeSubscription}`
            .replace(":memberId", memberId)
            .replace(":membershipId", membershipId);
        return state.http.get(url);
      },

      changeSubscriptionPrice(
        clubId,
        memberId,
        subscriptionId,
        newSubscriptionPrice,
      ) {
        assert.number(clubId, "clubId must be a number");
        assert.string(memberId, "memberId must be a string");
        assert.number(subscriptionId, "subscriptionId has to be specified");
        assert.number(
          newSubscriptionPrice,
          "newSubscriptionPrice must be a number",
        );
        const url =
          `${clubPrefix}${userMemberEndpoints.changeSubscriptionPrice}`.replace(
            ":memberId",
            memberId,
          );
        return state.http.patch(url, { subscriptionId, newSubscriptionPrice });
      },

      findInvoices(
        userMemberId,
        { page = 0, limit = 10, extraParams = {}, sort },
      ) {
        const url = `${clubPrefix}${userMemberEndpoints.findInvoices}`.replace(
          ":memberId",
          userMemberId,
        );

        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");

        const config = {
          params: {
            page,
            size: limit,
            ...extraParams,
          },
        };
        if (sort) {
          assert.string(
            sort.field,
            "sort.field must be a string if sort is specified.",
          );
          config.params.sort = state.createSortParam(sort.field, sort.desc);
        }

        return state.http.get(url, config);
      },

      creditInvoice(userMemberId, clubId, invoiceNumber, amount) {
        assert.string(userMemberId, "userMemberId must be a string");
        assert.number(clubId, "clubId must be a number");
        assert.string(invoiceNumber, "invoiceNumber must be a string");
        assert.number(amount, "amount must be a number");

        const url =
          `${clubPrefix}${userMemberEndpoints.creditInvoice}`.replace(
            ":memberId",
            userMemberId,
          ) + invoiceNumber;
        return state.http.post(url, {
          amountToCredit: amount,
        });
      },

      debitInvoice(userMemberId, clubId, invoiceNumber, amount) {
        assert.string(userMemberId, "userMemberId must be a string");
        assert.number(clubId, "clubId must be a number");
        assert.string(invoiceNumber, "invoiceNumber must be a string");
        assert.number(amount, "amount must be a number");

        const url =
          `${clubPrefix}${userMemberEndpoints.debitInvoice}`.replace(
            ":memberId",
            userMemberId,
          ) + invoiceNumber;
        return state.http.post(url, {
          amountToDebit: amount,
        });
      },

      collectInvoice(userMemberId, clubId, invoiceNumber, amount) {
        assert.string(userMemberId, "userMemberId must be a string");
        assert.number(clubId, "clubId must be a number");
        assert.string(invoiceNumber, "invoiceNumber must be a string");
        assert.number(amount, "amount must be a number");

        const url =
          `${clubPrefix}${userMemberEndpoints.collectInvoice}`.replace(
            ":memberId",
            userMemberId,
          ) + invoiceNumber;
        return state.http.post(url, {
          amountToPay: amount,
        });
      },

      refundInvoice(userMemberId, clubId, invoiceNumber) {
        assert.string(userMemberId, "userMemberId must be a string");
        assert.number(clubId, "clubId must be a number");
        assert.string(invoiceNumber, "invoiceNumber must be a string");

        const url =
          `${clubPrefix}${userMemberEndpoints.refundInvoice}`.replace(
            ":memberId",
            userMemberId,
          ) + invoiceNumber;

        return state.http.post(url);
      },

      writeOffInvoice(userMemberId, clubId, invoiceNumber) {
        assert.string(userMemberId, "userMemberId must be a string");
        assert.number(clubId, "clubId must be a number");
        assert.string(invoiceNumber, "invoiceNumber must be a string");

        const url =
          `${clubPrefix}${userMemberEndpoints.writeOffInvoice}`.replace(
            ":memberId",
            userMemberId,
          ) + invoiceNumber;

        return state.http.post(url);
      },

      invoiceFile(userMemberId, clubId, invoiceNumber) {
        assert.string(userMemberId, "userMemberId must be a string");
        assert.number(clubId, "clubId must be a number");
        assert.string(invoiceNumber, "invoiceNumber must be a string");

        const url = `${clubPrefix}${userMemberEndpoints.invoiceFile}`
          .replace(":memberId", userMemberId)
          .replace(":invoiceNumber", invoiceNumber);

        return state.http.get(url);
      },

      changeBillingDate(clubId, subscriptionId, newDate) {
        const url =
          `${clubPrefix}${userMemberEndpoints.changeBillingDate}`.replace(
            ":subscriptionId",
            subscriptionId,
          );

        const body = {
          changeDate: newDate,
        };

        return state.http.patch(url, body);
      },
      changeContractEndDate(subscriptionId, newDate) {
        assert.number(subscriptionId, "subscriptionId must be a number");
        assert.string(newDate, "newDate must be a string");

        const url = `${clubPrefix}user/member/change-subscription-contract-date/${subscriptionId}`;
        return state.http.patch(url, { changeDate: newDate });
      },
      assignStaff(memberId, staffId) {
        assert.string(memberId, "memberId must be a string");
        assert.string(staffId, "staffId must be a string");

        const url = `${clubPrefix}${userMemberEndpoints.assignStaff}`
          .replace(":memberId", memberId)
          .replace(":staffId", staffId);
        return state.http.put(url);
      },

      unassignStaff(memberId, staffId) {
        assert.string(memberId, "memberId must be a string");
        assert.string(staffId, "staffId must be a string");

        const url = `${clubPrefix}${userMemberEndpoints.assignStaff}`
          .replace(":memberId", memberId)
          .replace(":staffId", staffId);
        return state.http.delete(url);
      },

      membershipSold({
        startDate,
        endDate,

        membershipIncluded,
        membershipExcluded,
        page = 0,
        limit = 10,
        sort,
      }) {
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");

        const config = {
          params: {
            dateFrom: startDate,
            dateTo: endDate,
            membershipIncluded,
            membershipExcluded,
            page,
            size: limit,
          },
        };
        if (sort) {
          assert.string(
            sort.field,
            "sort.field must be a string if sort is specified.",
          );
          config.params.sort = state.createSortParam(sort.field, sort.desc);
        }

        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.membershipSold}`,
          config,
        );
      },

      membershipSoldCsv(filters) {
        return state.http.get(
          `${clubPrefix}${userMemberEndpoints.membershipSoldCsv}`,
          {
            params: filters,
          },
        );
      },
      accountDelete(memberId, clubId) {
        assert.string(memberId, "memberId must be a string");
        assert.number(clubId, "clubId must be a number");

        const url = `${clubPrefix}${userMemberEndpoints.accountDelete}`.replace(
          ":memberId",
          memberId,
        );
        return state.http.post(url);
      },
      updateEmailAndPassword(memberId, payload) {
        assert.string(memberId, "memberId must be a string");

        const url = `${clubPrefix}user/member/${memberId}/account`;
        return state.http.patch(url, payload);
      },
      updateHomeClub(memberId, clubId) {
        assert.string(memberId, "memberId must be a string");
        assert.number(clubId, "clubId must be a number");

        const url = `${clubPrefix}user/member/${memberId}/new-club/${clubId}`;
        return state.http.put(url);
      },
      membershipLinkMember(primarySubscriptionId, secondaryMemberId) {
        assert.number(
          primarySubscriptionId,
          "primarySubscriptionId must be a number",
        );
        assert.string(secondaryMemberId, "secondaryMemberId must be a string");

        const url = `${clubPrefix}user/member/${secondaryMemberId}/link-subscription/${primarySubscriptionId}`;
        return state.http.put(url);
      },
      listMembershipLinkedMembers(primarySubscriptionId) {
        assert.number(primarySubscriptionId, "memberId must be a string");

        const url = `${clubPrefix}user/member/membership/${primarySubscriptionId}/linked-members`;
        return state.http.get(url);
      },
      listNew(params) {
        return state.http.get(`${clubPrefix}user-member-list`, { params });
      },
      listNewCount(params) {
        return state.http.get(`${clubPrefix}user-member-list/count`, {
          params,
        });
      },
      listNewCsv(params) {
        return state.http.get(`${clubPrefix}user-member-list/csv`, {
          params,
          headers: {
            "Content-Disposition": "attachment; filename=users.csv",
          },
        });
      },
    },
  );
};

userMemberApi.endpoints = userMemberEndpoints;

export default userMemberApi;
