import "react-multi-carousel/lib/styles.css";

import { useQueryClubFeatureFlagsAsPublic } from "@gymflow/api";
import { AlertProvider, NotificationProvider, theme } from "@gymflow/common";
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";

import CustomStyleContext from "../../components/CustomStyle";
import Footer from "../../components/Footer/Footer";
import { useHtmlNodeDarkMode } from "../../hooks";
import useGoogleTagManager from "../../hooks/useGoogleTagManager";
import useHostedPagesStyle from "../../hooks/useHostedPagesStyle";
import useLoadClubSettings from "../../hooks/useLoadClubSettings";
import { useSiteInjectCss } from "../../hooks/useSiteInjectCss";
import {
  ApiResolverProvider,
  AuthenticationSupportedProvider,
  ClubSettingsAsPublicProvider,
  ModalProvider,
  useApiResolverContext,
  useClubSettings,
  YupExtensions,
} from "../../providers";
import {
  generateSiteRoutes,
  generateSiteWithAuthRoutes,
  RouteFeatureSite,
} from "../../routes";
import { defaultSiteRoute as defaultRoute } from "../../routes/default";
import useGymflowModels from "../../store";
import { SiteWithAuth } from "./SiteWithAuth";

function Site(props) {
  useHtmlNodeDarkMode({ supportDarkMode: false });
  const settings = useClubSettings();
  const match = useRouteMatch();
  const { api } = useGymflowModels();
  const { data: featureFlags } = useQueryClubFeatureFlagsAsPublic({
    api,
    clubId: settings.clubId,
  });

  const clubId = settings.clubId;
  const { customStyle, hostedStylesLoaded } = useHostedPagesStyle({
    clubId,
    useInjectCss: useSiteInjectCss,
  });

  const clubSettingsLoaded = useLoadClubSettings({ clubId });

  const getRoutes = (routes) => {
    const nodes = routes.map((prop) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      return (
        <Route path={match.path + prop.path} key={prop.path}>
          {(props) => <prop.component {...props} />}
        </Route>
      );
    });
    nodes.push(
      <Route key={"default" + nodes.length}>
        <Redirect to={match.url + defaultRoute} />
      </Route>,
    );
    return nodes;
  };

  useGoogleTagManager();

  const isStripeEnabled =
    settings.stripe_account_type === "STANDARD" || "CONNECTED";
  const isNotAvailableRoute = matchPath(
    match.url + RouteFeatureSite.NotAvailable,
    window.location.pathname,
  );
  if (!isStripeEnabled && !isNotAvailableRoute) {
    return <Redirect to={match.url + RouteFeatureSite.NotAvailable} />;
  }

  if (!hostedStylesLoaded || !clubSettingsLoaded) {
    return null;
  }

  return (
    <div className="wrapper site-content flex h-screen w-full">
      <ThemeProvider theme={theme}>
        <CustomStyleContext.Provider value={customStyle}>
          <NotificationProvider>
            <AlertProvider>
              <div className="main-panel flex h-full w-full flex-col overflow-y-auto">
                <div className="my-3 flex items-center justify-center">
                  <img
                    src={customStyle?.logo || "/public/img/gymflow_brand.svg"}
                    alt="brand-logo"
                    style={{ maxWidth: "300px", maxHeight: "100px" }}
                  />
                </div>
                <Switch>{getRoutes(generateSiteRoutes(featureFlags))}</Switch>
                {
                  // we don't want the Footer to be rendered on full screen maps page
                  props.location.pathname.indexOf("full-screen-map") !==
                  -1 ? null : (
                    <Footer fluid />
                  )
                }
              </div>
            </AlertProvider>
          </NotificationProvider>
        </CustomStyleContext.Provider>
      </ThemeProvider>
    </div>
  );
}

function SiteWithStore({ ...props }) {
  const { clubId } = useApiResolverContext();
  const { PublicHostedClubStore } = useGymflowModels();

  return (
    <ClubSettingsAsPublicProvider clubId={clubId} disableClubColorCustomization>
      <PublicHostedClubStore.Provider>
        <YupExtensions>
          <Site {...props} />
        </YupExtensions>
      </PublicHostedClubStore.Provider>
    </ClubSettingsAsPublicProvider>
  );
}

function SiteWithApiResolver(props) {
  const { clubId, authController } = useApiResolverContext();
  const match = useRouteMatch();
  const location = useLocation();
  const { api } = useGymflowModels();
  const { data: featureFlags } = useQueryClubFeatureFlagsAsPublic({
    api,
    clubId: clubId,
  });
  const routesWithAuth = generateSiteWithAuthRoutes(featureFlags);
  const isRouteWithAuth = routesWithAuth.some((r) =>
    location.pathname.startsWith(match.url + r.path),
  );
  return isRouteWithAuth ? (
    <AuthenticationSupportedProvider authController={authController}>
      <ClubSettingsAsPublicProvider clubId={clubId}>
        <NotificationProvider>
          <ModalProvider>
            <SiteWithAuth />
          </ModalProvider>
        </NotificationProvider>
      </ClubSettingsAsPublicProvider>
    </AuthenticationSupportedProvider>
  ) : (
    <SiteWithStore {...props} />
  );
}

function SiteWithClub(props) {
  return (
    <ApiResolverProvider userType="PUBLIC">
      <SiteWithApiResolver {...props} />
    </ApiResolverProvider>
  );
}

export default SiteWithClub;
