/* eslint-disable react/prop-types */
import {
  membershipListQueryFn,
  useMemberCalculateProRataForChangingSubscription,
  useMemberPaymentMethodList,
  useMemberSubscription,
} from "@gymflow/api";
import {
  NotificationContext,
  PaymentConfirmationStatus,
  useParseErrors,
  usePaymentAuthorizationAlert,
} from "@gymflow/common";
import { formatCurrency, membershipHelper } from "@gymflow/helpers";
import {
  InvoiceStatus,
  Membership,
  UserMemberSubscriptionBeanWithMembership,
} from "@gymflow/types";
import { ModalContext, useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import React, { ReactNode, useCallback, useContext, useState } from "react";

import { PaginatedSelect } from "../../../atoms";
import { ConfirmModal } from "../../../templates";
import { NotPrimaryMembershipWarning } from "../NotPrimaryMembershipWarning";

export interface ChangeMembershipModalProps {
  memberId: string;
  membership: UserMemberSubscriptionBeanWithMembership;
}

export const ChangeMembershipModal: React.FC<ChangeMembershipModalProps> = ({
  memberId,
  membership,
}) => {
  const { timezone, clubId, defaultCurrency } = useClubSettings();
  const [newMembershipId, setNewMembershipId] = useState<number>();
  const { api } = useGymflowModels();
  const { data: prorata } = useMemberCalculateProRataForChangingSubscription({
    api,
    memberId,
    membershipId: newMembershipId,
  });
  const { data: paymentMethods } = useMemberPaymentMethodList({
    api,
    clubId,
    memberId,
  });
  const defaultPaymentMethod = paymentMethods?.find(
    (pm) => pm.defaultPaymentMethod,
  );

  const parseError = useParseErrors();

  const { hide } = useContext(ModalContext);

  const { changeSubscriptionMutation } = useMemberSubscription(
    {
      api,
      tz: timezone,
    },
    {
      onError: (error) => {
        parseError((error as any)?.response);
      },
    },
  );
  const { notify, notifyDanger } = useContext(NotificationContext);
  const { show: showPaymentConfirmationAlert } = usePaymentAuthorizationAlert();
  const checkForScaResponse = useCallback(
    async ({
      status,
      paymentIntentIdForAuthorization,
    }: {
      status: InvoiceStatus;
      paymentIntentIdForAuthorization: string;
    }) => {
      if (status === "AWAITING_AUTHORIZATION") {
        const paymentConfirmationResult = await showPaymentConfirmationAlert({
          paymentIntentIdForAuthorization,
          confirmPayment: api.strongCustomerAuthorizationApi.confirmPayment,
          messageText: (
            <>
              The user must authorize this payment before it will be processed.
              <br />
              Please ask the user to authorize the payment by clicking the link
              sent to their email.
            </>
          ),
        });

        if (
          paymentConfirmationResult.status === PaymentConfirmationStatus.Waiting
        ) {
          notify({
            message: "Awaiting payment, check later.",
            type: "warning",
          });
        } else if (
          paymentConfirmationResult.status === PaymentConfirmationStatus.Failed
        ) {
          notify({ message: "Payment Failed.", type: "danger" });
        }
      }
    },
    [
      api.strongCustomerAuthorizationApi.confirmPayment,
      notify,
      showPaymentConfirmationAlert,
    ],
  );
  const changeSubscriptionByUser = useCallback(
    async (isWaived: boolean) => {
      if (!newMembershipId || !defaultPaymentMethod?.id) return;
      const result = await changeSubscriptionMutation.mutateAsync({
        isWaived,
        paymentMethodId: defaultPaymentMethod?.id,
        newMembershipId,
        memberId,
        clubId,
      });
      await checkForScaResponse({
        status: result.data?.invoice?.status,
        paymentIntentIdForAuthorization:
          result.data?.invoice?.paymentIntentIdForAuthorization,
      });
    },
    [
      changeSubscriptionMutation,
      checkForScaResponse,
      clubId,
      defaultPaymentMethod?.id,
      memberId,
      newMembershipId,
    ],
  );
  const [newMembership, setNewMembership] = useState<Membership>();
  return (
    <ConfirmModal
      title="Change Membership"
      type="default"
      onHide={async () => {
        hide();
      }}
      confirmText={
        prorata !== null && prorata !== undefined && prorata > 0
          ? "Collect"
          : "Confirm"
      }
      onConfirm={async () => {
        if (!defaultPaymentMethod) {
          notifyDanger("Member does not have a default payment method.");
          return;
        }
        if (prorata !== null && prorata !== undefined && prorata > 0) {
          await changeSubscriptionByUser(false);
        } else {
          await changeSubscriptionByUser(true);
        }
        hide();
      }}
      cancelText={
        prorata !== null && prorata !== undefined && prorata > 0
          ? "Waive"
          : "Cancel"
      }
      onCancel={async () => {
        if (prorata !== null && prorata !== undefined && prorata > 0) {
          await changeSubscriptionByUser(true);
        }
        hide();
      }}
    >
      <div className="flex flex-col gap-4">
        <div>Select Membership</div>
        <PaginatedSelect
          onChange={(v: { label: ReactNode; value: Membership }) => {
            setNewMembershipId(v.value.id);
            setNewMembership(v.value);
          }}
          value={
            newMembership
              ? {
                  label: newMembership?.name,
                  value: newMembershipId,
                }
              : undefined
          }
          placeholder="Select a membership"
          isSearchable
          loadOptions={async (searchParam, _, { page }) => {
            const memberships = await membershipListQueryFn({
              api,
              filter: {
                page,
                extraParams: { name: searchParam, status: "ACTIVE" },
              },
            });

            return {
              options: memberships.content
                .filter((e) => e.id !== membership?.membershipBean.id)
                .filter((e) => e.type === "RECURRING")
                .filter((e) =>
                  searchParam
                    ? e.name.toLowerCase().includes(searchParam.toLowerCase())
                    : true,
                )
                ?.map((e) => ({
                  label: (
                    <div className="flex flex-col justify-start gap-1">
                      <div className="text-sm font-medium">{e.name}</div>
                      <div className="text-xs text-gray-500">
                        {`${formatCurrency(
                          e.defaultPrice,
                          defaultCurrency,
                        )} ${membershipHelper.getBillingRecurrence(e)}`}
                      </div>
                    </div>
                  ),
                  value: e,
                })),
              hasMore: !memberships.last,
              additional: {
                page: page + 1,
              },
            };
          }}
        />
        {prorata !== undefined && prorata !== null && prorata > 0 && (
          <div className="text-error-600">
            There is an upgrade cost due of{" "}
            {formatCurrency(prorata, defaultCurrency)}.
          </div>
        )}
        <NotPrimaryMembershipWarning membership={membership} />
      </div>
    </ConfirmModal>
  );
};
