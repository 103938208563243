import { useAbility } from "@casl/react";
import {
  clubStaleTime,
  useClub,
  useEventOccurrenceDelete,
  useEventOccurrenceRsvp,
} from "@gymflow/api";
import { AlertContext, NotificationContext } from "@gymflow/common";
import {
  isAfter,
  iso8601WithoutTz,
  pluralize,
  zonedTime,
} from "@gymflow/helpers";
import { EventRsvpDTO } from "@gymflow/types";
import classNames from "classnames";
import { useContext } from "react";
import { useHistory } from "react-router";

import useSendEmails from "../../../hooks/useSendEmails";
import { AbilityContext, Subject, Verb } from "../../../permissions";
import { ModalContext, useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { Button, PrimaryButton, TextButton } from "../../atoms";
import { ConfirmModal } from "../../templates";
import SendEmailAlert from "../../UserMember/SendEmails/SendEmailAlert";
import { SideBarOccurrenceFormProviderContext } from "../SideBarOccurrence/SideBarOccurrenceFormProvider";
import { RecurrentCancelModal } from "./RecurrentCancelModal";

export function EventOccurrenceButtons({
  occurrenceId,
  rsvpList = [],
  isBookable,
  isRecurring,
  startDate,
}: {
  occurrenceId?: number;
  rsvpList: EventRsvpDTO[];
  isBookable: boolean;
  isRecurring: boolean;
  startDate?: string;
}) {
  const { openEdit } = useContext(SideBarOccurrenceFormProviderContext);

  const ability = useAbility(AbilityContext);

  const { sendEmails } = useSendEmails();
  const recipients = rsvpList
    .filter(({ status }) => status === "BOOKED" || status === "ATTENDED")
    .map(({ userMember }) => ({
      userMemberId: userMember.id,
    }));

  const { api } = useGymflowModels();
  const { checkInAll } = useEventOccurrenceRsvp({ api });

  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { data: club } = useClub({ clubId, api }, { staleTime: clubStaleTime });

  const { notifyDanger } = useContext(NotificationContext);
  const cancelOccurrence = useEventOccurrenceDelete({ api });

  const { setAlert, hide: hideAlert } = useContext(AlertContext);
  const { setModal, hide: hideModal } = useContext(ModalContext);
  const history = useHistory();

  const canBeCancelled =
    startDate &&
    club?.timezone &&
    isAfter(startDate, zonedTime(club.timezone), iso8601WithoutTz);

  return (
    <div className="flex flex-row-reverse gap-x-3 border border-gray-200 p-4">
      <PrimaryButton
        className="mt-0"
        disabled={!occurrenceId}
        onClick={() => {
          if (occurrenceId) {
            openEdit(occurrenceId);
          }
        }}
      >
        Edit Class
      </PrimaryButton>
      <Button
        className={classNames("mt-0", { hidden: !isBookable })}
        disabled={!rsvpList.some((rsvp) => rsvp.status === "BOOKED")}
        onClick={async () => {
          try {
            await checkInAll.mutateAsync({ occurrenceId: occurrenceId! });
          } catch (e) {
            notifyDanger(e);
          }
        }}
      >
        Check In All
      </Button>
      <Button
        className={classNames("mt-0", {
          hidden: !ability.can(Verb.Create, Subject.Email),
        })}
        disabled={!club || !occurrenceId || recipients.length === 0}
        onClick={() => {
          if (club) {
            setAlert(
              <SendEmailAlert
                allowMarketing
                from={club.email}
                to={`${recipients.length} ${pluralize(
                  "User",
                  "Users",
                  recipients.length,
                )}`}
                onSubmit={(values: any) => {
                  const bcc = values.bcc ? values.bcc.split(",") : [];
                  return sendEmails(
                    values.subject,
                    values.body,
                    recipients,
                    values.marketing,
                    bcc,
                  );
                }}
                onCancel={hideAlert}
              />,
            );
          }
        }}
      >
        Email All
      </Button>
      <TextButton
        disabled={!occurrenceId}
        className={classNames("!text-error-700", {
          hidden: !canBeCancelled,
        })}
        onClick={() => {
          if (isRecurring) {
            setModal(
              <RecurrentCancelModal
                onConfirm={async (cancelAll) => {
                  try {
                    await cancelOccurrence.mutateAsync({
                      occurrenceId: occurrenceId!,
                      isDeleteAll: cancelAll,
                    });
                    hideModal();
                    history.goBack();
                  } catch (e) {
                    notifyDanger(e);
                  }
                }}
                onCancel={() => {
                  hideModal();
                }}
              />,
            );
          } else {
            setModal(
              <ConfirmModal
                title="Cancel confirmation"
                onConfirm={async () => {
                  try {
                    await cancelOccurrence.mutateAsync({
                      occurrenceId: occurrenceId!,
                      isDeleteAll: false,
                    });
                    history.goBack();
                  } catch (e) {
                    notifyDanger(e);
                  }
                  hideModal();
                }}
                onCancel={() => {
                  hideModal();
                }}
              >
                Are you sure you want to cancel this event?
              </ConfirmModal>,
            );
          }
        }}
      >
        Cancel Class
      </TextButton>
    </div>
  );
}
