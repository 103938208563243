import { cn } from "@gymflow/helpers";
import { PaymentMethodDTO } from "@gymflow/types";
import React from "react";

import { Checkbox, PlusIcon } from "../atoms";

export type PaymentMethodPickerProps = {
  paymentMethods: PaymentMethodDTO[];
  currentPaymentMethodId?: string;
  onChange: (newValue?: string) => void;
  showAddPaymentMethodAlert: () => void;
  allowEmpty?: boolean;
};

export const PaymentMethodPicker: React.FC<PaymentMethodPickerProps> = ({
  paymentMethods,
  currentPaymentMethodId,
  onChange,
  showAddPaymentMethodAlert,
  allowEmpty,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col">
        <div className="dark:text-darkGray-200 text-sm font-semibold">
          Card details
        </div>
        <div className="dark:text-darkGray-200 text-sm font-normal">
          Select or add a payment method
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {paymentMethods?.map((paymentMethod) => {
          return (
            <div
              className={cn(
                "hover:bg-primary-25 cursor-pointer rounded-lg border border-gray-200 p-4 transition-all duration-200 ease-in-out",
                {
                  "ring-primary-500 border-transparent ring":
                    paymentMethod.id === currentPaymentMethodId,
                },
              )}
              onClick={() => {
                onChange(paymentMethod.id);
              }}
              key={paymentMethod.id}
            >
              <div className="flex items-center justify-between">
                <div className="text-sm font-bold text-gray-700">
                  Card ending in {paymentMethod.last4Digits}
                </div>
                <div>
                  <Checkbox
                    onChange={() => {
                      onChange(paymentMethod.id);
                    }}
                    value={currentPaymentMethodId === paymentMethod.id}
                  />
                </div>
              </div>
              <div className="text-sm font-normal text-gray-600">
                Expiry {paymentMethod.expMonth}/{paymentMethod.expYear}
              </div>
            </div>
          );
        })}
        {allowEmpty && (
          <div
            className={cn(
              "hover:bg-primary-25 cursor-pointer rounded-lg border border-gray-200 p-4 transition-all duration-200 ease-in-out",
              {
                "ring-primary-500 border-transparent ring":
                  currentPaymentMethodId === undefined,
              },
            )}
            onClick={() => {
              onChange(undefined);
            }}
          >
            <div className="flex items-center justify-between">
              <div className="text-sm font-semibold text-gray-600">
                No Payment Method
              </div>
              <div>
                <Checkbox
                  onChange={() => {
                    onChange(undefined);
                  }}
                  value={currentPaymentMethodId === undefined}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className="flex cursor-pointer gap-2"
        onClick={showAddPaymentMethodAlert}
      >
        <div>
          <PlusIcon pathClassName="stroke-primary-600" />
        </div>
        <div className="text-primary-600 text-sm font-semibold">
          Add new payment method
        </div>
      </div>
    </div>
  );
};
