import {
  getMembershipPrice,
  hasEnoughCredits,
  PARAMETER_DATE_FORMAT,
  ServiceStatus,
  ServiceType,
  tzDateTimeStringToUtc,
  useParseErrors,
  useUserFormFieldConfiguration,
} from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import { useStoreState } from "easy-peasy";
import PropTypes from "prop-types";
import qs from "qs";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Card, CardBody } from "reactstrap";

import { hasActiveMembershipSubscription } from "../../helpers/userMember";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import useProcessSaleCustomer from "../../hooks/useProcessSaleCustomer";
import useMemberships from "../../hooks/usePublicMemberships";
import { useClubSettings } from "../../providers";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import useGymflowModels from "../../store";
import { BuyMembershipAccordion } from "./BuyMembershipAccordion";
import BuyRoute from "./BuyRoute";

function BuyMembership({
  membershipType,
  fetchPaymentMethods,
  userDetails,
  userMemberId,
  isMiniUser,
  subscriptions,
  findByEmail,
  cardHoldersName,
  event,
}) {
  const { createClubLink } = usePortalRoutes();
  const { api, settingsStore } = useGymflowModels();
  const settings = useClubSettings();
  const dateFormat = settings.date_format;
  const clubId = settings.clubId;
  const defaultNationality = settings.default_nationality;
  const postCodeFormat = settings.postal_code_country;
  const telephoneFormat = useMemo(
    () => JSON.parse(settings.phone_number_country),
    [settings.phone_number_country],
  );
  const { defaultCurrency: currency, timezone } = useStoreState(settingsStore);
  const { fetchList: fetchMemberships, rows: memberships } = useMemberships();
  const { processExistingMemberMembership, processNewMemberMembership } =
    useProcessSaleCustomer({
      userMemberId,
      event,
    });
  const parseError = useParseErrors();
  const history = useHistory();
  const search = qs.parse(history?.location?.search, {
    ignoreQueryPrefix: true,
  });
  const [initialCart, setInitialCart] = useState([]);

  const { data: requiredFields } = useUserFormFieldConfiguration({
    clubId,
    api: {
      ruleApi: api.public.ruleApi,
    },
  });

  const outerMembershipMap = (membership) => ({
    ...membership,
    price: getMembershipPrice(membership),
    plus: membership.membershipAddonList
      ?.filter((a) => !a.recurring)
      ?.map((addon) => ({
        name: addon.name,
        price: formatCurrency(addon.price, currency),
      })),
    includes: membership.membershipAddonList
      ?.filter((a) => a.recurring)
      .map((addon) => addon.name),
  });

  const innerMembershipMap = ({
    id,
    name,
    description,
    price,
    type,
    billingType,
    billingPeriod,
    termsConditions,
    calculateProrata,
    weeklyBillingDay,
    monthlyBillingDay,
    ...otherFields
  }) => ({
    id,
    name,
    description,
    price: formatCurrency(price, currency),
    isRecurring: type === ServiceType.Recurring,
    billingType,
    billingPeriod,
    includes: otherFields?.includes,
    plus: otherFields?.plus,
    termsAndConditionsLink: termsConditions,
    calculateProrata,
    weeklyBillingDay,
    monthlyBillingDay,
  });

  useEffect(() => {
    fetchMemberships({
      status: ServiceStatus.Active,
      type: membershipType,
      extraParams: { unpaged: true },
    });
  }, [membershipType]);

  useEffect(() => {
    const processParams = async () => {
      if (search.membershipId) {
        const { data } = await api.public.serviceApi.findById(
          search.membershipId,
        );
        if (data.type !== membershipType) {
          const redirectToTypeUrl =
            data.type === ServiceType.Recurring
              ? createClubLink(
                  RouteLayout.Member,
                  RouteFeature.CustomerSale,
                  BuyRoute.RecurringMembership,
                )
              : createClubLink(
                  RouteLayout.Member,
                  RouteFeature.CustomerSale,
                  BuyRoute.PrepaidMembership,
                );
          history.push({
            pathname: redirectToTypeUrl,
            search: history?.location?.search,
          });
        }
        setInitialCart([innerMembershipMap(outerMembershipMap(data))]);
      }
    };
    processParams();
  }, [search.membershipId]);

  const membershipsToSell = useMemo(
    () =>
      memberships
        .filter(
          (membership) =>
            !event ||
            hasEnoughCredits(
              membership,
              event.event.sessionCost,
              event.event.activity.activityCategory.id,
            ),
        )
        .sort((a, b) => getMembershipPrice(a) - getMembershipPrice(b))
        .map(outerMembershipMap),
    [memberships],
  );

  if (hasActiveMembershipSubscription(subscriptions)) {
    return (
      <div className="content h-full overflow-y-auto p-8">
        <Card>
          <CardBody>
            <div>You already have an active membership.</div>
          </CardBody>
        </Card>
      </div>
    );
  }

  const purchase = async (purchaseValues) => {
    try {
      await processExistingMemberMembership(purchaseValues.id, clubId, {
        paymentMethod: purchaseValues.paymentMethod,
        membershipId: purchaseValues.cart[0].id,
        promotionCode: purchaseValues.promoCode,
        startDate: tzDateTimeStringToUtc(
          purchaseValues.startDate,
          timezone,
          PARAMETER_DATE_FORMAT,
        ),
      });
    } catch (e) {
      parseError(e.response);
    }
  };

  const purchaseMiniUser = async ({ user, ...purchaseValues }) => {
    delete user.clubId;
    try {
      await processNewMemberMembership({
        paymentMethod: purchaseValues.paymentMethod,
        membershipId: purchaseValues.cart[0].id,
        promotionCode: purchaseValues.promoCode,
        startDate: tzDateTimeStringToUtc(
          purchaseValues.startDate,
          timezone,
          PARAMETER_DATE_FORMAT,
        ),
        ...user,
      });
    } catch (e) {
      parseError(e.response);
    }
  };

  return (
    <div className="content h-full overflow-y-auto p-8">
      <BuyMembershipAccordion
        memberships={membershipsToSell.map(innerMembershipMap)}
        membershipType={membershipType}
        userMemberId={userMemberId}
        currency={currency}
        fetchPaymentMethods={() => fetchPaymentMethods({ clubId })}
        fetchLeadSources={async () => {
          const result = await api.public.leadApi.findLeadSources({
            extraParams: { activeSource: true },
          });
          return { data: { last: true, content: result.data } };
        }}
        dateFormat={dateFormat}
        summary={api.public.serviceApi.summary}
        purchase={isMiniUser ? purchaseMiniUser : purchase}
        defaultNationality={defaultNationality}
        postCodeFormat={postCodeFormat}
        telephoneFormat={telephoneFormat}
        findByEmail={findByEmail}
        timezone={timezone}
        cardHoldersName={cardHoldersName}
        onUserSubmit={async ({ values: user, isValid }) => {
          if (!isValid) {
            return;
          }
          if (!isMiniUser) {
            await api.profileApi.update(userMemberId, clubId, user);
          }

          return { ...user, id: userMemberId, clubId, pushCommunication: true };
        }}
        event={event}
        userDetails={userDetails}
        initialCart={initialCart}
        requiredFields={requiredFields}
      />
    </div>
  );
}

BuyMembership.defaultProps = {
  isMiniUser: false,
  subscriptions: [],
  cardHoldersName: "",
  event: null,
};

BuyMembership.propTypes = {
  membershipType: PropTypes.oneOf(Object.values(ServiceType)).isRequired,
  fetchPaymentMethods: PropTypes.func.isRequired,
  userMemberId: PropTypes.string.isRequired,
  cardHoldersName: PropTypes.string,
  userDetails: PropTypes.object.isRequired,
  isMiniUser: PropTypes.bool,
  subscriptions: PropTypes.array,
  findByEmail: PropTypes.func.isRequired,
  event: PropTypes.object,
};

function BuyMembershipWithProviders(props) {
  const { PublicServiceStore } = useGymflowModels();
  return (
    <PublicServiceStore.Provider>
      <BuyMembership {...props} />
    </PublicServiceStore.Provider>
  );
}

export default BuyMembershipWithProviders;
