import { subject } from "@casl/ability";
import { useAbility } from "@casl/react";
import { faDownload, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AlertContext,
  DATE_FORMAT,
  humanizeUserStatus,
  isMobile,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
  SubscriptionStatus,
  tzDateTimeStringToUtc,
  UserStatus,
} from "@gymflow/common";
import {
  downloadCsv,
  formatCurrency,
  pluralize,
  toLowerCaseExceptFirstChar,
} from "@gymflow/helpers";
import classNames from "classnames";
import { useStoreState } from "easy-peasy";
import noop from "lodash/noop";
import omit from "lodash/omit";
import moment from "moment-timezone";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import { Button, Card, CardBody, CardHeader } from "reactstrap";

import AsyncDropdownFilter from "../components/AsyncDropdownFilter";
import { PrimaryButton } from "../components/atoms";
import DropdownFilter from "../components/DropdownFilter";
import DateRangePicker from "../components/forms/DateRangePicker";
import MarketingFilter, { MarketingType } from "../components/MarketingFilter";
import SendEmailAlert from "../components/UserMember/SendEmails/SendEmailAlert";
import useSelectableMembers from "../components/UserMemberList/useSelectableMembers";
import useCreditPackLoadOptions from "../hooks/useCreditPackLoadOptions";
import useLeadSourceLoadOptions from "../hooks/useLeadSourceLoadOptions";
import useLeadStatusLoadOptions from "../hooks/useLeadStatusLoadOptions";
import useMembershipLoadOptions from "../hooks/useMembershipLoadOptions";
import { usePortalRoutes } from "../hooks/usePortalRoutes";
import usePromotionsLoadOptions from "../hooks/usePromotionsLoadOptions";
import useQueryParam from "../hooks/useQueryParam";
import useSendEmails from "../hooks/useSendEmails";
import useStaffLoadOptions from "../hooks/useStaffLoadOptions";
import { AbilityContext, anyRecord, Subject, Verb } from "../permissions";
import { useClubSettings } from "../providers";
import useGymflowModels from "../store";
import { LeadProfilePage } from "./LeadProfile";
import { UserMemberPage } from "./UserMember";

const colorStatusMap = {
  ACTIVE: "#007E4F",
  PAUSED: "#F05519",
  CANCELLED: "#0A0D16",
  EXPIRED: "#0A0D16",
  PENDING: "#9D9EA2",
  OVERDUE: "#E2003A",
};
const SelectTable = selectTableHOC(ReactTable);

const PAGE_SIZE = 20;
const MIN_ROW = 5;

function UserMemberList() {
  const ability = useAbility(AbilityContext);
  const canViewAllMembers = ability.can(
    Verb.View,
    subject(Subject.MemberProfile, anyRecord()),
  );
  const { createMemberLink, createLeadLink } = usePortalRoutes();
  const { api, MemberStore, settingsStore, authStore } = useGymflowModels();
  const settings = useClubSettings();
  const dateFormat = settings.date_format;
  const { defaultCurrency, timezone, email } = useStoreState(settingsStore);
  const { rows, pageCount, totalRecords } = MemberStore.useStoreState(
    (state) => {
      const rowsWithId = state.rows.map((row) => ({
        ...row,
        rowId:
          row.userType === UserStatus.Lead
            ? UserStatus.Lead + row.leadId
            : row.userMemberId,
      }));
      return { ...state, rows: rowsWithId };
    },
  );
  const { id: loggedInId } = useStoreState(authStore);
  const { fetchMemberStatusList } = MemberStore.useStoreActions(
    (actions) => actions,
  );
  const [page, setPage] = useQueryParam("page", "number");
  const [limit, setLimit] = useQueryParam("limit", "number");
  const [sort, setSort] = useQueryParam("sort", "string");
  const [userStatus, setUserStatus] = useQueryParam("userType", "string");
  const [membershipStatus, setMembershipStatus] = useQueryParam(
    "membershipStatus",
    "string",
  );
  const [dates, setDates] = useQueryParam("dates", "string");
  const [creditPack, setCreditPack] = useQueryParam("creditPack", "string");
  const [membership, setMembership] = useQueryParam("membership", "string");
  const [leadStatus, setLeadStatus] = useQueryParam("leadStatus", "string");
  const [leadSource, setLeadSource] = useQueryParam("leadSource", "string");
  const [marketing, setMarketing] = useQueryParam("marketing", "string");
  const [promotion, setPromotion] = useQueryParam("promotion", "string");
  const [trainer, setTrainer] = useQueryParam("trainer", "string");
  const [isPagingDataTableReady, setIsPagingDataTableReady] = useState(true);
  const [showFilter, setShowFilter] = useState(isMobile() ? false : true);
  const alert = useContext(AlertContext);

  const membershipLoadOptions = useMembershipLoadOptions();
  const creditPackLoadOptions = useCreditPackLoadOptions();
  const leadStatusLoadOptions = useLeadStatusLoadOptions();
  const leadSourceLoadOptions = useLeadSourceLoadOptions();
  const promotionLoadOptions = usePromotionsLoadOptions();
  const staffLoadOptions = useStaffLoadOptions({ allowNull: true });

  const createFilters = useCallback(
    (convertToUtc = false) => {
      let dateFrom = null;
      let dateTo = null;
      if (convertToUtc) {
        if (dates?.startDate) {
          dateFrom = moment(dates.startDate, DATE_FORMAT).format(
            PARAMETER_DATE_FORMAT_WITHOUT_TZ,
          );
          dateFrom = tzDateTimeStringToUtc(dateFrom, timezone);
        }

        if (dates?.endDate) {
          dateTo = moment(dates.endDate, DATE_FORMAT).endOf("day");
          dateTo = tzDateTimeStringToUtc(dateTo, timezone);
        }
      } else {
        dateFrom =
          dates?.startDate &&
          moment(dates.startDate, DATE_FORMAT).format(
            PARAMETER_DATE_FORMAT_WITHOUT_TZ,
          );
        dateTo =
          dates?.endDate &&
          moment(dates.endDate, DATE_FORMAT)
            .endOf("day")
            .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ);
      }

      return {
        userType: userStatus,
        membershipStatus,
        dateFrom,
        dateTo,
        membershipId: membership?.value?.id,
        sessionPackId: creditPack?.value?.id,
        leadStatus: leadStatus?.value?.name,
        leadSourceId: leadSource?.value?.id,
        promoCodeId: promotion?.value?.id,
        emailCommunication:
          marketing === MarketingType.EMAIL ? true : undefined,
        smsCommunication: marketing === MarketingType.SMS ? true : undefined,
        assignedStaffId: canViewAllMembers ? trainer?.id : loggedInId,
      };
    },
    [
      dates,
      leadSource?.value?.id,
      leadStatus?.value?.name,
      marketing,
      membershipStatus,
      userStatus,
      promotion?.value?.id,
      membership?.value?.id,
      creditPack?.value?.id,
      timezone,
      trainer,
      canViewAllMembers,
    ],
  );

  const { sendEmails, sendEmailsByFilter } = useSendEmails();

  const {
    areAllSelected,
    toggleSelection,
    toggleAll,
    isSelected,
    select,
    selectedLength,
    selected,
    excluded,
  } = useSelectableMembers();

  const selectRow = useCallback(
    (item, isShift) => {
      if (isShift && selected.length) {
        const selectionStart = selected[selected.length - 1];
        const selectionStartIndex = rows.findIndex((row) => {
          if (typeof selectionStart.leadId === "undefined") {
            return row.userMemberId === selectionStart.userMemberId;
          } else {
            return row.leadId === selectionStart.leadId;
          }
        });
        if (selectionStartIndex === -1) {
          toggleSelection(item);
          return;
        }
        const selectionEndIndex = rows.findIndex((row) => {
          if (item.userType === UserStatus.Lead) {
            return row.leadId === item.leadId;
          } else {
            return row.userMemberId === item.userMemberId;
          }
        });
        if (selectionStartIndex < selectionEndIndex) {
          for (let i = selectionStartIndex + 1; i <= selectionEndIndex; i++) {
            select(rows[i]);
          }
        } else {
          for (let i = selectionEndIndex; i <= selectionStartIndex - 1; i++) {
            select(rows[i]);
          }
        }
      } else {
        toggleSelection(item);
      }
    },
    [toggleSelection],
  );

  const selectAll = useCallback(() => {
    toggleAll(totalRecords);
  }, [toggleAll, totalRecords]);

  useEffect(() => {
    if (page > 0 && totalRecords > 0 && rows.length === 0) {
      setIsPagingDataTableReady(false);
      setPage(0);
    } else {
      setIsPagingDataTableReady(true);
    }
  }, [page, totalRecords, rows, setPage, setIsPagingDataTableReady]);

  useEffect(() => {
    const sortedParts = sort?.split(",");
    fetchMemberStatusList({
      page: page || 0,
      limit: limit || PAGE_SIZE,
      sort: sortedParts && {
        field: sortedParts.slice(0, sortedParts.length - 1).join(","),
        desc: sortedParts[sortedParts.length - 1],
      },
      extraParams: createFilters(),
    });
  }, [
    page,
    limit,
    sort,
    userStatus,
    membershipStatus,
    fetchMemberStatusList,
    dates?.startDate,
    dates?.endDate,
    membership?.value.name,
    creditPack?.value.name,
    leadStatus?.value?.name,
    leadSource?.value?.name,
    promotion?.value?.id,
    marketing,
    trainer?.id,
  ]);

  const handlePageChange = useCallback(
    (pageValue) => {
      setPage(pageValue);
    },
    [setPage],
  );

  const handlePageSizeChange = useCallback(
    (pageSize) => {
      setLimit(pageSize);
    },
    [setLimit],
  );

  const handleSortedChange = useCallback(
    (sort) => {
      setSort(`${sort[0]?.id},id,${sort[0]?.desc ? "desc" : "asc"}`);
    },
    [setSort],
  );

  const defaultSorted = useMemo(() => {
    if (sort) {
      const parts = sort.split(",");
      const first = parts[0];
      const last = parts.pop();
      return [{ id: first, desc: last === "desc" }];
    }
  }, [sort]);

  const handleUserStatusChange = useCallback(
    (value) => {
      setUserStatus(value.value);
    },
    [setUserStatus],
  );

  const handleMembershipStatusChange = useCallback(
    (value) => {
      setMembershipStatus(value.value);
    },
    [setMembershipStatus],
  );

  const handleLeadStatusChange = useCallback(
    (value) => {
      if (value.value === null) {
        setLeadStatus(null);
      } else {
        setLeadStatus({
          ...value,
          label: value.value.name,
        });
      }
    },
    [setLeadStatus],
  );

  const handleLeadSourceChange = useCallback(
    (value) => {
      if (value.value === null) {
        setLeadSource(null);
      } else {
        setLeadSource({
          ...value,
          label: value.value.name,
        });
      }
    },
    [setLeadSource],
  );

  const handleMembershipChange = useCallback(
    (value) => {
      if (value.value === null) {
        setMembership(null);
      } else {
        setMembership({
          ...value,
          label: value.value.name,
        });
      }
    },
    [setMembership],
  );

  const handleCreditPackChange = useCallback(
    (value) => {
      if (value.value === null) {
        setCreditPack(null);
      } else {
        setCreditPack({
          ...value,
          label: value.value.name,
        });
      }
    },
    [setCreditPack],
  );

  const handlePromotionChange = useCallback(
    (value) => {
      if (value.value === null) {
        setPromotion(null);
      } else {
        setPromotion({
          ...value,
          label: value.value.code,
        });
      }
    },
    [setPromotion],
  );

  const handleMarketingChange = useCallback(
    (value) => {
      setMarketing(value.value);
    },
    [setMarketing],
  );

  const handleDateChange = useCallback(
    (newDate) => {
      if (newDate) {
        const { startDate, endDate } = newDate;
        setDates({
          startDate: moment(startDate, dateFormat).format(DATE_FORMAT),
          endDate: moment(endDate, dateFormat).format(DATE_FORMAT),
        });
      } else {
        setDates(null);
      }
    },
    [setDates, dateFormat],
  );

  const handleTrainerChange = useCallback(
    (value) => {
      setTrainer(value.value);
    },
    [setTrainer],
  );

  const tableDefs = [
    {
      Header: "",
      id: "picture",
      accessor: "picture",
      Cell: ({ value }) => {
        if (value) {
          return (
            <img
              style={{ width: "30px", height: "30px", borderRadius: "15px" }}
              src={value}
              alt="guest"
            />
          );
        }
        return null;
      },
      show: !isMobile(),
      maxWidth: 70,
      sortable: false,
      filterable: false,
    },
    {
      Header: <h3>Full Name</h3>,
      id: "firstName",
      accessor: ({ firstName, lastName, userMemberId, leadId }) => ({
        firstName,
        lastName,
        userMemberId,
        leadId,
      }),
      Cell: ({ value: { firstName, lastName, userMemberId, leadId } }) => {
        if (userMemberId) {
          return (
            <Link to={createMemberLink(userMemberId, UserMemberPage.Profile)}>
              {firstName} {lastName}
            </Link>
          );
        } else if (leadId) {
          return (
            <Link to={createLeadLink(leadId, LeadProfilePage.Profile)}>
              {firstName} {lastName}
            </Link>
          );
        }
        return `${firstName} ${lastName}`;
      },
    },
    {
      Header: <h3>User Type</h3>,
      accessor: "userType",
      Cell: ({ value }) => humanizeUserStatus(value),
    },
    {
      Header: <h3>Lead Status</h3>,
      accessor: "leadStatus",
      show: !isMobile(),
    },
    {
      Header: <h3>Lifetime Value</h3>,
      accessor: "lifetimeValue",
      Cell: ({ value }) => formatCurrency(value, defaultCurrency),
      show: !isMobile(),
    },
    {
      Header: <h3>Mem Length</h3>,
      accessor: "membershipLength",
      Cell: ({ value }) => {
        if (typeof value === "undefined") {
          return "No Membership";
        } else if (value === 0) {
          return "First month";
        } else if (value === 1) {
          return "1 Month";
        }
        return `${value} Months`;
      },
      show: !isMobile(),
    },
    {
      Header: <h3>Membership Status</h3>,
      accessor: "membershipStatus",
      Cell: ({ value }) => (
        <div
          className="font-weight-bold"
          style={{ color: colorStatusMap[value] }}
        >
          {value && toLowerCaseExceptFirstChar(value)}
        </div>
      ),
    },
    {
      Header: <h3>Health</h3>,
      accessor: "health",
      show: false,
    },
  ];

  const userStatusOptions = Object.values(UserStatus).map((value) => ({
    label: humanizeUserStatus(value),
    value,
  }));
  userStatusOptions.unshift({
    label: "All",
    value: null,
  });

  const membershipStatusOptions = Object.values(SubscriptionStatus).map(
    (value) => ({
      label: SubscriptionStatus.humanize(value),
      value,
    }),
  );
  membershipStatusOptions.unshift({
    label: "All",
    value: null,
  });

  const rowFn = useCallback(
    (_, rowInfo) => ({
      style: {
        background: rowInfo && isSelected(rowInfo.original.rowId) && "#f8f8fa",
      },
    }),
    [isSelected],
  );

  const renderTotal = () => {
    if (selectedLength !== 0) {
      return (
        <>
          {selectedLength} {pluralize("User", "Users", selectedLength)} selected
          (total: {totalRecords})
        </>
      );
    }
    return (
      <>
        {totalRecords} {pluralize("User", "Users", totalRecords)}
      </>
    );
  };

  return (
    <div className="content h-full overflow-y-auto p-8">
      <Card className="floating-table">
        <CardHeader>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center" style={{ minWidth: 80 }}>
              <h2 className="mb-0">{renderTotal()}</h2>
            </div>
            <div className="d-flex">
              <Button
                className={classNames({
                  hidden:
                    selectedLength === 0 ||
                    !ability.can(Verb.Create, Subject.Email),
                })}
                color="primary"
                size="sm"
                style={{ minWidth: 70 }}
                onClick={() => {
                  alert.setAlert(
                    <SendEmailAlert
                      allowMarketing
                      from={email}
                      to={`${selectedLength} ${pluralize(
                        "User",
                        "Users",
                        selectedLength,
                      )}`}
                      onSubmit={(values) => {
                        const bcc = values.bcc ? values.bcc.split(",") : [];
                        if (areAllSelected) {
                          const leadIdsToExclude = excluded
                            .filter((row) => row.leadId)
                            .map((row) => row.leadId);
                          const userMemberIdsToExclude = excluded
                            .filter((row) => row.userMemberId)
                            .map((row) => row.userMemberId);
                          return sendEmailsByFilter(
                            values.subject,
                            values.body,
                            createFilters(true),
                            values.marketing,
                            leadIdsToExclude,
                            userMemberIdsToExclude,
                            bcc,
                          );
                        }
                        const emailRecipientList = selected.map((s) => {
                          const recipient = { ...s };
                          delete recipient.id;
                          return recipient;
                        });
                        return sendEmails(
                          values.subject,
                          values.body,
                          emailRecipientList,
                          values.marketing,
                          bcc,
                        );
                      }}
                      onCancel={alert.hide}
                    />,
                  );
                }}
              >
                <FontAwesomeIcon className="mr-lg-10 mr-2" icon={faEnvelope} />
                Email
              </Button>
              <PrimaryButton
                className={classNames(
                  {
                    hidden: !ability.can(Verb.Download, Subject.MemberList),
                  },
                  "ml-lg-10 ml-2",
                )}
                style={{ minWidth: 100 }}
                onClick={async () => {
                  const { data } = await api.memberApi.memberStatusListCsv(
                    createFilters(true),
                  );
                  downloadCsv(data, "Users.csv");
                }}
              >
                <FontAwesomeIcon className="mr-lg-10 mr-2" icon={faDownload} />
                Download
              </PrimaryButton>
            </div>
          </div>
          <div className="d-flex d-sm-none mt-2">
            <Button
              style={{ fontSize: 12 }}
              className="btn-link"
              onClick={() => setShowFilter(!showFilter)}
            >
              {showFilter ? "Hide" : "Show"} Filters
            </Button>
          </div>
          {showFilter && (
            <div className="d-flex mt-lg-20 mt-2">
              <h2 className="d-none d-sm-block">Filters</h2>
              <div className="box ml-lg-10 ml-2">
                <div className="sm-box-item">
                  <DateRangePicker
                    placeholder="Created Date"
                    onChange={handleDateChange}
                    value={
                      dates
                        ? {
                            startDate: moment(
                              dates.startDate,
                              DATE_FORMAT,
                            ).format(dateFormat),
                            endDate: moment(dates.endDate, DATE_FORMAT).format(
                              dateFormat,
                            ),
                          }
                        : null
                    }
                    dateFormat={dateFormat}
                  />
                </div>
                <div className="sm-box-item">
                  <AsyncDropdownFilter
                    placeholder="Membership"
                    value={
                      typeof membership === "undefined" ? null : membership
                    }
                    onChange={handleMembershipChange}
                    loadOptions={membershipLoadOptions}
                  />
                </div>
                <div className="sm-box-item">
                  <AsyncDropdownFilter
                    placeholder="Credit Packs"
                    value={
                      typeof creditPack === "undefined" ? null : creditPack
                    }
                    onChange={handleCreditPackChange}
                    loadOptions={creditPackLoadOptions}
                  />
                </div>
                <div className="sm-box-item">
                  <DropdownFilter
                    placeholder="User Status"
                    options={userStatusOptions}
                    value={userStatusOptions.find(
                      (status) => status.value === userStatus,
                    )}
                    onChange={handleUserStatusChange}
                  />
                </div>
                <div className="sm-box-item">
                  <DropdownFilter
                    placeholder="Membership Status"
                    options={membershipStatusOptions}
                    value={membershipStatusOptions.find(
                      (status) => status.value === membershipStatus,
                    )}
                    onChange={handleMembershipStatusChange}
                    styles={{
                      container: () => ({
                        width: "170px",
                        position: "relative",
                      }),
                    }}
                  />
                </div>
                <div className="sm-box-item">
                  <AsyncDropdownFilter
                    placeholder="Lead Status"
                    value={
                      typeof leadStatus === "undefined" ? null : leadStatus
                    }
                    onChange={handleLeadStatusChange}
                    loadOptions={leadStatusLoadOptions}
                  />
                </div>
                <div className="sm-box-item">
                  <AsyncDropdownFilter
                    placeholder="Lead Source"
                    value={
                      typeof leadSource === "undefined" ? null : leadSource
                    }
                    onChange={handleLeadSourceChange}
                    loadOptions={leadSourceLoadOptions}
                  />
                </div>
                <div className="sm-box-item">
                  <AsyncDropdownFilter
                    placeholder="Promotion"
                    value={typeof promotion === "undefined" ? null : promotion}
                    onChange={handlePromotionChange}
                    loadOptions={promotionLoadOptions}
                  />
                </div>
                <div className="sm-box-item">
                  <MarketingFilter
                    value={marketing}
                    onChange={handleMarketingChange}
                  />
                </div>
                <div
                  className={classNames("sm-box-item", {
                    hidden: !canViewAllMembers,
                  })}
                >
                  <AsyncDropdownFilter
                    placeholder="Trainer"
                    value={
                      typeof trainer === "undefined"
                        ? null
                        : {
                            label: `${trainer.firstName} ${trainer.lastName}`,
                            value: trainer,
                          }
                    }
                    onChange={handleTrainerChange}
                    loadOptions={staffLoadOptions}
                    isDisabled={!canViewAllMembers}
                  />
                </div>
              </div>
            </div>
          )}
        </CardHeader>
        <CardBody>
          {isPagingDataTableReady && (
            <SelectTable
              defaultSorted={defaultSorted}
              className="-highlight floating-table"
              columns={tableDefs}
              data={rows}
              defaultPageSize={limit || PAGE_SIZE}
              minRows={0}
              getTrGroupProps={() => ({
                style: {
                  maxHeight: "50px",
                },
              })}
              getTrProps={rowFn}
              manual
              defaultPage={page || 0}
              page={page || 0}
              pages={pageCount}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              onSortedChange={handleSortedChange}
              showPagination={totalRecords >= MIN_ROW}
              selectType="checkbox"
              keyField="rowId"
              toggleSelection={selectRow}
              isSelected={isSelected}
              selectAll={areAllSelected}
              toggleAll={selectAll}
              SelectInputComponent={({ onClick, row, ...checkboxProps }) => {
                return (
                  <input
                    type="checkbox"
                    className="-ml-2"
                    onChange={noop}
                    {...omit(checkboxProps, ["selectType"])}
                    onClick={(event) => {
                      onClick(row, event.shiftKey);
                    }}
                  />
                );
              }}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

function UserMemberListWithProvider() {
  const { MemberStore } = useGymflowModels();
  return (
    <MemberStore.Provider>
      <UserMemberList />
    </MemberStore.Provider>
  );
}

export default UserMemberListWithProvider;
