import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";

function LeadSourceSelect({ fetchLeadSources, value, onChange, placeholder }) {
  const loadOptions = async (_, __, { page }) => {
    const {
      data: { content, last },
    } = await fetchLeadSources();

    return {
      options: content.map(({ name, id }) => ({ label: name, value: { name, id } })),
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <AsyncPaginate
      classNamePrefix="react-select"
      additional={{
        page: 0,
      }}
      classNames={{
        control: () => "!w-full !bg-white !text-base !font-normal !border-gray-300 !rounded-lg !h-11",
        singleValue: () => "!text-gray-900",
        indicatorsContainer: () => "[&>div]:!text-gray-500",
        indicatorSeparator: () => "!hidden",
        container: () => "w-full",
      }}
      loadOptions={loadOptions}
      isSearchable={false}
      value={value && { label: value.name, value }}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
}

LeadSourceSelect.defaultProps = {
  value: null,
};

LeadSourceSelect.propTypes = {
  fetchLeadSources: PropTypes.func.isRequired,
  value: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default LeadSourceSelect;
