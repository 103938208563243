import { membershipListQueryFn } from "@gymflow/api";
import { ApiListResponse, Membership } from "@gymflow/types";

import useGymflowModels from "../../store";
import { LookingGlassIcon } from "./icons";
import { PaginatedSelect, SelectAllClickArgs } from "./PaginatedSelect";

export type MembershipSelectProps<T extends true | false | undefined> = {
  onChange: (
    option: T extends true ? MembershipSelectOption[] : MembershipSelectOption,
  ) => void;
  className?: string;
  disableMembershipIds?: number[];
  isMulti: T;
  value:
    | (T extends true ? MembershipSelectValue[] : MembershipSelectValue)
    | null;
  isSearchable?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  isClearable?: boolean;
  showSelectAll?: boolean;
  selectAllClick?: (params: SelectAllClickArgs) => Promise<boolean | void>;
};

export function MembershipSelect<T extends true | false | undefined>({
  onChange,
  value,
  className,
  disableMembershipIds,
  isMulti,
  isSearchable,
  isDisabled,
  placeholder,
  isClearable,
  showSelectAll,
  selectAllClick,
}: MembershipSelectProps<T>) {
  const { api } = useGymflowModels();

  const loadOptions = async (
    searchTerm: string,
    __: readonly any[],
    { page }: { page: number },
  ) => {
    const memberships = await membershipListQueryFn({
      api,
      filter: {
        page,
        extraParams: { name: searchTerm },
      },
    });

    return {
      options:
        memberships?.content.map((membership) => ({
          label: membership.name,
          value: membership.id,
          isDisabled: disableMembershipIds?.includes(membership.id),
        })) ?? [],
      hasMore: !memberships.last,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <PaginatedSelect
      isClearable={isClearable}
      loadOptions={loadOptions}
      value={value}
      onChange={onChange}
      isMulti={isMulti}
      className={className}
      isSearchable={isSearchable}
      icon={
        isSearchable && (
          <LookingGlassIcon
            className="h-9 w-9"
            pathClassName="stroke-grey-600"
          />
        )
      }
      isDisabled={isDisabled}
      placeholder={placeholder}
      showSelectAll={showSelectAll}
      selectAllClick={selectAllClick}
      refetchOnMenuOpen={true}
    />
  );
}

MembershipSelect.defaultProps = {
  disableMembershipIds: [],
  isMulti: false,
};

export interface MembershipSelectOption {
  readonly label: string;
  readonly value: number;
}

export interface MembershipSelectValue {
  readonly label: string | null;
  readonly value: number | null;
}

export interface MembershipListWithIsInitialData
  extends ApiListResponse<Membership> {
  isInitialData: false;
}
