import { AsyncPaginate } from "react-select-async-paginate";
import { ChevronDown, ChevronUp } from "react-feather";

/**
 * @deprecated
 */
function EventCategoryFilter({ category, fetchCategories, onChange, styles }) {
  return (
    <AsyncPaginate
      additional={{
        page: 0,
      }}
      isSearchable={false}
      className="react-select info react-select-btn"
      classNamePrefix="react-select"
      placeholder="Class Type"
      components={{
        DropdownIndicator: ({ selectProps: { menuIsOpen } }) => {
          if (menuIsOpen) {
            return <ChevronUp />;
          }
          return <ChevronDown />;
        },
      }}
      loadOptions={async () => {
        const { data } = await fetchCategories({ limit: 100, status: ["ACTIVE"] }).catch((err) => console.error(err));
        const options = data.content.map((category) => ({ value: category.id, label: category.name }));
        options.unshift({ value: null, label: "All" });
        return {
          hasMore: false,
          options,
        };
      }}
      value={category}
      onChange={onChange}
      styles={{
        control: (provided) => ({
          ...provided,
          minWidth: 150,
          borderRadius: "30px !important",
        }),
        ...styles,
      }}
    />
  );
}

export default EventCategoryFilter;
