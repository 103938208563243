
export function useGetPreviousElements() {
  const getPreviousElements = (
    data: any,
    length: number,
    currentIdx: number,
    includeCurrent = true
  ) => {
    const elements = new Array(length);
    for (let i = 0; i < length; i++) {
      elements[length - 1 - i] =
        data[
        ((includeCurrent ? currentIdx : currentIdx - 1) - i + data.length) %
        data.length
        ];
    }
    return elements;
  };
  return getPreviousElements;
}
