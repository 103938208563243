import { useCallback, useContext, useRef } from 'react';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { AsyncButton, AlertContext, NotificationContext, PaymentMethod } from '@gymflow/common';

import AddPaymentMethodWrapper from './AddPaymentMethodWrapper';

/*** @deprecated use AddPaymentMethodModal instead */
const useAddPaymentMethod = ({ user, onAddPaymentMethod, fetchBacsCheckoutId }) => {
  const { notifyDanger } = useContext(NotificationContext);
  const alert = useContext(AlertContext);
  const cardRef = useRef();

  const handleAddPaymentMethod = async () => {
    const values = await cardRef.current.getValues();
    if (values.paymentType === PaymentMethod.BacsDirectDebit) {
      alert.hide();
    } else if (values.isValid) {
      try {
        await onAddPaymentMethod({ paymentMethod: values.paymentMethod.id });
        alert.hide();
      } catch (e) {
        alert.hide();
        notifyDanger(e);
      }
    } else {
      await alert.showError('Invalid name or card number. Try again');
    }
  };

  const renderPaymentButtons = () => {
    return (
      <>
        <button
          type="button"
          onClick={alert.hide}
          className="btn btn-sm btn-outline-primary"
          style={{ marginRight: '8px' }}
        >
          Cancel
        </button>
        <AsyncButton
          type="button"
          className="btn btn-sm btn-primary"
          onClick={handleAddPaymentMethod}
          style={{
            marginRight: '8px',
            color: 'white',
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgba(0, 0, 0, 0.075) 0px 1px 1px inset, rgb(165, 202, 234) 0px 0px 8px',
          }}
        >
          Add
        </AsyncButton>
      </>
    );
  };

  const showAlert = useCallback(() => {
    alert.setAlert(
      <ReactBSAlert
        customButtons={renderPaymentButtons()}
        title="Add Payment Method"
        showCancel
        confirmBtnText="Add"
        onConfirm={handleAddPaymentMethod}
        onCancel={alert.hide}
        closeOnClickOutside={false}
      >
        {user && (
          <AddPaymentMethodWrapper
            cardHolderName={`${user?.firstName} ${user?.lastName}`}
            ref={cardRef}
            fetchBacsCheckoutId={fetchBacsCheckoutId}
          />
        )}
      </ReactBSAlert>
    );
  }, [alert, user, handleAddPaymentMethod, fetchBacsCheckoutId]);
  return { showAlert };
};

export default useAddPaymentMethod;
