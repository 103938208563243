import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';

const OrderLane = ({ index, noDragDrop, moveLane, isDragging, onDropLaneToLane, highlight, children, style }) => {
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: 'lane',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get horizon middle
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      // Only perform the move when the mouse has crossed half of the items width
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }
      moveLane(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: onDropLaneToLane
  });

  drop(ref);

  if (isDragging) {
    return <div ref={ref} style={{ background: '#d2f8d2', opacity: 0.5, minWidth: 220, height: '100%', border: '2px dashed green' }}/>
  }

  return (
    <div
      ref={!noDragDrop ? ref : null}
      style={{ ...style, minWidth: 220 }}
      data-handler-id={handlerId}
    >
      <div style={{ border: highlight && '2px dashed #3b73af', padding: '8px', height: '100%' }}>
        {children}
      </div>
    </div>
  );
};

export default OrderLane;
