import { PaymentMethodDTO } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberAsMemberQueryKeys } from "./memberAsMemberQueryKeys";

export function useMemberPaymentMethodAsMember({
  api,
}: {
  api: {
    profileApi: {
      attachPaymentMethod: (
        _: unknown,
        clubId: number,
        paymentMethodId: string,
      ) => Promise<{ data: PaymentMethodDTO[] }>;
      removePaymentMethod: (
        _: unknown,
        clubId: number,
        paymentMethodId: string,
      ) => Promise<{ data: PaymentMethodDTO[] }>;
      assignDefaultPaymentMethod: (
        _: unknown,
        clubId: number,
        paymentMethodId: string,
      ) => Promise<{ data: void }>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const attachPaymentMethodMutation = useMutation({
    mutationFn: ({
      clubId,
      paymentMethodId,
      memberId,
    }: {
      clubId: number;
      paymentMethodId: string;
      memberId: string;
    }) => api.profileApi.attachPaymentMethod(memberId, clubId, paymentMethodId),
    onSuccess: (
      _,
      { memberId, clubId }: { memberId: string; clubId: number },
    ) => {
      queryClient.invalidateQueries({
        queryKey: memberAsMemberQueryKeys.paymentMethods(clubId, memberId),
      });
    },
  });

  const removePaymentMethodMutation = useMutation({
    mutationFn: ({
      clubId,
      paymentMethodId,
      memberId,
    }: {
      clubId: number;
      paymentMethodId: string;
      memberId: string;
    }) => api.profileApi.removePaymentMethod(memberId, clubId, paymentMethodId),
    onSuccess: (
      _,
      { memberId, clubId }: { memberId: string; clubId: number },
    ) => {
      queryClient.invalidateQueries({
        queryKey: memberAsMemberQueryKeys.paymentMethods(clubId, memberId),
      });
    },
  });

  const assignDefaultPaymentMethodMutation = useMutation({
    mutationFn: ({
      clubId,
      paymentMethodId,
      memberId,
    }: {
      clubId: number;
      paymentMethodId: string;
      memberId: string;
    }) =>
      api.profileApi.assignDefaultPaymentMethod(
        memberId,
        clubId,
        paymentMethodId,
      ),
    onSuccess: (
      _,
      { memberId, clubId }: { memberId: string; clubId: number },
    ) => {
      queryClient.invalidateQueries({
        queryKey: memberAsMemberQueryKeys.paymentMethods(clubId, memberId),
      });
    },
  });

  return {
    attachPaymentMethodMutation,
    removePaymentMethodMutation,
    assignDefaultPaymentMethodMutation,
  };
}
