import {
  clubAsPublicQueryKeys,
  clubStaleTime,
  useClubPropertiesSettingsAsPublic,
} from "@gymflow/api";
import { ClubPropertiesSettings } from "@gymflow/types";
import { useQueryClient } from "@tanstack/react-query";
import { ReactNode, useEffect, useState } from "react";
import shadesOf from "tailwind-shades";

import { LoadingPortal } from "../../components/pages";
import useGymflowModels from "../../store";
import { ClubSettingsContext } from "./ClubSettingsProvider";
import { resetTailwindColors } from "./resetTailwindColors";

export function ClubSettingsAsPublicProvider({
  clubId,
  children,
  disableClubColorCustomization = false,
}: {
  clubId: number;
  children:
    | ((clubPropertiesSettings: ClubPropertiesSettings) => ReactNode)
    | ReactNode;
  disableClubColorCustomization: boolean;
}) {
  const { api } = useGymflowModels();
  const { data: propertiesSettings } = useClubPropertiesSettingsAsPublic(
    { api, clubId },
    { staleTime: clubStaleTime },
  );

  const [isCacheReady, setIsCacheReady] = useState(false);
  const queryClient = useQueryClient();
  useEffect(() => {
    if (propertiesSettings) {
      queryClient.setQueryData(
        clubAsPublicQueryKeys.details(clubId),
        propertiesSettings.club,
      );
      queryClient.setQueryData(clubAsPublicQueryKeys.featureFlags(clubId), {
        featureFlags: propertiesSettings.featureFlags,
      });
      queryClient.setQueryData(
        clubAsPublicQueryKeys.properties(clubId),
        propertiesSettings.properties,
      );
      if (propertiesSettings) {
        queryClient.setQueryData(
          clubAsPublicQueryKeys.hostedPagesSettings(clubId),
          { settings: propertiesSettings.settings },
        );

        const root = document.querySelector(":root");
        if (root) {
          if (propertiesSettings.settings && !disableClubColorCustomization) {
            const primaryColor = propertiesSettings.settings?.primaryColor;
            if (primaryColor) {
              const primaryShades = shadesOf(primaryColor);
              const setProperty = (root as HTMLElement).style.setProperty.bind(
                (root as HTMLElement).style,
              );
              setProperty("--primary-color-900", primaryShades["900"]);
              setProperty("--primary-color-800", primaryShades["800"]);
              setProperty("--primary-color-700", primaryShades["700"]);
              setProperty("--primary-color-600", primaryShades["600"]);
              setProperty("--primary-color-500", primaryShades["500"]);
              setProperty("--primary-color-400", primaryShades["400"]);
              setProperty("--primary-color-300", primaryShades["300"]);
              setProperty("--primary-color-200", primaryShades["200"]);
              setProperty("--primary-color-100", primaryShades["100"]);
              setProperty("--primary-color-50", primaryShades["50"]);
              setProperty("--primary-color-25", primaryShades["50"]);
              setProperty("--ring-color", primaryShades["500"]);
            }
          } else {
            resetTailwindColors();
          }
        }
      }
    }
    setIsCacheReady(true);
  }, [clubId, propertiesSettings, queryClient]);

  if (!propertiesSettings) {
    return <LoadingPortal message="Processing club settings ..." />;
  }
  if (!isCacheReady) {
    return <LoadingPortal message="Caching club settings and properties..." />;
  }

  return (
    <ClubSettingsContext.Provider
      value={{
        clubId,

        stripe_account_type: propertiesSettings.club.stripeAccountType,

        default_nationality: propertiesSettings.properties.defaultNationality,
        phone_number_country: propertiesSettings.properties.phoneNumberCountry,
        postal_code_country: propertiesSettings.properties.postalCodeCountry,

        date_format: propertiesSettings.properties.dateFormat,
        short_date_format: propertiesSettings.properties.shortDateFormat,
        long_date_format: propertiesSettings.properties.longDateFormat,

        stripe_api_key: propertiesSettings.properties.stripeApiKey,
        stripe_client_id: propertiesSettings.properties.stripeClientId,
        minimum_charge: propertiesSettings.properties.minimumCharge,

        max_image_upload_width: propertiesSettings.properties.maxImgUploadWidth,
        max_image_upload_height:
          propertiesSettings.properties.maxImgUploadHeight,

        allow_phone_country_selection:
          propertiesSettings.properties.allowPhoneCountrySelection,
        allow_customer_pausing_memberships:
          propertiesSettings.properties.allowPausingMembershipsCustomer,
        allow_customer_cancel_memberships:
          propertiesSettings.properties.allowCustomerCancelMemberships,
        allow_direct_debit: propertiesSettings.properties.allowDirectDebit,
        allow_customer_selecting_start_date:
          propertiesSettings.properties.allowCustomerSelectingStartDate,

        gym_opening_date: propertiesSettings.properties?.gymOpeningDate,

        defaultCurrency: propertiesSettings.club.defaultCurrency,
        timezone: propertiesSettings.club.timezone,
      }}
    >
      {typeof children === "function" ? children(propertiesSettings) : children}
    </ClubSettingsContext.Provider>
  );
}
