import { clubStaleTime, useClub } from "@gymflow/api";
import { DATE_FORMAT, PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import { useClubSettings } from "apps/portal/src/providers";
import moment from "moment";
import QueryString from "qs";
import { useContext } from "react";
import { useHistory } from "react-router";

import useGymflowModels from "../../../../../store";
import ReportWidgetCard from "../../../../ReportWidgetCard/ReportWidgetCard";
import { ReportContext } from "../../../ReportContext";
import { usePortalRoutes } from "./../../../../../hooks/usePortalRoutes";
import { RouteFeature } from "./../../../../../routes/feature";
import { RouteLayout } from "./../../../../../routes/layout";
import { LineCategory } from "./../../../../../views/reports/RevenueTotal";

export const ReportRevenueSalesSummaryMembershipWidget = ({
  parentRoute,
}: {
  parentRoute: string;
}) => {
  const { createClubLink } = usePortalRoutes();
  const data = useContext(ReportContext);
  const history = useHistory();

  const settings = useClubSettings();
  const { api } = useGymflowModels();
  const { data: club } = useClub(
    { api, clubId: settings.clubId },
    { staleTime: clubStaleTime },
  );

  return (
    <ReportWidgetCard
      title="Membership Revenue"
      value={formatCurrency(
        Math.round(data.revenue?.membership?.total || 0),
        club?.defaultCurrency!,
        true,
      )}
      isLoading={data.isLoading}
      variation={data.revenue?.membership?.variation}
      explanation="Sum of all recurring and pre-paid membership sales, less any refunds or credits applied. Does not include payments awaiting authorization."
      onClick={() =>
        history.push({
          pathname: createClubLink(RouteLayout.Staff, RouteFeature.Revenue),
          search: QueryString.stringify({
            dates: {
              startDate: moment(data.startDate, DATE_FORMAT).format(
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ),
              endDate: moment(data.endDate, DATE_FORMAT)
                .endOf("day")
                .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
            },
            category: LineCategory.Membership,
            backRoute: parentRoute,
          }),
        })
      }
    />
  );
};
