import { assert } from "check-types";

import endpoints from "./endpoints";
import { canFind, canFindById } from "./features/apiResource";
import { createPublicAxiosInstance } from "./publicAxiosInstance";

const creditPackEndpoints = {
  summary: `${endpoints.publicCreditPack}/summary`,
};

const publicCreditPack = (apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.publicCreditPack}`,
    http: createPublicAxiosInstance({
      apiUrl,
      paramsSerializer: {
        indexes: null,
      },
    }),
  };

  return Object.assign(state, canFind(state), canFindById(state), {
    findByClubId(clubId, { page, limit, extraParams, sort, status }) {
      assert.number(clubId, "clubId must be a number");
      const findExtraParams = {
        ...extraParams,
        "clubList.id": clubId,
      };

      if (status) {
        findExtraParams.status = status;
      }

      return state.find({
        page,
        limit,
        sort,
        extraParams: findExtraParams,
      });
    },

    summary({ creditPackId, promotionCode, userMemberId }) {
      assert.number(creditPackId, "creditPackId must be a number");
      assert.maybe.string(promotionCode, "promotion code must be a string");
      assert.maybe.string(userMemberId, "userMemberId must be a string");

      return state.http.post(`${clubPrefix}${creditPackEndpoints.summary}`, {
        sessionPackId: creditPackId,
        promotionCode,
        userMemberId,
      });
    },
  });
};

export default publicCreditPack;
