import { useMutationLeadStatus } from "@gymflow/api";
import { NotificationContext } from "@gymflow/common";
import { cn } from "@gymflow/helpers";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useContext } from "react";

import useGymflowModels from "../../../store";
import {
  Button,
  DotsVerticalIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
} from "../../atoms";

export function LeadCardActionsDropdown({
  className,
  dealLostStatusId,
  dealWonStatusId,
  leadId,
}: {
  className: string;
  dealLostStatusId: number;
  dealWonStatusId: number;
  leadId: number;
}) {
  const { api } = useGymflowModels();
  const {
    changeLeadStatusMutation: { mutateAsync: changeStatus },
  } = useMutationLeadStatus({ api });

  const { notifyDanger } = useContext(NotificationContext);
  return (
    <Menu
      as="div"
      className={cn("relative z-[9999] inline-block text-left", className)}
    >
      <div>
        <Menu.Button
          as="div"
          className="flex w-full cursor-pointer items-center justify-center"
        >
          <Button className="mt-0 px-3 py-0">
            <DotsVerticalIcon
              className="h-6 w-6"
              pathClassName="stroke-gray-700"
            />
          </Button>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="min-w-32 absolute right-0 z-[9999] mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="min-w-[9rem] py-2">
            <Menu.Item>
              {({ active }) => (
                <div
                  className={cn(
                    "flex cursor-pointer items-center gap-2 p-2 text-sm text-gray-700",
                    {
                      "bg-gray-50": active,
                    },
                  )}
                  onClick={async () => {
                    try {
                      await changeStatus({
                        leadId,
                        newColumn: dealWonStatusId,
                      });
                    } catch (e) {
                      notifyDanger(e);
                    }
                  }}
                >
                  <div>
                    <ThumbsUpIcon pathClassName="stroke-[#079455]" />
                  </div>
                  <div>Deal Won</div>
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  className={cn(
                    "flex cursor-pointer items-center gap-2 p-2 text-sm text-gray-700",
                    {
                      "bg-gray-50": active,
                    },
                  )}
                  onClick={async () => {
                    try {
                      await changeStatus({
                        leadId,
                        newColumn: dealLostStatusId,
                      });
                    } catch (e) {
                      notifyDanger(e);
                    }
                  }}
                >
                  <ThumbsDownIcon pathClassName="stroke-[#F04438]" />
                  <div>Deal Lost</div>
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
