import { utcToZonedTime, zonedTimeToUtc } from "@gymflow/helpers";
import {
  EventAttendanceDTO,
  UserMemberEventAttendanceDTO,
} from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";
import merge from "lodash/merge";

import { defaultPage } from "../defaultPage";
import { reportQueryKeys } from "./reportQueryKeys";

export function useEventAttendanceReport({
  api,
  startDate,
  endDate,
  gt,
  lt,
  eq,
  membership,
  page,
  limit,
  sort,
  tz,
}: {
  api: {
    memberApi: {
      eventAttendance: (params: {
        startDate: string;
        endDate: string;
        gt?: number;
        lt?: number;
        eq?: number;
        membership?: number[];
        page?: number;
        limit?: number;
        sort: any;
      }) => Promise<any>;
    };
  };
  startDate?: string;
  endDate?: string;
  gt?: number;
  lt?: number;
  eq?: number;
  membership?: number[];
  page?: number;
  limit?: number;
  sort?: any;
  tz: string;
}) {
  const result = useQuery({
    queryKey: reportQueryKeys.eventAttendance({
      startDate,
      endDate,
      gt,
      lt,
      eq,
      membership,
      page,
      limit,
      sort,
    }),
    queryFn: async () => {
      const utcStart = zonedTimeToUtc(startDate as string, tz);
      const utcEnd = zonedTimeToUtc(endDate as string, tz);
      const result = await api.memberApi.eventAttendance({
        startDate: utcStart,
        endDate: utcEnd,
        gt,
        lt,
        eq,
        membership,
        page,
        limit,
        sort,
      });
      return result.data as EventAttendanceDTO;
    },
    placeholderData: () => {
      return {
        userMemberEventAttendanceDTOPage:
          defaultPage<UserMemberEventAttendanceDTO>(),
        attendanceTotal: 0,
      };
    },
    select: (data: EventAttendanceDTO) => {
      const dto = merge({}, data.userMemberEventAttendanceDTOPage, {
        content: data.userMemberEventAttendanceDTOPage.content.map(
          ({ lastAttended, ...rest }) => {
            return {
              lastAttended: lastAttended && utcToZonedTime(lastAttended, tz),
              ...rest,
            };
          },
        ),
      });
      return {
        ...data,
        userMemberEventAttendanceDTOPage: dto,
      };
    },
    enabled: !!startDate && !!endDate && !!tz,
  });
  return result;
}
