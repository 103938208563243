import { useAbility } from "@casl/react";
import { useClubFeatureFlags } from "@gymflow/api";

import PillTabsLayout from "../components/molecules/PillTabsLayout";
import {
  Integrations,
  StripePaymentSettings,
  WebsiteSettings,
} from "../components/pages";
import { ClassesTab } from "../components/Settings/Activity/ClassesTab";
import { AppointableTable } from "../components/Settings/Appointable/AppointableTable";
import { ClubInfo } from "../components/Settings/ClubInfo/ClubInfo";
import { FacilitiesTab } from "../components/Settings/Facility/FacilitiesTab";
import { Forms } from "../components/Settings/Forms/Forms";
import { HostedPages } from "../components/Settings/Hosted/HostedPages";
import { MembershipsTab } from "../components/Settings/Membership/MembershipsTab";
import { ProductsTab } from "../components/Settings/Product/ProductsTab";
import { RuleForm } from "../components/Settings/Rule/RuleForm";
import { CreditPackTab } from "../components/Settings/Sessions/CreditPackTab";
import { SettingsRoute } from "../components/Settings/SettingsRoute";
import { StaffTable } from "../components/Settings/Staff/StaffTable";
import { usePortalRoutes } from "../hooks/usePortalRoutes";
import { AbilityContext, Subject, Verb } from "../permissions";
import { useClubSettings } from "../providers";
import { RouteFeature } from "../routes/feature";
import { RouteLayout } from "../routes/layout";
import useGymflowModels from "../store";

export function Settings() {
  const { createClubLink } = usePortalRoutes();
  const ability = useAbility(AbilityContext);

  const { clubId } = useClubSettings();
  const { api } = useGymflowModels();
  const { data: featureFlags } = useClubFeatureFlags({ api, clubId });

  const layoutConfig = [
    {
      text: "Club",
      path: createClubLink(
        RouteLayout.Staff,
        RouteFeature.Settings,
        SettingsRoute.Club,
      ),
      component: ClubInfo,
    },
    {
      text: "Memberships",
      path: createClubLink(
        RouteLayout.Staff,
        RouteFeature.Settings,
        SettingsRoute.MembershipType,
      ),
      component: MembershipsTab,
    },
    {
      text: "Credits",
      path: createClubLink(
        RouteLayout.Staff,
        RouteFeature.Settings,
        SettingsRoute.Sessions,
      ),
      component: CreditPackTab,
    },
    {
      text: "Products",
      path: createClubLink(
        RouteLayout.Staff,
        RouteFeature.Settings,
        SettingsRoute.Product,
      ),
      component: ProductsTab,
    },
    {
      text: "Payments",
      path: createClubLink(
        RouteLayout.Staff,
        RouteFeature.Settings,
        SettingsRoute.StripePayments,
      ),
      component: StripePaymentSettings,
      invisible:
        !featureFlags?.featureFlags.STRIPE_CONNECTED_ACCOUNTS ||
        (ability.cannot(Verb.View, Subject.StripeConnect) &&
          ability.cannot(Verb.Create, Subject.StripeConnect)),
    },
    {
      text: "Facilities",
      path: createClubLink(
        RouteLayout.Staff,
        RouteFeature.Settings,
        SettingsRoute.Facility,
      ),
      component: FacilitiesTab,
    },
    {
      text: "Classes",
      path: createClubLink(
        RouteLayout.Staff,
        RouteFeature.Settings,
        SettingsRoute.Activity,
      ),
      component: ClassesTab,
    },
    {
      text: "Appointments",
      path: createClubLink(
        RouteLayout.Staff,
        RouteFeature.Settings,
        SettingsRoute.Appointable,
      ),
      component: AppointableTable,
      invisible: !ability.can(Verb.View, Subject.Appointments),
    },
    {
      text: "Staff",
      path: createClubLink(
        RouteLayout.Staff,
        RouteFeature.Settings,
        SettingsRoute.Staff,
      ),
      component: StaffTable,
      invisible: !ability.can(Verb.View, Subject.StaffAccount),
    },
    {
      text: "Rules",
      path: createClubLink(
        RouteLayout.Staff,
        RouteFeature.Settings,
        SettingsRoute.Rule,
      ),
      component: RuleForm,
    },
    {
      text: "Website",
      path: createClubLink(
        RouteLayout.Staff,
        RouteFeature.Settings,
        SettingsRoute.Hosted,
      ),
      component: featureFlags?.featureFlags.WEBSITE_SETTINGS_REDESIGN
        ? WebsiteSettings
        : HostedPages,
    },
    {
      text: "Forms",
      path: createClubLink(
        RouteLayout.Staff,
        RouteFeature.Settings,
        SettingsRoute.Forms,
      ),
      component: Forms,
    },
    {
      text: "Integrations",
      path: createClubLink(
        RouteLayout.Staff,
        RouteFeature.Settings,
        SettingsRoute.Integrations,
      ),
      component: Integrations,
    },
  ];
  return (
    <div className="flex h-full max-h-full w-full p-4 lg:p-8">
      <PillTabsLayout tabs={layoutConfig} />
    </div>
  );
}
