import useGymflowModels from "../store";

/**
 * @deprecated
 */
const usePromotionsLoadOptions = () => {
  const { api } = useGymflowModels();
  const loadOptions = async (_, __, { page }) => {
    const results = await api.promotionApi.find({ page });
    const options = results.data.content.map(({ id, code }) => ({ value: { id, code }, label: code }));

    if (page === 0) {
      options.unshift({
        label: "All",
        value: null,
      });
    }

    return {
      options,
      hasMore: !results.data.last,
      additional: {
        page: page + 1,
      },
    };
  };
  return loadOptions;
};

export default usePromotionsLoadOptions;
