import { createContext } from "react";

interface ReportValue {
  readonly total: number;
  readonly variation?: number;
}

interface ReportContextProperties {
  readonly isLoading: boolean;
  readonly startDate?: string;
  readonly endDate?: string;
  readonly revenue?: {
    readonly total?: ReportValue;
    readonly membership?: ReportValue;
    readonly sessionPack?: ReportValue;
    readonly product?: ReportValue;
    readonly creditsApplied?: ReportValue;
    readonly writeOff?: ReportValue;
    readonly refunds?: ReportValue;
    readonly awaitingAuth?: ReportValue;
  };
  readonly members?: {
    readonly membershipsSold?: ReportValue;
    readonly ending?: ReportValue;
    readonly growth?: ReportValue;
    readonly total?: ReportValue;
    readonly active?: ReportValue;
    readonly overdue?: ReportValue;
    readonly paused?: ReportValue;
    readonly pending?: ReportValue;
    readonly pausing?: ReportValue;
    readonly resuming?: ReportValue;
  };
  readonly leads?: {
    readonly created?: ReportValue;
    readonly opened?: ReportValue;
    readonly closed?: ReportValue;
    readonly lost?: ReportValue;
    readonly closeRatio?: ReportValue;
  };
  readonly events?: {
    readonly attendance?: ReportValue;
    readonly bookingPercentage?: ReportValue;
    readonly classesRun?: ReportValue;
    readonly noShows?: ReportValue;
    readonly lateCancellations?: ReportValue;
  };
}

export const ReportContext = createContext<ReportContextProperties>({
  isLoading: false,
});
