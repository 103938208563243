import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { memberQueryKeys } from './memberQueryKeys';

export function useMemberTrainer(
  {
    api,
  }: {
    api: {
      memberApi: {
        assignStaff: (memberId: string, staffId: string) => Promise<any>;
        unassignStaff: (memberId: string, staffId: string) => Promise<any>;
      };
    };
  },
  mutationOpts?: UseMutationOptions<
    void,
    unknown,
    { memberId: string; clubId: number; staffId: string }
  >,
) {
  const queryClient = useQueryClient();

  const assignMutation = useMutation({
    mutationFn: async ({
      memberId,
      clubId,
      staffId,
    }: {
      memberId: string;
      clubId: number;
      staffId: string;
    }) => {
      await api.memberApi.assignStaff(memberId, staffId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
    },
    ...mutationOpts,
  });

  const unassignMutation = useMutation({
    mutationFn: async ({
      memberId,
      clubId,
      staffId,
    }: {
      memberId: string;
      clubId: number;
      staffId: string;
    }) => {
      await api.memberApi.unassignStaff(memberId, staffId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
    },
    ...mutationOpts,
  });

  return { assignMutation, unassignMutation };
}
