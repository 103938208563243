import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor as LibMouseSensor,
  TouchSensor as LibTouchSensor,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  clubStaleTime,
  leadStatusListQueryFn,
  useClub,
  useInfiniteQueryLeadList,
  useLeadNote,
  useMutationLeadStatus,
  useMutationLeadStatusOrder,
  useNoteCreate,
  useQueryLeadStatus,
  useTaskCreate,
  useTaskDelete,
  useTaskEdit,
} from "@gymflow/api";
import {
  AlertContext,
  NotificationContext,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
} from "@gymflow/common";
import { cn } from "@gymflow/helpers";
import { LeadPipelineItem, PresetLeadStatusType } from "@gymflow/types";
import moment from "moment-timezone";
import {
  MouseEvent,
  TouchEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

import { usePortalRoutes } from "../../../hooks";
import { useEditOrCreateTask } from "../../../hooks/useEditOrCreateTask";
import useSendEmails from "../../../hooks/useSendEmails";
import { Can, Subject, Verb } from "../../../permissions";
import {
  ModalContext,
  useAuthenticatedUser,
  useClubSettings,
} from "../../../providers";
import { RouteFeature, RouteLayout } from "../../../routes";
import useGymflowModels from "../../../store";
import {
  Button,
  CheckDoneIcon,
  DotsVerticalIcon,
  FilePlusIcon,
  FilterIcon,
  MailIcon,
  PlusIcon,
} from "../../atoms";
import { NewUserSideBarProviderContext } from "../../molecules";
import { MemberCard } from "../../organisms";
import SendEmailAlertWithProvider from "../../UserMember/SendEmails/SendEmailAlert";
import { LaneActionsDropdown } from "./LaneActionsDropdown";
import { LeadCardActionsDropdown } from "./LeadCardActionsDropdown";
import { LeadCardActionsModal } from "./LeadCardActionsModal";
import { LeadPipelineActionsDropdown } from "./LeadPipelineActionsDropdown";
import {
  LeadFilters,
  LeadPipelineFilterSidebar,
} from "./LeadPipelineFilterSidebar";
import { LeadStatusFormModal } from "./LeadStatusFormModal";

export function LeadPipeline() {
  const { api } = useGymflowModels();
  const { data: originalLanes } = useQueryLeadStatus({ api });

  const { id: loggedInId } = useAuthenticatedUser();
  const { routeId } = usePortalRoutes();
  const [filters, setFilters] = useLocalStorage<LeadFilters>(
    buildFilterLocalStorageKey(loggedInId as string, routeId),
    {},
  );
  const lanesToShow = useMemo(() => {
    if (!originalLanes) {
      return undefined;
    }
    return originalLanes
      .filter((lane) => {
        if (filters?.leadStatus && filters.leadStatus.length > 0) {
          return !!filters.leadStatus.find(
            (status) => status.value === lane.id,
          );
        }
        return (
          lane.presetType !== "DEAL_CLOSED" && lane.presetType !== "DEAL_LOST"
        );
      })
      .sort((a, b) => {
        return a.statusOrder - b.statusOrder;
      });
  }, [filters.leadStatus, originalLanes]);

  const { timezone } = useClubSettings();
  const {
    data: originalLeads,
    hasNextPage: hasMoreLeads,
    fetchNextPage: fetchMoreLeads,
  } = useInfiniteQueryLeadList(
    {
      api,
      opts: {
        includeNotCompleteTasks: true,
        leadStatusId: lanesToShow ? lanesToShow.map((lane) => lane.id) : [],
        leadSourceId: filters?.leadSource
          ? filters.leadSource.map((source) => source.value)
          : undefined,
        createdFrom: filters?.createdFrom
          ? moment(filters.createdFrom, "YYYY-MM-DD")
              .startOf("day")
              .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ)
          : undefined,
        createdTo: filters?.createdTo
          ? moment(filters.createdTo, "YYYY-MM-DD")
              .endOf("day")
              .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ)
          : undefined,
      },
      tz: timezone,
    },
    { enabled: !!lanesToShow },
  );

  useEffect(() => {
    if (hasMoreLeads) {
      fetchMoreLeads();
    }
  }, [hasMoreLeads, originalLeads?.pageParams.length, fetchMoreLeads]);

  const {
    changeLeadStatusMutation: { mutateAsync: changeStatus },
  } = useMutationLeadStatus({ api });
  const { mutateAsync: changeOrder } = useMutationLeadStatusOrder({ api });

  const [lanes, setLanes] = useState<Lane[]>([]);

  const { notifyDanger } = useContext(NotificationContext);

  const [isDraggingLead, setIsDraggingLead] = useState(false);
  const onDragEnd = useCallback(
    async (event: DragEndEvent) => {
      if (event.over) {
        if (event.active.data.current?.["objectType"] === "lead") {
          setIsDraggingLead(false);
          try {
            const overData = event.over.data.current as DragEventLaneData;
            const activeData = event.active.data.current as DragEventLeadData;
            if (activeData.leadStatusId === overData.leadStatusId) {
              return;
            }
            await changeStatus({
              newColumn: overData.leadStatusId,
              leadId: activeData.leadId,
            });
            const oldLane = lanes.find(
              (lane) => lane.id === activeData.leadStatusId,
            );
            const newLane = lanes.find(
              (lane) => lane.id === overData.leadStatusId,
            );

            if (oldLane && newLane) {
              const leadToMove = oldLane.leads.find(
                (lead) => lead.leadId === activeData.leadId,
              );
              if (leadToMove) {
                setLanes(
                  lanes.map((lane) => {
                    if (lane.id === oldLane.id) {
                      lane.leads = lane.leads.filter(
                        (lead) => lead.leadId !== leadToMove.leadId,
                      );
                    }
                    if (lane.id === newLane.id) {
                      lane.leads.push(leadToMove);
                    }
                    return lane;
                  }),
                );
              }
            }
          } catch (e) {
            notifyDanger(e);
          }
        } else if (event.active.data.current?.["objectType"] === "lane") {
          const overData = event.over.data.current as DragEventLaneData;
          const activeData = event.active.data.current as DragEventLaneData;

          if (overData.isDraggable) {
            try {
              await changeOrder({
                statusId: activeData.leadStatusId,
                statusOrder: overData.order,
              });
            } catch (e) {
              notifyDanger(e);
            }
          }
        }
      }
    },
    [lanes],
  );

  useEffect(() => {
    const lanes: Lane[] = [];

    if (lanesToShow && originalLeads) {
      lanesToShow.forEach((lane) => {
        lanes.push({
          id: lane.id,
          name: lane.name,
          order: lane.statusOrder,
          isDraggable: lane.editableOrder,
          presetType: lane.presetType,
          leads: originalLeads.pages
            .flatMap((t) => t.content)
            .filter((lead) => lead.leadStatusId === lane.id),
          isDeletable: lane.deletable,
        });
      });
    }

    setLanes(lanes);
  }, [lanesToShow, originalLeads]);
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const { open: openNewUserSideBar } = useContext(
    NewUserSideBarProviderContext,
  );
  const { setModal, hide: hideModal } = useContext(ModalContext);
  const [isFilterSideBarOpen, setIsFilterSideBarOpen] = useState(false);

  const [dealLostStatusId, setDealLostStatusId] = useState(-1);
  const [dealWonStatusId, setDealWonStatusId] = useState(-1);

  useEffect(() => {
    async function fetch() {
      const response = await leadStatusListQueryFn({ api });
      const dealWon = response.find((s) => s.presetType === "DEAL_CLOSED");
      if (dealWon) {
        setDealWonStatusId(dealWon.id);
      }
      const dealLost = response.find((s) => s.presetType === "DEAL_LOST");
      if (dealLost) {
        setDealLostStatusId(dealLost.id);
      }
    }
    fetch();
  }, [api]);

  // TODO: Add Spinner
  return (
    <div className="mt-8 overflow-y-auto">
      <LeadPipelineFilterSidebar
        isVisible={isFilterSideBarOpen}
        onVisibilityChange={(newVisibility) => {
          setIsFilterSideBarOpen(newVisibility);
        }}
        value={filters}
        onChange={(newFilters) => {
          setFilters(newFilters);
        }}
      />
      <div className="flex justify-between px-8">
        <div className="flex flex-col justify-center">
          <div className="font-bold">Leads</div>
          <div className="text-gray-600">
            All prospective customers of the business.
          </div>
        </div>
        <div className="hidden items-center gap-3 lg:flex">
          <Button
            onClick={() => {
              setModal(<LeadStatusFormModal onClose={hideModal} />);
            }}
          >
            Add Step
          </Button>
          <Button
            onClick={() => {
              setIsFilterSideBarOpen(true);
            }}
          >
            <div className="flex items-center gap-2">
              <FilterIcon
                className="h-[1.125rem] w-[1.125rem]"
                pathClassName="stroke-gray-500"
              />
              <div>Filters</div>
            </div>
          </Button>
          <Button
            intent="secondary"
            onClick={() => {
              openNewUserSideBar({ creationMode: "LEAD" });
            }}
          >
            <div className="flex items-center gap-2">
              <PlusIcon
                className="h-[1.125rem] w-[1.125rem]"
                pathClassName="stroke-white"
              />
              <div>Add Lead</div>
            </div>
          </Button>
        </div>
        <div className="flex lg:hidden">
          <LeadPipelineActionsDropdown
            openFiltersSidebar={() => setIsFilterSideBarOpen(true)}
          />
        </div>
      </div>
      <DndContext
        onDragStart={(event) => {
          if (event?.active?.data?.current?.["objectType"] === "lead") {
            setIsDraggingLead(true);
          }
        }}
        onDragEnd={onDragEnd}
        sensors={sensors}
      >
        <div className="mt-6 flex min-h-[calc(100vh-10rem)] gap-4 overflow-auto px-8 pb-6">
          {lanes.map((lane) => {
            return (
              <StatusLane
                key={lane.id}
                statusId={lane.id}
                statusName={lane.name}
                presetType={lane.presetType}
                leads={lane.leads}
                order={lane.order}
                isDraggable={lane.isDraggable}
                isDeletable={lane.isDeletable}
                dealWonStatusId={dealWonStatusId}
                dealLostStatusId={dealLostStatusId}
                isCardBeingDragged={isDraggingLead}
              />
            );
          })}
        </div>
      </DndContext>
    </div>
  );
}

function StatusLane({
  statusId,
  statusName,
  order,
  leads = [],
  isDraggable,
  presetType,
  isDeletable,
  dealLostStatusId,
  dealWonStatusId,
  isCardBeingDragged,
}: {
  statusId: number;
  statusName: string;
  order: number;
  leads?: LeadPipelineItem[];
  isDraggable: boolean;
  presetType?: PresetLeadStatusType;
  isDeletable: boolean;
  dealLostStatusId: number;
  dealWonStatusId: number;
  isCardBeingDragged: boolean;
}) {
  const presetColors: {
    [Key in PresetLeadStatusType]?: {
      lane: string;
      badge: string;
      badgeCircle: string;
    };
  } = {
    NEW_LEAD: { lane: "#F8F9FC", badge: "#EAECF5", badgeCircle: "#B3B8DB" },
  };

  const { setNodeRef: setDroppableNodeRef, over: hovering } = useDroppable({
    id: `lead-status-${statusId}`,
    data: {
      leadStatusId: statusId,
      order,
      isDraggable,
      objectType: "lane",
    } satisfies DragEventLaneData,
  });
  const {
    setNodeRef: setDraggableNodeRef,
    listeners,
    attributes,
    transform,
    active,
  } = useDraggable({
    id: `lead-lane-${statusId}`,
    data: {
      leadStatusId: statusId,
      order,
      isDraggable,
      objectType: "lane",
    } satisfies DragEventLaneData,
    disabled: !isDraggable,
  });

  const style: any = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : {};
  let badgeStyle: any = {};
  let badgeCircleStyle: any = {};
  if (presetType && presetColors[presetType]) {
    style["background"] = presetColors[presetType].lane;
    badgeStyle["background"] = presetColors[presetType].badge;
    badgeCircleStyle["border-color"] = presetColors[presetType].badgeCircle;
  } else {
    style["background"] = "#F8F9FC";
    badgeStyle["background"] = "#EAECF5";
    badgeCircleStyle["border-color"] = "#B3B8DB";
  }
  const isNotEditablePreset = [
    "NEW_LEAD",
    "DEAL_CLOSED",
    "DEAL_LIST",
    "TRIAL",
  ].includes(presetType || "");
  return (
    <div
      className={cn(
        "flex min-h-[calc(100%-6rem)] w-96 min-w-[25rem] flex-col gap-4 rounded-xl border p-4",
        {
          "border-2 border-dashed border-gray-300":
            (active?.data?.current as any)?.objectType === "lane",
        },
      )}
      ref={setDraggableNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <div
            style={badgeStyle}
            className="flex basis-auto items-center gap-2 rounded-xl px-3 py-1"
          >
            <div
              style={badgeCircleStyle}
              className="h-4 w-4 rounded-lg border-4"
            />
            <div>{statusName}</div>
          </div>
          <div className="font-semibold text-gray-500">{leads.length}</div>
        </div>
        <div
          className={cn("flex items-center", {
            hidden: isNotEditablePreset,
          })}
          data-no-dnd={true}
        >
          <LaneActionsDropdown
            leadStatusId={statusId}
            leadStatusName={statusName}
            isDeletable={isDeletable && !!leads.length}
          />
        </div>
      </div>
      <div
        className={cn("flex h-full flex-col gap-4", {
          "border-2 border-dashed border-gray-300": isCardBeingDragged,
        })}
        ref={setDroppableNodeRef}
      >
        {leads.map((lead) => {
          return (
            <LeadCard
              key={lead.leadId}
              lead={lead}
              dealWonStatusId={dealWonStatusId}
              dealLostStatusId={dealLostStatusId}
              disableInteractions={!!hovering}
            />
          );
        })}
      </div>
    </div>
  );
}

function LeadCard({
  lead,
  dealLostStatusId,
  dealWonStatusId,
  disableInteractions,
}: {
  lead: LeadPipelineItem;
  dealLostStatusId: number;
  dealWonStatusId: number;
  disableInteractions: boolean;
}) {
  const { setNodeRef, transform, listeners, attributes } = useDraggable({
    id: "lead-" + lead.leadId,
    data: {
      leadId: lead.leadId,
      leadStatusId: lead.leadStatusId,
      objectType: "lead",
    } satisfies DragEventLeadData,
  });
  const defaultStyle = { touchAction: "none" };

  const style = transform
    ? {
        ...defaultStyle,
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : defaultStyle;

  const { setAlert, hide } = useContext(AlertContext);
  const { clubId } = useClubSettings();
  const { api } = useGymflowModels();
  const { data: club } = useClub({ clubId, api }, { staleTime: clubStaleTime });

  const { sendEmails } = useSendEmails();

  const { mutateAsync: deleteTask } = useTaskDelete({ api });
  const { timezone } = useClubSettings();
  const { mutateAsync: createTask } = useTaskCreate({ api, tz: timezone });
  const { mutateAsync: editTask } = useTaskEdit({ api, tz: timezone });
  const { mutateAsync: createMemberNote } = useNoteCreate({ api });
  const {
    createLeadNoteMutation: { mutateAsync: createLeadNote },
  } = useLeadNote({ api });
  const { notify, notifyDanger } = useContext(NotificationContext);

  const { setModal, hide: hideModal } = useContext(ModalContext);
  const [isHoverOpen, setIsHoverOpen] = useState(false);
  const isDragging = !!transform;

  const { createClubLink } = usePortalRoutes();
  const history = useHistory();

  const { setEditingTaskId } = useEditOrCreateTask();
  return (
    <div
      className="flex flex-col gap-4 rounded-lg border border-gray-100 bg-white p-4 drop-shadow"
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      <div className="flex items-center justify-between gap-2">
        <div className="flex gap-2">
          <div className="flex max-w-[14rem] flex-col">
            <div>
              <div
                className="inline-block"
                onMouseOver={() => {
                  if (isDragging || disableInteractions) {
                    return;
                  }
                  setIsHoverOpen(true);
                }}
                onMouseLeave={() => setIsHoverOpen(false)}
              >
                <MemberCard
                  isOpen={isHoverOpen}
                  leadId={lead.leadId}
                  memberId={lead.userMemberId}
                >
                  <div
                    className="overflow-y-hidden text-ellipsis font-bold"
                    onClick={() => {
                      if (lead.userMemberId) {
                        history.push(
                          createClubLink(
                            RouteLayout.Staff,
                            RouteFeature.UserMember.replace(
                              ":id",
                              lead.userMemberId,
                            ),
                          ),
                        );
                      } else {
                        history.push(
                          createClubLink(
                            RouteLayout.Staff,
                            RouteFeature.LeadProfile.replace(
                              ":id",
                              lead.leadId.toString(),
                            ),
                          ),
                        );
                      }
                    }}
                  >{`${lead.firstName} ${lead.lastName}`}</div>
                </MemberCard>
              </div>
            </div>

            <div className="overflow-hidden text-ellipsis font-normal text-gray-700">
              {lead.email}
            </div>
          </div>
        </div>
        <div className="flex gap-2" data-no-dnd={true}>
          <Can I={Verb.Create} a={Subject.Email}>
            <Button
              className="mt-0 hidden px-3 py-0 lg:block"
              onClick={async () => {
                // TODO: Refactor this so that the email sending logic is handled by the email creation component and not it's parent, similar to the strategy for note creation
                setAlert(
                  <SendEmailAlertWithProvider
                    allowMarketing={lead.emailCommunication}
                    from={club?.email!}
                    to={`${lead.firstName} ${lead.lastName}`}
                    onSubmit={(values: any) => {
                      const emailRecipientList = [];
                      if (lead.userMemberId) {
                        emailRecipientList.push({
                          userMemberId: lead.userMemberId,
                        });
                      } else {
                        emailRecipientList.push({
                          leadId: lead.leadId,
                        });
                      }

                      const bcc = values.bcc ? values.bcc.split(",") : [];
                      return sendEmails(
                        values.subject,
                        values.body,
                        emailRecipientList,
                        values.marketing,
                        bcc,
                      );
                    }}
                    onCancel={hide}
                  />,
                );
              }}
            >
              <MailIcon className="h-6 w-6" pathClassName="stroke-gray-700" />
            </Button>
          </Can>
          <LeadCardActionsDropdown
            className="hidden lg:inline-block"
            dealLostStatusId={dealLostStatusId}
            dealWonStatusId={dealWonStatusId}
            leadId={lead.leadId}
          />
          <Button
            className="mt-0 block px-3 py-0 lg:hidden"
            onClick={() => {
              setModal(
                <LeadCardActionsModal
                  onCancel={hideModal}
                  userMemberId={lead.userMemberId}
                  leadId={lead.leadId}
                  firstName={lead.firstName}
                  lastName={lead.lastName}
                  emailCommunication={lead.emailCommunication}
                  leadSourceName={lead.leadStatusName}
                />,
              );
            }}
          >
            <DotsVerticalIcon
              className="h-6 w-6"
              pathClassName="stroke-gray-700"
            />
          </Button>
        </div>
      </div>
      <div
        className={cn(
          "hidden flex-col gap-4 border-t border-t-gray-100 pt-5 lg:flex",
          { "lg:hidden": isDragging },
        )}
        data-no-dnd={true}
      >
        {lead.leadTasks && (
          <div className="flex flex-col gap-2">
            {lead.leadTasks.map((task) => {
              return (
                <div className="flex justify-between gap-4" key={task.taskId}>
                  <div className="flex gap-4">
                    <input
                      defaultChecked={false}
                      type="checkbox"
                      className="text-secondary-600 focus:ring-secondary-600 h-4 w-4 border-gray-300"
                      onChange={async () => {
                        try {
                          await editTask({
                            taskId: task.taskId,
                            patchedFields: { complete: true },
                          });
                        } catch (e) {
                          notifyDanger(e);
                        }
                      }}
                    />

                    <div
                      className="mb-1"
                      onClick={() => {
                        //@ts-ignore
                        setEditingTaskId(task.taskId);
                      }}
                    >
                      {task.taskName}
                    </div>
                  </div>
                  <div className="mr-2">
                    <FontAwesomeIcon
                      onClick={async () => {
                        try {
                          await deleteTask(task.taskId);
                        } catch (e) {
                          notifyDanger(e);
                        }
                      }}
                      className="cursor-pointer text-xl text-gray-400 hover:text-gray-500"
                      icon={faClose}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className={cn("flex gap-2", { hidden: isDragging })}>
          <CheckDoneIcon pathClassName="stroke-gray-500" />
          <input
            className="flex-1 outline-none"
            type="text"
            placeholder="Add Task..."
            onKeyDown={async (e) => {
              e.stopPropagation();
              if (e.key === "Enter") {
                e.preventDefault();
                const payload: {
                  name: string;
                  relatedUserIdList?: string[];
                  relatedLeadIdList?: number[];
                } = { name: e.currentTarget.value };
                if (lead.userMemberId) {
                  payload.relatedUserIdList = [lead.userMemberId];
                } else {
                  payload.relatedLeadIdList = [lead.leadId];
                }
                e.currentTarget.value = "";
                try {
                  await createTask(payload);
                } catch (e) {
                  notifyDanger(e);
                }
              }
            }}
          />
        </div>
        <div className={cn("flex gap-2", { hidden: isDragging })}>
          <FilePlusIcon pathClassName="stroke-gray-500" />
          <input
            className="flex-1 outline-none"
            type="text"
            placeholder="Add Note..."
            onKeyDown={async (e) => {
              e.stopPropagation();
              if (e.key === "Enter") {
                e.preventDefault();
                try {
                  const content = e.currentTarget.value;
                  e.currentTarget.value = "";
                  e.currentTarget.blur();
                  if (lead.userMemberId) {
                    createMemberNote({
                      fields: {
                        userMemberId: lead.userMemberId,
                        content,
                      },
                    });
                  } else {
                    createLeadNote({
                      newNote: {
                        leadId: lead.leadId,
                        content,
                      },
                    });
                  }

                  notify({ message: "Note added." });
                } catch (e) {
                  notifyDanger(e);
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

interface Lane {
  id: number;
  name: string;
  order: number;
  leads: LeadPipelineItem[];
  presetType?: PresetLeadStatusType;
  isDraggable: boolean;
  isDeletable: boolean;
}

interface DragEventLeadData {
  objectType: "lead";
  leadId: number;
  leadStatusId: number;
}

interface DragEventLaneData {
  objectType: "lane";
  leadStatusId: number;
  order: number;
  isDraggable: boolean;
}

// Block DnD event propagation if element have "data-no-dnd" attribute
const handler = ({ nativeEvent: event }: MouseEvent | TouchEvent) => {
  let cur = event.target as HTMLElement;

  while (cur) {
    if (cur.dataset && cur.dataset["noDnd"]) {
      return false;
    }
    cur = cur.parentElement as HTMLElement;
  }

  return true;
};

export class MouseSensor extends LibMouseSensor {
  static override activators = [
    { eventName: "onMouseDown", handler },
  ] as (typeof LibMouseSensor)["activators"];
}

export class TouchSensor extends LibTouchSensor {
  static override activators = [
    { eventName: "onTouchStart", handler },
  ] as (typeof LibTouchSensor)["activators"];
}

function buildFilterLocalStorageKey(userId: string, routeId: string) {
  return `lead-list-filter-${userId}-${routeId}`;
}
