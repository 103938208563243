import {
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
  PARAMETER_DATE_ONLY_FORMAT,
  PARAMETER_TIME_FORMAT,
} from "@gymflow/common";
import { utcToZonedTime } from "@gymflow/helpers";
import moment from "moment";

export const deadlineDatesToZonedTime = (
  {
    deadlineDate,
    deadlineTime,
  }: { deadlineDate: string; deadlineTime: string },
  tz: string,
) => {
  if (deadlineDate && deadlineTime) {
    const deadline = utcToZonedTime(`${deadlineDate}T${deadlineTime}Z`, tz);

    const result = {
      deadlineDate: moment(deadline, PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
        PARAMETER_DATE_ONLY_FORMAT,
      ),
      deadlineTime: moment(deadline, PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
        PARAMETER_TIME_FORMAT,
      ),
    };

    return result;
  }

  return undefined;
};
